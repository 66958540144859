<template>
  <div class="SidebarService sidebar-item">
    <i class="iconfont icon-a-sidekefu3" @click="navigateTo" />

    <div class="explain-box">

      <div class="service-list">
        <div class="service-item">
          <i class="iconfont icon-onlinekefu" />
          <div class="service-item-content">
            <div class="name">在线客服</div>
            <div class="date">工作日：9:00-18:00</div>
            <div class="service-btn title-wrap"  @click="navigateTo">
              在线咨询
<!--              <div class="dropbox" >
                <div class="dropbox-item">
                  {{ ' 春节放假时间：1月16日-1月29日' }}
                </div>
              </div>-->
            </div>
          </div>
        </div>

<!--        <div class="service-item service-item-tel">
          <i class="iconfont icon-tel" />
          <div class="service-item-content">
            <div class="name">电话客服</div>
            <div class="date">工作日：9:00-18:00</div>
           <div class="date date2">TEL：021-38760461</div>
          </div>
        </div>-->
      </div>


    </div>
  </div>
</template>

<script>
import {fnClipboard, fnRouter} from "@/fn";
import {PAGE_LINK} from "@/constant/PAGE_PATH";

export default {
  name: "SidebarService",
  data(){
    return{
      qq: '850284905'
    }
  },
  methods:{
    copyUid(){
      fnClipboard.copy(this.qq)
    },
    navigateTo(){
      fnRouter.navigationTo(PAGE_LINK.SERVICE)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/components/common/sidebar/common/sidebar.scss";


.SidebarService{
  .explain-box{
    cursor: default;
    padding: 20px 0 20px 20px;

    width: 210px;
    //height: 216px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.1);
    border-radius: 4px;
    box-sizing: border-box;

    .service-list{
      .service-item{
        display: flex;
        margin-bottom: 20px;
        &:last-child{
          margin-bottom: 0;
        }
        &.service-item-tel{
          .date{
            margin-bottom: 6px !important;
          }
          .date2{
            margin: 0 !important;
          }
        }

        .iconfont{
          color: #00001A;
          font-size: 18px;
          margin-right: 12px;
        }

        .service-item-content{
          .name{
            font-size: 14px;
            color: #00001A;
            line-height: 18px;
          }
          .date{
            margin: 10px 0 8px;
            font-size: 13px;
            color: #424866;
            line-height: 18px;
          }
          .service-btn{
            width: 118px;
            height: 32px;
            background: #6969FF;
            border-radius: 16px;
            font-size: 13px;
            color: #FFFFFF;
            line-height: 32px;
            text-align: center;
            cursor: pointer;
            &:hover{
              background: #7878FF;
            }

          }
        }

      }

    }
  }
}

.title-wrap {
  color: #424866;
  position: relative;

  &:hover {
    .dropbox {
      display: block;
    }
  }

  .dropbox {
    @include Position(1, 5, 50%, 40px);
    transform: translateX(-50%);
    z-index: 9;
    background: #424866;
    border-radius: 8px;
    display: none;


    &:after {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #424866;
      @include Position(1, 5, 50%, -5px);
      transform: translateX(-50%) rotate(135deg);
    }

    .dropbox-item {
      //width: 200px;
      height: 28px;
      line-height: 28px;
      padding: 0px 8px;
      font-size: 12px;
      color: #FFFFFF;
      white-space: nowrap;

      &:last-child {
        margin-bottom: 0;
      }

    }
  }
}

</style>

