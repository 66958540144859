<template>
  <div class="HeaderLogin">
    <no-ssr>
      <div class="login-box _pointer">
        <div
          v-if="isLogin === false"
          class="text-nologin _hover-element"
          @mouseover="toggleShowCombobox(2,true)"
          @mouseleave="toggleShowCombobox(2,false)"
        >
        <span class="text-login" @click.stop="showLogin">
          登录/注册
          <span class="text-login-tip animate-bounce-down">
            首次登录即送10天免费下载
          </span>
        </span>
          <div class="CopyrightCombobox ">
            <CopyrightCombobox
              ref="CopyrightCombobox"
            />
          </div>
        </div>

        <div
          v-if="isLogin === true"
          class="avatar-box _hover-element"
          @mouseover="toggleShowCombobox(3,true)"
          @mouseleave="toggleShowCombobox(3,false)"
        >
          <a
            :href="USER_CENTER_DEFAULT"
            target="_blank"
            class="avatar-wrap"
            >
            <img
              :src="avatar"
              class="avatar"
              alt="">
          </a>
          <!-- 登录设备 -->
          <HeaderLoginDevice class="HeaderLoginDevice" />

          <div class="UserCombobox">
            <HeaderLoginUserCombobox
              :company-info-by-person="companyInfoByPerson"
              ref="UserCombobox"
            />
          </div>
        </div>

      </div>
    </no-ssr>
  </div>
</template>

<script>
import HeaderLoginDevice from './header-login-device';
import HeaderLoginUserCombobox from './header-login-user-combobox'
import {
   CopyrightCombobox
} from "@/components/home/index.js"
import {mapGetters} from "vuex";
import {fnLogin, fnRouter} from "@/fn";
import {getCompanyInfoByPerson} from "@/api/company/home";
import {USER_CENTER} from "@/constant/PATH";
import {USER_CENTER_DEFAULT} from "@/constant/_Public/Path";

export default {
  name: "HeaderLogin",
  components:{
    HeaderLoginDevice,
    CopyrightCombobox,
    HeaderLoginUserCombobox
  },
  computed:{
    ...mapGetters('user', ['isLogin', 'avatar']),
  },
  data(){
    return{
      companyInfoByPerson: {},
      USER_CENTER,
      USER_CENTER_DEFAULT
    }
  },
  mounted() {
  },
  methods:{
    toggleShowCombobox(type, flag, e, enter = false) {
      if (type === 1) {
        setTimeout(() => {
          if (this.$refs.searchComboBox != undefined) {
            this.$refs.searchComboBox.isShowCombobox = flag
          }
        }, 500)
      } else if (type === 2) {
        this.$refs.CopyrightCombobox.isShowCombobox = flag;
      } else if (type === 3) {
        this.$refs.UserCombobox.isShowCombobox = flag;
      } else if (type === 4) {
        this.$refs.VipMandateCombobox.isShowCombobox = flag;
      }

    },
    navigatorTo(path, target = '_blank') {
      let currentPath = this.$route.path
      // fnRouter.navigationTo(path,  (currentPath == '/' || currentPath == '') ? '_self':'blank')
      fnRouter.navigationTo(path, target)
    },

    showLogin(){
      fnLogin.loginIntercept(false);
    }
  }
}
</script>

<style lang="scss" scoped>

.HeaderLogin{
  position: relative;
  z-index: 98;
}
.login-box {
  @include LayoutFlex(1);
  position: relative;



  .UserCombobox {
    @include Position(1, 2, 0, 40px);
    z-index: 98;

    &:after{
      content:'';
      display: inline-block;
      width: 100%;
      height: 20px;
      //background: pink;
      @include Position(1,5,0,-10px);
    }

  }


  .text-nologin {
    line-height: 32px;
    color: #FFFFFF;
    letter-spacing: 1px;
    font-size: 16px;
    display: flex;
    position: relative;
    @include ElementHover(rgba(255, 255, 255, 0.75), '');

    .text-login {
      position: relative;
      width: 90px;
      height: 36px;
      background: #FFFFFF;
      border-radius: 20px;
      font-size: 14px;
      color: #6969FF;
      line-height: 36px;
      text-align: center;
      @include ElementHover(rgba(105, 105, 255, 0.9), rgba(255, 255, 255,0.9));

      .text-login-tip {
        font-size: 14px;
        //@include Position(1, 2, -84px, 30px);
        @include Position(1,5,-38px,38px);
        background-color: #F45CA4;
        color: #FFFFFF;
        display: inline-block;
        line-height: 38px;
        text-align: center;
        border-radius: 4px;
        @include BgImage(188px, 34px, 'login/icon_tip-bg.png');
      }

    }

    .CopyrightCombobox {
      @include Position(1, 2, -30px, 38px);
    }
  }

  .avatar-box {
    display: inline-block;
    width: 32px;
    height: 32px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 50%;
    position: relative;
    z-index: 88;

    &:after{
    content:'';
    display: inline-block;
    //border: 1px solid pink;
    padding: 10px;
    width: 130%;
    height: 120%;
    @include Position(1,5,-50%,-50%);


  }
    .avatar-wrap{
      position: relative;
      z-index: 10;
      display: inline-block;
    }

    .avatar {
      display: inline-block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }




  }

}
</style>
