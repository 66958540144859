/* store方法 */

const moduleFlag = true;
const methods = 'setUserCheckNewProtocol20240426';
const storePath = '_Privately/User/';

export const UserCheckNewProtocol20240426State = {
  userCheckNewProtocol20240426: true
}

export const setUserCheckNewProtocol20240426Mutations = {
  [methods](state, payload) {
    moduleFlag && (state.userCheckNewProtocol20240426 = payload)
  },

}

export function setUserCheckNewProtocol20240426(store, payload) {
  moduleFlag && store.commit(storePath + methods, payload);
}


