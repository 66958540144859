let storeName = "ChaoTu";
const localStorage = {
  // constructor() {
  //   /* 设置默认值 */
  //   // this.store = window.localStorage;
  //   // storeName = "ChaoTu";
  // },

  setItem(key, value) {
    let storeData = window.localStorage.getItem(storeName);
    if (!storeData) {
      window.localStorage.setItem(storeName, '{}');
      storeData = window.localStorage.getItem(storeName);
    }
    storeData = JSON.parse(storeData);
    storeData[key] = value;

    window.localStorage.setItem(storeName, JSON.stringify(storeData));
    return storeData;
  },

  setItemDate(key, value = 1) {
    let year = new Date().getFullYear()
    let date = new Date().getDate();
    let month = new Date().getMonth() + 1;
    return this.setItem(`${key}_${year}/${month}/${date}`, value);
  },

  setItemMore(obj) {
    let arr = Object.keys(obj);
    if (arr.length == 0) {
      return;
    }
    for (const key in obj) {
      this.setItem(key, obj[key]);
    }
  },

  removeItemDate(key){
    let year = new Date().getFullYear()
    let date = new Date().getDate();
    let month = new Date().getMonth() + 1;
    this.remove(`${key}_${year}/${month}/${date}`)
  },


  getItem(key) {
    let result = this.getItemAll();
    if (!result) {
      return null;
    }
    return result[key];
  },

  /* 获取一个带有日期的key */
  getItemDate(key) {
    let year = new Date().getFullYear()
    let date = new Date().getDate();
    let month = new Date().getMonth() + 1;
    return this.getItem(`${key}_${year}/${month}/${date}`);
  },


  getItemMore() {
    let result = {}
    const allLocal = this.getAll()
    if (!allLocal) {
      return result
    }
    for (let i = 0; i < arguments.length; i++) {
      const item = arguments[i]
      result[item] = allLocal[item]
    }
    return result
  },

  // 获取全部
  getItemAll() {
    let storeData = window.localStorage.getItem(storeName);
    if (!storeData) {
      return null;
    }
    storeData = JSON.parse(storeData);
    return storeData;
  },

  // 删除一个
  remove(key) {
    let storeData = window.localStorage.getItem(storeName);
    if (!storeData) {
      return;
    }
    storeData = JSON.parse(storeData);
    delete storeData[key];
    window.localStorage.setItem(storeName, JSON.stringify(storeData));
    return storeData;
  },

  // 清除
  clear() {
    window.localStorage.removeItem(storeName);
  }

}

export default localStorage;


