<template>
  <div class="home-layout">
    <no-ssr>
<!--      <ActGuoQingBar />-->
      <actBar @close="getPlaceholderHeight" />
    </no-ssr>
<!--    <ActTwelveTopBar />-->
<!--    <actBar />-->
    <nuxt/>
    <GlobalSlot/>
    <HomeFooter/>
    <no-ssr>
      <Login/>
       <actFloatBar />
    </no-ssr>

    <Sidebar/>

    <PublicSlot/>
  </div>
</template>

<script>
import {LoginHome, Login} from "@/components/common/index.js"
import {TopUpHomeBar} from "@/components/act/top-up";
import FeedbackBuoy from '@/components/common/feedback-buoy/feedback-buoy'
import Sidebar from '@/components/common/sidebar'
import GlobalSlot from '@/components/common/global-slot';
import HomeFooter from '@/components/home/home-footer'
import ActGuoQingBar from "@/components/act/guoqing/ActGuoQingBar";
import PublicSlot from "@/components/_Public/PublicSlot";
// import actBar from "@/pages/act/618/actBar/index.vue";
import actFloatBar from "@/pages/act/618/actBar/bar.vue";
import actBar from "~/pages/act/618/actBar/index.vue";
import Cookie from "js-cookie";
// import ActTwelveTopBar from "@/components/_Privately/Act/Act202312/ActTwelveTopBar";

export default {
  name: "Home",
  components: {
    actBar,
    // actBar,
    actFloatBar,
    Login,
    LoginHome,
    TopUpHomeBar,
    FeedbackBuoy,
    Sidebar,
    GlobalSlot,
    HomeFooter,
    ActGuoQingBar,
    PublicSlot,
    // ActTwelveTopBar
  },
  methods:{
    getPlaceholderHeight(){
      setTimeout(()=>{
        const element = this.$refs.headerWrap;
        const headerHeight = element.offsetHeight;
        /* 读取本地存储的头部高度，一致则跳过，否则覆盖 */
        const localHeaderHeight = Cookie.get("headerHeight");

        console.log({
          headerHeight,
          localHeaderHeight
        })
        this.$store.commit('_Public/Header/setHeaderHeight',headerHeight)
        if (headerHeight != localHeaderHeight){
          Cookie.set("headerHeight",headerHeight)
          this.placeholderHeight = headerHeight;
          this.$store.commit('_Public/Header/setHeaderHeight',headerHeight)

        }
      },300)
    },
  }
}
</script>
