<template>
  <!-- 用户登录下拉框-->
  <div class="user-combobox _user-select">
    <div class="user-panel">
      <div class="avatar-box">
        <div class="avatar-wrap">
          <NavigationTo :path="USER_CENTER">
            <img slot="content" :src="avatar" class="avatar" alt=""/>
          </NavigationTo>
          <!-- 登录设备 -->
          <HeaderLoginDevice v-if="accountType === COMMON.HEADER.AVATAR_COMBOBOX.ACCOUNT_TYPE.PERSON"/>
          <div class="company-role" v-else>成员</div>
        </div>

        <div class="user-box">
          <div class="user-name-box">
            <div class="user-name">潮国创意用户</div>
            <VipLabel/>
          </div>
          <div class="uid-box">
            <span class="uid"
            >潮国ID：<span class="clipboardBtn">{{ uniq_id }}</span></span
            >
            <i
              id="ClipboardBtn"
              class="icon_copy"
              :data-clipboard-text="uniq_id"
              @click="copyUid"
            />

            <div
              class="free-down"
              v-if="downloadCount.free_down_cnt - downloadCount.down_cnt > 0"
            >
              免费下载（{{
                downloadCount.free_down_cnt - downloadCount.down_cnt >
                downloadCount.day_free_limit
                  ? downloadCount.day_free_limit
                  : downloadCount.free_down_cnt - downloadCount.down_cnt > 0
                  ? downloadCount.free_down_cnt - downloadCount.down_cnt
                  : 0
              }}次/{{
                downloadCount.free_down_cnt - downloadCount.down_cnt > 0
                  ? downloadCount.free_down_cnt - downloadCount.down_cnt
                  : 0
              }}次）
            </div>
          </div>
          <div class="badge"/>
        </div>

        <div class="card-box">
          <div class="card-item company">
            <div class="title">企业VIP</div>
            <div class="desc">商用版权保障，授权书在线下载</div>
            <NuxtLink
              :to="PAGE_PATH.PAYMENT.COMPANY"
              target="_blank"
              class="btn"
              @click="tag('充值:头像下拉入口:图片')"
            >
              立即开通
            </NuxtLink>
            <i class="icon_vip" />
          </div>

          <div class="card-item business">
            <div class="title">个人VIP</div>
            <div class="desc">
              {{ personCase }}
            </div>

            <div class="vip-status">
              <a
                :href="PAY_PERSON"
                target="_blank"
                v-if="personVipInfo.vip != 6"
                class="btn"
                @click="tag('充值:头像下拉入口:图片')"
              >
                {{ personVipState === VIP_STATE.ON ? "续费" : "立即开通" }}
              </a>

              <a
                :href="PAGE_PATH.BASE_URL + PAGE_PATH.PAYMENT.UPGRADE"
                target="_blank"
                v-if="
                  personVipInfo.vip != 6 && personVipInfo.remaining_days <= 365
                "
                class="btn"
              >
                {{ personVipState === VIP_STATE.ON ? "升级" : "" }}
              </a>

              <!-- 普通终身 -->
              <template v-if="personVipInfo.vip == 6">
                <a
                  :href="
                    personVipState == VIP_STATE.ON
                      ? setUrl(payment.UPGRADE)
                      : PAY_PERSON
                  "
                  target="_blank"
                  class="btn"
                >
                  {{ personVipState == VIP_STATE.ON ? "升级" : "立即开通" }}
                </a>
              </template>
            </div>

            <i class="icon_vip" />
          </div>
          <!--          <div class="card-item business">
                      <div class="title">商用VIP</div>
                      <div class="desc">
                        {{ businessVipState === VIP_STATE.ON ? filterTime(businessVipInfo.end_time) + '到期' : '海量图片免费下' }}
                      </div>

                      <div class="vip-status">
                          <a
                            :href="PAGE_PATH.BASE_URL + PAGE_PATH.PAYMENT.BUSINESS"
                            target="_blank"
                            class="btn" @click="tag('充值:头像下拉入口:图片')">
                            {{ businessVipState === VIP_STATE.ON ? '续费' : '立即开通' }}
                          </a>

                          <a
                            v-if="businessVipInfo.remaining_days <= 365"
                            :href="PAGE_PATH.BASE_URL + PAGE_PATH.PAYMENT.UPGRADE"
                            target="_blank"
                            class="btn">
                            {{ businessVipState === VIP_STATE.ON ? '升级' : '' }}
                          </a>

                      </div>
                    </div>-->
        </div>
      </div>
    </div>

    <!--  会员到期提示和过期提示  -->
    <div class="VipNotification-wrap">
      <VipNotification class="VipNotification"/>
    </div>

    <div class="box">
      <!-- 若修改list常量，注意navTo事件需要同步修改 -->
      <a
        :href="vo.path"
        target="_blank"
        v-for="vo in list"
        :key="vo.name"
        class="box-item _pointer"
        @click.prevent="navTo(vo)"
      >
        <i
          class="icon"
          :class="[vo.iconClass, { _hide: vo.name === '领优惠券' }]"
        />
        <span class="name" :class="{ _hide: vo.name === '领优惠券' }"
        >{{ vo.name }}
          <i v-if="vo.name === '免费下载'" class="iconfont icon-question">
            <span class="dropbox">
              <span class="dropbox-item">
                您可在素材详情页将链接分享给好友，好友通过专属链接注册成功，即可免费下载此图。
              </span>
            </span>
          </i>
        </span>

        <!-- 小红点提示 -->
        <span v-if="vo.name === '宠粉专区'" class="red-circle"> </span>

        <!--  优惠券GIF -->
        <div v-if="vo.name === '领优惠券'" class="box-item-coupon">
          <i class="icon_coupon"/>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
//页面引入swiper
import {mapGetters, mapState} from "vuex";
import {
  COMPANY_INTRODUCE,
  INVITE_FRIENDS,
  MEMBER_LICENSE_VIP,
  PAY,
  PAY_BUSINESS,
  USER_CENTER,
} from "@/constant/PATH";
import {tag} from "@/utils/statUtil";
import {BUSINESS_VIP_INFO, PERSON_VIP_INFO, VIP_STATE} from "@/constant/VIP";
import {DEVICE} from "@/constant/SYSTEM";
import _ from "lodash";
import Clipboard from "clipboard";
import VipLabel from "@/components/user/vip-label";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import VipNotification from "@/components/user/vip-notification/indexV2";
import {fnInviteFriend, fnRouter} from "~/fn";

import HeaderLoginDevice from "@/components/common/header/header-login/header-login-device";
import {COMMON} from "@/constant/COMMON";
import {VIP_MANAGE} from "@/constant/VIP_MANAGE";
import {receiveCouponByUserCenter} from "@/api/user";
/* 引入公共方法和常量 */
import * as fnCommon from "@/fn/_fn";
import * as CON_COMMON from "@/constant/_constant";

import { PAY_PERSON } from "@/constant/_Public/Path"

const dayjs = require("dayjs");
export default {
  name: "HeaderLoginUserComboboxPerson",
  components: {
    VipLabel,
    VipNotification,
    HeaderLoginDevice,
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      list: COMMON.HEADER.AVATAR_COMBOBOX.USER_CENTER_NAV_LIST,
      isShowCombobox: false,
      MEMBER_LICENSE_VIP,
      USER_CENTER,
      INVITE_FRIENDS,
      PERSON_VIP_INFO,
      BUSINESS_VIP_INFO,
      DEVICE,
      PAY_BUSINESS,
      VIP_STATE,
      PAY,
      COMPANY_INTRODUCE,
      PAGE_PATH,
      accountType: COMMON.HEADER.AVATAR_COMBOBOX.ACCOUNT_TYPE.PERSON,
      COMMON,
      VIP_MANAGE,
      personForeverVipIsExpire: false, // 普通终身是否过期

      payment: PAGE_PATH.PAYMENT,

      PAY_PERSON
    };
  },
  computed: {
    ...mapGetters("user", [
      "name",
      "uid",
      "avatar",
      "uniq_id",
      "vipIsPerson",
      "vipIsPersonData",
      "vipIsBusiness",
      "vipIsBusinessData",
      "downloadCount",
    ]),
    ...mapState("system", ["device"]),
    ...mapState("user", [
      "personVipState",
      "personVipInfo",
      "businessVipState",
      "businessVipInfo",
      "companyVipState",
      "companyVipInfo",
    ]),

    /* 普通VIP 文案展示 */
    personCase() {
      let _text = "";
      let personVipState = this.personVipState;
      let personVipInfo = this.personVipInfo;

      if (personVipInfo.vip == 6) {
        _text = personVipState == VIP_STATE.ON ? "终身使用" : "海量图片免费下";
      } else {
        _text =
          personVipState == VIP_STATE.ON
            ? this.filterTime(personVipInfo.end_time) + "到期"
            : "海量图片免费下";
      }
      return _text;
    },

    /* 普通终身按钮跳转 */
  },
  methods: {
    setUrl(path) {
      return fnCommon.fnBasic.getChaopxFullLink(path);
    },

    init() {
      this.checkPersonForeverVipIsExpire();
    },
    navigationTo(path) {
      this.$router.push(path);
    },
    loginOut() {
      this.$store.dispatch("user/loginOut");
      this.$emit("loginOut");
    },
    tag(stat) {
      tag(stat);
    },

    copyUid: _.throttle(function () {
      let clipboard = new Clipboard("#ClipboardBtn");
      clipboard.on("success", (e) => {
        this.$myNotify.success("复制成功");
        // this.updateCopyStatus(1)
        // setTimeout(() => {
        //   this.updateCopyStatus(0)
        // }, 2000)

        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        this.$myNotify.show("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    }, 2000),

    filterTime(val) {
      return val.slice(0, 10) || "";
    },

    navigationToInvite() {
      fnInviteFriend.inviteFriendEntry(7);
    },

    navTo(item) {
      if (item.name === "领优惠券") {
        receiveCouponByUserCenter().then((res) => {
          let {status, data} = res.data;
          if (status == 200) {
            /*if (data.length) {
              this.$myNotify.success('成功领取优惠券', () => {
                fnRouter.navigationTo(item.path)
              }, 1500)
            } else {
              this.$myNotify.show('暂无优惠券可领', () => {
              }, 1500)
            }*/
            fnRouter.navigationTo(item.path, "_blank", true);
          }
        });
      } else {
        fnRouter.navigationTo(item.path, "_blank", true);
      }
    },

    /* 检查普通终身VIP是否过期 */
    checkPersonForeverVipIsExpire() {
      const currDate = dayjs(new Date());
      const currDate2 = dayjs(this.personVipInfo.end_time);
      this.personForeverVipIsExpire = currDate2.isBefore(currDate);
      return currDate2.isBefore(currDate);
    },
  },
  // eslint-disable-next-line vue/order-in-components
  filters: {
    filterTime(val) {
      return val.slice(0, 10) || "";
    },

    disposePersonForever(val) {
      const currDate = dayjs(new Date());
      const currDate2 = dayjs(val);
      const isTime1BeforeTime2 = currDate2.isBefore(currDate);

      return isTime1BeforeTime2 ? `海量图片免费下` : "终身使用";
    },
  },
};
</script>

<style lang="scss" scoped>
.VipNotification-wrap {
  margin: 0 28px 0 20px;

  /deep/ .VipNotification {
    //margin: 0 28px;
    margin: 0 auto;
    //text-align: center;
    border-radius: 0px 0px 12px 12px !important;
    padding-left: 8px;
    box-sizing: border-box;

    .icon-cha {
      margin-left: 12px !important;
    }
  }
}

@keyframes fadeinT {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.user-combobox {
  width: 480px;
  //height: 333px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 26, 0.08);
  border-radius: 12px;
  z-index: 10;
  //padding-bottom: 28px;
  //padding-bottom: 10px;
  animation: fadeinT 0.5s 1;
  overflow: visible !important;
  cursor: default;

  width: 424px;
  //height: 335px;
  background: #FFFFFF;
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.06), 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
  border-radius: 12px;


  .user-panel {
    width: 100%;
    padding: 28px 0 0 20px;
    box-sizing: border-box;
    height: 182px;
    background: linear-gradient(134deg, #f7f8fb 0%, #eceff6 100%);
    border-radius: 12px;
    position: relative;
    @include BgImage2(424px, 173px, "header/person-panel-bg.png");

    .avatar-box {
      display: flex;
      align-items: center;

      .avatar-wrap {
        width: 52px;
        height: 52px;
        position: relative;
        margin-right: 16px;

        .avatar {
          display: inline-block;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          border: 1px solid rgba(255, 255, 255, 0.6);
          margin-right: 16px;
          overflow: hidden;
        }

        .company-role {
          height: 18px;
          background: #ffffff;
          border-radius: 9px;
          color: $color-primary;
          font-size: 12px;
          padding: 0 6px;
          @include Position(1, 4, 50%, -8px);
          transform: translateX(-50%);
          display: inline-block;
          white-space: nowrap;
        }
      }

      .user-box {
        margin-top: 4px;

        .user-name-box {
          display: flex;
          align-items: center;

          .user-name {
            font-size: 16px;
            color: #2d2b33;
            margin-right: 8px;
          }


        }

        .uid-box{
          display: flex;
          align-items: center;
          margin-top: 6px;

          .uid {
            font-size: 12px;
            color: #596088;
            line-height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .icon_copy {
            @include BgImage(12px, 12px, "home/icon_copy.png");
            margin-left: 12px;
            position: relative;

            &:after {
              content: "";
              position: absolute;
              top: -10px;
              right: -10px;
              bottom: -10px;
              left: -10px;
            }
          }

          .free-down {
            font-size: 12px;
            color: #ed5700;
            line-height: 16px;
            margin-left: 16px;
          }

        }

      }

      .card-box {
        @include Position(1, 4, 24px, 0);
        right: 24px;
        @include LayoutFlex(3);

        .VipNotification {
          //@include Position(1, 4, 0, -26px);
        }

        @mixin CardItem(
          $titleColor: #ffffff,
          $btnTxtColor: transparent,
          $btnBgColor: rgba(255, 255, 255, 0.9),
          $btnHoverBgColor: rgba(255, 255, 255, 1)
        ) {
          padding: 8px 0 0 16px;
          box-sizing: border-box;
          position: relative;
          transition: all 0.5s ease-in-out;

          @include ElementHover("", "") {
          }
          .title {
            font-size: 14px;
            font-weight: bold;
            color: $titleColor;
            line-height: 20px;
          }
          .desc {
            font-size: 12px;
            color: $titleColor;
            line-height: 16px;
            margin: 2px 0 6px;
            font-weight: normal;
          }
          .btn {
            display: inline-block;
            padding: 0 8px;
            background: $btnBgColor;
            font-size: 12px;
            color: $btnTxtColor;
            line-height: 18px;
            border-radius: 4px;
            @include ElementHover("", $btnHoverBgColor);

            a {
              color: $btnTxtColor;
            }
          }

          .expire-tip {
            @include Position(1, 4, 0, -26px);
            display: flex;
            width: 293px;
            height: 28px;
            background: #424866;
            z-index: 3;
            font-size: 12px;
            color: #ffffff;
            @include LayoutFlex(1);
            border-radius: 4px;

            .remaining-days {
            }

            a {
              color: #efc78c;
              margin: 0 12px 0 8px;
            }

            .icon-cha {
              font-size: 10px;
            }
          }
        }

        .card-item {
          //@include LayoutFlex(2);
          overflow: hidden;

          &.person {
            //@include CardItem("card-person", #ffffff, #29bea9);


          }

          &.business {
            @include CardItem(#ffffff, #5c5cf2);
            width: 140px;
            height: 77px;
            background: #5E5EF4;
            border-radius: 4px 26px 0px 0px;
            position: relative;

            .icon_vip{
              @include IconVip(1,76,75);
              position: absolute;
              right: -25px;
              bottom: -24px;
              opacity: 0.3;
              filter: alpha(opacity=30);
              transform: rotate(-16deg);
            }


          }

          &.company {
            @include CardItem(
                #ffe198,
                #253f92,
                rgba(255, 225, 152, 0.9),
                rgba(255, 225, 152, 1)
            );

            width: 224px;
            height: 77px;
            background: #254092;
            border-radius: 4px 26px 0px 0px;

            .icon_vip{
              @include IconVip(2,76,75);
              position: absolute;
              right: -25px;
              bottom: -27px;
              opacity: 0.3;
              filter: alpha(opacity=30);
              transform: rotate(-16deg);
            }

          }
        }
      }

    }
  }

  .box {
    @include LayoutFlex(1);
    flex-wrap: wrap;
    margin: 10px 0 10px;

    .box-item {
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @include ElementHover("", #f5f6fa);
      position: relative;
      margin: 0 0 4px 0;
      width: 125px;
      height: 69px;

      &:hover {
        background: rgba(245, 246, 250, 0.5);
      }



      .box-item-coupon {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include Position(1, 5, 0, 0);

        .icon_coupon {
          @include BgImage(
              54px,
              54px,
              "common/sidebar/box-item-coupon.gif?v=2"
          );
          position: relative;
          top: 4px;
        }
      }

      &:nth-child(3n) {
        margin-right: 0;
      }

      &:nth-child(4) {
        .name {
          //padding-left: 20px;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      .icon {
        //margin-bottom: 10px;
        //margin-bottom: 8px;
      }

      span.name {
        font-size: 13px;
        color: #596088;
        //line-height: 17px;
        position: relative;

        .icon-question {
          font-size: 16px;
          color: rgba(89, 96, 128, 0.5);
          position: relative;
          top: 1px;
          cursor: pointer;

          &:hover {
            .dropbox {
              display: block;
            }
          }
        }

        .dropbox {
          @include Position(1, 5, 50%, 26px);
          transform: translateX(-50%);
          z-index: 9;
          background: #424866;
          border-radius: 8px;
          display: none;
          width: 167px;
          padding: 3px 10px 9px;

          &:after {
            content: "";
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 2px;
            background: #424866;
            @include Position(1, 5, 50%, -5px);
            transform: translateX(-50%) rotate(135deg);
          }

          .dropbox-item {
            font-size: 12px;
            color: #ffffff;
            line-height: 20px;
          }
        }
      }

      .red-circle {
        width: 10px;
        height: 10px;
        background: rgba(255, 22, 19, 0.1);
        border-radius: 50%;
        @include Position(1, 2, 46px, 6px);

        &:after {
          content: "";
          display: inline-block;
          width: 6px;
          height: 6px;
          background: #ff1613;
          @include Position(1, 5, 50%, 50%);
          transform: translate(-50%, -50%);
          border-radius: 50%;
        }
      }

      .icon_eyes {
        @include BgImage(24px, 24px, "home/images/user-combobox/icon_eyes.svg");
        margin-bottom: 8px;
      }

      .icon_collect {
        @include BgImage(
            24px,
            24px,
            "home/images/user-combobox/icon_collect.svg"
        );
        margin-bottom: 8px;
      }

      .icon_download {
        @include BgImage(
            24px,
            24px,
            "home/images/user-combobox/icon_download.svg"
        );
        margin-bottom: 8px;
      }

      .icon_user {
        @include BgImage(24px, 24px, "home/images/user-combobox/icon_user.svg");
        margin-bottom: 8px;
      }

      .icon_free_down {
        @include BgImage(
            24px,
            24px,
            "home/images/user-combobox/icon_free_down.svg"
        );
        margin-bottom: 5px;
      }

      .icon_coupon {
        @include BgImage(
            24px,
            24px,
            "home/images/user-combobox/icon_coupon.svg"
        );
        margin-bottom: 5px;
      }

      .icon_vip {
        @include BgImage(24px, 24px, "home/images/user-combobox/icon_vip.svg");
        margin-bottom: 5px;
      }

      .icon_command {
        @include BgImage(
            24px,
            24px,
            "home/images/user-combobox/icon_command.svg"
        );
        margin-bottom: 5px;
      }
    }
  }
}
</style>
