/* 图片列表页公共方法 */
import {materialKeywordTrack, toggleMaterialCollect} from "@/api/material";
import {parseListUrl,} from "@/components/list/module";
import {topbarMenuList} from "@/components/common/header/module";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import {MATERIAL_LIST_PATH} from "@/constant/PATH";
import {usageTypeList, menuList, typeList} from "@/components/list/module";
import {fnLogin} from "@/fn/index";
import {toggleTopicCollect} from "@/api/topic";

class FnTuPian {
  constructor() {
  }

  /* 素材列表keyword cookie埋点 */
  async keywordTrack(searchKeyword) {
    let res = await materialKeywordTrack({keyword: searchKeyword});
  }

  /* 处理二三级分类 */
  disposeClassify(data, tempParams, styleClassify) {
    let {
      pinyin,
      home_keyword_id,
      layout,
      time_filter,
      module,
      category,
      scene,
      page,
      desc,
      layer,
      categoryType,
      design,
      real_home_keyword_id
    } = tempParams;


    let {other_list, self_list} = data.module_list;
    other_list = Array.isArray(other_list) ? other_list : []
    self_list = Array.isArray(self_list) ? self_list : []

    let tempSelfList = tempParams.home_keyword_id == 0 ? [{
      id: 0,
      name: '全部',
      pid: tempParams.home_keyword_id
    }].concat(other_list) : [{id: 0, name: '全部', pid: tempParams.home_keyword_id}].concat(self_list)
    let tempOther_list = tempParams.home_keyword_id == 0 ? [] : other_list
    let category_list = (data.category_list && Array.isArray(data.category_list) == true) ? [{
      id: 0,
      name: '全部'
    }].concat(data.category_list) : [];
    let scene_list = (data.scene_list && Array.isArray(data.scene_list) == true) ? [{
      id: 0,
      name: '全部'
    }].concat(data.scene_list) : [];
    let style_list = (styleClassify && Array.isArray(styleClassify) == true) ? [{
      id: 0,
      name: '全部'
    }].concat(styleClassify) : [];

    /* 为分类增加跳转链接 */
    if (Array.isArray(tempSelfList) && tempSelfList.length) {
      tempSelfList.forEach(vo => {
        vo.link = MATERIAL_LIST_PATH + `/${pinyin}-${vo.pid}-0-${time_filter}-${vo.id}-0-0-default-1-0-0-0.html`;
      })
    }

    if (Array.isArray(tempOther_list) && tempOther_list.length) {
      tempOther_list.forEach(vo => {
        vo.link = MATERIAL_LIST_PATH + `/${pinyin}-${vo.pid}-0-${time_filter}-${vo.id}-0-0-${desc}-1-0-0-0.html`;
      })
    }

    if (Array.isArray(category_list) && category_list.length) {
      category_list.forEach(vo => {
        vo.link = MATERIAL_LIST_PATH + `/${pinyin}-${home_keyword_id}-${layout}-${time_filter}-${module}-${vo.id}-${scene}-${desc}-1-0-0-0.html`;
      })
    }

    /* 场景 */
    if (Array.isArray(scene_list) && scene_list.length) {
      scene_list.forEach(vo => {
        vo.link = MATERIAL_LIST_PATH + `/${pinyin}-${home_keyword_id}-${layout}-0-${module}-${category}-${vo.id}-${desc}-1-0-0-0.html`;
      })
    }

    if (Array.isArray(style_list) && style_list.length) {
      style_list.forEach(vo => {
        vo.link = MATERIAL_LIST_PATH + `/${pinyin}-${home_keyword_id}-${layout}-0-${module}-0-${scene}-${desc}-1-0-${vo.id}-1.html`;
      })
    }

    if (Array.isArray(typeList) && typeList.length) {
      typeList.forEach(vo => {

        if (layer != 0 && layer.indexOf(vo.id) > -1) {
          vo.isChecked = true;
        } else {
          vo.isChecked = false;
        }

        // vo.link = MATERIAL_LIST_PATH + `/${pinyin}-${home_keyword_id}-${layout}-0-${module}-${category}-${vo.id}-${desc}-1-0.html`;
      })
    }


    usageTypeList.forEach(vo => {
      if (categoryType == 0) {

        if (tempParams.real_home_keyword_id == 999) {
          vo.link = MATERIAL_LIST_PATH + `/${pinyin}-${tempParams.real_home_keyword_id}-${vo.id}-${time_filter}-${module}-${category}-${scene}-${desc}-1-${layer}-0-0.html`;
        } else {
          vo.link = MATERIAL_LIST_PATH + `/${pinyin}-${home_keyword_id}-${vo.id}-${time_filter}-${module}-${category}-${scene}-${desc}-1-${layer}-0-0.html`;
        }

      } else {
        vo.link = MATERIAL_LIST_PATH + `/${pinyin}-${home_keyword_id}-${vo.id}-${time_filter}-${module}-${category}-${scene}-${desc}-1-${layer}-${design}-1.html`;

      }
    })

    menuList.forEach(vo => {
      if (categoryType == 0) {
        if (tempParams.real_home_keyword_id == 999) {
          vo.link = MATERIAL_LIST_PATH + `/${pinyin}-${tempParams.real_home_keyword_id}-${layout}-${time_filter}-${module}-${category}-${scene}-${vo.sort}-1-${layer}-0-0.html`;
        } else {
          vo.link = MATERIAL_LIST_PATH + `/${pinyin}-${home_keyword_id}-${layout}-${time_filter}-${module}-${category}-${scene}-${vo.sort}-1-${layer}-0-0.html`;
        }


      } else {
        vo.link = MATERIAL_LIST_PATH + `/${pinyin}-${home_keyword_id}-${layout}-${time_filter}-${module}-${category}-${scene}-${vo.sort}-1-${layer}-${design}-1.html`;

      }
    })


    return {
      category_list: category_list,
      self_list: tempSelfList,
      other_list: tempOther_list,
      scene_list: scene_list,
      usageTypeList,
      menuList,
      style_list
    }
  }

  parseUrl(app) {
    const {route} = app.context;
    let id = route.params.id;
    return parseListUrl(route.path, id)
  }

  findCrumbList(tempParams, self_list, scene_list, category_list, keyword, styleClassify) {
    /* 查找一级分类 */
    let crumbList = [];
    let {
      pinyin,
      home_keyword_id,
      layout,
      time_filter,
      module,
      category,
      scene,
      page,
      desc,
      categoryType,
      design
    } = tempParams;

    if (categoryType == 0) {
      let homeKeyWordItem = topbarMenuList.find(vo => vo.id === parseInt(tempParams.home_keyword_id))
      if (!!homeKeyWordItem) {
        homeKeyWordItem.type = 'home_keyword_id'
        if (homeKeyWordItem.name !== '全部热点') {
          crumbList = crumbList.concat(homeKeyWordItem)
        }
      }
      let moduleItem = self_list.find(vo => vo.id == module)
      if (!!moduleItem && self_list.length > 1) {
        moduleItem.path = `${PAGE_PATH.BASE_URL}${PAGE_PATH.TU_PIAN.LIST}/${pinyin}-${home_keyword_id}-0-0-${moduleItem.id}-0-0-default-1-0.html`;
        moduleItem.type = 'module'
        if (moduleItem.name != '全部') {
          crumbList = crumbList.concat(moduleItem)
        }
      }
      let sceneItem = scene_list.find(vo => vo.id == scene)
      if (!!sceneItem && scene_list.length > 1) {
        sceneItem.path = `${PAGE_PATH.BASE_crumbListURL}${PAGE_PATH.TU_PIAN.LIST}/${pinyin}-${home_keyword_id}-0-0-${moduleItem.id}-0-${sceneItem.id}-default-1-0.html`;
        sceneItem.type = 'scene'
        if (sceneItem.name != '全部') {
          crumbList = crumbList.concat(sceneItem)
        }
      }

      let categoryItem = category_list.find(vo => vo.id == category)
      if (!!categoryItem && category_list.length > 1) {
        categoryItem.path = `${PAGE_PATH.BASE_URL}${PAGE_PATH.TU_PIAN.LIST}/${pinyin}-${home_keyword_id}-0-0-${moduleItem.id}-${categoryItem.id}-0-default-1-0.html`;
        categoryItem.type = 'category'
        if (categoryItem.name != '全部') {
          crumbList = crumbList.concat(categoryItem)
        }
      }

      /* 检查有无搜索词 */
      if (keyword != '') {
        crumbList = crumbList.concat({
          name: keyword,
          path: `${PAGE_PATH.BASE_URL}${PAGE_PATH.TU_PIAN.LIST}/${pinyin}-${home_keyword_id}-${layout}-${time_filter}-${category}-${module}-${scene}-${desc}-${page}.html`

        })

      }
    } else {
      let homeKeyWordItem = styleClassify.find(vo => vo.id == design)
      if (!!homeKeyWordItem) {
        if (homeKeyWordItem.name !== '全部') {
          homeKeyWordItem.path = `${PAGE_PATH.BASE_URL}${PAGE_PATH.TU_PIAN.LIST}/0-${home_keyword_id}-${layout}-${time_filter}-${category}-${module}-${scene}-default-${page}-0-${tempParams.design}-1.html`
          crumbList = crumbList.concat(homeKeyWordItem)
        }
      }

      /* 检查有无搜索词 */
      if (keyword != '') {
        crumbList = crumbList.concat({
          name: keyword,
          path: `${PAGE_PATH.BASE_URL}${PAGE_PATH.TU_PIAN.LIST}/${pinyin}-${home_keyword_id}-${layout}-${time_filter}-${category}-${module}-${scene}-${desc}-${page}-0-0-1.html`

        })

      }
    }

    let item = 'https://chaopx.com/tupian/meishituijian-0-0-0-0-0-0-default-1.html'

    /* 检查是否可转为短连接 */
    let _tempUrl = '-0-0-0-0-0-0-default-1'
    if (crumbList.length) {
      crumbList.forEach(item => {
        if (item.path && item.path.indexOf(_tempUrl) > -1) {
          item.path = item.path.replace('-0-0-0-0-0-0-default-1', '');
        }
      })
    }
    return crumbList;
  }


  /* 收藏图片/图片包 */
  collectTupian(vo) {
    return new Promise((resolve, reject) => {
      let isCollect = vo.isCollect || false;
      toggleMaterialCollect({
        uniq_id: vo.uniq_id,
        act: isCollect === false ? 'collect' : 'remove',
        pck: vo.package == 1 ? 'package' : ''
      }).then(res => {

        resolve(res)
      }).catch(err => {
        reject(err);
      })
    })
  }

  /* 收藏专题 */
  collectTupianTopic(vo) {
    return new Promise((resolve, reject) => {
      let urlArr = vo.link_url.split('/')
      let idStr = urlArr[urlArr.length - 1];
      let id = idStr.replace('.html', '');
      id = parseInt(id)
      let isCollect = vo.isCollect || false
      toggleTopicCollect({
        id,
        mode: isCollect === false ? 'add' : 'rem'
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err);
      })
    })


  }

}

export default new FnTuPian();
