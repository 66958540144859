<template>
  <div>
<!--    <InviteBanner/>-->
    <nuxt/>
    <Footer/>
    <no-ssr>
      <Login />
      <PublicSlot />
    </no-ssr>
  </div>
</template>

<script>
import {PublicHeader, Footer, Login} from "@/components/common/index.js"
import { InviteBanner } from "@/components/act/invite/index.js"
import PublicSlot from "@/components/_Public/PublicSlot";

export default {
  name: 'App',
  components: {
    PublicHeader,
    Footer,
    Login,
    InviteBanner,
    PublicSlot
  },
  data() {
    return {
      isShow: false
    }
  },
  mounted() {
  },
  destroyed() {
  },

  methods: {

  },
}
</script>



