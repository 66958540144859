<template>
  <div>
    <template v-if="!topicItem.isCollect">
      <div
        class="subscription subscription-topic"
        @click.stop.prevent="toggleCollect(topicItem)"
      >
        <i class="iconfont icon-sub"/>
        <span class="txt-subscription">订阅专题</span>
      </div>
    </template>

    <template v-else>
      <div
        class="subscription no-subscription-topic"
        @mouseover="subscriptionStatusTxt = '取消订阅'"
        @mouseleave="subscriptionStatusTxt = '已订阅'"
        @click.stop.prevent="toggleCollect(topicItem)"
      >
        <i class="icon_subscription"/>
        <span class="txt-subscription">{{ subscriptionStatusTxt }}</span>
      </div>
    </template>
  </div>
</template>

<script>
import {fnLogin} from "@/fn";
import {toggleTopicCollect} from "@/api/topic";
import {completeTaskByFanClub} from "@/api/fans/club";

export default {
  name: "SubscriptionBtn",
  props: {
    topicItem: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      subscriptionStatusTxt: '已订阅'
    }
  },
  watch: {
    topicItem: {
      handler(n) {
        if (n) {
          if (!n.isCollect) {
            n.isCollect = false
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    toggleCollect(vo) {
      if (fnLogin.checkLogin()) {
        let isCollect = vo.isCollect || false
        toggleTopicCollect({
          id: vo.id,
          mode: isCollect === false ? 'add' : 'rem'
        }).then(res => {
          let {status} = res.data;
          console.log(status)

          if (status == 200) {
            let msg = isCollect === false ? '订阅成功' : '已取消订阅';
            /* 订阅人数修改 */
            let tempSubscribe = parseInt(vo.subscribe);
            tempSubscribe = isCollect === false ? (tempSubscribe += 1) : (tempSubscribe -= 1)
            this.$set(vo, 'isCollect', !vo.isCollect)
            this.$set(vo, 'subscribe', tempSubscribe)
            this.$myNotify.success(msg);

            /* 检查是否是领取任务 */
            setTimeout(() => {
              let {id, task_type} = this.$route.query
              if (task_type == 1) {
                completeTaskByFanClub({id}).then(res => {
                  let {status, data, message} = res.data;
                  if (status == 200) {
                    this.$myNotify.success("任务已完成")
                    this.$router.push({
                      query: {}
                    })
                  } else {
                    this.$myNotify.error(message)

                  }
                })
              }
            }, 1000)

          } else if (status == 405) {
            this.$myNotify.success('已订阅');
            this.$set(vo, 'isCollect', true)


          }
        })
      } else {
        fnLogin.loginIntercept('请先登录');
      }
    },
  }

}
</script>

<style lang="scss" scoped>
.subscription {
  width: 176px;
  height: 48px;
  border-radius: 24px;
  position: relative;
  z-index: 8;

  &:after {
    content: '';
    display: inline-block;
    width: 1px;
    height: 14px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 1px;
    @include Position(1, 5, 68px, 17px);
  }

  .txt-subscription {
    font-size: 14px;
    color: #FFFFFF;
    margin-left: 83px;
    line-height: 48px;
  }


  &.subscription-topic {
    background: linear-gradient(44deg, #FE7E7E 0%, #F94D67 100%);
    line-height: 48px;
    @include ElementHover('', linear-gradient(44deg, #F94D67 0%, #FE7E7E 100%));

    .icon-sub {
      display: inline-block;
      font-size: 22px;
      color: #FFFFFF;
      @include Position(1, 5, 32px, 0px);

    }

    .txt-subscription {
      font-size: 14px;
      color: #FFFFFF;
      margin-left: 83px;
      line-height: 48px;
    }

  }

  &.no-subscription-topic {
    background: #FFFFFF;
    border: 1px solid #ECEFF6;

    @include ElementHover('', #F7F9FD);

    &:after {
      background: rgba(66, 72, 102, 0.15);
    }

    .txt-subscription {
      color: #7A7E93;
    }

    .icon_subscription {
      @include BgImage(22px, 22px, 'topic/index/icon_subscription.svg');
      @include Position(1, 5, 32px, 13px);

    }
  }
}
</style>
