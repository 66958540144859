/**
 * @Author: wulizhou
 * @Description: 页面级别 仅放置网页/OSS/FileZilla资源链接
 * @Date:
 *
 **/
export const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://chaopx.com' : 'http://chaopx.com';
export const BASE_IMG_UPLOAD = 'https://js.chaopx.com/chaotu/'  // FTP资源路径
export const BASE_IMG_UPLOAD2 = 'https://js.chaopx.com/chaotu_web/'  // FTP资源路径
export const BASE_IMG_URL = 'https://pic.chaopx.com'  // 请求图片资源

/* 支付页 */
export const PAY_DEFAULT = "/pay";
export const PAY_COMPANY = '/pay/' + 38;  // 企业默认VIP
export const PAY_COMPANY_UPGRADE = '/pay/' + 37;  // 企业转售版
export const PAY_BUSINESS = '/pay/' + 2;   // 商用默认VIP
export const PAY_PERSON = '/pay/' + 19;   // 个人默认VIP
export const PAY_UPGRADE = '/pay/upgrade';   // 升级
export const PAY_PERSON_UPGRADE = "/pay/upgrade/1-" + 3;  // 个人升级默认选中商用年
export const PAY_PAY_BUSINESS_UPGRADE = "/pay/upgrade/2-" + 38;  // 商用升级默认选中商用年
export const PAY_PAY_COMPANY_UPGRADE = "/pay/upgrade/3-" + 2;  // 企业升级默认选中商用年

export const PAY_LEAFLET = '/pay/leaflet'

export const TUPIAN_DETAIL = '/detail';          // 素材详情页
export const TUPIAN_LIST = '/tupian';          // 素材列表

export const THREE_D = '/3d';          // 素材列表
export const PART_TIME = '/part-time';          // 素材列表

/* 兑换码、口令兑换 */
export const EXCHANGE_DEFAULT = "/exchange-code"   // 兑换码
export const EXCHANGE_CODE = "/exchange-code";     // 兑换码
export const EXCHANGE_PASSWORD = "/exchange-code?type=1"  // 口令

/* 介绍页 */
export const INTRODUCE_VIP = "/member/license-vip";         // 会员介绍页
export const INTRODUCE_COMPANY_VIP = "/introduce/company";         // 会员介绍页
export const INTRODUCE_COMPANY_VIP_PLUS = "/introduce/company-plus";         // 会员介绍页

/* 分享页面 */
export const SHARE_CODE_BY_RED_BOOK = "/collection/share-code"; // 小红书二维码页面

/* 会场 */
export const VENUE_DEFAULT = "/venue";


/* 宠粉 */
export const FANS = "/fans"
export const DEMAND_POOL_DETAIL = FANS + "/demand-detail"
export const FANS_CLUB = "/fans-club"

/* 创作者中心 */
export const CREATOR = "/creator";
export const CREATOR_WORKS = CREATOR + "/works"     // 作品
export const CREATOR_WORKS_MANAGE = CREATOR + "/works-manage" // 作品管理
export const CREATOR_DESIGN_GUIDE = CREATOR + "/design-guide" // 设计指南
export const CREATOR_SIGN = CREATOR + "/sign"        // 签约
export const CREATOR_TASK = CREATOR + "/task"        // 任务中心
export const CREATOR_PIC = CREATOR + "/pic"        // 任务中心
export const CREATOR_PIC_YANG_JI = CREATOR + "/pic?tabId=2"        // 任务中心
export const CREATOR_SIGN_PROGRESS = CREATOR + "/sign/sign-progress"        // 签约进度
export const CREATOR_SIGN_WRITE = CREATOR + "/sign/sign-write"        // 签约进度
// export const CREATOR_SIGN_WRITE_PERSON = CREATOR + "/sign/sign-write?type=" + SIGN_TYPE.PERSON        // 签约—个人
export const CREATOR_SIGN_WRITE_PERSON = CREATOR + "/sign/sign-write?type=person"       // 签约—个人
// export const CREATOR_SIGN_WRITE_WORK_ROOM = CREATOR + "/sign/sign-write?type=" + SIGN_TYPE.WORK_ROOM        // 签约-工作室
export const CREATOR_SIGN_WRITE_WORK_ROOM = CREATOR + "/sign/sign-write?type=work_room"        // 签约-工作室
export const CREATOR_USER_CENTER = CREATOR + "/user-center"   // 个人中心
export const CREATOR_USER_CENTER_CERTIFICATE = CREATOR + "/user-center/certificate"   // 认证资料


/* 活动页 */
export const ACTIVE_GUOQING = "/act/11"; // 国庆活动

/* 企业案例 */
export const ENTERPRISE_CASE = "/enterprise/case";
export const ENTERPRISE_CASE_DETAIL = "/enterprise/case/detail";
