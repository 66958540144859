<template>
  <div class="Sidebar">

    <!--    <inviteCombobox/>-->

    <!--        <div class=" coupon-gif"
                 @click="toUserCenterCoupon"
            ></div>-->


    <!-- 口令管 -->
<!--    <div class="command-box"
         @mouseover="isShowCommandQrCode = true"
         @mouseleave="isShowCommandQrCode = false"
    >
      <i class="icon_command"/>

      <div class="icon_command-hover-box">
        <div class="icon_command-hover" :class="{ 'ON': isShowCommandQrCode }">
          <vue-qr
            class="qr-code"
            :text="commandLink"
            :margin="0"
            :logo-scale="0.3"
            :size="92"
            :binarizeThreshold="1"
          />
        </div>


      </div>

    </div>-->

    <no-ssr>
      <div class="sidebar-list">
<!--        <a
          :href="ACT_HUABAN_CAOTURE"
          target="_blank"
          class="sidebar-item sidebar-item-hb">
          <i class="icon_hb"></i>
          <div class="explain">花瓣采集赚钱</div>
        </a>-->
        <div
          v-for="vo in SidebarList"
          :key="vo.iconClass"
          class="sidebar-item"
          @click="navigatorTo(vo.link)">
          <i
            :class="[vo.iconClass,'iconfont']"
          />
          <div class="explain">{{ vo.explain }}</div>
        </div>
        <SidebarCode/>
        <SidebarService/>
        <SidebarItemBackToTop/>
      </div>
    </no-ssr>

    <!--    <LoginGuide />-->
  </div>
</template>

<script>
import SidebarItemBackToTop from '@/components/common/sidebar/components/back-to-top';
import InviteCombobox from '@/components/act/invite/invite-combobox';  // 邀请浮标
import LoginGuide from "@/components/modal/login-guide/login-guide";
import SidebarCode from '@/components/common/sidebar/components/sidebar-code';
import SidebarService from '@/components/common/sidebar/components/sidebar-service';
import vueQr from 'vue-qr'

import {PAGE_LINK, PAGE_PATH} from "@/constant/PAGE_PATH";
import {fnLogin, fnRouter} from "@/fn";
import {ACT_HUABAN_CAOTURE} from "@/constant/_Public/Path";

const SidebarList = [
  {
    iconClass: 'icon-a-sidezhaomu',
    explain: '设计师赚钱',
    link: PAGE_PATH.PART_TIME
  },
  {
    iconClass: 'icon-a-sidechat',
    explain: '意见反馈',
    link: PAGE_PATH.FEEDBACK
  },
  /* {
     iconClass: 'icon-a-sidekefu3',
     explain: '人工客服',
     link: PAGE_LINK.SERVICE
   },*/

]

export default {
  name: "Sidebar",
  components: {
    SidebarItemBackToTop,
    InviteCombobox,
    LoginGuide,
    SidebarCode,
    SidebarService,
    vueQr
  },
  data() {
    return {
      SidebarList,
      PAGE_PATH,
      isShowCommandQrCode: false,
      commandLink: 'https://m.chaopx.com/act-command',
      ACT_HUABAN_CAOTURE
    }
  },
  methods: {
    navigatorTo(path) {
      window.open(path, '_blank');
    },

    toUserCenterCoupon() {
      if (fnLogin.checkLogin()) {
        fnRouter.navigationTo(PAGE_PATH.USER_CENTER.COUPON)
      } else {
        fnLogin.loginIntercept(false);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./common/sidebar";

.sidebar-list {
  // filter: grayscale(100%) !important;
}

.coupon-gif {
  @include BgImage(75px, 70px, 'common/sidebar/coupon2.gif?v=2');
  cursor: pointer;
}

.command-box {
  position: relative;
  margin-bottom: 20px;

  .icon_command {
    @include BgImage(58px, 68px, 'common/sidebar/icon_command.png?v=2');
    cursor: pointer;
  }

  .icon_command-hover-box {

    .icon_command-hover {
      @include BgImage(230px, 140px, 'common/sidebar/icon_command%40hover.png');
      border-radius: 10px;
      overflow: hidden;
      @include Position(1, 2, -99962px, 50%);
      transform: translateY(-50%);
      transition: width 0.5s ease;

      &.ON {
        right: 62px;
      }
    }

    .qr-code{
      @include Position(1,2,14px,24px);
      transition: all 2s ease;

    }
  }
}
</style>
