import {getJoinNoReadNum} from "@/api/company/team-manage";

export default {
  namespaced: true,
  state() {
    return {
      companyRecordState: 0,
      joinNum: 0
    }
  },

  mutations: {
    SET_COMPANY_RECORD_STATE(state, payload) {
      state.companyRecordState = payload;
    },
    SET_JOIN_NUM(state, payload) {
      state.joinNum = payload;
    },
  },
  actions: {
    async getJoinNoReadNum({dispatch, commit, state, rootGetters}) {
      return new Promise((resolve, reject) => {
        getJoinNoReadNum().then(res => {
          let count = res.data.data.count;
          if (res.data.status == 200) {
            commit('SET_JOIN_NUM', count);
            resolve(res);
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
  },

}
