<template>
  <div
    :class="[ {'on': NAV.PIC_PACKAGE.id == navIndex} ]"
    class="NavItemPicPackage menu-item"
  >
    <a
      :href="picPackageLink "
      target="_blank"
    >
      图片包
    </a>
  </div>
</template>

<script>
import {fnRouter} from "@/fn";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import {NAV} from "../common/module";

export default {
  name: "NavItemPicPackage",
  props: {
    navIndex: {
      type: String | Number,
      default: -1
    }
  },
  computed:{
    picPackageLink(){
      return fnRouter.getRouterLink(PAGE_PATH.PIC_PACKAGE);
    },
  },
  data() {
    return {
      NAV
    }
  },
  methods: {
    navigateToPicPackage() {
      this.$emit('navigateToPicPackage', NAV.PIC_PACKAGE)
      // fnRouter.navigationTo(PAGE_PATH.PIC_PACKAGE)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../common/nav";
</style>
