import {flowStatistics, flowStatisticsPromotion} from "@/api/flow-statistics";

export const PROMOTION_POSITION = {
  BANNER: 'banner',
  TOP: 'top',
  RECOMMEND: 'recommend',
  CALENDAR: 'calendar',
}

class FlowStatistics {
  constructor() {
  }


  /**
   * 页面入口统计
   * @param position
   * @returns {Promise<void>}
   */
  async pageFlow(position) {
    await flowStatistics({position})
  }


  /**
   * banner和搜索下拉词数据统计
   * @returns {Promise<void>}
   * @param id 点击素材id
   * @param type  类型 见 PROMOTION_POSITION
   */
  async promotion( type = 0,id = 0,) {
    await flowStatisticsPromotion({act:type, id})
  }


}

export default new FlowStatistics();

