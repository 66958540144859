/* 企业后台 */

import {PAGE_PATH} from "@/constant/PAGE_PATH";



/* 测试数据 */
export const ACCOUNT_LIST = [
  {
    id: 1,
    version: '主体1：线上用途版',
    isCertification: true,
    companyName: '上海图遇信息有限公司',
    industry: '互联网 影音播放',
    iphone: '18765654567',
    endTime: '2023-7-23',
    accountAll: 4,
    remainAccount: 1,
  },
  {
    id: 2,
    version: '主体2：全用途版',
    isCertification: true,
    companyName: '上海图遇信息有限公司',
    industry: '互联网 影音播放',
    iphone: '18765654567',
    endTime: '2023-7-23',
    accountAll: 4,
    remainAccount: 1,
  },
  {
    id: 3,
    version: '主体1：线上用途版',
    isCertification: false,
    companyName: '上海图遇信息有限公司',
    industry: '互联网 影音播放',
    iphone: '18765654567',
    endTime: '2023-7-23',
    accountAll: 4,
    remainAccount: 1,
  },
  {
    id: 4,
    version: '主体1：线上用途版',
    isCertification: false,
    companyName: '上海图遇信息有限公司',
    industry: '互联网 影音播放',
    iphone: '18765654567',
    endTime: '2023-7-23',
    accountAll: 4,
    remainAccount: 1,
  },
]

/* 认证表单 */
export const CERTIFICATE_FORM = [
  {
    id: 0,
    name: '企业名称',
    inputVal: '',
    placeholder: '请输入公司名称（必填）',
    errTxt: '请输入公司名称',
    isShowErrTxt: false,
    key: 'name'
  },
  {
    id: 1,
    name: '统一社会信用代码',
    inputVal: '',
    placeholder: '请输入统一社会信用代码（必填）',
    errTxt: '请输入统一社会信用代码',
    isShowErrTxt: false,
    key: 'code'
  },
  {
    id: 2,
    name: '企业联系人',
    inputVal: '',
    placeholder: '请输入企业联系人（必填）',
    errTxt: '请输入企业联系人',
    isShowErrTxt: false,
    key: 'contact_user'
  },
  {
    id: 3,
    name: '企业联系电话',
    inputVal: '',
    placeholder: '请输入企业联系电话（必填）',
    errTxt: '请输入企业联系电话',
    isShowErrTxt: false,
    key: 'phone'
  },
  {
    id: 4,
    name: '所属行业',
    inputVal: '',
    placeholder: '请选择所属行业（必填）',
    errTxt: '请输入企业联系电话',
    isShowErrTxt: false,
    key: 'phone'
  },

]

/* 行业列表 */
export const INDUSTRY_LIST = [
  {
    label: '互联网',
    val: '互联网'
  },
  {
    label: '设计',
    val: '设计'
  },
  {
    label: '开发',
    val: '开发'
  },
  {
    label: '摄影',
    val: '摄影'
  },
  {
    label: '前端',
    val: '前端'
  },
  {
    label: '绘画',
    val: '绘画'
  },
  {
    label: '其他',
    val: '其他'
  }
]

/* 主体列表 */
export const MAIN_COMPANY_LIST = [
  {
    label: '1上海图遇网络科技有限公司',
    id: 1,
    selected: true,
  },
  {
    label: '2杭州海洋网络科技有限公司',
    id: 2
  },
  {
    label: '3江苏海洋网络科技有限公司',
    id: 3
  },
  {
    label: '4西安海洋网络科技有限公司',
    id: 4
  },
  {
    label: '5杭州海洋网络科技有限公司',
    id: 5
  },
  {
    label: '6上海图遇网络科技有限公司',
    id: 6
  },
  {
    label: '7江苏海洋网络科技有限公司',
    id: 7
  },
  {
    label: '8西安海洋网络科技有限公司',
    id: 8
  }
]



/*_____侧边栏菜单_____*/
export const SIDEBAR_LIST = {
  /* 默认 */
  DEFAULT:[
    {
      id: 1,
      label: '企业首页',
      iconClass: 'icon-qy-home-line',
      link: PAGE_PATH.COMPANY.HOME,
      level: 1
    },
    {
      id: 2,
      label: '授权记录',
      iconClass: 'icon-qy-download-file-line',
      link: PAGE_PATH.COMPANY.MANDATE_RECORD,
      level: 1
    },
    {
      id: 6,
      label: '收藏记录',
      iconClass: 'icon-qy-collect4',
      link: PAGE_PATH.COMPANY.COLLECT_RECORD,
      level: 1
    },
    {
      id: 3,
      label: '成员管理',
      iconClass: 'icon-qy-member-line',
      link: PAGE_PATH.COMPANY.TEAM.INDEX,
      level: 1
    },
  ],
  /* 管理员 */
  ADMIN:[
    {
      id: 1,
      label: '企业首页',
      iconClass: 'icon-qy-home-line',
      link: PAGE_PATH.COMPANY.HOME,
      level: 1
    },
    {
      id: 2,
      label: '授权记录',
      iconClass: 'icon-qy-download-file-line',
      link: PAGE_PATH.COMPANY.MANDATE_RECORD,
      level: 1
    },
    {
      id: 6,
      label: '收藏记录',
      iconClass: 'icon-qy-collect4',
      link: PAGE_PATH.COMPANY.COLLECT_RECORD,
      level: 1
    },
    {
      id: 3,
      label: '团队管理',
      iconClass: 'icon-qy-member-line',
      link: PAGE_PATH.COMPANY.TEAM.INDEX,
      collapse: false,
      children: [
        {
          id: 3,
          label: '成员管理',
          iconClass: 'icon-qy-member-line',
          link: PAGE_PATH.COMPANY.TEAM.INDEX,
          parentId: 3,
          level: 2
        },
        {
          id: 32,
          label: '团队角色',
          iconClass: 'icon-qy-member-line',
          link: PAGE_PATH.COMPANY.TEAM.ROLE,
          parentId: 3,
          level: 2
        },
        {
          id: 33,
          label: '申请加入',
          iconClass: 'icon-qy-member-line',
          link: PAGE_PATH.COMPANY.TEAM.JOIN,
          parentId: 3,
          level: 2
        }
      ]
    },
    {
      id: 4,
      label: '充值/续费',
      iconClass: 'icon-qy-pay-line',
      link: PAGE_PATH.COMPANY.PAY,
      level: 1
    },
    {
      id: 5,
      label: '充值记录',
      iconClass: 'icon-qy-payrecord-line',
      link: PAGE_PATH.COMPANY.PAY_RECORD,
      level: 1
    }
  ]
}





/* ______团队管理______ */
/* 邀请好友 tab */
export const TEAM_MANAGE_INVITE_TAB = [
  {
    label: '添加ID邀请',
    id: 1,
  },
  {
    label: '链接邀请',
    id: 2
  }
]


export const TEAM_MANAGE = {

  /* 操作下拉菜单 */
  HANDLE_MENU: [
    {
      id: 1,
      label: '修改备注名',
      refName:'TeamManageHandleUpdateRemarksDialog'
    },
    {
      id: 2,
      label: '调整所属主体',
      refName:'TeamManageHandleUpdateMainDialog'
    },
    {
      id: 3,
      label: '修改团队/角色',
      refName: 'TeamManageHandleUpdateTeamRoleDialog'
    },
    {
      id: 4,
      label: '转让管理员',
      refName: 'TeamManageTransferAdminDialog'
    },
    {
      id: 5,
      label: '移出团队',
      refName: 'TeamManageRemoveMemberDialog'
    },
  ],

  HANDLE_MENU_MEMBER: [
    {
      id: 6,
      label: '退出团队',
      refName: 'TeamManageQuitTeamDialog'
    },
  ],


  ROLE: {
    /* 操作下拉菜单 */
    HANDLE_MENU: [
      {
        id: 1,
        label: '修改团队/角色',
        refName:'TeamManageRoleUpdateTeamRoleDialog'
      },
      {
        id: 2,
        label: '删除团队角色',
        refName:'TeamManageRoleHandleDeleteTeamDialog'
      },
    ],

  },
  JOIN: {}

}

/* 充值记录 */
export const PAY_RECORD = {
  /* 发票表单 */
  INVOICE_FORM:[
    {
      id: 0,
      name: '发票抬头',
      inputVal: '',
      placeholder: '填写开具发票的企业名称（必填）',
      errTxt: '请输入发票抬头',
      isShowErrTxt: false,
      key: 'CustomerName',
      required: true
    },
    {
      id: 1,
      name: '统一社会信用代码',
      inputVal: '',
      placeholder: '请输入统一社会信用代码（必填）',
      errTxt: '请输入统一社会信用代码',
      isShowErrTxt: false,
      key: 'CustomerCode',
      required: true
    },
    {
      id: 2,
      name: '开户银行',
      inputVal: '',
      placeholder: '选填',
      errTxt: '请输入开户银行',
      isShowErrTxt: false,
      key: 'bank'
    },
    {
      id: 3,
      name: '银行帐号',
      inputVal: '',
      placeholder: '选填',
      errTxt: '请输入银行帐号',
      isShowErrTxt: false,
      key: 'bank_account'
    },
    {
      id: 4,
      name: '企业地址',
      inputVal: '',
      placeholder: '选填',
      errTxt: '请输入企业地址',
      isShowErrTxt: false,
      key: 'address'
    },
    {
      id: 5,
      name: '企业电话',
      type:'iphone',
      inputVal: '',
      placeholder: '选填',
      errTxt: '请输入企业电话',
      isShowErrTxt: false,
      key: 'tel'
    },
  ]
}


/* 企业后台 */

export const COMPANY_MANAGE = {

  HOME:{
    CERTIFICATE_FORM : [
      {
        id: 0,
        name: '企业名称',
        inputVal: '',
        placeholder: '请输入公司名称（必填）',
        errTxt: '请输入公司名称',
        isShowErrTxt: false,
        key: 'name'
      },
      {
        id: 1,
        name: '统一社会信用代码',
        inputVal: '',
        placeholder: '请输入统一社会信用代码（必填）',
        errTxt: '请输入统一社会信用代码',
        isShowErrTxt: false,
        key: 'code'
      },
      {
        id: 2,
        name: '联系人',
        inputVal: '',
        placeholder: '请输入联系人（必填）',
        errTxt: '请输入联系人',
        isShowErrTxt: false,
        key: 'contact_user'
      },
      {
        id: 3,
        name: '联系方式',
        inputVal: '',
        placeholder: '请输入联系方式（必填）',
        errTxt: '请输入企业联系电话',
        isShowErrTxt: false,
        key: 'phone'
      },
      {
        id: 4,
        name: '所属行业',
        type:'cascader',
        inputVal: '',
        val:'',
        placeholder: '请选择所属行业（必填）',
        errTxt: '请选择所属行业',
        isShowErrTxt: false,
        key: 'industry_id',
        options: []
      },
      // 企业规模: 1 1-10人,2 10-50人,3 50-100人,4 100人以上(企业认证必填)
      {
        id: 5,
        name: '企业规模',
        type:'select',
        inputVal: '',
        placeholder: '请选择企业规模（必填）',
        errTxt: '请选择企业规模',
        isShowErrTxt: false,
        key: 'firm_size',
        options: [
          {
            label: '1-50人',
            value: '1'
          },
          {
            label: '50-500人',
            value: '2'
          },
          {
            label: '500-1000人',
            value: '3'
          },
          {
            label: '1000-20000人',
            value: '4'
          },
          {
            label: '20000人以上',
            value: '5'
          }
        ]
      },

      {
        id: 6,
        name: '企业地址',
        type:'selectMore',
        inputVal: '',
        inputVal2: '',
        inputVal3: '',
        placeholder: '请选择省份（必填）',
        placeholder2: '请选择城市（必填）',
        placeholder3: '请选择地区（必填）',
        errTxt: '请选择省市区',
        isShowErrTxt: false,
        key: 'address',
        options: [],
        options2: [],
        options3: [],
        isShowOption3: true
      },

    ],
    MAIN_CERTIFICATE_FORM : [
      {
        id: 0,
        name: '企业名称',
        inputVal: '',
        placeholder: '请输入公司名称（必填）',
        errTxt: '请输入公司名称',
        isShowErrTxt: false,
        key: 'name'
      },
      {
        id: 1,
        name: '统一社会信用代码',
        inputVal: '',
        placeholder: '请输入统一社会信用代码（必填）',
        errTxt: '请输入统一社会信用代码',
        isShowErrTxt: false,
        key: 'code'
      },

      {
        id: 2,
        name: '联系人',
        inputVal: '',
        placeholder: '请输入联系人（必填）',
        errTxt: '请输入联系人',
        isShowErrTxt: false,
        key: 'contact_user'
      },
      {
        id: 3,
        name: '联系方式',
        inputVal: '',
        placeholder: '请输入联系方式（必填）',
        errTxt: '请输入企业联系电话',
        isShowErrTxt: false,
        key: 'phone'
      },
      {
        id: 4,
        name: '所属行业',
        type:'cascader',
        inputVal: '',
        val:'',
        placeholder: '请选择所属行业（必填）',
        errTxt: '请选择所属行业',
        isShowErrTxt: false,
        key: 'industry_id',
        options: []
      },

      // 企业规模: 1 1-10人,2 10-50人,3 50-100人,4 100人以上(企业认证必填)
      {
        id: 5,
        name: '企业规模',
        type:'select',
        inputVal: '',
        placeholder: '请选择企业规模（必填）',
        errTxt: '请选择企业规模',
        isShowErrTxt: false,
        key: 'firm_size',
        options: [
          {
            label: '1-50人',
            value: '1'
          },
          {
            label: '50-500人',
            value: '2'
          },
          {
            label: '500-1000人',
            value: '3'
          },
          {
            label: '1000-20000人',
            value: '4'
          },
          {
            label: '20000人以上',
            value: '5'
          }
        ]
      },

      {
        id: 6,
        name: '企业地址',
        type:'selectMore',
        inputVal: '',
        inputVal2: '',
        inputVal3: '',
        placeholder: '请选择省',
        placeholder2: '请选择市',
        placeholder3: '请选择区域',
        errTxt: '请选择省市区',
        isShowErrTxt: false,
        key: 'address',
        options: [],
        options2: [],
        options3: [],
        isShowOption3: true
      },

    ],
    MAIN_CERTIFICATE_FORM_PEROSN : [
      {
        id: 0,
        name: '姓名',
        inputVal: '',
        placeholder: '请输入姓名（必填）',
        errTxt: '请输入姓名',
        isShowErrTxt: false,
        key: 'name'
      },
      {
        id: 4,
        name: '所属行业',
        type:'cascader',
        inputVal: '',
        val:'',
        placeholder: '请选择所属行业（必填）',
        errTxt: '请选择所属行业',
        isShowErrTxt: false,
        key: 'industry_id',
        options: []
      },
      // {
      //   id: 2,
      //   name: '联系人',
      //   inputVal: '',
      //   placeholder: '请输入联系人（必填）',
      //   errTxt: '请输入联系人',
      //   isShowErrTxt: false,
      //   key: 'contact_user'
      // },
      {
        id: 1,
        name: '身份证号',
        inputVal: '',
        placeholder: '请输入身份证号（必填）',
        errTxt: '请输入身份证号',
        isShowErrTxt: false,
        key: 'code'
      },
      {
        id: 3,
        name: '联系方式',
        inputVal: '',
        placeholder: '请输入联系方式（必填）',
        errTxt: '请输入企业联系电话',
        isShowErrTxt: false,
        key: 'phone'
      },

      // {
      //   id: 6,
      //   name: '企业地址',
      //   type:'selectMore',
      //   inputVal: '',
      //   inputVal2: '',
      //   inputVal3: '',
      //   placeholder: '请选择省',
      //   placeholder2: '请选择市',
      //   placeholder3: '请选择区域',
      //   errTxt: '请选择省市区',
      //   isShowErrTxt: false,
      //   key: 'address',
      //   options: [],
      //   options2: [],
      //   options3: [],
      //   isShowOption3: true
      // },

    ],

    /* 认证类型 */
    CERTIFICATE_TYPE:{
      COMPANY: 'company', // 公司
      MAIN:'main',  // 主体
    }

  },

  TEAM_MANAGE:{

    INVITE:{
      STATUS: {
        DEFAULT: -1,  // 未申请
        SUCCESS: 1,   // 成功
        FAIL: 2,      // 失败，被拒绝
        LOADING: 0    // 审核中
      }
    }
  },


  /* 充值续费 */
  PAY:{
    RENEW_TAB:[
      {
        label: '续费主体',
        id: 1,
      },
      {
        label: '加购子帐号',
        id: 2
      }
    ]
  }
}
