/* 页面路由配置 */
import {BUSINESS_VIP_INFO, PERSON_VIP_INFO, PERSON_VIP_LIFELONG} from "@/constant/VIP";
import {COMPANY_All_PURPOSE, COMPANY_ONLINE, COMPANY_RESALE} from "@/constant/VIP_COMPANY";
import {MEMBER_LICENSE_VIP_PATH} from "@/constant/PATH";
import upyun from "upyun";
import {SERVICE_LINK} from "@/constant/_Public/Path";
export const PAGE_PATH = {
  BASE_URL:  process.env.NODE_ENV === 'production' ? 'https://chaopx.com' : 'http://chaopx.com:3000',
  // BASE_URL: 'http://chaopx.com:3000',
  BASE_IMG_URL: 'https://pic.chaopx.com',
  BASE_IMG_UPLOAD: 'https://js.chaopx.com/chaotu/',
  BASE_IMG_UPLOAD_UPYUN: 'https://pic.chaopx.com/chaotu/portal_upload/',
  BASE_IMG_UPLOAD_UPYUN2: 'https://pic.chaopx.com/chaotu/portal_upload',
  HOME: '/',
  TUPIAN_LIST: '/tupian',
  TUPIAN_DETAIL: '/tupian/detail',
  PAY: '/pay',
  PAY_PERSON: '/pay' + '/' + PERSON_VIP_INFO.ID,        // 个人充值
  PAY_BUSINESS: '/pay' + '/' + BUSINESS_VIP_INFO.ID,    // 商用充值
  PAY_COMPANY: '/pay' + '/' + COMPANY_All_PURPOSE.ID,   // 企业充值
  INTRO_VIP: '/member/license-vip',         // 会员介绍页
  INTRO_COMPANY_VIP: '/introduce/company',  // 企业会员介绍页

  /* 分模块，以上继续使用,但不推荐 */
  PIC_PACKAGE: '/pic-package',  // 图片包列表
  CALENDAR: '/calendar',        // 日历列表
  TIMELINE: '/timeline-update', // 时间轴
  PART_TIME: '/part-time',      // 招聘页
  FEEDBACK: '/feedBack',        // 意见反馈
  TAGE:'/tags',                 // 标签页

  NAV:{
    HOT:'/tupian',
    HOT_DIAN: '/tupian/0-1-0-0-0-0-0-default-1-0-0-0.html',
    THREE_D: '/tupian/0-4-0-0-0-0-0-default-1-0.html',
    FESTIVAL: '/tupian/0-2-0-0-0-0-0-new-1-0-0-0.html',
    PPT: '/tupian/0-137-0-0-0-0-0-dnum-1-0-0-0.html',
    BUSINESS: '/tupian/0-43-0-0-0-0-0-default-1-0.html',
    FREE: '/tupian/0-999-0-0-0-0-0-default-1-1.html',
    STYLE: '/tupian/0-0-0-0-0-0-0-new-1-0-0-1.html',
  },

  EXCHANGE_CODE:'/exchange-code',

  /* 图片 */
  TU_PIAN: {
    DETAIL: '/detail',   // 图片详情
    // DETAIL: '/detail',   // 图片详情
    LIST: '/tupian',            // 图片列表
  },
  THREE_D:{
    DEFAULT: '/3d'
  },
  /* 介绍页 */
  INTRODUCE: {
    VIP: '/member/license-vip',         // 会员介绍页
    COMPANY_VIP: '/introduce/company',  // 会员介绍页
  },
  /* 支付 */
  PAYMENT: {
    DEFAULT: '/pay',
    PERSON: '/pay' + '/' + PERSON_VIP_LIFELONG.ID,        // 个人充值
    BUSINESS: '/pay' + '/' + 1,    // 商用充值
    COMPANY: '/pay' + '/' + COMPANY_All_PURPOSE.ID,   // 企业充值

    COMPANY_VIP:{
      RESALE: '/pay' + '/' + COMPANY_RESALE.ID
    },


    UPGRADE: '/pay/upgrade',
    UPGRADE_PERSON: '/pay/upgrade/1',
    UPGRADE_BUSINESS: '/pay/upgrade/2',
    UPGRADE_COMPANY: '/pay/upgrade/3',
    ACT_COMPANY_PAY: '/pay/act-company',
  },
  /* 邀请好友 */
  INVITE: {
    H5: '/invite-h5',       // h5拉新活动
    DEFAULT: '/invite',      // 拉新活动
    MEMBER: '/invite-member'      // 拉新活动
  },

  /* 兔年活动 */
  RABBIT_ACT:{
    INVITE: '/act/rabbit',
    STADIUM: '/act/rabbit/stadium',
    INVITE_STADIUM_4: '/act/rabbit/stadium/4',
    INVITE_STADIUM_5: '/act/rabbit/stadium/5',
    INVITE_STADIUM_6: '/act/rabbit/stadium/6',
    INVITE_STADIUM_7: '/act/rabbit/stadium/7',
    INVITE_STADIUM_8: '/act/rabbit/stadium/8',
  },

  /* 企业后台 */
  COMPANY: {
    HOME: '/company',                           // 企业首页
    MAIN_CERTIFICATE: '/company/main-certificate',                           // 企业首页
    MANDATE_RECORD: '/company/mandate-record',  // 授权记录
    COLLECT_RECORD: '/company/collect-record',  // 收藏记录
    PAY: '/company/pay',                        // 充值/续费
    PAY_RECORD: '/company/pay-record',          // 充值记录
    TEAM: {
      INDEX: '/company/team-manage',            // 团队管理
      ROLE: '/company/team-manage/role',        // 角色管理
      COLLECT: '/company/collect-record',        // 角色管理
      JOIN: '/company/team-manage/join',        // 加入团队
      INVITE:'/company/team-manage/invite'      // 企业后台管理邀请链接
    },
    CASE_COLLECTION: '/company/case-collection'
  },

  /* 专题 */
  TOPIC:{
    DEFAULT: '/topic',
    COLLECTION:'/collection'
  },

  /* 粉丝专区 */
  FANS:{
    DEFAULT: '/fans',
    DEMAND_POOL: '/fans/demand-pool',
    DEMAND_DETAIL: '/fans/demand-detail',
  },

  /* 用户中心 */
  USER_CENTER:{
    DEFAULT:'/user-center/vip-record',
    COUPON: '/user-center/coupon-record',
    COLLECT: '/user-center/collect-record',
  },

  USER:{
    COLLECT: '/user-center/collect-record',
  }
}

/*  */
export const PAGE_LINK = {
  SERVICE: SERVICE_LINK
}
