<template>
  <div class="CompanyLayout">
    <no-ssr>
      <Header @showNewUserGuide="showNewUserGuide"/>
      <Sidebar/>
      <Main class="CompanyMain">
        <div slot="CompanyMain" >
          <nuxt/>
          <Menu @backToTop="backToTop"/>
        </div>
      </Main>
      <Login/>
      <CompanyGuide ref="CompanyGuide" />

      <PublicSlot />
    </no-ssr>
  </div>
</template>

<script>
import {
  Header,
  Sidebar,
  Main,
  Menu
} from '@/components/company/layout'
import {Login} from "@/components/common/index.js"
import { mapState } from "vuex";
import {fnRouter} from "@/fn";
import {COMMON} from "@/constant/COMMON";
import CompanyGuide from '@/components/company/common/guide'
import PublicSlot from "@/components/_Public/PublicSlot";


export default {
  name: "CompanyLayout",
  // middleware: "companyManage",
  components: {
    Header,
    Sidebar,
    Main,
    Login,
    Menu,
    CompanyGuide,
    PublicSlot
  },
  computed: {
    ...mapState('user', ['isLogin','companyInfoByPerson'])
  },
  watch: {
    isLogin(n, o) {
      if (n == false) {
        fnRouter.navigationTo('/','_self')
      }
    },
    companyInfoByPerson:{
      handler(n,o){
        if (n.switch && n.switch.type == COMMON.HEADER.AVATAR_COMBOBOX.ACCOUNT_TYPE.PERSON){
          fnRouter.navigationTo('/','_self')
        }
      },
      deep: true
    },
  },

  mounted() {
  },
  methods:{
    showNewUserGuide(){
      console.log(1111111)
      this.$refs.CompanyGuide.step = 1
      this.$refs.CompanyGuide.newUserShow = true;
    },
    backToTop(){
      console.log(222222222)
      var _wrapper=document.querySelector(".CompanyMain");//获取wrapper
      var wrapperY=_wrapper.scrollTop;	//获取Y轴滚动的距离
      console.log(wrapperY)
      var id=setInterval(function(){		//定时器函数
        if(wrapperY<=0){				//如果wrapperY轴小于等于0
          clearInterval(id);			//停止计时器
        }
        wrapperY-=100;
        _wrapper.scrollTop=wrapperY;
      },16.7);
    }
  }
}
</script>

<style scoped>

</style>
