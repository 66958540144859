<template>
  <div class="HeaderSubmitPic"
       @mouseover="isShow = true"
       @mouseleave="isShow = false">
    <a
      :href="PAGE_PATH.FANS.DEFAULT"
      target="_blank"
      class="contribute-box">
    </a>

    <div  v-show="isShow"
      class="hot-combobox">

      <a href="https://chaopx.com/fans/demand-pool"
         target="_blank"
        class="hot-item">
        需求定制
      </a>
<!--      <a
        href="https://chaopx.com/fans-club"
        target="_blank"
        class="hot-item">
        签到有礼
      </a>-->
        <a
        :href="CALENDAR"
        target="_blank"
        class="hot-item">
         自定义日程
      </a>
      <a class="hot-item" href="https://chaopx.com/pay/edu" target="_blank">
        教育优惠
      </a>

    </div>
  </div>
</template>

<script>
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import {fnFlowStatistics} from "@/fn";
import {CALENDAR} from "@/constant/_Public/Path";

export default {
  name: "HeaderSubmitPic",
  data(){
    return{
      PAGE_PATH,
      isShow: false,
      CALENDAR
    }
  },
  methods:{
    updateNavId(navId){
      return

    },

    wait(){
      this.$myNotify.show('敬请期待');
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes fadeinT {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


.HeaderSubmitPic{
  position: relative;
  .contribute-box {
    display: inline-block;
    width: 100px;
    font-size: 15px;
    color: #FFFFFF;
    height: 100%;
    letter-spacing: 1px;
    //margin-right: 22px;
    align-items: center;
    position: relative;


    @include BgImage(68.75px,23px,'header/icon_fans.gif');

    .icon_contribute {
      margin-right: 8px;
      @include BgImage(24px, 24px, 'header/icon_contribute.png');
      @include BgImage(24px, 24px, 'fans/chongfen.svg');

      @include Screen(1){
        @include BgImage(22px, 22px, 'header/icon_contribute.png');
        @include BgImage(22px, 22px, 'fans/chongfen.svg');
      }
    }

    @include ElementHover(rgba(255, 255, 255, 0.75), '');

    .opening{
      width: 46px;
      height: 16px;
      background: #FE0000;
      border-radius: 100px 100px 100px 1px;
      position: absolute;
      top: -16px;
      right: -18px;
      font-size: 12px;
      color: #FFFFFF;
      line-height: 16px;
      text-align: center;
    }
  }
}


.hot-combobox{
  @include Position(1, 5, -10px, 40px);
  width: 110px;
  //height: 120px;
  background: #FFFFFF;
  box-shadow: 0px 12px 24px 0px rgba(0,0,0,0.06), 0px 0px 12px 0px rgba(0,0,0,0.08);
  border-radius: 10px;
  //height: 432px;
  //padding: 15px 34px 21px 34px;
  box-sizing: border-box;
  animation: fadeinT 0.5s 1;
  cursor: default;
  padding: 6px;

  &:before {
    content: '';
    width: 110px;
    height: 20px;
    background-color: transparent;
    @include Position(1, 5, 0,-20px);
  }


  .hot-item{
    display: inline-block;
    width: 98px;
    height: 36px;
    border-radius: 4px;
    line-height: 36px;
    text-align: center;
    font-size: 14px;
    color: #001013;
    cursor: pointer;
    &:hover{
      color: $color-primary;
    }
  }

  &:after {
    content: '';
    width: 10px;
    height: 10px;
    background-color: #FFFFFF;
    @include Position(1, 5, 50px, -4px);
    transform: rotate(135deg);
  }
}
</style>
