<template>
  <div>
    <div
      v-show="isShowLoginHome"
      class="login-wrap  ">

      <div class="__box-cover"/>

      <div class="login-box __box-content">

        <div class="login-picture">
          <i class="icon_logo"/>
          <div class="headline">天天拥有好设计</div>
          <div class="subhead">每日更新 新用户免费下载</div>
          <i class="icon_go"/>
        </div>

        <div class="login-content">
          <div class="headline"> {{ loginIndex === 0 ? '手机号' : '微信扫码' }}登录注册</div>
          <i class="txt_register"/>

          <!--    v-if="isShowLoginCloseBtn"      -->
          <i
             class="iconfont icon-cha _pointer"
             @click="toggleShowLogin"/>

          <div class="form" v-if="loginIndex === 0">
            <div class="form-item form-item-iphone">
              <input
                v-model="iphone"
                placeholder="输入手机号"
                @input="changeIPhone"
                @blur="verifyIphone">
              <div class="form-clues">{{ verifyIphoneClues }}</div>
            </div>
            <div class="form-item form-item-code">
              <input
                v-model="code"
                type="number"
                placeholder="输入验证码"
                @input="changeCode"
              >
              <span
                :class="[
                  downTimeStatus ? 'code-on': 'code-disabled'
                ]"
                class="text-get-code _pointer"
                @click="getCode">{{ codeText }}</span>
              <div class="form-clues">{{ verifyCodeClues }}</div>
            </div>
            <div
              class="form-item form-item-submit"
              @click="login">登录
            </div>
          </div>
          <div class="login-wx" v-if="loginIndex === 1">
            <img :src="wxLoginCode" alt="" class="login-wx-code">
            <div class="mask" v-if="wxLoginCodeState === 0"
                 @click="getWxLoginCode"
            >
              <i class="icon_refresh"/><br>
              二维码失效 <br>
              请点击刷新
            </div>
          </div>


          <div class="login-ways toggle-login-icon">
            <div class="login-way-item" v-if="loginIndex === 0">
              <i class="iconfont icon-wechat2 _pointer" @click="toggleLoginWay(1)"></i>
              微信登录
            </div>
            <div class="login-way-item" v-else>
              <i class="iconfont icon-mobile _pointer" @click="toggleLoginWay(0)"></i>
              手机号登录
            </div>
            <div class="login-way-item" >
              <i class="iconfont icon-QQ2 _pointer" @click="loginByQQ"></i>
              QQ登录
            </div>

          </div>
          <div class="protocol">

            <i
              :class="[ 'icon_gouxuan  _pointer', { 'on' : isChecked } ]"
              @click="toggleCheckProtocol"
            >
               <span class="tips" v-if="isCheckedTip">
                请阅读并勾选协议
              </span>
            </i>
            同意并阅读
            <a :href="PROTOCOL_PROBLEM_0" target="_blank">《用户协议》</a>、
            <a :href="PROTOCOL_PROBLEM_1" target="_blank">《版权声明》</a>、
            <a :href="PROTOCOL_PROBLEM_2" target="_blank">《隐私政策》</a>
          </div>
        </div>

      </div>

    </div>
  </div>

</template>

<script>
import _ from 'lodash'

import Cookies from 'js-cookie'
import {PROTOCOL_PROBLEM_0, PROTOCOL_PROBLEM_1, PROTOCOL_PROBLEM_2} from "@/constant/PATH";
import {getAttentionWxState, getWxLoginCode} from "@/api/login";
import {mapActions} from 'vuex'
import {DEVICE} from "@/constant/SYSTEM";
import {fnLogin} from "@/fn";

export default {
  name: "Login",
  data() {
    return {
      codeText: "获取验证码",
      verifyIphoneClues: "",   // 请输入手机号 手机号格式不正确
      verifyCodeClues: "",     // 请输入验证码 验证码不正确
      iphone: "",
      code: "",
      verifyStatus: false,
      countdownTime: 60,
      downTimeStatus: true,
      isChecked: false,
      PROTOCOL_PROBLEM_0,
      PROTOCOL_PROBLEM_1,
      PROTOCOL_PROBLEM_2,
      isCheckedTip: false,

      loginIndex: 0,// 0手机号码 1微信登录
      wxLoginCode: '',
      wxLoginCodeState: 1,
      wxLoginCodeTimer: null,
      attentionWxStateTimer: null,
      showWxLogin: false

    }
  },
  watch: {
    isChecked(newVal, oldVal) {
      if (newVal === true) {
        this.isCheckedTip = false;
      }
    }
  },
  computed: {
    // ...mapGetters('user',['isShowLogin'])
    isShowLoginHome: {
      get() {
        return this.$store.state.user.isShowLoginHome
      },
      set(val) {
        this.$store.commit("user/SET_IS_SHOW_LOGIN", val)
      }
    },
    isShowLoginCloseBtn: {
      get() {
        return this.$store.state.user.isShowLoginCloseBtn
      },
      set(val) {
        this.$store.commit("user/SET_IS_SHOW_LOGIN_CLOSE_BTN", val)
      }
    }
  },
  mounted() {
    if (process.client) {
      // this.$localStorage.setItem('isCheckedProtocol', 1)
      let flag = this.$localStorage.getItem('isCheckedProtocol')
      if (flag == 1) {
        // this.$localStorage.setItem('isCheckedProtocol', 1)
        this.isChecked = Boolean(flag)
      } else {

      }
    }

    let showWxLogin = this.$localStorage.getItem('showWxLogin');
    if (showWxLogin != undefined) {
      this.showWxLogin = showWxLogin;
    }
  },
  methods: {
    ...mapActions('user', ['LoginSuccessHandle']),
    toggleLoginWay(index) {
      /* 检查是否勾选协议 */
      if (!this.isChecked) {
        this.$myNotify.error('请先勾选协议')
        this.isCheckedTip = true
        return false
      }
      this.clearAllTimer();
      this.loginIndex = index;
      fnLogin.clearListenLoginByQQ();

      if (index === 1) {
        this.getWxLoginCode();
      } else {
        // this.resetForm();
        this.clearAllTimer();

      }
    },

    /* 微信登陆码倒计时 */
    wxLoginCodeCountdown() {
      clearInterval(this.wxLoginCodeTimer);
      //
      let count = 60;
      this.wxLoginCodeTimer = setInterval(() => {
        count--;
        console.log(count)
        if (count === 0) {
          this.wxLoginCodeState = 0;  // 过期
          // this.wxLoginCode = ''
          clearInterval(this.wxLoginCodeTimer);
          clearInterval(this.attentionWxStateTimer)
        }
      }, 1000)

    },

    getWxLoginCode() {
      this.wxLoginCodeState = 1;
      // clearInterval(this.wxLoginCodeTimer);
      clearInterval(this.attentionWxStateTimer)

      getWxLoginCode().then(res => {
        if (res.data.status === 1) {
          this.wxLoginCode = res.data.code
          this.wxLoginCodeCountdown();
          this.attentionWxStateTimer = setInterval(() => {
            this.getAttentionWxState()
          }, 2000)
        }
      })
    },

    getAttentionWxState() {
      getAttentionWxState().then(res => {
        console.log(res)
        if (res.data.status === 1) {
          fnLogin.clearListenLoginByQQ();
          this.$myNotify.success('微信登录成功', () => {
            console.log('login success')
            this.loginIndex = 0;
            clearInterval(this.attentionWxStateTimer)
            this.$store.commit('system/SET_DEVICE',DEVICE.wx)
            this.LoginSuccessHandle();
            this.clearAllTimer();
          })
        }
      })
    },

    verifyIphone() {
      let temp = this.$utils.verify.iphone(this.iphone);
      let clues = ["请输入手机号", "", "手机号格式不正确"];
      this.verifyIphoneClues = clues[temp.status];
      this.verifyStatus = temp.status === 1;
      return temp.status === 1;
    },

    changeIPhone() {
      this.iphone = this.iphone.replace(/[^\d]/g, '')
      if (this.iphone.length) {
        this.verifyIphoneClues = '';
      }
    },

    verifyCode() {
      let bool = !(this.code === '');
      let clues = ["请输入验证码", ""];
      this.verifyCodeClues = clues[Number(bool)];
      this.verifyStatus = bool;
      return bool
    },

    changeCode() {
      if (this.code.length > 0) {
        this.verifyCodeClues = '';
      }
    },

    getCode: _.throttle(function () {

      if (!this.isChecked) {
        this.$myNotify.error('请先勾选协议')
        this.isCheckedTip = true
        return false
      }
      this.verifyIphone();
      if (this.verifyStatus && this.downTimeStatus) {
        this.$utils.time.countdown(this.countdownTime, 60, time => {
          this.downTimeStatus = false;
          this.codeText = `${time}秒后再试`
        }).then(r => {
          this.codeText = `获取验证码`;
          this.downTimeStatus = true;
        })
        this.$store.dispatch("user/GetVerificationCode", this.iphone)
          .then(res => {
            let {info, status} = res.data;
            if (status !== 1) {
              this.verifyCodeClues = info;
            }
          })
          .catch(err => {

          });
      } else {
      }
    }, 2000),

    login: _.throttle(function () {

      if (!this.isChecked) {
        this.$myNotify.error('请先勾选协议')
        this.isCheckedTip = true
        return false
      }
      if (this.verifyIphone() && this.verifyCode()) {
        if (this.verifyStatus) {
          // 调用登录接口
          let userInfo = {
            phone: this.iphone,
            code: this.code
          }
          this.$store.dispatch("user/LoginByVerificationCode", userInfo)
            .then(res => {
              let  { status,info } = res.data;
              if (status == 0){
                this.verifyCodeClues =  info
              }else if(status === 1){
                // 关闭qq查询
                fnLogin.clearListenLoginByQQ();
                this.$store.commit('system/SET_DEVICE',DEVICE.iphone)
                setTimeout(()=>{
                  this.resetForm();
                },2000)
              }
            })
            .catch(err => {
            })
        }

        return false;

      }
    }, 2000),

    toggleCheckProtocol() {
      this.isChecked = !this.isChecked;
      this.$localStorage.setItem('isCheckedProtocol', Number(this.isChecked))
    },

    resetForm() {
      setTimeout(()=>{
        this.verifyIphoneClues = '';
        this.verifyCodeClues = '';
        this.codeText = '获取验证码';
        this.iphone = ''
        this.code = ''
        this.countdownTime = 60;
        this.downTimeStatus = true;
        this.verifyStatus = true;
      },2000)
    },

    clearAllTimer() {
      clearInterval(this.wxLoginCodeTimer);
      clearInterval(this.attentionWxStateTimer)
    },
    toggleShowLogin() {
      this.resetForm();
      this.clearAllTimer();
      this.$store.commit("user/SET_IS_SHOW_LOGIN_HOME", 0);
      this.loginIndex = 0;
    },

    loginByQQ(){
      fnLogin.loginByQQ(this.$store);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index";
</style>
