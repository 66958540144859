<template>

  <div>
    <nuxt/>
    <Footer/>
    <GlobalSlot />
    <no-ssr>
      <Login />
      <PublicSlot />
    </no-ssr>
  </div>
</template>

<script>
import { Footer, Login} from "@/components/common/index.js"
import GlobalSlot from '@/components/common/global-slot'
import PublicSlot from "@/components/_Public/PublicSlot";

export default {
  name: "LayoutFooter",
  components: {
    Footer,
    Login,
    GlobalSlot,
    PublicSlot
  },
}
</script>

<style scoped>


</style>
