<template>
  <div class="layoutEmpty">
    <nuxt/>
    <no-ssr>
      <Login/>
    </no-ssr>
  </div>
</template>

<script>
import {Login} from "@/components/common/index.js";
import ActiveBackHome from "@/components/_components/active/ActiveBackHome"

export default {
  name: "layoutEmpty",
  components: {
    Login,
    ActiveBackHome
  },
}
</script>

<style scoped>

</style>
