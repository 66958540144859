import request from "@/utils/request";
import qs from "qs";

export function getAuthList(query) {
  return request({
    url: '/?m=Redeem&a=auth',
    method: 'get',
    params: query
  })
}

export function getExchangeCodeListByUserCenter(data) {
  return request({
    url: '/?m=Redeem&a=query',
    method: 'post',
    data: qs.stringify(data)
  })
}


export function cancelExchangeCode(data) {
  return request({
    url: '/?m=Redeem&a=cancel',
    method: 'post',
    data: qs.stringify(data)
  })
}


/* 企业兑换主体列表 */
export function getCompanyMainList(query) {
  return request({
    url: '/?m=Company&a=giftMain',
    method: 'get',
    params: query
  })
}

/* 新增企业主体 */
export function addMain(data) {
  return request({
    url: '/?m=Company&a=addMain',
    method: 'post',
    data: qs.stringify(data)
  })
}

export function exchangeCodeByCompany(data) {
  return request({
    url: '/?m=Company&a=mainCodeExchange',
    method: 'post',
    data: qs.stringify(data)
  })
}
