<template>
  <div>
    <!-- 8.8红包弹框 -->
    <CgModalMiddle
      :is-show-close="false"
    >
      <div
        slot="modal_content"
        class="modal_content _user-select">
        <div class="title">您的免费下载次数已用完</div>
        <div class="coupon-name">
          会员惊喜红包
        </div>
        <div class="coupon-price">
          <i class="icon_dollar">¥</i>
          <div class="price">8.8</div>
          <div class="text-coupon-type">
            全站 <br>
            通用
          </div>
        </div>
        <div
          class="receive-coupon"
          @click="receive"
        >
          立即领取
        </div>
        <div class="txt-indate">领取后24小时内有效</div>
        <i
          class="iconfont icon-cha"
          @click="close"
        />
      </div>
    </CgModalMiddle>
  </div>
</template>

<script>
import {fnGuideDownloadCount, fnLocalStorage, fnRouter} from "@/fn";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import ToolDate from "@/tools/ToolDate";
import {HOME} from "@/constant/HOME";
import {getRmbEightCoupon} from "@/api/home";
import {tag} from "@/utils/statUtil";

export default {
  name: "GuideDownloadCountCoupon",
  data() {
    return {
      state: 1
    }
  },
  mounted(){
    fnGuideDownloadCount.trackNewUser(HOME.NEW_USER_COUPON_TYPE.RED_PACKET,HOME.NEW_USER_COUPON_TYPE.POSITION.CURRENT_PAGE)
  },
  methods: {
    async receive() {
      await getRmbEightCoupon({ couponId:6 })
      await fnRouter.navigationTo(PAGE_PATH.PAYMENT.BUSINESS);
      await tag('充值:会员惊喜礼包:图片')
      await fnGuideDownloadCount.trackNewUser(HOME.NEW_USER_COUPON_TYPE.RED_PACKET,HOME.NEW_USER_COUPON_TYPE.POSITION.TO_PAY_PAGE)
      this.$emit('close')
    },
    close() {
      /* 获取当天时间，关闭加入红包显示天数数组 */
      let date = ToolDate.getDate().date;
      let local_showCouponDate = fnLocalStorage.getItem(HOME.GUIDE.DOWNLOAD_COUNTS.RMB_EIGHT_COUPON.SHOW_DATE_ARR);
      console.log(local_showCouponDate)
      if (!local_showCouponDate) {
        let arr = [];
        arr.push(date)
        fnLocalStorage.setItem(HOME.GUIDE.DOWNLOAD_COUNTS.RMB_EIGHT_COUPON.SHOW_DATE_ARR, arr);
      } else {
        local_showCouponDate.push(date)
        let _local_showCouponDate = Array.from(new Set(local_showCouponDate));
        fnLocalStorage.setItem(HOME.GUIDE.DOWNLOAD_COUNTS.RMB_EIGHT_COUPON.SHOW_DATE_ARR, _local_showCouponDate);
      }
      this.$emit('close')
    },
    tag(stat) {
      tag(stat)
    },
  }
}
</script>

<style lang="scss" scoped>
.modal_content {
  background: #FFFFFF;
  width: 407px;
  height: 415px;
  border-radius: 30px;
  @include BgImage(407px, 415px, 'home/download-count-guide/coupon-bg.png');
  padding-top: 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .title {
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 19px;
  }

  .coupon-name {
    font-size: 34px;
    font-weight: bold;
    color: #FF6156;
    line-height: 34px;
    margin: 34px 0 67px;
  }

  .coupon-price {
    display: flex;
    align-items: flex-end;
    font-size: 0;

    .icon_dollar {
      font-size: 23px;
      font-weight: 400;
      color: #F64438;
      line-height: 18px;
      position: relative;
      top: -4px;
    }

    .price {
      font-size: 83px;
      font-weight: bold;
      color: #F64438;
      line-height: 67px;
      margin: 0 14px 0 17px;
    }

    .text-coupon-type {
      font-size: 22px;
      color: #F64438;
      line-height: 28px;
      font-weight: bold;
    }


  }

  .receive-coupon {
    width: 285px;
    height: 89px;
    line-height: 89px;
    border-radius: 30px;
    font-size: 23px;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
    @include BgImage(285px, 89px, 'home/download-count-guide/receive-btn.png');
    margin-top: 20px;

    @include ElementHover('', '') {
      @include BgImage(285px, 89px, 'home/download-count-guide/receive-btn@active.png');
    }
  ;
  }

  .txt-indate {
    font-size: 15px;
    font-weight: 400;
    color: rgba(246, 68, 56, 0.6);
    line-height: 15px;
  }

  .icon-cha {
    width: 32px;
    height: 32px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    font-size: 14px;
    @include LayoutFlex(1);
    @include Position(1, 2, -55px, 15px);
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    @include ElementHover('', '') {
      transform: rotate(180deg);
    }
  }
}
</style>
