<template>
  <div
    class="ct-pagination"
    @click="pageClick">
    <button
      :class="{ 'btn-disabled' :currentPage == 1 }"
      class="prev-page _pre"
      type="button"
    >
      <i class="iconfont icon-a-arrowright _pre"/>
    </button>

    <ul>
      <li
        :class="{ 'curr': 1 == currentPage}"
        class="first-page">
        1
      </li>

      <li
        v-show="showPreMore"
        class="prev-more _left"
      >
        <i class="iconfont icon-more _left"/>
      </li>

      <li
        v-for="vo in $totalPage"
        :key="vo"
        :class="{ 'curr': vo == currentPage}"
      >
        {{ vo }}
      </li>

      <li
        v-show="showNextMore"
        class="next-more _right">
        <i class="iconfont icon-more _right"/>
      </li>

      <li
        :class="{ 'curr': lastPage == currentPage}"
        class="next-page">
        {{ lastPage }}
      </li>
    </ul>
    <button
      :class="{ 'btn-disabled' :currentPage == lastPage }"
      class="last-page _next"
      type="button"
    >
      <i class="iconfont icon-a-arrowright _next"/>
    </button>


    <div class="jump-page">
      到
      <input
        type="text" class="_jump_input"
        v-model="jumpPage"
        @keyup.enter="onSubmit"
        @change="changeJumpInput"
      >
      <button
        class="_jumpPage"
        @click.stop="onSubmit"
      >确定</button>
      页
    </div>
  </div>


</template>

<script>
import _ from 'lodash'
export default {
  name: "CtPagination",
  props: {

    totalPage: {
      type: Number | String,
      default: 5
    },
    paperCount: {
      type: Number | String,
      default: 5
    },
    currentPage: {
      type: Number | String,
      default: 100
    },
  },

  data() {
    return {
      showPreMore: false,
      showNextMore: false,
      jumpPage:'',
    }
  },
  computed: {
    // 中间页数组
    $totalPage() {
      const foldPage = this.paperCount; // 最大标签数
      const current = Number(this.currentPage)  // 当前页
      const halfFoldPage = Math.floor((foldPage - 2) / 2) // 中间页

      if( this.lastPage < foldPage ){
        this.showNextMore = false
        this.showPreMore = false
        let array = []
        for (let i = 2; i < this.lastPage; i++) {
          array.push(i)
        }
        return array;
      }
      /* 是否显示 左右省略号 */
      /* 如果总页数 - 中间页 > 2 */
      if (this.lastPage > foldPage) {

        if (current - halfFoldPage > 2) {
          this.showPreMore = true
        } else {
          this.showPreMore = false
        }
        if (current + halfFoldPage < this.lastPage) {
          this.showNextMore = true
        } else {
          this.showNextMore = false
        }

      }

      let array = []
      // folder1显示
      if (this.showNextMore && !this.showPreMore) {
        for (let i = 2; i < foldPage; i++) {
          array.push(i)
        }
        // folder1 和 folder2都显示
      } else if (this.showPreMore && this.showNextMore) {
        for (let i = current - halfFoldPage; i <= current + halfFoldPage; i++) {
          array.push(i)
        }
        // folder2显示
      } else if (!this.showNextMore && this.showPreMore) {
        // 当folder2显示的时候,页码不能大于$last,需要往前多显示差额
        let dis = current + halfFoldPage - this.lastPage + 1;
        for (let i = current - halfFoldPage - dis; i < this.lastPage; i++) {
          array.push(i)
        }
        // 都不显示
      } else {
        for (let i = 2; i < this.lastPage; i++) {
          array.push(i)
        }
      }
      console.log(array)
      return array


    },
    // 总页数
    lastPage() {
      return this.totalPage
    },
  },


  methods: {
    // pageClick(e) {
    pageClick:_.throttle(function (e) {
        let newPage = Number(e.target.textContent)
        console.log(isNaN(newPage))
        if(isNaN(newPage)){
          return false
        }
        let tempPage = this.currentPage;
        if (!isNaN(newPage) && newPage) {
          tempPage = newPage
        } else {
          // 下一页
          if (e.target.className.indexOf('_next') != -1) {
            if (this.currentPage == this.lastPage) {
              return;
            }
            tempPage++
          }
          // 上一页
          else if (e.target.className.indexOf('_pre') != -1) {
            if (this.currentPage == 1) {
              return
            }
            tempPage--
          }

          else if (
            e.target.className.indexOf('_left') != -1 ||
            e.target.className.indexOf('_right') != -1
          ){
            return
          }

          else if (
            e.target.className.indexOf('_jump_input') != -1
          ){
            return
          }

          else if (
            e.target.className.indexOf('_jumpPage') != -1
          ){
            this.jumpPage =  tempPage = this.jumpPage <= 0 ?  1 : this.jumpPage
            this.jumpPage =  tempPage = this.jumpPage > this.totalPage ? this.totalPage : this.jumpPage ;
            console.log(this.jumpPage)
            if (this.jumpPage === ''){
              this.$myNotify.show('请输入跳转页码');
              return false;
            }
          }

          // 省略向左
          /*else if (e.target.className.indexOf('_left') != -1) {
            tempPage = this.$totalPage[0]
            tempPage -= this.paperCount - 2

            if (this.currentPage <= 1) {
              tempPage = 1
              return
            }

          }
          // 省略向右
          else if (e.target.className.indexOf('_right') != -1) {
            tempPage += this.paperCount - 2
            if (this.currentPage >= this.lastPage) {
              tempPage = this.lastPage
              return
            }
          }*/
        }
        this.$emit('pageClick', tempPage)
      },
      200),

    changeJumpInput(){
      this.jumpPage = this.jumpPage.replace(/[^\d]/g, '')
    },

    onSubmit(){
      console.log(this.jumpPage)
      this.jumpPage = this.jumpPage.replace(/[^\d]/g, '')
      if (this.jumpPage === ''){
        return false;
      }
      this.jumpPage = this.jumpPage <= 0 ?  1 : this.jumpPage
      this.jumpPage = this.jumpPage > this.totalPage ? this.totalPage : this.jumpPage ;


      this.$emit('pageClick', this.jumpPage)


    }
  },
}
</script>

<style lang="scss" scoped>
.ct-pagination {
  margin: 50px;
  display: flex;
  align-items: center;
  justify-content: center;


  button {
    //消除button的默认样式
    margin: 0;
    padding: 0;
    border: 1px solid transparent; //自定义边框
    outline: none; //消除默认点击蓝色边框效果

    &.btn-disabled {
      cursor: not-allowed;
      color: #c0c4cc;

      &:hover {
        background: #EBEDF5 !important;
        color: #c0c4cc !important;
      }
    }
  }

  ul {
    display: flex;

  }

  li, button {
    display: inline-block;
    min-width: 40px;
    height: 40px;
    background: #EBEDF5;
    border-radius: 8px;
    padding: 0 12px;
    box-sizing: border-box;
    line-height: 40px;
    text-align: center;
    margin-right: 12px;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    color: #596080;

    &:hover {
      background: #6969FF;
      color: #FFFFFF;
    }

    &.curr {
      background: #6969FF;
      color: #FFFFFF;
    }
  }

  .prev-more,
  .next-more {
    color: rgba(89, 96, 128, 0.25);
    background-color: #fff !important;
    color: #596080 !important;
    cursor: not-allowed !important;
    @include ElementHover('', '');
  }

  .prev-page {
    transform: rotate(180deg);
  }

  .jump-page{
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #596088;
    margin-left: 12px;

    input{
      width: 55px;
      height: 40px;
      border-radius: 8px 0px 0px 8px;
      border: 1px solid #EBEDF5;
      padding: 0 5px;
      box-sizing: border-box;
      text-align: center;
      font-size: 14px;
      color: #2D2B33;
      margin-left: 8px;
    }
    button{
      width: 48px;
      height: 40px;
      background: #EBEDF5;
      border-radius: 0px 8px 8px 0px;
      padding: 0;
      margin: 0;
      margin-right: 8px;

      @include ElementHover(#FFFFFF, #6969FF)
    }
  }

}
</style>
