const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['authV2'] = require('../middleware/authV2.js')
middleware['authV2'] = middleware['authV2'].default || middleware['authV2']

middleware['axiosMiddle'] = require('../middleware/axiosMiddle.js')
middleware['axiosMiddle'] = middleware['axiosMiddle'].default || middleware['axiosMiddle']

middleware['botState'] = require('../middleware/botState.js')
middleware['botState'] = middleware['botState'].default || middleware['botState']

middleware['companyManage'] = require('../middleware/companyManage.js')
middleware['companyManage'] = middleware['companyManage'].default || middleware['companyManage']

middleware['initCommonApi'] = require('../middleware/initCommonApi.js')
middleware['initCommonApi'] = middleware['initCommonApi'].default || middleware['initCommonApi']

middleware['login'] = require('../middleware/login.js')
middleware['login'] = middleware['login'].default || middleware['login']

export default middleware
