const env = {
  development: {
    DESCRIPTION: '开发环境',
    API_BASE_URL: 'http://chaopx.com/',
    APP_BASE_API: "https://api.chaopx.com",
  },
  production: {
    DESCRIPTION: '线上环境',
    API_BASE_URL: 'https://chaopx.com/',
    APP_BASE_API: "https://api.chaopx.com"
  }
}
export default env;
