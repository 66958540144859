<template>
  <div class="FansHeader">
    <div class="inner_center">
      <div class="layout_left">
        <HeaderLogo class="HeaderLogo"
                    :showCollect="false"
                    v-bind="$attrs"
                    v-on="$listeners"

        />
        <div class="fan-section" :class=" navColor ">宠粉专区</div>
        <div class="header-nav _hide">
          <div
            class="header-nav-item"
            ref="HeaderNavItem"
            v-for="(item,index) in FANS.COMMON.HEADER_NAV"
            :key="item.label"
            :class="[ { 'ON' : index == currNavIdx }, navColor ]"
            @click="toggleHeaderNav({item,index})"
          >
            {{ item.label }}
            <div class="bar" v-show="index == currNavIdx && isShowBar " :style="{ width: barWidth }"></div>

            <i class="opening" v-if="item.label === '会员需求池'|| item.label === '签到有礼' ">已开放</i>
          </div>
        </div>
      </div>
      <div class="layout_right">
        <HeaderLogin/>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderLogo from '@/components/common/header/header-logo';
import HeaderLogin from '@/components/common/header/header-login';
import {FANS} from "@/constant/fans/FANS";
import {fnRouter} from "~/fn";

export default {
  name: "FansHeader",
  props: {
    navColor: {
      type: String | Number,
      default: '_white'
    },
    currNavIdx:{
      type:  Number | String,
      default: -1
    }
  },
  components: {
    HeaderLogin,
    HeaderLogo,
  },
  data() {
    return {
      FANS,
      barWidth: 0,
      isShowBar: false
    }
  },
  watch: {
    currNavIdx: {
      handler(n, o) {
        if (n> -1){
          this.barWidth = 0
          this.$nextTick(() => {
            if (this.$refs.HeaderNavItem) {
              this.isShowBar = true;
              this.barWidth = this.$refs.HeaderNavItem[n].offsetWidth + 'px';
            }
          })
        }

      },
      immediate: true
    }
  },
  mounted() {
    // this.initWait();
  },
  methods: {
    toggleHeaderNav(info) {
      let {item, index} = info;
      this.currNavIdx = index;
      /*if (item.label == '会员需求池 '){
        fnRouter.routerTo()
      }else{
        this.$myNotify.show('敬请期待')
      }*/
      if (item.link == ''){
        this.$myNotify.show('敬请期待')
        return false
      }
      fnRouter.navigationTo(item.link)

    },
    initWait() {
      let handleBtn = document.getElementsByClassName('header-nav-item');
      for (let i = 0; i < handleBtn.length; i++) {
        handleBtn[i].onclick = () => {
          this.$myNotify.show('敬请期待')
        }

      }
    },
  }
}
</script>

<style lang="scss" scoped>

._black {
  color: #000;
}

._white {
  color: #FFF;
}

.FansHeader {
  height: 76px;
  margin-top: 24px;

  > .inner_center {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .layout_left {
      display: flex;
      align-items: center;

      .icon-logo1 {
        font-size: 24px;
        margin-right: 26px;
      }

      .HeaderLogo {
        //color: #FFFFFF;
        font-size: 24px;
        margin-right: 26px;

        /deep/ .icon-logo {
          //color: #FFFFFF;
        }
      }

      .fan-section {
        font-size: 17px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 1px;
        margin-right: 60px;
        position: relative;

        &:after {
          content: '';
          display: inline-block;
          width: 2px;
          height: 14px;
          background: #333333;
          border-radius: 2px;
          @include Position(1, 5, -12px, 55%);
          transform: translateY(-50%);
        }

        &._white {
          &:after {
            background: #FFFFFF;
          }
        }
      }

      .header-nav {
        display: flex;
        align-items: center;
        color: #FFFFFF;

        .header-nav-item {
          margin-right: 32px;
          position: relative;
          cursor: pointer;

          &.ON {
            color: $color-fans;
          }

          .bar {
            width: 0;
            //display: none;
            transition: all 0.5s ease;
          }

          &:last-child {
            margin-right: 0;
          }

          .opening{
            width: 46px;
            height: 16px;
            background: #FE0000;
            border-radius: 100px 100px 100px 1px;
            position: absolute;
            top: -16px;
            right: -14px;
            font-size: 12px;
            color: #FFFFFF;
            line-height: 16px;
            text-align: center;
          }

          &.ON {
            .bar {
              //display: block;
              width: 0;
              height: 3px;
              background: linear-gradient(270deg, rgba(255, 93, 110, 0.15) 0%, rgba(255, 93, 110, 0.5) 44%, #FF5D6E 100%);
              border-radius: 2px;
              @include Position(1, 4, 0, -11px);
            }
          }

        }
      }
    }
  }
}
</style>
