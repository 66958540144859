<!--
  说明：此弹框为独立绑定手机弹框
-->
<template>
  <!--  -->
  <div v-show="userBindIphoneAloneModal || actIsShow" class="bind-iphone-wrap">
    <!--  绑定手机  -->
    <cg-dialog @close="close">
      <div
        v-show="isBindIphone"
        slot="dialog_content"
        class="bind-iphone">
        <div class="headline text-center">绑定手机号</div>
        <div class="subhead text-center">
          根据法律法规要求，请绑定手机号完成实名认证，<br>
          潮国创意将严格保密用户隐私信息
        </div>
        <div class="bind-iphone-err-tip text-center _hide" v-if="bindIphoneErr">该手机号已注册/绑定，请更换手机号重试。</div>
        <div class="form">
          <div class="form-item form-item-iphone">
            <input
              v-model="iphone"
              type="number"
              placeholder="输入手机号"
              @input="changeIPhone"
              @blur="verifyIphone"
            >
            <div class="form-clues">{{ verifyIphoneClues }}</div>
          </div>
          <div id="nc2"></div>
          <div class="form-item form-item-code">
            <input
              v-model="code"
              type="number"
              placeholder="输入验证码"
              @input="changeCode"
            >
            <span
              :class="[downTimeStatus ? 'code-on': 'code-disabled']"
              class="text-get-code _pointer"
              @click="getCode">{{ codeText }}</span>
            <div class="form-clues">{{ verifyCodeClues }}</div>
          </div>
          <div
            class="form-item form-item-submit"
            @click="bindIphone">绑定手机号
          </div>
        </div>
      </div>
      <div
        v-show="!isBindIphone"
        slot="dialog_content"
        class="bind-iphone-succ">
        <i class="iconfont icon-gou2"/>
        <div class="text-bind-succ">绑定成功</div>
        <div class="text-bind-iphone">已绑定手机号：{{ iphone }}</div>
        <div class="text-bind-iphone">您可用手机号或社交账号登录潮国创意</div>
        <div
          class="btn-submit"
          @click="finish">完成
        </div>
      </div>
    </cg-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import user from "@/store/modules/user";
import {bindIphone, getCode} from "~/api/login";
import localStorage from "@/utils/localStorage";
import {mapActions, mapMutations, mapState} from "vuex";
import Cookies from 'js-cookie'
import {MODEL_BIND_IPHONE, MODEL_LOGIN_PV_COUNT, MODEL_PIC_ID} from "~/constant/MODELS";

import {fnBindIphone, fnLocalStorage, fnLogin} from '~/fn';
import {MATERIAL_DOWNLOAD} from "@/constant/PATH";
import {getCookie} from "@/utils/auth";
import {completeTaskByFanClub} from "@/api/fans/club";
import {setUserBindIphoneAloneModalStatus} from "@/components/_Privately/User/UserBindIphoneAloneModal/module";

export default {
  name: "UserBindIphoneAloneModal",
  data() {
    return {
      codeText: "获取验证码",
      verifyIphoneClues: "",   // 请输入手机号 手机号格式不正确
      verifyCodeClues: "",     // 请输入验证码 验证码不正确
      iphone: "",
      code: "",
      verifyStatus: false,
      countdownTime: 60,
      downTimeStatus: true,

      isBindIphone: true,
      visible: true,
      bindIphoneErr: false,
      codeForm: {
        sessionId: undefined,
        sig: undefined,
        token: undefined,
        scene: undefined,
      },
      isShow: false,
      actIsShow: false,
      showHuakuai: false,
    }
  },
  mounted() {

  },
  computed: {
    ...mapState('_Privately/User', ['userBindIphoneAloneModal'])
  },
  watch: {

    isShowBindIphone: {
      handler(n, o) {
        if (n == false) {
          this.resetForm();
        } else {
          this.initHuaKuai();
        }
      },
      immediate: true
    },

    actIsShow: {
      handler(n, o) {
        if (n == false) {
          this.resetForm();
        } else {
          this.initHuaKuai();
        }
      },
      immediate: true
    },
  },
  methods: {

    checkIsBind() {
      if (fnLogin.checkLogin()) {
        if (getCookie('phone') == 0 && !this.getTodayIsBind()) {
          console.log('--手机号码未绑定--')
          try {
            // this.isShow = true;
            // this.isShowBindIphone = true;
            this.$store.commit('user/SET_IS_SHOW_BIND_IPHONE', true)
          } catch (e) {
            console.log(e)
          }
        } else {
          this.$store.commit('user/SET_IS_SHOW_BIND_IPHONE', false)
        }
      }
    },
    /* 获取当天本地绑定 */
    getTodayIsBind() {
      return localStorage.getItemDate(MODEL_BIND_IPHONE);
    },

    finish() {
      // this.visible = false;
      this.actIsShow = false;
      this.$store.commit('user/SET_IS_SHOW_BIND_IPHONE', false)
      this.resetForm();
    },
    close() {
      this.isShow = false;
      this.actIsShow = false;
      setUserBindIphoneAloneModalStatus(this.$store, false)

      // this.visible = false;
      fnBindIphone.updateTodayBindState();
      // this.$emit('close')

      let picId = localStorage.getItemDate(MODEL_PIC_ID);
      if (!picId) {
        console.log(111)
      } else {
        console.log(2222)
        // console.log(this.$route.path)
        if (this.$nuxt._route.path.indexOf('tupian/detail/') > -1) {
          let routeUrl = MATERIAL_DOWNLOAD + `/${picId}.html`;
          window.open(routeUrl, '_blank');
        }
      }
    },
    changeIPhone() {
      this.iphone = this.iphone.replace(/[^\d]/g, '')
      if (this.iphone.length) {
        this.verifyIphoneClues = '';
      }
    },
    verifyIphone() {
      let temp = this.$utils.verify.iphone(this.iphone);
      let clues = ["请输入手机号", "", "手机号格式不正确"];
      this.verifyIphoneClues = clues[temp.status];
      this.verifyStatus = temp.status === 1;
      return temp.status === 1;
    },

    verifyCode() {
      let bool = !(this.code === '');
      let clues = ["请输入验证码", ""];
      this.verifyCodeClues = clues[Number(bool)];
      this.verifyStatus = bool;
      return bool
    },

    changeCode() {
      if (this.code.length > 0) {
        this.verifyCodeClues = '';
      }
    },

    getCode: _.throttle(function () {

      let temp = this.$utils.verify.iphone(this.iphone);
      let clues = ["请输入手机号", "", "手机号格式不正确"];
      if (temp.status != 1) {
        this.$myNotify.error(clues[temp.status])
        return false
      }

      /* 验证滑块 */
      if (this.codeForm.sessionId == undefined) {
        this.$myNotify.error('请先进行滑块验证')
        return false
      }


      if (this.verifyStatus && this.downTimeStatus) {
        let params = Object.assign(this.codeForm, {phone: this.iphone, act: 'bind'})

        getCode(params).then(res => {
          let {status, info} = res.data;

          if (status === 1) {
            // this.bindIphoneErr = false;
            this.$myNotify.success('发送成功');
            this.$utils.time.countdown(this.countdownTime, 60, time => {
              this.downTimeStatus = false;
              this.codeText = `${time}秒后再试`
            }).then(r => {
              this.codeText = `获取验证码`;
              this.downTimeStatus = true;
            })
          } else if (status == -1) {
            // this.bindIphoneErr = true;
            // this.$myNotify.error(`${response.info}`);
            this.verifyIphoneClues = info;
          } else if (status == -2) {
            this.verifyCodeClues = info;
          } else {
            // this.$myNotify.error(`${response.info}`);
            this.verifyCodeClues = `${response.info}`
          }

          if (status != 1) {
            window.nc2.reset()
          }
        }).catch(error => {
          this.$myNotify.error(`发送失败，请稍后重试`);
        })


      }
    }, 2000),

    resetForm() {
      this.verifyIphoneClues = '';
      this.verifyCodeClues = '';
      this.codeText = '获取验证码';
      this.iphone = ''
      this.code = ''
      this.countdownTime = 60;
      this.downTimeStatus = true;
      this.verifyStatus = true;
    },

    bindIphone() {
      /* 验证滑块 */

      if (this.verifyIphone() && this.verifyCode()) {

        if (this.verifyStatus) {
          bindIphone({
            phone: this.iphone,
            code: this.code,
          }).then(res => {
            console.log(res)
            let {status, info} = res.data;
            if (status == 1) {
              this.isBindIphone = false;
              Cookies.set('phone', this.iphone)
              this.$store.commit('user/SET_PHONE', this.iphone)
              this.$store.dispatch('user/getUserInfo')
              /* 检测是否是粉丝基础任务 */
              let {task_type, id} = this.$route.query;

              if (task_type && id) {
                completeTaskByFanClub({id}).then(res => {
                  let {status, data, message} = res.data;
                  if (status == 200) {
                    this.$myNotify.success("任务已完成")
                    this.$router.push({
                      query: {}
                    })
                  } else {
                    this.$myNotify.error(message)

                  }
                })
              }


            } else if (status == -1) {
              this.verifyIphoneClues = info;
            } else if (status == -2) {
              this.verifyCodeClues = info;
            } else {
              this.$myNotify.error(info, () => {
              }, 3000);
            }
          })
        }
      }


    },
    initHuaKuai() {
      let _this = this;

      if (this.showHuakuai) {
        return
      }
      this.showHuakuai = true;

      AWSC.use("nc", function (state, module) {
        // 初始化
        window.nc2 = module.init({
          // 应用类型标识。它和使用场景标识（scene字段）一起决定了滑动验证的业务场景与后端对应使用的策略模型。您可以在阿里云验证码控制台的配置管理页签找到对应的appkey字段值，请务必正确填写。
          appkey: "FFFF0N0000000000638F",
          //使用场景标识。它和应用类型标识（appkey字段）一起决定了滑动验证的业务场景与后端对应使用的策略模型。您可以在阿里云验证码控制台的配置管理页签找到对应的scene值，请务必正确填写。
          scene: "nc_login",
          // 声明滑动验证需要渲染的目标ID。
          renderTo: "nc2",
          width: 330,
          //前端滑动验证通过时会触发该回调参数。您可以在该回调参数中将会话ID（sessionId）、签名串（sig）、请求唯一标识（token）字段记录下来，随业务请求一同发送至您的服务端调用验签。
          success: function (data) {
            let {sessionId, sig, token} = data
            _this.codeForm = {
              sessionId,
              sig,
              token,
              scene: 'nc_login',
            }
          },
          // 滑动验证失败时触发该回调参数。
          fail: function (failCode) {
            window.console && console.log(failCode)
          },
          // 验证码加载出现异常时触发该回调参数。
          error: function (errorCode) {
            window.console && console.log(errorCode)
          }
        });
      })
    },
  }
}
</script>

<style lang="scss" scoped>

.bind-iphone-wrap {
  /deep/ .dialog-cover {
    z-index: 999 !important;
  }

  /deep/ .dialog-content {
    z-index: 1000 !important;
  }
}

.text-center {
  text-align: center;
}

.bind-iphone {
  padding: 56px 93px;

  .headline {
    font-size: 20px;
    color: #2D2B33;
    line-height: 28px;
  }

  .subhead {
    margin: 4px 0 28px;
    color: #596088;
  }

  .bind-iphone-err-tip {
    //margin-bottom: 10px;
    color: #EE5253;

  }
}

#nc2 {
  position: relative;
  margin-bottom: 24px;
  border-radius: 8px;
  overflow: hidden;

  /deep/ #nc_2_n1z {
    border-radius: 8px !important;
    overflow: hidden;
  }

  /deep/ #nc_1_n1z {
    border-radius: 8px !important;
    overflow: hidden;
  }
}

.form {
  //margin: 21px 0 44px;
  .form-item {
    width: 330px;
    height: 50px;
    position: relative;
    margin-bottom: 24px;


    input {
      width: 100%;
      height: 100%;
      padding: 0 50px 0 20px;
      box-sizing: border-box;
      font-size: 14px;
      color: #001013;
      border: 1px solid #E0E3E9;
      border-radius: 8px;
      transition: all 0.3s ease-in;

      &::placeholder {
        color: rgba(0, 16, 19, 0.4);
      }

      &:focus {
        outline: none;
        border: 1px solid $color-primary;

      }
    }

    .text-get-code {
      height: 100%;
      line-height: 50px;
      @include Position(1, 2, 0, 50%);
      padding-right: 20px;
      transform: translateY(-50%);
    }

    .code-disabled {
      color: rgba(0, 16, 19, 0.4);
    }

    .code-on {
      color: rgba(105, 105, 255, 0.8);
      @include ElementHover(#6969FF, '');
    }


    .form-clues {
      //@include Position(1,5,0,0);
      font-size: 12px;
      color: #EE5253;
      line-height: 24px;
      padding-left: 20px;
    }
  }

  .form-item-submit {
    width: 330px;
    height: 50px;
    background: #6969FF;
    border-radius: 8px;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 50px;
    text-align: center;
    border: none;
    @include ElementHover('', #5E5EE8);
    margin-bottom: 0;
  }


}

.bind-iphone-succ {
  width: 516px;
  height: 382px;
  background: #FFFFFF;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon-gou2 {
    display: flex;
    font-size: 32px;
    color: #35D0BA;
    width: 64px;
    height: 64px;
    border: 4px solid #35D0BA;
    border-radius: 50%;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    margin: 72px 0 28px;

  }

  .text-bind-succ {
    font-size: 20px;
    color: #2D2B33;
    line-height: 28px;
    margin-bottom: 8px;
  }

  .text-bind-iphone {
    font-size: 14px;
    color: #596088;
    line-height: 26px;
    //margin: 8px 0 8px;
  }

  .btn-submit {
    margin-top: 40px;
    width: 240px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #6969FF;
    border-radius: 8px;
    font-size: 16px;
    color: #FFFFFF;
    cursor: pointer;
    @include ElementHover('', #5E5EE8);
  }
}
</style>
