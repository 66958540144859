<template>
  <div v-if="(companyInfoByPerson.switch && companyInfoByPerson.switch.type== COMMON.HEADER.AVATAR_COMBOBOX.ACCOUNT_TYPE.COMPANY) ">
    <div class="CompanyGuide Modal-wrap"
         v-show="
         (isShow && companyInfoByPerson.is_admin == 1 && companyInfoByPerson.company.status== 0 ) || newUserShow">
      <div class="Modal-cover"></div>
      <div class="Modal-content">

        <div class="step-1" v-show="step == 1">
          <div class="icon_step"></div>
          <div class="msg">
            <div class="guide-ball">
              <i class="icon_ball"></i>
            </div>
            <i class="iconfont2 icon-qy-profile-line" />

            <div class="title">企业认证</div>
            <div class="desc">
              企业认证通过后，可使用团队协作、企业主页等更多丰富功能
            </div>

            <div class="HtButton-wrap">

              <span class="skip _pointer" @click="skip">
                跳过(1/{{ count }})
              </span>


              <div class="btn-group">
<!--
                <HtButton
                  class="HtButton HtButton step-l" text="上一步" type="_white-plain"
                  @click="submit"
                ></HtButton>
-->

                <HtButton
                  class="HtButton HtButton" text="下一步" type="_blue"
                  @click="submit"
                ></HtButton>
              </div>
            </div>
          </div>



        </div>

        <div class="step-2" id="step-2" v-show="step == 2">

          <div class="msg">
            <div class="guide-ball">
              <i class="icon_ball"></i>
            </div>
            <i class="iconfont2 icon-qy-home-line" />

            <div class="title">主体认证</div>
            <div class="desc">
              主体认证是什么？就是把该版本授权给认证的公司使用。完善信息后，即可邀请成员加入主体，成员都可享受该主体版本的权益。
            </div>

            <div class="HtButton-wrap">

              <span class="skip _pointer" @click="skip">
                跳过(2/{{ count }})
              </span>


              <div class="btn-group">
                <HtButton
                  class="HtButton HtButton step-l" text="上一步" type="_white-plain"
                  @click="back"
                ></HtButton>

                <HtButton
                  class="HtButton HtButton" text="下一步" type="_blue"
                  @click="submit"
                ></HtButton>
              </div>
            </div>
          </div>


          <div class="icon_step"></div>





        </div>



      </div>
      <div class="step-3" v-show="step == 3">
        <div class="icon_step"></div>

        <div class="msg">
          <div class="guide-ball">
            <i class="icon_ball"></i>
          </div>
          <i class="iconfont2 icon-qy-member-line" />

          <div class="title">添加/邀请成员</div>
          <div class="desc">
            点击成员管理，可通过直接添加用户的潮国ID，然后选择主体，将好友快速加入团队，或者通过链接邀请好友加入团队。
          </div>

          <div class="HtButton-wrap">

              <span class="skip _pointer" @click="skip">
                跳过(3/{{ count }})
              </span>


            <div class="btn-group">
              <HtButton
                class="HtButton HtButton step-l" text="上一步" type="_white-plain"
                @click="back"
              ></HtButton>

              <HtButton
                class="HtButton HtButton" text="下一步" type="_blue"
                @click="submit"
              ></HtButton>
            </div>
          </div>
        </div>
      </div>
      <div class="step-4" v-show="step == 4">

        <div class="msg">
          <div class="guide-ball">
            <i class="icon_ball"></i>
          </div>
          <i class="iconfont2 icon-qy-switch2" />

          <div class="title">切换企业版</div>
          <div class="desc">
            您可以在潮国创意/企业服务，将鼠标悬浮在右上角头像，在弹窗里点击切换企业，使用潮国企业版服务。
          </div>

          <div class="HtButton-wrap">

              <span class="skip _pointer" @click="skip">
                跳过(4/{{ count }})
              </span>


            <div class="btn-group">
              <HtButton
                class="HtButton HtButton step-l" text="上一步" type="_white-plain"
                @click="back"
              ></HtButton>

              <HtButton
                class="HtButton HtButton" text="下一步" type="_blue"
                @click="submit"
              ></HtButton>
            </div>
          </div>
        </div>
        <div class="icon_step"></div>

      </div>
      <div class="step-4 step-5" v-show="step == 5">

        <div class="msg">
          <div class="guide-ball">
            <i class="icon_ball"></i>
          </div>
          <i class="iconfont2 icon-qy-switch2" />

          <div class="title">切换主体</div>
          <div class="desc">
            当您切换到企业版后，可以在弹窗内看到企业服务相关功能入口，最下方显示你当前被授权的主体，如果您拥有多家主体授权，在下方可以进行主体切换后再使用。
          </div>

          <div class="HtButton-wrap">

              <span class="skip _pointer" @click="skip">
                跳过(5/{{ count }})
              </span>


            <div class="btn-group">
              <HtButton
                class="HtButton HtButton step-l" text="上一步" type="_white-plain"
                @click="back"
              ></HtButton>

              <HtButton
                class="HtButton HtButton" text="知道了" type="_blue"
                @click="submit"
              ></HtButton>
            </div>
          </div>
        </div>
        <div class="icon_step"></div>

      </div>
    </div>
  </div>
</template>

<script>
import {fnLocalStorage} from "@/fn";
import {MODEL_COMPANY_GUIDE} from "@/constant/MODELS";
import {COMMON} from "@/constant/COMMON";
import {mapState} from "vuex";

export default {
  name: "CompanyGuide",
  data(){
    return{
      step: 1,
      isShow: false,
      COMMON,
      count: 5,
      newUserShow: false

    }
  },
  computed: {
    ...mapState('user', ['companyInfoByPerson']),
  },
  mounted() {
    this.checkIsShow();
  },
  methods:{
    submit(){
      this.step+=1;
      if (this.step > 5){
        this.isShow = false;
        this.newUserShow = false;
        this.close();
      }

    },
    back(){
      this.step-=1;
    },
    skip(){
      this.isShow = false;
      this.newUserShow = false;
      this.step=1;
    },
    checkIsShow() {
      this.isShow = !fnLocalStorage.getItemDate(MODEL_COMPANY_GUIDE) ? true : false;
    },

    close() {
      this.isShow = false;
      fnLocalStorage.setItemDate(MODEL_COMPANY_GUIDE);
    }
  }
}
</script>

<style lang="scss" scoped>
.Modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 999;

  .Modal {
    margin: 0 auto;
    position: relative;
    animation: window-open 0.3s 1;
  }

  .Modal-cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.55);
    //backdrop-filter: blur(20px);
  }

  .Modal-content {
    //min-width: 400px;
    //min-height: 300px;
    //background-color: #FFFFFF;
    border-radius: 14px;
    //overflow: hidden;
    animation-duration: 0.5s;
    position: relative;
    margin: 0 auto;
  }

  .Modal-close {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;

    .icon-guanbi {
      font-size: 24px;
      color: #FFFFFF;
      transition: transform 0.5s ease-out;
      display: inline-block;

      &:hover {
        transform: rotateZ(180deg);
      }
    }
  }
}

.msg {
  width: 422px;
  height: 208px;
  background: #FFFFFF;
  border-radius: 20px;
  position: relative;
  margin-top: 32px;
  padding: 20px 32px 0 40px;
  box-sizing: border-box;

  .title {
    font-size: 20px;
    color: #131519;
    line-height: 26px;
    margin-bottom: 10px;
  }

  .desc {
    font-size: 14px;
    color: rgba(19, 21, 25, 0.65);
    line-height: 24px;
    margin-bottom: 16px;
  }

  .HtButton-wrap{
    @include Position(1,3,0px,20px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0px 32px 0 40px;
    box-sizing: border-box;

    .skip{
      font-size: 14px;
      color: rgba(19, 21, 25,0.45);

      @include ElementHover(rgba(19, 21, 25,0.65),'');
    }

    .HtButton{
      width: 90px;
      height: 40px;
      margin-left: 12px;
    }
    /deep/.HtButton{
      border: 0px solid #CBD1DE;
    }

  }

  .guide-ball {
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    //border: 3px solid rgba(255, 255, 255,0.3);
    //border: 3px solid pink;
    //@include Position(1, 5, -105px, -7px);

    &:after {
      content: '';
      display: inline-block;
      width: 58px;
      height: 2px;
      background: rgba(255, 255, 255, 0.3);
      border-radius: 1px;
      //@include Position(1, 5, x, 50%);
      //transform: translateY(-50%);
    }

    .icon_ball {
      display: inline-block;
      width: 14px;
      height: 14px;
      margin: 3px;
      background: #FFFFFF;
      border-radius: 50%;
    }

  }

  .iconfont2 {
    width: 64px;
    height: 64px;
    background: #255BFF;
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    border-radius: 50%;
    @include Position(1, 5, -32px, -32px);
  }
}

.step-1{
  display: flex;
  .icon_step{
    @include BgImage(750px,592px,'company/home/guide/1.png');
    margin-right: 137px;
  }
  .msg{
    width: 422px;
    height: 208px;
    background: #FFFFFF;
    border-radius: 20px;
    position: relative;
    margin-top: 32px;
    padding: 20px 32px 0 40px;
    box-sizing: border-box;
    .title{
      font-size: 20px;
      color: #131519;
      line-height: 26px;
      margin-bottom: 10px;
    }
    .desc{
      font-size: 14px;
      color: rgba(19, 21, 25,0.65);
      line-height: 24px;
      margin-bottom: 16px;
    }

    .HtButton-wrap{
      @include Position(1,3,0px,20px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0px 32px 0 40px;
      box-sizing: border-box;

      .skip{
        font-size: 14px;
        color: rgba(19, 21, 25,0.45);

        @include ElementHover(rgba(19, 21, 25,0.65),'');
      }

      .HtButton{
        width: 90px;
        height: 40px;
        margin-left: 12px;
      }
      /deep/.HtButton{
        border: 0px solid #CBD1DE;
      }

    }

    .guide-ball{
      width: 20px;
      height: 20px;
      background: rgba(255, 255, 255,0.3);
      border-radius: 50%;
      //border: 3px solid rgba(255, 255, 255,0.3);
      //border: 3px solid pink;
      @include Position(1,5,-105px,-7px);

      &:after{
        content: '';
        display: inline-block;
        width: 58px;
        height: 2px;
        background: rgba(255, 255, 255,0.3);
        border-radius: 1px;
        @include Position(1,5,x,50%);
        transform: translateY(-50%);
      }

      .icon_ball{
        display: inline-block;
        width: 14px;
        height: 14px;
        margin: 3px;
        background: #FFFFFF;
        border-radius: 50%;
      }

    }

    .icon-qy-profile-line{
      width: 64px;
      height: 64px;
      background: #255BFF;
      font-size: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      border-radius: 50%;
      @include Position(1,5,-32px,-32px);
    }


  }
}

#step-2{
  //@include Position(1,4,280px,168px);
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .msg{
    width: 422px;
    height: 208px;
    background: #FFFFFF;
    border-radius: 20px;
    margin-bottom: 117px;
    position: relative;


    .guide-ball{
      @include Position(1,4,33px,53px);
      top: 261px;

      &:after {
        width: 2px;
        height: 58px;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 1px;
        @include Position(1,4,50%,20px);
        transform: translateX(-50%);
      }
    }
  }
  .icon_step{
    @include BgImage(1265px,199px,'company/home/guide/2.png');
  }
}

.step-3{
  @include Position(1,5,24px,264px);
  display: flex;

  .guide-ball{
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255,0.3);
    border-radius: 50%;
    //border: 3px solid rgba(255, 255, 255,0.3);
    //border: 3px solid pink;
    @include Position(1,5,-105px,-7px);

    &:after{
      content: '';
      display: inline-block;
      width: 58px;
      height: 2px;
      background: rgba(255, 255, 255,0.3);
      border-radius: 1px;
      @include Position(1,5,x,50%);
      transform: translateY(-50%);
    }

    .icon_ball{
      display: inline-block;
      width: 14px;
      height: 14px;
      margin: 3px;
      background: #FFFFFF;
      border-radius: 50%;
    }

  }

  .icon_step{
    @include BgImage(556px,404px,'company/home/guide/6.png');
    margin-right: 145px;
  }
}

.step-4{
  @include Position(1,2,45px,10px);
  display: flex;

  .msg{
    margin-top: 90px;
  }

  .guide-ball{
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255,0.3);
    border-radius: 50%;
    //border: 3px solid rgba(255, 255, 255,0.3);
    //border: 3px solid pink;
    //@include Position(1,5,-105px,-7px);
    @include Position(1,2,-56px,14px);

    &:after{
      content: '';
      display: inline-block;
      width: 58px;
      height: 2px;
      background: rgba(255, 255, 255,0.3);
      border-radius: 1px;
      @include Position(1,5,-56px,50%);
      transform: translateY(-50%);
    }

    .icon_ball{
      display: inline-block;
      width: 14px;
      height: 14px;
      margin: 3px;
      background: #FFFFFF;
      border-radius: 50%;
    }

  }

  .icon_step{
    @include BgImage(384px,367px,'company/home/guide/3.png');
    margin-left: 105px;
  }
}

.step-5{
  .icon_step{
    @include BgImage(384px,367px,'company/home/guide/4.png');
  }
}
</style>
