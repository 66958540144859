/**
 * 素材常量控制
 */
import {fnLocalStorage} from "~/fn";
import {getCookie} from "~/utils/auth";

// export const PIC_TOTAL_NUM = '15w+';   // 图片总数量
export const PIC_TOTAL_NUM = getCookie('MODEL_updateMaterialNum') || '160000+';   // 图片总数量

export const MATERIALS = {
  PIC_NUM : getCookie('MODEL_updateMaterialNum') || '160000+',
  PIC_NUM_2:getCookie('MODEL_updateMaterialNum') || '160000+'
}
