<template>
  <div class="FavoritesGroup">

    <!-- 收藏弹框  -->
    <FavoritesDefault v-bind="$attrs"
                      v-on="$listeners"/>

<!--    &lt;!&ndash; 收藏夹创建 &ndash;&gt;
    <FavoritesCreate v-bind="$attrs"
                     v-on="$listeners"/>-->

    <!-- 收藏成功提示 -->
    <FavoritesCollectSuccess v-bind="$attrs"
                             v-on="$listeners"/>

<!--    <FavoritesMove />-->

    <!-- 非会员创建收藏夹超出提示 -->
<!--    <FavoritesCreateErr />-->

    <FavoritesCompany />
  </div>
</template>

<script>
/* 收藏夹 */
import FavoritesDefault from "@/components/user-center/favorites/index";
import FavoritesCompany from "@/components/user-center/favorites/favorites-company";
import FavoritesCreate from "@/components/user-center/favorites/favorites-create";
import FavoritesCollectSuccess from "@/components/user-center/favorites/favorites-collect-success"
import FavoritesMove from "@/components/user-center/favorites/favorites-move";
import FavoritesCreateErr from "@/components/user-center/favorites/favorites-create-err";

export default {
  name: "FavoritesGroup",
  components: {
    FavoritesDefault,
    FavoritesCreate,
    FavoritesCollectSuccess,
    FavoritesMove,
    FavoritesCreateErr,
    FavoritesCompany
  }
}
</script>

<style scoped>

</style>
