<template>
  <div class="FestivalTab">
    <div class="festival-month"
         :style="{backgroundColor: headerBgColor} ">
      <i class="festival-month-item-bar"
         :style="{ 'left': festivalLeft + 'px' }"/>
      <span
        :class="{ 'active' : monthIdx === vo.key }"
        class="festival-month-item"
        v-for="(vo,idx) in FESTIVAL_MONTH"
        :key="vo.label + 'festival' "
        @click="toggleFestivalMonth(vo);trackingNavDrop('festival',1,'tab')"
      >
         <span class="festival-month-txt"> {{ vo.label }} <br> 月</span>
      </span>
    </div>
  </div>
</template>

<script>
import {getDropdownByFestival, getDropdownByHot} from "@/api/nav";
import {IMG_BASE_URL} from "@/constant/PATH";
import ToolDate from "@/tools/ToolDate";
import {topbarMenuList} from "@/components/common/header/module";
import {TrackingNav} from "@/tracking";
import {fnRouter} from "@/fn";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import headerNavMixin from "@/components/common/header/header-nav/common/header-nav-mixin";
import {HEADER} from "@/constant/common/HEADER";
import {FESTIVAL_MONTH} from "@/components/_Public/Header/HeaderAllDropDown/module";


export default {
  name: "NavItemFestival",
  mixins: [headerNavMixin],
  data() {
    return {
      IMG_BASE_URL,
      isShow: false,
      FESTIVAL_MONTH,
      monthIdx: 1,    // 默认显示月份
      currentMonth: [],  // 当月
      headerBgColor: '', // 头部背景色
      topbarMenuList,
      festivalLeft: 0,
      HEADER,
      PAGE_PATH
    }
  },
  props: {
    navIndex: {
      type: String | Number,
      default: -1
    },
    festivalData: {}
  },
  computed: {
    festivalLink() {
      return fnRouter.getRouterLink(PAGE_PATH.NAV.FESTIVAL);
    },
  },
  watch: {
    monthIdx: {
      handler(n, o) {
        if (n) {
          this.findCurrentMonthPic();
          this.headerBgColor = this.FESTIVAL_MONTH[n - 1].bgColor
          /*
          * 1 - 3
          * 2 - 61-17 44    3+ 41
          * 3 - 102 -17 85  3 +82
          * 4 - 143 -17 126  3+ 123
          * */
          this.festivalLeft = 41 * (n - 1) + 3
        }
      },
      immediate: true
    }
  },
  mounted() {

  },
  async fetch() {
    this.getCurrentMonth();
  },
  methods: {

    init() {
      this.getCurrentMonth();
      this.getData();
    },

    navigateToList() {
      return
      let findItem = this.topbarMenuList.find(vo => vo.id === 2)
      this.$emit('navigateToList', findItem)
    },

    /* 获取当前月份 */
    getCurrentMonth() {
      let currentMonth = ToolDate.getDate().month;
      this.monthIdx = currentMonth
      return currentMonth;
    },

    /* 根据条件筛选对应的月份图片 */
    findCurrentMonthPic() {
      // let currentMonth  = ToolDate.getDate().month;
      let findCurrent = this.FESTIVAL_MONTH.find(vo => vo.key == this.monthIdx)
      this.currentMonth = []
      setTimeout(() => {
        this.currentMonth = findCurrent.pics;
      }, 0)
    },

    /* 获取下拉所有图片数据 */
    getData() {
      getDropdownByFestival().then(res => {
        if (res.data.status == 1) {
          let result = res.data.data
          if (Object.prototype.toString.call(result) === '[object Object]' && Object.keys(result).length > 0) {
            for (const key in result) {
              this.FESTIVAL_MONTH.forEach(vo => {
                if (vo.key == key) {
                  vo.pics = result[key]
                }
              })
            }
            /* 显示当月 */

            this.findCurrentMonthPic();
          }
        }
      })
    },

    toggleFestivalMonth(vo) {
      this.monthIdx = vo.key;
      this.$emit('toggleFestivalMonth',vo)
    },

    trackingNavDrop(act, pos, e) {
      TrackingNav.navDrop(act, pos, e)
    }
  },

  filters: {
    disposeDate(data) {
      let dateArr = data.split('-');
      return dateArr[1] + '月' + dateArr[2] + '日'
    },
  }
}
</script>

<style lang="scss" scoped>
.festival-month {
  width: 100%;
  border-radius: 12px 12px 0px 0px;
  display: flex;
  align-items: center;
  padding-top: 12px;
  //padding-left: 4px;
  padding-left: 20px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  //justify-content: space-around;
  height: 50px;
  box-sizing: border-box;
  position: relative;


  .festival-month-item-bar {
    @include BgImage(52px, 42px, 'header/nav/icon_festival-bg.png');
    position: absolute;
    left: 50%;
    transition: all 0.5s ease;
    width: 52px;
    height: 42px;
    //transform: translateX(-50%);
  }

  .festival-month-item {
    //margin-right: 28px;
    //margin: 0 14px;
    //padding: 0 14px;
    font-size: 14px;
    color: #FFFFFF;
    cursor: pointer;
    position: relative;
    z-index: 6;
    text-align: center;
    line-height: 15px;
    width: 18px;
    margin-right: 23px;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      transition: all 0.5s ease;

      .festival-month-item-bar {
        @include BgImage(84px, 37px, 'header/nav/icon_festival-bg.png');
        position: absolute;
        z-index: -1;
        left: 50%;
        transform: translateX(-50%);
      }

      .festival-month-txt {
        position: relative;
        z-index: 6;
        font-size: 14px;
        font-weight: bold;
        color: #424866;
      }
    }


  }
}

</style>
