import {toolDate} from "@/tools";
import {fnUser} from "@/fn";

class FnCoupon {
  constructor() {
  }

  /* 红包列表和新人红包状态 */
  saveNewUserCoupon(couponData){
    console.log('saveNewUserCoupon----')
    console.log(couponData)
    let {$store} = $nuxt;
    let couponList = [];
    for (const key in couponData) {
      couponList = couponList.concat(couponData[key])
    }
    let isHasUserCoupon = false;
    for (let i = 0; i < couponList.length; i++) {
      let item = couponList[i]
      if (item.coupon_id == 4 || item.coupon_id == 5){
        isHasUserCoupon = true;
        break;
      }
    }

    let flag = (isHasUserCoupon && (fnUser.checkUserRegisterDay() < 7) )? 1: 0;
    console.log('COUPON-----')
    console.log(flag)
    $store.commit('user/SET_IS_HAS_USER_COUPON',flag)
  }
}

export default new FnCoupon();
