import {fnLocalStorage} from "~/fn";

export default {
  namespaced: true,
  state(){
    return{
      navIndex: 0 // 顶部导航菜单选中
    }
  },
  mutations: {
    SET_NAV_INDEX(state, payload) {
      state.navIndex = fnLocalStorage.getItem('updateNavId');
    },
  },
  actions: {},
}
