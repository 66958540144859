<template>
  <div class="SignByDayModalWrapper">

    <!-- 每日签到浮标          @click="showModal" -->
    <a class="signByDayBuoy"
         v-if="isShowBuoy"
        :href="fansClubLink"
       target="_blank"
    >
      <i class="close" @click.stop="isShowBuoy = false"/>
    </a>


    <!-- 每日签到弹框  -->
    <div class="SignByDayModal" v-if="isShowModal">
      <ModalMiddle :is-show-close="false">
        <div slot="ModalContent" class="ModalContent">

          <div class="ModalBtn"
               @click="sign"

          >
            <div class="ModalBtnHand"></div>
          </div>

          <i class="close"
             @click="closeModal"
          />
        </div>
      </ModalMiddle>
    </div>

  </div>
</template>

<script>

/* 引入公共方法和常量 */
import * as fnCommon from '@/fn/_fn';
import * as CON_COMMON from '@/constant/_constant';

/* 引入组件 */

/* 引入VUEX */
import {mapState} from "vuex";
import {signByWeek} from "@/api/fans/club";

/* 引入API */

export default {
  name: "SignByDayModal"

  , components: {}

  , props: {}

  , watch: {}

  , computed: {
    fansClubLink(){
      return fnCommon.fnBasic.getChaopxFullLink(CON_COMMON.CON_BASIC.FANS_CLUB)
    }
  }

  , data() {
    return {
      isShowModal: false
      ,isShowBuoy: true
    }
  }

  , methods: {

    showModal() {
      this.isShowModal = true
    }

    , closeModal() {
      this.isShowModal = false
    }

    ,sign(){
      signByWeek().then(res => {
        let {status, data, message} = res.data;
        if (status == 200) {
          this.$myNotify.success(message);
          this.currDaySignStatus = 1;
          this.getActInfo();
        } else {
          this.$myNotify.error(message);
        }
      })
    }
  }

}
</script>

<style lang="scss" scoped>

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}


.signByDayBuoy {
  @include BgImage(100px, 100px, '_chaotu/active/sign-by-day/signByDay%40%20buoy.png');
  position: fixed;
  top: 300px;
  right: 0;
  z-index: 99;
  cursor: pointer;

  .close {
    @include Position(1, 4, 50%, -24px);
    transform: translateX(-50%);
    @include BgImage(14px, 14px, '_chaotu/active/sign-by-day/signByDay@ buoyClose.png');
    cursor: pointer;
  }
}

.ModalContent {
  @include BgImage(817px, 823px, '_chaotu/active/sign-by-day/signByDay%40bg.png');
  position: relative;

  .close {
    @include Position(1, 4, 453px, 100px);
    @include BgImage(28px, 28px, '_chaotu/active/sign-by-day/signByDay%40%20close.png');
    cursor: pointer;
  }

  .ModalBtn {
    @include BgImage(474px, 122px, '_chaotu/active/sign-by-day/signByDay%40btn.png');
    @include Position(1, 4, 235px, 125px);
    animation-name: pulse; // 动画名称
    animation-direction: alternate; // 动画在奇数次（1、3、5...）正向播放，在偶数次（2、4、6...）反向播放。
    animation-timing-function: linear; // 动画执行方式，linear：匀速；ease：先慢再快后慢；ease-in：由慢速开始；ease-out：由慢速结束；ease-in-out：由慢速开始和结束；
    animation-delay: 0.5s; // 动画延迟时间
    animation-iteration-count: infinite; //  动画播放次数，infinite：一直播放
    animation-duration: 1s; // 动画完成时间
    transform: scale(0.9);
    cursor: pointer;

    .ModalBtnHand {
      @include BgImage(133px, 167px, '_chaotu/active/sign-by-day/signByDay%40hand.png');
      @include Position(1, 2, -10px, 20px);
    }


  }
}
</style>
