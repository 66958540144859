import {getDropdownByHot} from "@/api/nav";
import {setCustomCookie} from "@/utils/auth";

export default {
  namespaced: true,
  state() {
    return {
      picList: [],
      picList2: [],
    }
  },

  mutations: {
    SET_PIC_LIST(state, payload) {
      console.log('SET_PIC_LIST1-----------------')
      console.log({
        payload
      })
      state.picList = payload
      state.picList2 = payload
    },
  },
  actions: {

    async getHomeMaterialList({commit, state}) {
      return new Promise((resolve, reject) => {

        getDropdownByHot().then(res => {
          const {status, data} = res.data;
          console.log(data.pic)

          if (status == 1) {
            commit('SET_PIC_LIST', JSON.stringify(data.pic));
            resolve(res);
          }

        }).catch(err => {
          reject(error)
        })

      })

    }
  },
}
