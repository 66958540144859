import request from '@/utils/request'
import qs from 'qs'

/* 获取支付码 */
export function getPayCode(data) {
  return request({
    url: '/?m=Pay&a=pay',
    method: 'post',
    data: qs.stringify(data)
  })
}

/*获取支付结果 */
export function getPayResult(data) {
  return request({
    url: '/?m=Pay&a=scanOrder',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 升级简单版 */
export function payUpgrade(data) {
  return request({
    url: '/?m=Pay&a=priceSpread',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 获取优惠券 */
export function getCouponList(query) {
  return request({
    url: '/?m=Api&a=getUserCoupon',
    method: 'get',
    params: query
  })
}

/* 获取优惠券新版 */
export function getCouponListNew(query) {
  return request({
    url: '/?m=Coupon&a=payCenter',
    method: 'get',
    params: query
  })
}


/* 自主升级VIP */
export function customVipUpgrade(data) {
  return request({
    url: '/?m=Pay&a=upgrade',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 支付中心访问日志统计 */
export function payCenterTracking(query) {
  return request({
    url: '/?m=StatApi&a=payCenter',
    method: 'get',
    params: query
  })
}

/* 获取企业活动充值页列表 */
export function getCompanyActPayList(query) {
  return request({
    url: '/?m=Company&a=actMap',
    method: 'get',
    params: query
  })
}


/* 单张购买 */
export function payLeaflet(data) {
  return request({
    url: '/?m=Pay&a=singlePagePay',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 单张下载鉴权 */
export function leafletDownloadCheck(query) {
  return request({
    url: '/?m=Authorize&a=isAuth',
    method: 'get',
    params: query
  })
}

/* 授权图片 */
export function leafletAuthorizePic(query) {
  return request({
    url: '/?m=Authorize&a=singleAuthorize',
    method: 'get',
    params: query
  })
}

/* 单张下载鉴权 */
export function getMultipleList(query) {
  return request({
    url: '/?m=Authorize&a=checkPackage',
    method: 'get',
    params: query
  })
}

// /?m=Picture&a=singlePageAuthRule
/* 单张规则 */
export function getLeafletMeal(query) {
  return request({
    url: '/?m=Picture&a=singlePageAuthRule',
    method: 'get',
    params: query
  })
}


export function leafletClickStat(query) {
  return request({
    url: '/?m=StatApi&a=packageStat',
    method: 'get',
    params: query
  })
}


/* 支付列表 */
export function geVipList(query) {
  return request({
    url: '/?m=Pay&a=vipInfo',
    method: 'get',
    params: query
  })
}
