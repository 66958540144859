/**
 * @Author: wulizhou
 * @Description:  自定义指令封装
 * @Date: 2023.4.10
 **/
// plugins/custom-directives.js
import Vue from 'vue'

Vue.directive('hover-delay', {
  bind: function(el, binding) {
    let timer = null
    el.addEventListener('mouseenter', function() {
      timer = setTimeout(() => {
        binding.value()
        const param1 = binding.arg; // 'param1'
        const extraParam = binding.value; // 'xxx'
      }, 500)
    })
    el.addEventListener('mouseleave', function() {
      clearTimeout(timer)
    })
  }
})

Vue.directive('focus-on-insert', {
  bind: function(el) {
    el.focus()
  }
})
