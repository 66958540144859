<template>
  <div class="pull-down-menus _hide">
    <div
      class="menu-item menu-item-time"
      @mouseover="isShowMenu = true"
      @mouseleave="isShowMenu = false"
    >
      <div class="menu-item-text _pointer ">
        <span>{{ menuActive }}</span>
        <i class="iconfont icon-a-arrowupbold"/>
      </div>
      <div
        v-show="isShowMenu"
        :class="['menu-item-content', isShowMenu === false ? 'leave': 'on']">
        <div
          v-for="(vo,idx) in menuList"
          :class="[ 'menu-item-child _pointer', { 'on': idx === timeIndex } ]"
          :key="vo.id"
          @click="toggleMenu(vo,idx)"
          @mouseover="timeIndex = idx">
          <div>{{ vo.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pulldown",
  props:{
    menuList:{
      type: Array,
      default: ()=>[
        {
          id: 0,
          title: "全部时间"
        },
        // {
        //   id: 1,
        //   title: "最近24小时"
        // },
        {
          id: 2,
          title: "最近一周"
        },
        {
          id: 3,
          title: "最近一月"
        },
        {
          id: 4,
          title: "最近半年"
        },
        {
          id: 5,
          title: "最近一年"
        }
      ]
    }
  },
  data(){
    return{
      timeIndex:0,
      menuActive:'',
      isShowMenu: false

    }
  },
  created() {
    this.menuActive = this.menuList[0].title;
  },
  methods:{
    getMaterialListS(){

    },

    toggleMenu(vo,idx){
      this.timeIndex = idx;
      this.menuActive = vo.title;
      this.isShowMenu = false;
      this.$emit('toggleMenu',vo)
    }
  }
}
</script>

<style lang="scss" scoped>

.pull-down-menus {
  height: 100%;
  @include LayoutFlex(1);


  .menu-item {
    height: 100%;
    transition: all 0.5s ease-in;
    position: relative;
    margin-left: 30px;

    &:hover {
      .icon-a-arrowupbold {
        transition: all 0.5s ease-in;
        display: inline-block;
        transform: rotateZ(180deg) !important;
      }
    }

    .menu-item-text {
      text-align: center;
      line-height: 58px;
      transition: all 0.5s ease-in;
      position: relative;
      z-index: 10;
      background-color: #fff;

      span {
        display: inline-block;
        min-width: 60px;
        text-align: center;
      }

      .icon-a-arrowupbold {
        transition: all 0.5s ease-in;
        display: inline-block;
        transform: rotateZ(0deg);
        margin-left: 0px;
        font-size: 14px;
      }


    }

    .menu-item-content {
      @include Position(1, 5, 50%, 58px);
      transform: translateX(-50%);
      z-index: 5;
      //width: 122px;
      //height: 192px;
      padding: 6px;
      background: #FFFFFF;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 26, 0.08);
      border-radius: 10px;
      @include LayoutFlex(2);
      transition: all 0.5s ease-in;

      &.leave {
        opacity: 0;
        z-index: -1;
      }

      &.on {
        opacity: 1;
        z-index: 5;
      }

      .menu-item-child {
        width: 110px;
        height: 36px;
        background: #FFFFFF;
        border-radius: 2px;
        @include LayoutFlex(1);
        font-size: 14px;

        a {
          display: inline-block;
          width: 100%;
          height: 100%;
          line-height: 36px;
          text-align: center;
        }

        &.on {
          background-color: $color-primary;
          color: #FFFFFF;

          a {
            color: #FFFFFF;
          }
        }
      }


    }


  }


}

</style>
