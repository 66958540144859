<template>
  <div class="CompanyMenu Sidebar">
    <div class="sidebar-list">
      <div
        v-for="vo in SidebarList"
        :key="vo.iconClass"
        class="sidebar-item"
        @click="navigatorTo(vo.link)">
        <i
          :class="[vo.iconClass,'iconfont']"
        />
      </div>

      <div class="sidebar-item" @click="backToTop" v-show="visible">
        <i class="iconfont icon-qy-a-sidetop"/>
        <!--      <div class="explain">返回顶部</div>-->
      </div>
    </div>
  </div>
</template>

<script>
import SidebarItemBackToTop from '@/components/common/sidebar/components/back-to-top';
import InviteCombobox from '@/components/act/invite/invite-combobox';  // 邀请浮标
import LoginGuide from "@/components/modal/login-guide/login-guide";

import {PAGE_LINK, PAGE_PATH} from "@/constant/PAGE_PATH";

const SidebarList = [
  {
    iconClass: 'icon-qy-a-sidekefu3',
    explain: '人工客服',
    link: PAGE_LINK.SERVICE
  }
]

export default {
  name: "CompanyMenu",
  components: {
    SidebarItemBackToTop,
    InviteCombobox,
    LoginGuide
  },
  data() {
    return {
      SidebarList,
      visible:true,
    }
  },
  methods: {
    navigatorTo(path) {
      window.open(path, '_blank');
    },
    handleScroll() {

      console.log( this.visibilityHeight )
      console.log( window.pageYOffset )
      this.visible = window.pageYOffset > this.visibilityHeight
    },
    backToTop() {
      console.log(1111111)

      /*if (this.isMoving) return
      const start = window.pageYOffset
      let i = 0
      this.isMoving = true
      this.interval = setInterval(() => {
        const next = Math.floor(this.easeInOutQuad(10 * i, start, -start, 500))
        if (next <= this.backPosition) {
          window.scrollTo(0, this.backPosition)
          clearInterval(this.interval)
          this.isMoving = false
        } else {
          window.scrollTo(0, next)
        }
        i++
      }, 16.7)*/

      this.$emit('backToTop')
    },
    easeInOutQuad(t, b, c, d) {
      if ((t /= d / 2) < 1) return c / 2 * t * t + b
      return -c / 2 * (--t * (t - 2) - 1) + b
    }
  }
}
</script>

<style lang="scss" scoped>
//@import "@/components/common/sidebar/common/sidebar.scss";
.Sidebar {
  position: fixed;
  right: 20px;
  bottom: 100px;
  z-index: 98;
  display: flex;
  flex-direction: column;
  //justify-content: flex-end;
  align-items: flex-end;

  .sidebar-list {
    width: 42px;
    //height: 168px;
    background: #FFFFFF;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1000);
    //border-radius: 6px 0 0 6px;
    border-radius: 6px;
    margin-top: 20px;

  }

  .sidebar-item {
    width: 42px;
    height: 42px;
    position: relative;
    transition: all 0.5s ease-in-out;
    @include LayoutFlex(1);
    @include ElementHover($color-primary, #F7F7FF) {
      .iconfont {
        color: $color-primary;
      }
      .explain {
        color: #FFFFFF;
        display: block;
      }
    }
  ;

    &:first-child {
      border-radius: 6px 0 0 0;
    }

    &:last-child {
      border-radius: 0 0 0 6px;
    }

    .iconfont {
      font-size: 26px;
      color: #424866;
    }

    .explain {
      width: 80px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      color: #FFFFFF;
      background: #424866;
      border-radius: 4px;
      @include Position(1, 4, -90px, 50%);
      transform: translateY(50%);
      display: none;

      &:after {
        content: '';
        display: inline-block;
        width: 9px;
        height: 9px;
        background-color: #424866;
        @include Position(1, 2, -7px, 50%);
        transform: rotateZ(135deg) translateY(50%);
      }

      &:before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 100%;
        background-color: transparent;
        @include Position(1, 2, -10px, 0);

      }
    }
  }

}
</style>

