/**
 * @Author: wulizhou
 * @Description: 图片配置
 * @Date:
 *
 **/

/* 又拍云操作信息 */
export const UP_YUN = {
  bucketName: "chaopx-pic",
  account: "qianku",
  password: "HCkj58BKu@2017"
}


/* --下载权益弹框 */

/* 1.弹框默认传参 */
export let DOWNLOAD_INTEREST_PARAMS = {
  isShow: false,      // 是否显示弹框
  isOpenTimer: false, // 是否需要打开定时器
  upgradeLink: ""     // 设置升级按钮跳转链接
}

