let CON_PAY = {}


/* 充值埋点多种方式常量 */
export const DATA_TRACK = {
  FAVORITE_UPGRADE: "充值:收藏夹升级会员:图片:click",
  PIC_DETAIL_UPGRADE: "充值:详情页升级弹窗:图片:click",
  UPGRADE_VIEW: "充值:升级页访问:图片:click"
}


/* 会员等级 */
export const VIP_GRADE = {
  DEFAULT: -1, // 非会员
  PERSON: '1',   // 普通会员
  BUSINESS: '2', // 商用会员
  COMPANY: '3', // 企业会员
}

/* 会员配置 */
export const VIP_MANAGE = [
  {
    label: "普通VIP终身",
    id: 9,
    parentId: VIP_GRADE.PERSON
  },
  {
    label: "普通VIP包年",
    id: 1,
    parentId: VIP_GRADE.PERSON
  },
  {
    label: "普通VIP包季",
    id: 5,
    parentId: VIP_GRADE.PERSON
  },
  {
    label: "普通VIP包月",  // 已停售
    id: 7,
    parentId: VIP_GRADE.PERSON
  },
  {
    label: "普通VIP包天", // 该版本只能参与活动赠送，不可购买
    id: 8,
    parentId: VIP_GRADE.PERSON,
    isBuy: false
  },

  {
    label: "商用VIP包年",
    id: 2,
    parentId: VIP_GRADE.BUSINESS
  },
  {
    label: "商用VIP包季",
    id: 6,
    parentId: VIP_GRADE.BUSINESS
  },
  {
    label: "商用VIP包月",
    id: 4,
    parentId: VIP_GRADE.BUSINESS
  },


  {
    label: "企业标准版",  // 已停售
    id: 2,
    parentId: VIP_GRADE.COMPANY,
    isBuy: false,
    pointingVersion: 8, // 指向后续升级版本
  },

  {
    label: "企业标准版", // 新标准版
    id: 8,
    parentId: VIP_GRADE.COMPANY
  },

  {
    label: "企业升级版",
    id: 5,
    parentId: VIP_GRADE.COMPANY
  },

  {
    label: "企业高级版",
    id: 6,
    parentId: VIP_GRADE.COMPANY
  },

  {
    label: "企业转售版",
    id: 7,
    parentId: VIP_GRADE.COMPANY
  },


]


