<!--
  调用方法 this.$store.commit('home/SET_IS_SHOW_GUIDE_DOWNLOAD_COUNT',true)
-->

<template>
  <div
    class="DownloadCountGuide"
    v-if="isShow">

    <!-- 免费总次数>0 -->
    <template v-if="remainDownloadTotalCount > 0">
      <HintDialog @close="guideCouponClose"/>
    </template>


    <!-- 免费次数为0 -->
    <template v-else>
      <!-- 8.8红包弹框 -->
      <DownloadCoupon
        v-if="type === HOME.GUIDE.DOWNLOAD_COUNTS.TYPE.RMB_EIGHT_COUPON"
        @close="guideCouponClose"
      />

      <!-- 7天免费vip弹框 -->
      <GuideDownloadReceiveVipDialog
        v-if="type === HOME.GUIDE.DOWNLOAD_COUNTS.TYPE.FREE_SEVEN_VIP"
        @close="guideCouponClose"/>
    </template>
  </div>
</template>

<script>
import {fnGuideDownloadCount, fnInviteFriend, fnLocalStorage, fnLogin, fnUser} from "@/fn";
import {PAY} from "@/constant/PATH";
import localStorage from "@/utils/localStorage";
import {MODEL_DOWNLOAD_COUNT_GUIDE, MODEL_NEW_USER_GIFT, MODEL_NEW_USER_GIFT_DAY} from "@/constant/MODELS";
import {mapGetters, mapState} from "vuex";
import {tag} from "@/utils/statUtil";
import HintDialog from './hint-dialog';
import DownloadCoupon from './coupon-dialog';
import GuideDownloadReceiveVipDialog from './receive-vip-dialog'
import {HOME} from "@/constant/HOME";
import {getRmbEightCouponStatus} from "@/api/home";

export default {
  name: "DownloadCountGuide",
  components: {
    HintDialog,
    DownloadCoupon,
    GuideDownloadReceiveVipDialog
  },
  data() {
    return {
      isShow: false,
      type: -1,
      HOME
    }
  },
  computed: {
    ...mapState('user', ['downloadCount',
      'personVipState',
      'personVipInfo',
      'businessVipState',
      'businessVipInfo',
      'companyVipState',
      'companyVipInfo',
    ]),
    ...mapState('home', ['isShowGuideDownloadCount']),

    /* 下载剩余总次数 */
    remainDownloadTotalCount() {
      return this.downloadCount.free_down_cnt - this.downloadCount.down_cnt
    }
  },

  watch: {
    isShowGuideDownloadCount: {
      handler(n, o) {
        if (n && n == true) {
          this.init();
        }
      },
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$store.commit('home/SET_IS_SHOW_GUIDE_DOWNLOAD_COUNT', false)
      this.checkIsShow();
    },
    async checkIsShow() {
      let flag = fnLocalStorage.getItemDate(MODEL_DOWNLOAD_COUNT_GUIDE);

      if (this.personVipState == 0 && this.businessVipState == 0 && this.companyVipState == 0 && !flag) {
        // 显示条件
        let res = await fnGuideDownloadCount.checkIsNoVipAndNoEightCoupon();

        if (res.state != -1) {
          this.isShow = true;
          this.type = res.type;

        }
      }
    },

    getState() {
      return localStorage.getItemDate(MODEL_DOWNLOAD_COUNT_GUIDE);
    },
    updateState(value = 1) {
      localStorage.setItemDate(MODEL_DOWNLOAD_COUNT_GUIDE, value)
    },

    inviteFriendEntry() {
      fnInviteFriend.inviteFriendEntry(13);
      this.close();
    },
    navigatorToPay(id = 2) {

      if (fnLogin.checkLogin()) {
        let path = `${PAY}/${id}`
        window.open(path, '_blank')
      } else {
        this.$store.commit("user/SET_IS_SHOW_LOGIN", 1);
      }

      this.close();

    },
    close() {
      this.isShow = false;
      // this.updateState()
      // localStorage.removeItemDate(MODEL_NEW_USER_GIFT_DAY)
      localStorage.setItemDate(MODEL_NEW_USER_GIFT_DAY, -1)
    },
    tag(stat) {
      tag(stat)
    },


    /* 8.8红包关闭 */
    guideCouponClose() {
      this.isShow = false;
      fnLocalStorage.setItemDate(MODEL_DOWNLOAD_COUNT_GUIDE, 1);
    }
  }
}
</script>

<style lang="scss" scoped>
.icon_hj {
  @include BgImage(18px, 24px, 'tupian/icon_hj.png');
  margin-right: 3px;
}

.tip-dialog {
  width: 516px;
  height: 318px;
  background: #FFFFFF;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .title {
    font-size: 24px;
    font-weight: bold;
    color: #424866;
    line-height: 40px;
    margin: 80px 68px 12px;
    text-align: center;

    .tips {
      font-size: 14px;
    }
  }

  .desc {
    font-size: 14px;
    color: #596088;
    line-height: 20px;
    text-align: center;
    margin-bottom: 72px;
  }

  .btn-group {
    display: flex;
    @include Position(1, 4, 50%, 50px);
    transform: translateX(-50%);

    .btn-item {
      height: 44px;
      line-height: 44px;
      text-align: center;
      border-radius: 25px;
      cursor: pointer;
      width: 180px;
      background: #E0E3E9;
      color: #596088;
    }

    .btn-item-dl {
      margin-right: 40px;
      color: #596088;
      @include ElementHover('', rgba(224, 227, 233, 0.8));
    }

    .btn-item-sy {
      background: #6969FF;
      border-radius: 25px;
      color: #FFFFFF;

      @include ElementHover('', #5E5EE8);
    }
  }
}
</style>
