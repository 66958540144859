import LRU from 'lru-cache'
const CACHED = new LRU({
  max: 100, // 缓存队列长度
  ttl: 1000 * 60 // 缓存时间
})
export default {
  async asyncData({ app, query }) {

    /* 导航api */
    try {
      let banner, footer
      if (CACHED.has('navHotData')) {
        // 存在缓存，使用缓存数据
        let data = CACHED.get('navHotData')
        data = JSON.parse(data)
        banner = data.banner
        footer = data.footer
      } else {
       /* // 获取页面顶部轮播图信息
        const getBanner = () => {
          return app.$axios.$get('zz/zy/banner')
        }
        // 获取底部配置信息
        const getFooter = () => {
          return app.$axios.$get('zz/zy/footer', {
            params: {
              smark: query.smark
            }
          })
        }*/

        console.log('--11111111111111111111111--')
        let hotMenuList = app.$api.getHomeMaterialList()
        console.log(hotMenuList)


      }
      // [banner, footer] = await Promise.all([getBanner(), getFooter()])
      // 将数据写入缓存
      // CACHED.set('baseData', JSON.stringify({ banner: banner, footer: footer}))
      // return {mods: mods, footer: footer}
    }catch (e) {
      console.log('interface timeout or format error => ', e)
      return {}
    }
  }
}
