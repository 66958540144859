import {INVITE_FRIENDS} from "@/constant/PATH";

/* 跳转活动页  */

/**
 * 跳转活动页
 * @param entry 入口位置
   头部导航 1
   轮播图 2
   免费次数下载完 3
   浮标 5
   确认下载页 6
   头像下拉 7
 */
export function navToInviteAct(entry) {
  let routeUrl = `${INVITE_FRIENDS}?entry= ${entry}`;
  window.open(routeUrl, '_blank');
}
