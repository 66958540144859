<template>

  <div
      class="NavItemMore menu-item"
      v-if="list.length"
      :class="[ {'on': list.filter(item => item['id']=== navIndex ).length !== 0 } ]"
      @mouseover="isShow = true"
      @mouseleave="isShow = false"
  >
    <a>
      <i class="iconfont icon-more"></i>
    </a>

    <div class="more-combobox" v-if="isShow">
      <div class="nav-list">
        <a :href="vo.link"
           target="_blank"
           v-for="vo in list"
           :key="vo.id"
           class="nav-item"
           :class="[ {'ON': vo.id == navIndex} ]"
           @click="updateNavId(vo.id)"
           >
          {{ vo.label }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {NAV} from "../common/module";
import {COMMON} from "@/constant/COMMON";
import headerNavMixin from "~/components/common/header/header-nav/common/header-nav-mixin";

const comboboxList = [
  NAV.CALENDAR,
  NAV.TOPIC,
  NAV.TIMELINE,
  NAV.INVITE,
  NAV.PART_TIME,
  NAV.STYLE,
]

export default {
  name: "NavItemMore",
  props: {
    navType: {
      type: String,
      default: COMMON.HEADER.NAV.TYPE.OTHER
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  mixins: [headerNavMixin],
  data() {
    return {
      NAV,
      comboboxList: comboboxList.slice(),
      isShow: false,
      COMMON,
      ids: []
    }
  },
  mounted() {
    let ids = this.comboboxList.map(vo => vo.id)
    this.ids = ids;

    setTimeout(() => {
      if (ids.indexOf(this.navIndex)) {

      }
    })
  },
  methods: {
    navigateTo(vo) {
      this.$emit('navigateTo', vo)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../common/nav";
@import "../common/icon";

.NavItemMore {
  position: relative;

  .icon-more {
    font-size: 24px;
  }

  .more-combobox {
    @include Position(1, 5, -26px, 40px);
    background: #FFFFFF;
    padding: 6px;
    box-sizing: border-box;
    animation: fadeinT 0.5s 1;
    cursor: default;
    width: 110px;
    //height: 84px;
    background: #FFFFFF;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.0600), 0px 0px 12px 0px rgba(0, 0, 0, 0.0800);
    border-radius: 10px;

    .nav-list {
      display: flex;
      flex-direction: column;
      .nav-item {
        display: inline-block;
        //width: 98px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        //background: #6969FF;
        border-radius: 4px;

        @include ElementHover($color-primary);

        &.ON {
          color: $color-primary;
        }
      }
    }


    &:after {
      content: '';
      width: 10px;
      height: 10px;
      background-color: #FFFFFF;
      @include Position(1, 5, 45%, -4px);
      transform: rotate(135deg);
    }
  }


}
</style>
