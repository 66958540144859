import {setTagCookie, tagStat} from "@/api/stat";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import {VIP_MANAGE} from "@/constant/VIP_MANAGE";
import _ from 'lodash'
import {leafletClickStat} from "@/api/pay";

class FnPay {

  constructor() {
  }

  /* 数据充值埋点 */
  dataTrackByPay(stat) {
    tagStat({stat}).then(res => {
      setTagCookie({stat})
    })
  }

  async dataTrackByClickLeaflet() {
    await leafletClickStat({event:'singlePage'})
  }


  /* 红包模块 */
  /**
   * 红包列表处理类型，跳转类型等
   * @param coupon Array 红包列表
   * @returns {{length}|*}
   */
  completeCoupon(coupon) {

    if (Array.isArray(coupon) && coupon.length) {
      coupon.forEach(vo => {
        if (vo.module) {
          switch (parseInt(vo.module)) {
            case 0:
              vo._vip_type = '通用VIP';
              break;
            case 1:
              vo._vip_type = '普通VIP';
              break;
            case 2:
              vo._vip_type = '商用VIP';
              break;
            case 3:
              vo._vip_type = '企业VIP';
              break;
          }
        }
        /* 处理跳转对应的支付页面 */
        if (vo.module == 0) {
          /* 通用券去商用年 */
          vo._payLink = PAGE_PATH.PAYMENT.BUSINESS;
        } else {

          /* 使用module和vip_type判断具体的套餐 */
          if (vo.vip_type == 0) {

            /* 如果vip_type为0，则为当前vip下的通用券 */
            let _tempVipId = 0;
            if (vo.module == 1) {
              _tempVipId = VIP_MANAGE.PERSON.FOREVER.ID
            } else if (vo.module == 2) {
              _tempVipId = VIP_MANAGE.BUSINESS.YEAR.ID
            } else if (vo.module == 3) {
              _tempVipId = VIP_MANAGE.COMPANY.All_PURPOSE_NEW.ID
            }
            vo._payLink = PAGE_PATH.PAYMENT.DEFAULT + '/' + _tempVipId;

          } else {

            let _tempVip = VIP_MANAGE.LIST.filter(item => {
              return item.PARENT_ID == vo.module && item.REAL_ID == vo.vip_type
            })

            if (_tempVip.length) {
              vo._payLink = PAGE_PATH.PAYMENT.DEFAULT + '/' + _tempVip[0].ID;
            } else {
              vo._payLink = PAGE_PATH.PAYMENT.BUSINESS;
            }
          }

        }

        /* 处理档位可用文案 */
        let _tempRequireAmount = _.round(vo.require_amount, '1');
        console.log(_tempRequireAmount)

        if (vo.module == 0) {
          vo._vipTxt = _tempRequireAmount == 0 ? '全站可用' : `全站满${_tempRequireAmount}元可用`
        } else if (vo.module == 3) {
          vo._vipTxt = _tempRequireAmount == 0 ? '企业VIP可用' : `企业VIP满${_tempRequireAmount}元可用`
        } else if (vo.module == 1) {
          vo._vipTxt = _tempRequireAmount == 0 ? '普通VIP可用' : `普通VIP满${_tempRequireAmount}元可用`
        } else if (vo.module == 2) {
          vo._vipTxt = _tempRequireAmount == 0 ? '商用VIP可用' : `商用VIP满${_tempRequireAmount}元可用`
        }

        /* 去掉小数点末尾0 */
        if (vo.discount_amount) {
          vo._discount_amount = _.round(vo.discount_amount, '1');
        }

      })
      return coupon
    } else {
      console.error('请传入一个数组')
    }
  }


}

export default new FnPay();
