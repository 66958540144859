<template>
  <div class="CompanyHeader">
    <div class="header-wrap">
      <div class="inner_center">
        <div class="bar-left">
          <a href="/"
             target="_blank"> <i class="iconfont icon-logo"/>
          </a>
          <i class="dot"/>
          <span> 企业服务</span>
        </div>
        <!-- 头像下拉 -->
        <div class="bar-right">

          <div
            class="icon-box" @click="showNewUserGuide"
            v-if="(companyInfoByPerson.switch && companyInfoByPerson.switch.type== COMMON.HEADER.AVATAR_COMBOBOX.ACCOUNT_TYPE.COMPANY) && companyInfoByPerson.is_admin == 1"
          >
            <i class="iconfont2 icon-qy-guide-line" />
            <!--  hover 下拉  -->
            <div class="dropbox" >
              <div class="dropbox-item">
                新人引导
              </div>
            </div>
          </div>

          <div class="icon-box _hide">
            <i class="iconfont2 icon-qy-question-line" />
            <!--  hover 下拉  -->
            <div class="dropbox">
              <div class="dropbox-item">
                帮助中心
              </div>
            </div>
          </div>
          <HeaderLogin />
<!--          <span
            v-if="isLogin"
            class="id">潮国ID：{{ uniq_id }}
          </span>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AvatarCombobox from '@/components/common/user/avatar-combobox.vue'
import {mapState} from "vuex";
import HeaderLogin from '@/components/common/header/header-login';
import {COMMON} from "@/constant/COMMON";

export default {
  name: "CompanyHeader",
  computed: {
    ...mapState('user', [
      'uniq_id',
      'isLogin',
      'companyInfoByPerson',
    ])
  },
  components: {
    AvatarCombobox,
    HeaderLogin
  },
  data() {
    return {
      interceptMouse: true,
      COMMON
    }
  },
  methods: {
    /* 显示新人引导 */
    showNewUserGuide(){
      this.$emit('showNewUserGuide')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "index";

.CompanyHeader{
  position: relative;
  z-index: 99;
}

/* 头像下拉样式重写 */
.AvatarCombobox {
  /deep/ > .avatar-box {
    width: 36px !important;
    height: 36px !important;
  }

  /deep/ .text-login {
    color: #001013 !important;
  }
}


.header-wrap {
  width: 100%;
  height: $headerHeight;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0 2px 20px -1px rgba(0, 17, 144, 0.05);
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;

  .inner_center {
    width: 100% !important;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    box-sizing: border-box;

    .bar-left {
      display: flex;
      align-items: center;

      .icon-logo {
        font-size: 24px;
        color: $color-primary;
        margin-right: 10px;
      }

      .dot {
        display: inline-block;
        width: 4px;
        height: 4px;
        color: #001013;
        border-radius: 50%;
        margin: 0 10px;
        background-color: #001013;
      }

      span {
        font-size: 16px;
        color: #001013;
        line-height: 22px;
        letter-spacing: 1px;
      }
    }

    .bar-right{
      display: flex;
      align-items: center;
      span{
        margin-left: 15px;
      }

      .icon-box{
        position: relative;
        margin-right: 8px;
        width: 36px;
        height: 36px;
        background: #FFFFFF;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .iconfont2{
          font-size: 22px;
        }

        &:nth-child(2){
          margin-right: 31px;
        }

        @include ElementHover('', '') {
          background: #F8F9FD;
          color: $color-company-server;
          .dropbox {
            display: block;
          }

          &:after {
            display: inline-block;
          }
        }

        .dropbox {
          @include Position(1, 5, 50%, 40px);
          transform: translateX(-50%);
          z-index: 9;
          max-height: 156px;
          background: #424866;
          border-radius: 8px;
          display: none;
          padding: 5px 8px;


          &:after {
            content: '';
            display: inline-block;
            width: 10px;
            height: 10px;
            background: #424866;
            @include Position(1, 5, 50%, -5px);
            transform: translateX(-50%) rotate(135deg);
          }

          .__form-scrollbar{
            //padding-right: 6px;
            width: 100%;
            overflow-y: scroll;
            max-height: 156px;
          }

          .dropbox-item {
            //width: 200px;
            height: 20px;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 19px;
            margin-bottom: 4px;
            white-space: nowrap;
            //padding-right: 6px;

            &:last-child{
              margin-bottom: 0;
            }

          }
        }
      }
    }

    .avatar-wrap {
      display: flex;
      align-items: center;

      .id {
        font-size: 14px;
        color: #2D2B33;
        line-height: 20px;
        margin-left: 14px;
      }
    }
  }

}


.avatar-box {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.6);
  margin-right: 23px;
  position: relative;
  cursor: pointer;

  .UserCombobox {
    @include Position(1, 2, 0, 55px);
    z-index: 5;
  }

  .avatar {
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .icon_device {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 16px;
    @include LayoutFlex(1);
    @include Position(1, 3, 0, 0);
    color: #fff;

  }

  .icon-qy-mobile {
    background: #6969FF;
  }

  .icon-qy-wechat2 {
    background: #51CF50;
  }

  .icon-qy-QQ2 {
    background: #06B4FD;
  }

}

.text-login {
  font-size: 16px;
  color: #001013;
  line-height: 52px;
  letter-spacing: 1px;

}
</style>
