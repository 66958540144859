<template>
  <div class="VipExpireDialog">
    <CgModalMiddle
      :is-show-close="false"

    >
      <div
        slot="modal_content"
        class="modal-content"
        @click.prevent="receiveCoupon"
      >
        <i
          class="iconfont icon-cha"
          @click.stop="closeDialog" />

        <div class="receive anima" />
      </div>
    </CgModalMiddle>
  </div>
</template>

<script>
import {fnGuideDownloadCount, fnRouter, fnUser} from "@/fn";
import {getCookie} from "@/utils/auth";
import {VIP_STATE} from "@/constant/VIP";
import {mapState} from "vuex";
import {getRmbEightCoupon} from "@/api/home";
import {getCouponState} from "@/api/coupon";
import { PAGE_PATH } from '@/constant/PAGE_PATH'
import {HOME} from "@/constant/HOME";
export default {
  name: "VipExpireDialog",
  computed:{
    ...mapState('user',['isLogin','companyInfoByPerson','companyVipState','isSwitchAccountType'])

  },
  data(){
    return{
      isShow: false
    }
  },
  watch:{
    isLogin:{
      handler(n,o){
        if (n == true){
          setTimeout(()=>{
            this.init();
          },500)
        }
      }
    },
    isSwitchAccountType:{
      handler(n,o){
        console.log(n)
        if (n == true){
          console.log('-- isSwitchAccountType 登录检查VIP --')
          setTimeout(()=>{
            this.init();
          },500)
        }
      }
    }
  },
  mounted() {

  },
  methods:{
    init(){
      this.$store.commit('user/SET_SWITCH_ACCOUNT_TYPE',false)
      /* 非vip */
      let personVipState = getCookie('personVipState')
      let personVipInfo = JSON.parse(getCookie('personVipInfo'))
      let businessVipState = getCookie('businessVipState')
      let companyVipState = getCookie('companyVipState')

      let isOpenVip = [];
      if (personVipState !== VIP_STATE.NO && personVipInfo.vip != 1){
        isOpenVip.push(personVipState)
      }
      if (businessVipState !== VIP_STATE.NO){
        isOpenVip.push(businessVipState)
      }
      if (companyVipState !== VIP_STATE.NO){
        isOpenVip.push(companyVipState)
      }
      // console.log('已开通VIP状态'+ isOpenVip)

      /* 一个都没有 */
      if (isOpenVip.length == 0){
        return false
      }
      let flag = isOpenVip.every(item => item == VIP_STATE.OUT )

      if (flag){
        getCouponState({
          couponId:8
        }).then(res =>{
          let { data, status } = res.data;
          console.log(data,status)
          if (status === 200){
            if (data.num == 0){
              console.log('未领取 10 元优惠券')
              this.isShow = true
              fnGuideDownloadCount.trackNewUser(HOME.NEW_USER_COUPON_TYPE.RENEW,HOME.NEW_USER_COUPON_TYPE.POSITION.CURRENT_PAGE)

            }else{
              console.log('已领取 10 元优惠券')
              this.isShow = false
            }

          }
        })
      }

      this.$store.commit('user/SET_SWITCH_ACCOUNT_TYPE',false)

    },

    receiveCoupon(){
      getRmbEightCoupon({ couponId:8 }).then(res =>{
        this.isShow = false;
        if (res.data.status == 1){
          fnGuideDownloadCount.trackNewUser(HOME.NEW_USER_COUPON_TYPE.RENEW,HOME.NEW_USER_COUPON_TYPE.POSITION.TO_PAY_PAGE)
          fnRouter.navigationTo(PAGE_PATH.PAYMENT.BUSINESS)
        }else if (res.data.status == -1){
          this.$myNotify.show(res.data.msg,()=>{
            fnGuideDownloadCount.trackNewUser(HOME.NEW_USER_COUPON_TYPE.RENEW,HOME.NEW_USER_COUPON_TYPE.POSITION.TO_PAY_PAGE)
            fnRouter.navigationTo(PAGE_PATH.PAYMENT.BUSINESS)
          })
        }
      })
    },
    closeDialog(){
      this.isShow = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.VipExpireDialog{

  .modal-content{
    @include BgImage(377.5px,475px,'home/vip-expire-dialog/vip-expire%40bg.png');

    .icon-cha{
      width: 38px;
      height: 38px;
      background: #E3E3E3;
      border-radius: 50%;
      font-size: 17px;
      color: #20395B;
      @include LayoutFlex(1);
      @include Position(1,2,-57px,36px);
      cursor: pointer;
      transition: all 0.5s ease-in-out;
      @include ElementHover('', '') {
        transform: rotate(180deg);
      }
    }

    .receive{
      @include BgImage(240px,73px,'home/vip-expire-dialog/vip-expire%40receive.png');
      @include Position(1,4,50%,10px);
      transform: translateX(-50%);
      cursor: pointer;
    }
  }


}


.anima {
  animation-name: likes; // 动画名称
  animation-direction: alternate; // 动画在奇数次（1、3、5...）正向播放，在偶数次（2、4、6...）反向播放。
  animation-timing-function: linear; // 动画执行方式，linear：匀速；ease：先慢再快后慢；ease-in：由慢速开始；ease-out：由慢速结束；ease-in-out：由慢速开始和结束；
  animation-delay: 0.5s; // 动画延迟时间
  animation-iteration-count: infinite; //  动画播放次数，infinite：一直播放
  animation-duration: 1.8s; // 动画完成时间
}

@keyframes likes {
  0%{
    transform: translateX(-50%) scale(1);
  }
  50%{
    transform: translateX(-50%) scale(0.9);
  }
  100%{
    transform: translateX(-50%) scale(1);
  }
}
</style>
