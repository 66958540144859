export const MENU = [
  {
    label: "近期热门",
    act: 1
  },
  {
    label: "电商",
    act: 2
  },
  {
    label: "3D",
    act: 3
  },
  {
    label: "节日节气",
    act: 4
  },
  {
    label: "海报",
    act: 5
  },
  {
    label: "PPT",
    act: 6
  },
  {
    label: "流行风格",
    act: 7
  },
]


export const FESTIVAL_MONTH = [
  {
    label: '1',
    bgColor: "#FA6269",
    key: 1
  },
  {
    label: '2',
    bgColor: "#FF6D9C",
    key: 2
  },
  {
    label: '3',
    bgColor: "#9ACE73",
    key: 3
  },
  {
    label: '4',
    bgColor: "#78BC8E",
    key: 4
  },
  {
    label: '5',
    bgColor: "#FCA661",
    key: 5
  },
  {
    label: '6',
    bgColor: "#F88562",
    key: 6
  },
  {
    label: '7',
    bgColor: "#3EC7E7",
    key: 7
  },
  {
    label: '8',
    bgColor: "#68CCBE",
    key: 8
  },
  {
    label: '9',
    bgColor: "#FCA661",
    key: 9
  },
  {
    label: '10',
    bgColor: "#F88562",
    key: 10
  },
  {
    label: '11',
    bgColor: "#FA6269",
    key: 11
  },
  {
    label: '12',
    bgColor: "#2EB39E",
    key: 12
  },
]

export const DROP_DOWN_DATA = {
  hot: {
    pic: [],
    topic: [],
    marketing_festival: [],
    twenty_festival: [],
  },
  navigate_3d: {
    category: [],
    recommend: [],

  },
  festival: {},
  design: {
    design: [],
    recommendPic: [],
  },
  business: {
    category: [],
    content: [],
    pic: [],
  },
  plane: {
    category: [],
    content: [],
    pic: [],
  },
  ppt: {
    scene: [],
    category: [],
    pic: [],
  },
}

/* 处理全部下拉数据 */
export function disposeDropDownData(data) {

  let dropDownData;

  if (Object.keys(data).length === 0) {
    dropDownData = DROP_DOWN_DATA
  } else {
    dropDownData = JSON.parse(data);
  }

  let {
    hot = {
      pic: [],
      topic: [],
      marketing_festival: [],
      twenty_festival: [],
    },
    navigate_3d = {
      category: [],
      recommend: [],

    },
    festival = {},
    design = {
      design: [],
      recommendPic: [],
    },
    business = {
      category: [],
      content: [],
      pic: [],
    },
    plane = {
      category: [],
      content: [],
      pic: [],
    },
    ppt = {
      scene: [],
      category: [],
      pic: [],
    },
  } = dropDownData

  /* 电商分类增加链接 */
  if (business && Array.isArray(business.category) && business.category.length) {
    business.category.forEach(item => {
      item._link = `/tupian/0-${item.pid}-0-0-${item.id}-0-0-default-1-0-0-0.html`
    })
  }
  if (business && Array.isArray(business.content) && business.content.length) {
    business.content.forEach(item => {
      item._link = item.link_url;
    })
  }

  /* 平面分类增加链接 */
  if (plane && Array.isArray(plane.category) && plane.category.length) {
    plane.category.forEach(item => {
      item._link = `/tupian/0-${item.pid}-0-0-${item.id}-0-0-default-1-0-0-0.html`
    })
  }

  if (plane && Array.isArray(plane.content) && plane.content.length) {
    plane.content.forEach(item => {
      item._link = item.link_url
    })
  }

  if (ppt && Array.isArray(ppt.scene) && ppt.scene.length) {
    ppt.scene.forEach(item => {
      item._link = `/tupian/0-137-0-0-0-0-${item.id}-default-1-0-0-0.html`
    })
  }
  if (ppt && Array.isArray(ppt.category) && ppt.category.length) {
    ppt.category.forEach(item => {
      item._link = `/tupian/0-137-0-0-${item.id}-0-0-default-1-0-0-0.html`
    })
  }

  if (design && Array.isArray(design.design) && design.design.length) {
    design.design.forEach(item => {
      item._link = `/tupian/0-0-0-0-0-0-0-new-1-0-${item.id}-1.html`
    })
  }


  /* 节日节气每个限制在6个 */
  if (festival && JSON.stringify(festival) != '{}') {
    for (const key in festival) {
      if (Array.isArray(festival[key]) && festival[key].length) {
        festival[key] = festival[key].length > 6 ? festival[key].slice(0, 6) : festival[key];
      }
    }
  }

  console.log(festival)


  return {
    hot,
    navigate_3d,
    festival,
    design,
    business,
    plane,
    ppt
  }
}
