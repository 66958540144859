import {
  BUSINESS_QUARTER_INFO,
  BUSINESS_VIP_INFO,
  BUSINESS_VIP_MONTH_INFO,
  PERSON_QUARTER_INFO, PERSON_QUARTER_MONTH_INFO,
  PERSON_VIP_INFO, PERSON_VIP_LIFELONG
} from "~/constant/VIP";
import {
  COMPANY_All_PURPOSE,
  COMPANY_All_PURPOSE_HALF,
  COMPANY_ONLINE,
  COMPANY_ONLINE_HALF
} from "@/constant/VIP_COMPANY";


export const vipList = [
  {
    ...PERSON_VIP_LIFELONG,
    LIST: [
      PERSON_VIP_LIFELONG,
      PERSON_VIP_INFO,
      PERSON_QUARTER_INFO,
      // PERSON_QUARTER_MONTH_INFO
    ]
  },
  {
    ...BUSINESS_VIP_INFO,
    LIST: [
      BUSINESS_VIP_INFO,
      BUSINESS_QUARTER_INFO,
      BUSINESS_VIP_MONTH_INFO
    ]
  },
  {
    // ...COMPANY_ONLINE,
    ...COMPANY_All_PURPOSE,
    LIST: [
      COMPANY_All_PURPOSE,
      COMPANY_All_PURPOSE_HALF,
      COMPANY_ONLINE,
      COMPANY_ONLINE_HALF,
    ]
  }
]
