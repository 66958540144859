/* 企业授权下拉 */

import {
  COMPANY_All_PURPOSE,
  COMPANY_ONLINE,
  COMPANY_RESALE,
  COMPANY_SENIOR,
  COMPANY_UPGRADE
} from "@/constant/VIP_COMPANY";

export const COMPANY_LICENSE = {

  HEADLINE:'企业VIP授权',
  SUBHEAD:'推荐适用企业：中小型企业、大型集团企业、国企/事业单位、广告公司/代运营公司等',
  FEATURE:{
    LIST1:[
      '新媒体用途',
      '电商平台',
      '线上推广',
      '物料印刷',
      '线下活动',
      '户外广告',
      '影视制作',
      '传媒平台',
      '实物印刷',
      '商品生产',
      '产品内置',
      '敏感用途',
      '商标LOGO',
      '内置转售',
    ],
    LIST2:[
      '企业商用授权',
      '商用海量下载',
      '图片包一键下载',
      '图片版权保障',
      '在线申请发票',
      '专属客服1对1',
    ],
  },
  VIP_LIST:[
    /*{
      name:'线上版',
      ID: COMPANY_ONLINE.ID,
      PARENT_ID: COMPANY_ONLINE.PARENT_ID,
      PRICE: COMPANY_ONLINE.PRICE,
      ACCOUNT_NUMBER: COMPANY_ONLINE.ACCOUNT_NUMBER,
      time:'1年',
      className:'online',
      accountNumber: 1,
      color:'rgba(177, 119, 81, 1)',
      bgColor:'linear-gradient(180deg, #FFF8EE 0%, #FFEFD9 100%)',
      barBgColor:'#FFF3E5'

    },*/
    {
      name:'标准版',
      ID: COMPANY_All_PURPOSE.ID,
      PARENT_ID: COMPANY_All_PURPOSE.PARENT_ID,
      PRICE: COMPANY_All_PURPOSE.PRICE,
      ACCOUNT_NUMBER: COMPANY_All_PURPOSE.ACCOUNT_NUMBER + '个子帐号',
      time:'1年',
      className:' online',
      accountNumber: 1,
      color:'rgba(179, 77, 41, 1)',
      bgColor:'linear-gradient(180deg, #FFF2E5 0%, #FEE0C6 100%)',
      barBgColor:'#FDE9D6'
    },
    {
      name:'升级版',
      ID: COMPANY_UPGRADE.ID,
      PARENT_ID: COMPANY_UPGRADE.PARENT_ID,
      PRICE: COMPANY_UPGRADE.PRICE,
      ACCOUNT_NUMBER: COMPANY_UPGRADE.ACCOUNT_NUMBER + '个子帐号',
      time:'1年',
      className:' all',
      accountNumber: 4,
      color:'rgba(137, 70, 44, 1)',
      bgColor:'linear-gradient(180deg, #FCECE1 0%, #F8D5BF 100%)',
      barBgColor:'#F9E3D3'
    },
    {
      name:'高级版',
      ID: COMPANY_SENIOR.ID,
      PARENT_ID: COMPANY_SENIOR.PARENT_ID,
      PRICE: COMPANY_SENIOR.PRICE,
      ACCOUNT_NUMBER: COMPANY_SENIOR.ACCOUNT_NUMBER + '个子帐号',
      time:'1年',
      className:' upgrade',
      tagClass: 'company-gj',
      accountNumber: 10,
      color:'rgba(86, 40, 5, 1)',
      bgColor:'linear-gradient(180deg, #FDE9D9 0%, #FBCEB1 100%)',
      barBgColor:'#FCDDC9'
    },
    {
      name:'转售版',
      ID: COMPANY_RESALE.ID,
      PARENT_ID: COMPANY_RESALE.PARENT_ID,
      PRICE: COMPANY_RESALE.PRICE,
      ACCOUNT_NUMBER: COMPANY_RESALE.ACCOUNT_NUMBER + '个子帐号',
      time:'1年',
      className:' senior',
      tagClass: 'company-gj',
      accountNumber: 20,
      color:'rgba(65, 28, 0, 1)',
      bgColor:'linear-gradient(180deg, #FFE6BC 0%, #FFDFB3 36%, #FFD3A3 100%)',
      barBgColor:'#FFE0BD'
    },
    {
      name:'定制版',
      ID: -1,
      PARENT_ID: COMPANY_RESALE.PARENT_ID,
      PRICE: COMPANY_RESALE.PRICE,
      ACCOUNT_NUMBER: '1个起（可加购）',
      time:'1年',
      className:'resale',
      accountNumber: 20,
      color:'rgba(65, 28, 0, 1)',
      bgColor:'linear-gradient(180deg, #FFE6BC 0%, #FFDFB3 36%, #FFD3A3 100%)',
      barBgColor:'#FFE0BD'
    }
  ]
}
