export const industry = [
  "IT/互联网",
  "电商/微商",
  "广告营销",
  "自媒体/新媒体",
  "酒店旅游",
  "金融保险",
  "教育/培训",
  "家装/房地产",
  "制造业",
  "印刷出版",
  "法律咨询",
  "传媒娱乐",
  "医疗美容",
  "餐饮/零售",
  "在校学生",
  "政府/社会公益"
]
export const jobs = [
  // "设计师",
  "平面设计",
  "UI设计",
  "包装设计",
  "电商设计",
  "插画设计",
  "商务人员",
  "编辑/策划",
  "市场/销售",
  "新媒体运营",
  "主播/博主",
  "电商运营",
  "医生/护士",
  "店长/店员",
  "人事/行政",
  "医药从业者",
  "教师/培训师",
  "个体商户",
  "自由职业者",
  "学生",
  "公务员/机关人员",
]
export const ageRange = [
  "18岁以下",
  "18-24岁",
  "25-34岁",
  "35-44岁",
  "45-54岁",
  "55岁以上"
]
export const sex = [
  "男",
  "女"
]

