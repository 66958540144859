import {fnLogin} from "@/fn";
import {getCookie} from "@/utils/auth";
import {PAGE_PATH} from "@/constant/PAGE_PATH";

class FnCompany {


  /* 路由拦截 */
  intercept(app,store, route,next){

    if (fnLogin.checkLogin()){
      store.dispatch('user/getCompanyInfoByPerson')
    }

    if (route.path.indexOf('/company') > -1
      && route.path.indexOf('/company/team-manage/invite') < 0
      && route.path.indexOf('/introduce/company') < 0
      && route.path.indexOf('/company/main-certificate') < 0
    ) {

       /* 管理员或者有主体的成员才有权限进入企业后台 */
      /* if (fnLogin.checkLogin()){
         // store.dispatch('user/getCompanyInfoByPerson')
         let companyInfoByPerson = JSON.parse(getCookie('companyInfoByPerson'))
         console.log('companyInfoByPerson')
         console.log(companyInfoByPerson)
         store.commit('user/SET_COMPANY_INFO_BY_PERSON', !getCookie('companyInfoByPerson') ? {} : JSON.parse(getCookie('companyInfoByPerson')));

         let joinWhere = companyInfoByPerson.is_admin == 1 || ( companyInfoByPerson.is_admin == 0 && companyInfoByPerson.auth_main_num > 0 )
         if (joinWhere){
           console.log( '已有企业后台权限' )
           if (companyInfoByPerson.is_admin == 1){
             store.dispatch('company/getJoinNoReadNum')
           }
           next();
         }else{
           console.log( '暂无后台权限' )
           window.location.href = '/'
         }
       }else{
         window.location.href = '/'
         // next('/');
         // fnLogin.loginIntercept(false)
       }*/
    }
  }

  getCompanyRouterList(){

    let router = JSON.parse(JSON.stringify(PAGE_PATH.COMPANY))
    console.log(  router)
    let arr = [];

    Recurve(router)

    console.log(arr)
    return arr


    function Recurve(obj){
      for (const key in obj) {

        if (typeof obj[key] == 'object'){
          Recurve(obj[key])
        }else{
          arr.push(obj[key])
        }

      }
    }


  }

  backTop(){
    let _wrapper=document.querySelector(".CompanyMain");//获取wrapper
    let wrapperY=_wrapper.scrollTop;	//获取Y轴滚动的距离
    console.log(wrapperY)
    let id=setInterval(function(){		//定时器函数
      if(wrapperY<=0){				//如果wrapperY轴小于等于0
        clearInterval(id);			//停止计时器
      }
      wrapperY-=88;
      _wrapper.scrollTop=wrapperY;
    },16.7);
  }

}

export default new FnCompany()
