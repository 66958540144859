<template>
  <div class="CustomImage">

    <img v-if="spider"
         :src="src"
         :alt="alt"
         class="pic-img "
         @contextmenu.prevent=""
    >


    <template v-else>

      <img
        v-if="customAHref == ''"
        v-lazy="src"
        :alt="alt"
        class="pic-img "
        @contextmenu.prevent=""
      >

      <a v-else :href="customAHref" target="_blank" @click.prevent="">
        <img
          v-lazy="src"
          :alt="alt"
          class="pic-img "
          @contextmenu.prevent=""
        >
      </a>

    </template>

  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "CustomImage",
  computed: {
    ...mapState('base', ['spider']),
  },
  props: {
    src: "",
    alt: "",
    customAHref: {
      type: String,
      default: ""
    }
  }
}
</script>

<style lang="scss" scoped>
.CustomImage {
  width: 100%;
  height: 100%;

  img {
    display: inline-block;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease-in-out;
  }

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

</style>
