<template>
  <div class="CompanyCertificateDialog" v-if="isShow">

    <CgModalMiddle
      @close="isShow = false"
    >
      <div
        slot="modal_content"
        class="modal-content __company-modal _user-select"
      >
        <i class="iconfont2 icon-qy-profile-line"
          @click="isShow = false"
        ></i>
        <div class="msg">
          <!--          -->
          <template v-if="type == 1">你当前是个人用户身份，请在头像下拉切换为企业身份继续下载</template>
          <template v-if="type == 2">您当前企业帐户未认证， 请前往企业服务认证后继续下载</template>
          <template v-if="type == 3">您当前企业帐户主体未认证，请前往【企业服务】填写授权企业名称，确保企业授权保障</template>
        </div>
        <div class="btn-group">
          <template v-if="type == 1">
            <div
              @click="isShow = false"
              class="btn-item btn-cancel">取消</div>
            <div class="btn-item btn-confirm"
                 @click="isShow = false"
            >知道了</div>
          </template>

          <template v-if="type == 2">
            <div
              @click="isShow = false"
              class="btn-item btn-cancel">知道了</div>
            <div class="btn-item btn-confirm" @click="navigator">去认证</div>
          </template>

          <template v-if="type == 3">
            <div
              @click="isShow = false"
              class="btn-item btn-cancel">知道了</div>
            <div class="btn-item btn-confirm" @click="navigator">去认证</div>
          </template>

        </div>
      </div>
    </CgModalMiddle>


  </div>
</template>

<script>
import {fnRouter} from "@/fn";
import { PAGE_PATH } from '@/constant/PAGE_PATH'

export default {
  name: "CompanyCertificateDialog",
  data(){
    return{
      isShow: false,
      PAGE_PATH
    }
  },
  props:{
    type: String | Number,
    default:  3
  },
  methods:{
    navigator(){
      fnRouter.navigationTo(PAGE_PATH.COMPANY.HOME)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-content{
  width: 440px;
  height: 276px;
  background: #FFFFFF;
  border-radius: 14px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .icon-qy-profile-line{
    font-size: 40px;
    color: $color-primary;
    margin: 40px 0 14px;

  }
  .msg{
    font-size: 20px;
    font-weight: bold;
    color: #424866;
    line-height: 26px;
    margin: 0 40px 28px;
    text-align: center;
  }

  .btn-group{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 68px;
    box-sizing: border-box;

    .btn-item{
      width: 140px;
      height: 44px;
      background: #E0E3E9;
      border-radius: 25px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .btn-cancel{
      color: #596088;
    }
    .btn-confirm{
      color: #FFFFFF;
      background: #6969FF;
    }
  }
}
</style>
