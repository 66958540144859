/* 收藏夹功能函数 */

import {getCookie} from "@/utils/auth";

class fnFavorites {


  /* 显示弹框 */
  showDialog(flag = true) {
    let {$store} = $nuxt;
    $store.commit('favorites/SET_SHOW_DIALOG', flag)
  }


  /* 显示弹框企业收藏 */
  showDialogByCompany(flag = true) {
    let {$store} = $nuxt;
    $store.commit('favorites/SET_SHOW_DIALOG_BY_COMPANY', flag)
  }

  /* 创建 */
  showCreateDialog(flag = true) {
    let {$store} = $nuxt;
    $store.commit('favorites/SET_SHOW_CREATE_DIALOG', flag)
  }

  /* 更新 */
  showUpdateDialog(flag = true) {
    let {$store} = $nuxt;
    $store.commit('favorites/SET_SHOW_UPDATE_DIALOG', flag)
  }

  /* 取消拦截 */
  showCancelDialog(flag = true) {
    let {$store} = $nuxt;
    $store.commit('favorites/SET_SHOW_CANCEL_DIALOG', flag)
  }

  /* 移动 */
  showMoveDialog(flag = true) {
    let {$store} = $nuxt;
    $store.commit('favorites/SET_SHOW_MOVER_DIALOG', flag)
  }

  /* 收藏成功提示 */
  showCollectSuccessDialog(flag = true) {
    let {$store} = $nuxt;
    $store.commit('favorites/SET_SHOW_COLLECT_SUCCESS_DIALOG', flag)
  }

  /* 创建收藏夹失败提示 */
  showCreateErrDialog(flag = true) {
    let {$store} = $nuxt;
    $store.commit('favorites/SET_SHOW_CREATE_ERR_DIALOG', flag)
  }

  /* 保存当前选中图片信息 */
  saveCurrentTupianInfo(info) {
    let {$store} = $nuxt;
    $store.commit('favorites/SET_CURRENT_TUPIAN_INFO', info)
  }

  getUserIsVip() {
    let userVipInfo = JSON.parse(getCookie('userVipInfo'));

    let {
      business_vip_id,
      company_vip_id,
      personal_vip_id
    } = userVipInfo;
    return business_vip_id != 0 || company_vip_id != 0 || personal_vip_id != 0
  }


}

export default new fnFavorites();
