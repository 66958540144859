import {
  USER_CENTER, USER_CENTER_CODE_RECORD,
  USER_CENTER_COLLECT_RECORD, USER_CENTER_COUPON_RECORD,
  USER_CENTER_DOWNLOAD_RECORD, USER_CENTER_LEAFLET_RECORD, USER_CENTER_USER_INTO,
  USER_CENTER_VIP_RECORD
} from "@/constant/_Public/Path";

export const menu = [
  {
    name: 'VIP会员',
    iconClass: 'icon-user-vip',
    module: 'invoice',
    dataName: 'invoiceRecord',
    id: 0,
    path: USER_CENTER_VIP_RECORD
  },
  {
    name: '优惠券',
    iconClass: 'icon-user-eye',
    module: 'glance',
    id: 7,
    path: USER_CENTER_COUPON_RECORD
  },
  {
    name: '收藏记录',
    iconClass: 'icon-user-collect',
    module: 'collect',
    dataName: 'collectRecord',
    id: 1,
    path: USER_CENTER_COLLECT_RECORD
  },
  {
    name: '下载记录',
    iconClass: 'icon-user-download',
    module: 'download',
    dataName: 'downloadRecord',
    id: 2,
    path: USER_CENTER_DOWNLOAD_RECORD
  },

  /*{
    name: '单张购买记录',
    iconClass: 'icon-packgerenzhongxin',
    module: 'packageOrder',
    dataName: 'leafletRecord',
    id: 8,
    path: USER_CENTER_LEAFLET_RECORD
  },*/
  {
    name: '个人信息',
    iconClass: 'icon-user-profile',
    module: 'information',
    id: 5,
    path: USER_CENTER_USER_INTO
  },
  {
    name: '兑换码查询',
    iconClass: 'icon-user-profile',
    module: 'information',
    id: 6,
    path: USER_CENTER_CODE_RECORD
  },
  /*{
    name: '企业授权',
    iconClass: 'icon-user-download',
    module: 'companyDownload',
    dataName: 'companyMemberRecord',
    id: 3
  },
  {
    name: '图片包购买',
    iconClass: 'icon-packgerenzhongxin',
    module: 'packageOrder',
    dataName: 'picPackageRecord',
    id: 4
  },*/
]
