<!--
  邀请好友悬浮框
-->
<template>
  <div>
    <div
      v-show="isShow && isLogin"
      class="invite-cloud-box" >
      <div
        class="invite-cloud"
        @click="navigatorTo"
      />
    </div>
  </div>

</template>

<script>
import {INVITE_FRIENDS} from "@/constant/PATH";
import {fnInviteFriend} from "~/fn";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import {mapState} from "vuex";

/* 邀请好友bar和浮标白名单 */
const WHITE_LIST = [
  PAGE_PATH.TUPIAN_LIST,
  PAGE_PATH.TUPIAN_DETAIL,
]
export default {
  name: "InviteCombobox",
  data() {
    return {
      isShow: false,
      INVITE_FRIENDS,
      WHITE_LIST
    }
  },
  computed: {
    ...mapState('user',
      ['isLogin']
    ),
  },
  watch: {
    /* 登录更新详情收藏 */
    isLogin(n, o) {
      if (n == true || n == false) {
        this.init();
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.initShow();
    },
    initShow() {
      let path = this.$route.path

      if (path === '/') {
        this.isShow = true;
        return;
      }

      for (let i = 0; i < WHITE_LIST.length - 1; i++) {
        if (path.indexOf(WHITE_LIST[i]) > -1) {
          this.isShow = true;
          break;
        } else {
          this.isShow = false;
        }
      }
    },
    navigatorTo() {
      fnInviteFriend.inviteFriendEntry(5)
    }
  }
}
</script>

<style lang="scss" scoped>

.invite-cloud-box {
  /*  position: fixed;
    top: 370px;
    right: 10px;
    z-index: 888;*/
  cursor: pointer;
  margin-right: -7px;
  // filter: grayscale(100%) !important;

  .icon-cha2 {
    @include Position(1, 2, -5px, -5px);
    font-size: 19px;
    color: #54626F;
    padding: 5px;
    z-index: 10;
  }

  .invite-cloud {
    //@include BgImage(105px, 91.5px, 'invite/invite-money.png');
    @include BgImage(89px, 89px, 'invite/invite-cloud3.gif?v=2');

    position: relative;

    span {
      @include Position(1, 4, 50%, 4px);
      transform: translateX(-50%);
      white-space: nowrap;
      font-size: 15px;
      font-weight: bold;
      color: #FF5D5A;
      box-sizing: border-box;
    }
  }
}
</style>
