<template>
  <no-ssr>
    <a class="TupianMask SpiderHide"
       :href="link"
       v-if="link != ''"
       :style="{ 'z-index': zIndex }"
       target="_blank"
       :title="title"
    >
      {{ spider ? "" : "潮国创意 天天都有好设计www.chaopx.com" }}
    </a>


    <!-- 不可点击 -->
    <a class="TupianMask SpiderHide"
       :href="link"
       v-else
       :style="{ 'z-index': zIndex }"
       target="_blank"
       @click.prevent.stop=""
       :title="title"
    >
      {{ spider ? "" : "潮国创意 天天都有好设计www.chaopx.com" }}
    </a>
  </no-ssr>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "TupianMask",
  computed: {
    ...mapState('base', ['spider']),
  },
  props: {
    zIndex: {
      type: Number | String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.TupianMask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;

}
</style>
