<template>
  <div class="TimelinePicTomorrow">
    <TimelinePicHeader
      :date="date"
      :isShowMore="false"
    />

    <div class="stay-tuned-box">
<!--      <i class="icon_stay-tuned"/>-->
<!--      <span class="stay-tuned-text">明日更新，敬请期待</span>-->

      <div class="txt-desc">设计不打烊，明天持续上新更精彩…</div>

      <div class="file-format">
        <span class="txt-file-format">
          海量文件格式，兼容主流设计软件
        </span>

        <span class="file-format-icons">
          <i class="icon_ps" />
          <i class="icon_ai" />
          <i class="icon_sketch" />
          <i class="icon_3dMax" />
          <i class="icon_cdr" />
          <i class="icon_c4d" />
          <i class="icon_blender" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import TimelinePicHeader from './timeline-pic-header';
import {toolDate} from "@/tools";

export default {
  name: "TimelinePicTomorrow",
  components: {
    TimelinePicHeader
  },
  data() {
    return {
      date: {
        week: '',
        date: ''
      },
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.initDate();
    },
    initDate() {
      let {month, day, week_zn} = toolDate.getDate(toolDate.getNextDate(1));
      this.date.date = `${month}月${day}日`;
      this.date.week = week_zn;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/components/tupian/common/common.scss";

.TimelinePicTomorrow{
  margin-bottom: 48px;
  /deep/.TimelinePicHeader{
    margin-bottom: 0px;
  }
}
/* 明日更新，敬请期待 */
.stay-tuned-box {
  //@include LayoutFlex(1);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: -8px;
  position: relative;
  z-index: 10;

  .txt-desc{
    font-size: 18px;
    font-weight: bold;
    color: #6969FF;
    line-height: 24px;
    letter-spacing: 1px;
  }

  .file-format{
    padding: 0 20px;
    height: 48px;
    background: linear-gradient(224deg, rgba(255, 110, 144, 0.08) 0%, rgba(208, 147, 255, 0.08) 42%, rgba(105, 105, 255,0.08) 100%, rgba(105, 105, 255,0.08) 100%);
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    .txt-file-format{
      font-size: 13px;
      font-weight: bold;
      color: #424866;
      line-height: 18px;
      margin-right: 17px;

    }
    .file-format-icons{
      font-size: 0;
      i{
        margin-right: 9px;
      }
    }
  }

  .icon_stay-tuned {
    @include BgImage(150px, 150px, 'home/icon_stay-tuned.gif');
  }

  .stay-tuned-text {
    border-radius: 1px;
    margin-left: 32px;
    font-size: 22px;
    color: $color-primary;
    @supports (-webkit-background-clip: text) or (background-clip: text) {
      background: linear-gradient(224deg, #FF6E90 0%, #D093FF 42%, #6969FF 100%, #6969FF 100%);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  }
}
</style>
