import {toolDate} from "@/tools";

class FnBuild {

  /* 根据日期时间戳生成文件夹 */
  createDirName(){
    let date = toolDate.getDate();
    return `${date.date}-${date.hours}-${date.minutes}-${date.seconds}`
  }
}

export default new FnBuild();
