<template>
  <div class="FooterChannel">

    <div class="channel-list">
      <div
        v-for="vo in list"
        :key="vo.id"
        class="channel-item"
        @mouseover.prevent="toggleShowQrCode(vo)"
        @mouseleave.prevent="toggleShowQrCode(vo,false)"
      >
        <i :class="['icon_item', vo.iconClass]" />
        <div
          v-if="vo.isShow"
          class="qr-code">
          <img :src="PAGE_PATH.BASE_IMG_UPLOAD + vo.qrCodeImg" >
          <div class="desc">{{ vo.qrCodeDesc }}</div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import {COMMON} from "@/constant/COMMON";
import {PAGE_PATH} from "@/constant/PAGE_PATH";

export default {
  name: "FooterChannel",
  data(){
    return{
      list: COMMON.Footer.CHANNEL_LIST.slice(),
      PAGE_PATH
    }
  },
  methods:{
    toggleShowQrCode(vo,flag = true){
      vo.isShow = flag
      let index = this.list.findIndex(item => vo.id == item.id)
      this.$set(this.list, index, vo)
    }
  }
}
</script>

<style lang="scss" scoped>
.FooterChannel{

  .channel-list{
    display: flex;

    .channel-item{
      position: relative;
      margin-right: 20px;
      &:after{
        content: '';
        display: inline-block;
        padding: 10px;
        @include Position(1,5,0,0);
        display: none;
      }

      @include ElementHover('',''){
        &:after{
          //display: block;
        }
      }

      .icon_item{
        //
        &.icon_weibo{
          @include BgImage(22px,18px,'common/footer/channel/weibo.svg');
          @include ElementHover('',''){
            @include BgImage(22px,18px,'common/footer/channel/weibo2.svg');
          }
        }
        &.icon_weixin{
          @include BgImage(22px,18px,'common/footer/channel/wechat.svg');
          @include ElementHover('',''){
            @include BgImage(22px,18px,'common/footer/channel/wechat2.svg');
          }
        }
        &.icon_douyin{
          @include BgImage(18px,21px,'common/footer/channel/douyin.svg');
          @include ElementHover('',''){
            @include BgImage(18px,21px,'common/footer/channel/douyin2.svg');
          }
        }
        &.icon_xhs{
          @include BgImage(36px,15px,'common/footer/channel/xhs.svg');
          @include ElementHover('',''){
            @include BgImage(36px,15px,'common/footer/channel/xhs2.svg');
          }
        }
      }

      .qr-code{
        width: 162px;
        background: #FFFFFF;
        box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.08);
        @include Position(1,5,50%,32px);
        transform: translateX(-50%);
        padding: 16px 0 16px;
        box-sizing: border-box;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &:after{
          content: '';
          display: inline-block;
          width: 10px;
          height: 10px;
          background: #FFFFFF;
          @include Position(1,5,50%,-5px);
          transform: translateX(-50%) rotate(135deg);
          box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.08);
        }

        &:before{
          content: '';
          display: inline-block;
          //width: 10px;
          height: 10px;
          background: #FFFFFF;
          width: 80%;
          border-radius: 12px;
          @include Position(1,5,50%,0);
          transform: translateX(-50%);
          z-index: 2;
        }

        img{
          width: 130px;
          height: 130px;
          margin-bottom: 6px;
          vertical-align: middle;
        }
        .desc{
          font-size: 14px;
          color: #2D2B33;
          line-height: 20px;
          text-align: center;
        }
      }
    }
  }
}
</style>
