import {MATERIAL_DETAIL, IMG_BASE_URL} from "@/constant/PATH";
import PicTagCollect from '@/components/pic/pic-tag/pic-tag-collect';
import PicTagCollectNew from '@/components/pic/pic-tag/pic-tag-collection-new';
import PicTagCollectTopic from '@/components/pic/pic-tag/pic-tag-collect-topic';
import PicTagTitle from '@/components/pic/pic-tag/pic-tag-title';
import PicTagLegalCopy from '@/components/pic/pic-tag/pic-tag-legal-copy';
import PicTagLegalCopyRecommend from '@/components/pic/pic-tag/pic-tag-legal-copy-recommend';
import PicButtonFree from '@/components/pic/pic-button/pic-button-free'
import PicButtonBusiness from '@/components/pic/pic-button/pic-button-business';
import PicButtonGroup from '@/components/pic/pic-button-group/pic-button-group';
import PicButtonGroupPackage from '@/components/pic/pic-button-group/pic-button-group-package';
import {mapState} from "vuex";
import { PAGE_PATH } from '@/constant/PAGE_PATH'
import TupianMask from '@/components/tupian/tupian-mask'
import {TUPIAN_DETAIL} from "@/constant/_Public/Path";


let picItemExtend = {
  computed: {
    ...mapState('user',
      [
        'personVipState',
        'personVipInfo',
        'businessVipState',
        'businessVipInfo',
        'companyVipState',
        'companyVipInfo',
        'isLogin'
      ]
    ),
  },
  components: {
    PicTagCollectTopic,
    PicTagCollect,
    PicTagCollectNew,
    PicTagTitle,
    PicTagLegalCopy,
    PicTagLegalCopyRecommend,
    PicButtonFree,
    PicButtonBusiness,
    PicButtonGroup,
    PicButtonGroupPackage,
    TupianMask
  },

  props: {
    // 单个图片信息
    picItemInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      MATERIAL_DETAIL,
      TUPIAN_DETAIL,
      IMG_BASE_URL,
      PAGE_PATH
    }
  },
  methods: {

    picItemClick(picItem){
      this.$emit('picItemClick',picItem)
    }

  }
}

export default picItemExtend;
