<template>
  <div class="home-bar-wrap">
    <a
      v-show="isShow"
      :href="MEMBER_LICENSE_VIP"
      target="_blank"
      class="home-bar" @click="tag('充值:首页横幅:图片')">

      <i
        class="iconfont icon-cha"
        @click.stop.prevent="close"
      ></i>

    </a>
  </div>
</template>

<script>
import {MEMBER_LICENSE_VIP} from "@/constant/PATH";
import localStorage from "@/utils/localStorage";
import {MODEL_HOME_BAR} from "@/constant/MODELS";
import {fnLogin, fnUser} from "@/fn";
import {tag} from "@/utils/statUtil";

export default {
  name: "TopUpHomeBar",
  data() {
    return {
      MEMBER_LICENSE_VIP,
      isShow: false
    }
  },
  computed: {
    isLogin() {
      return this.$store.state.user.isLogin
    }
  },
  watch: {
    isLogin(n, o) {
      if (n == true) {
        setTimeout(() => {
          this.checkIsShow();
        }, 1000)
      } else {
        this.isShow = true
      }
    }
  },

  mounted() {
    console.log(this.getTodayIsShow())

    this.checkIsShow();

    /* 未登录显示 、登录非vip */
  },
  methods: {

    checkIsShow() {
      let whereNoLogin = !this.getTodayIsShow() && !fnLogin.checkLogin()
      let whereLogin = !this.getTodayIsShow() && fnLogin.checkLogin() && !fnUser.checkIsVip() ;

      if (whereNoLogin || whereLogin) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    },

    getTodayIsShow() {
      return this.$localStorage.getItemDate(MODEL_HOME_BAR);
    },

    close() {
      this.isShow = false;
      this.$localStorage.setItemDate(MODEL_HOME_BAR);
    },
    tag(stat) {
      tag(stat)
    }
  }
}
</script>
<style lang="scss" scoped>

.home-bar-wrap {
  max-width: 1920px;
  margin: 0 auto;

  .inner_center {
    position: relative;
    height: 100%;
  }

  .home-bar {
    @include BgImage(100%, 40px, 'act/top-up/home-bar2.jpg?v=2', 1);
    position: relative;
  }

  .icon-cha {
    //@include BgImage(39px, 34px, 'act/top-up/icon_close.png?v=2');
    @include Position(1, 2, 10px, 50%);
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 16px;
    color: #FFFFFF;
  }
}

</style>
