/* 花瓣采集活动  */
import request from "@/utils/request";
import qs from "qs";

export function act618Sign(query) {
  return request({
    url: '/?m=Activity&a=signState',
    method: 'get',
    params: query
  })
}



export function act618SignIn(query) {
  return request({
    url: '/?m=Activity&a=signIn',
    method: 'get',
    params: query
  })
}


export function getCoupon(query) {
  return request({
    url: '/?m=Activity&a=couponGet',
    method: 'get',
    params: query
  })
}
