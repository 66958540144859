<template>
  <div class="vip-label-wrap" v-if="isLogin">
    <!-- 企业VIP -->
    <div
      v-show="companyVipState === VIP_STATE.ON"
      :class="{
        'active': companyVipState === VIP_STATE.ON,
        'company-vip-l': companyVipInfo.type == 3,
      }"
      class="company-vip common-vip-item _hide"
    >

      <div class="end-time-tips end-time-tips1">
        <div class="vip-name">{{ companyVipInfo.type == 3 ? '企业线上用途版' : '企业全用途版' }}</div>
        <div class="vip-renew">
          <span>{{ companyVipInfo.END_TIME }}到期</span>
          <NavigationTo :path="companyVipInfo.PAY_URL">
            <span slot="content" class="txt-renew">续费</span>
          </NavigationTo>
        </div>
      </div>

    </div>

    <!-- 商用VIP -->
    <div
      v-show="businessVipState === VIP_STATE.ON"
      :class="{ 'active': businessVipState === VIP_STATE.ON }"
      class="business-vip common-vip-item"
    >
      <!-- 到期时间 -->
      <div class="end-time-tips end-time-tips1" v-if="businessVipState === VIP_STATE.ON">
        <div class="vip-name">商用{{ businessVipInfo.vip | getVipTimeType }}VIP</div>
        <div class="vip-renew">
          <span>{{ businessVipInfo.END_TIME }}到期</span>
          <!--          <NavigationTo :path="businessVipInfo.PAY_URL">
                      <span slot="content" class="txt-renew">续费</span>
                    </NavigationTo>-->
        </div>
      </div>
    </div>

    <!-- 普通VIP -->
    <div
      v-show="personVipState === VIP_STATE.ON"
      :class="{ 'active': personVipState === VIP_STATE.ON }"
      class="person-vip common-vip-item"
    >
      <!-- 到期时间 -->
      <div class="end-time-tips end-time-tips1">
        <div class="vip-name">个人{{ personVipInfo.vip | getVipTimeType }}VIP</div>
        <div class="vip-renew">
          <span v-if="personVipInfo.vip != 6">{{ personVipInfo.END_TIME }}到期</span>

          <template v-if="personVipInfo.vip != 6 && personVipInfo.vip != 1">
            <NavigationTo :path="personVipInfo.PAY_URL">
              <span slot="content" class="txt-renew">续费</span>
            </NavigationTo>
          </template>


          <template v-if="personVipInfo.vip == 6 ">
            <span>终身使用</span>
            <NavigationTo :path="PAGE_PATH.BASE_URL + PAGE_PATH.PAYMENT.UPGRADE">
              <span slot="content" class="txt-renew">升级</span>
            </NavigationTo>
          </template>

          <!-- 体验vip跳转至终身 -->
          <!--          <template v-show="personVipInfo.vip == 1">
                      <NavigationTo :path="PAGE_PATH.PAYMENT.PERSON">
                        <span slot="content" class="txt-renew">续费</span>
                      </NavigationTo>
                    </template>-->
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapState} from "vuex";
import {VIP_STATE} from "@/constant/VIP";
import {PAGE_PATH} from "@/constant/PAGE_PATH";

export default {
  name: "VipLabel",
  computed: {
    ...mapState('user',
      [
        'personVipState',
        'personVipInfo',
        'businessVipState',
        'businessVipInfo',
        'companyVipState',
        'companyVipInfo',
        'isLogin'
      ]
    ),
  },

  mounted() {
    console.log(typeof this.personVipState)
  },

  data() {
    return {
      VIP_STATE,
      PAGE_PATH
    }
  },
  filters: {
    getVipTimeType(val) {
      switch (parseInt(val)) {
        case 5:
          return '年';
        case 4:
          return '季';
        case 3:
          return '月';
        case 6:
          return '终身';
        case 1:
          return '体验';
      }
    },
    getCompanyVipTimeType(val) {
      switch (parseInt(val)) {
        case 3:
          return '线上用途';
        case 5:
          return '全用途';
      }
    }
  }
}
</script>

<style lang="scss" scoped>


@mixin LabelBg($bgImg,$bgActiveImg:'',$bgWidth:58px,$bgHeight:22px) {
  //@include BgImage($bgWidth, $bgHeight, 'home/vip/#{$bgImg}.png');
  position: relative;

  &.active {
    @include BgImage($bgWidth, $bgHeight, 'home/vip/#{$bgActiveImg}.svg');
  }
}


.txt-vip {
  //@include BgImage(30px,12px,'home/vip/txt-year.png');
  //@include Position(1,5,24px,50%);
  //transform: translateY(-50%);
  font-size: 9px;
  font-weight: normal;
  color: rgba(255, 255, 255, 1);
  line-height: 100%;
  @include Position(1, 2, 8px, 50%);
  transform: translateY(-40%);
}

.txt-quarter {
  //@include BgImage(30px,12px,'home/vip/txt-quarter.png');

}

.txt-month {
  @include BgImage(30px, 12px, 'home/vip/txt-month.png');
}

/* 会员标签 */
.vip-label-wrap {
  display: flex;
  align-items: center;

  > div {
    margin-right: 6px;

    &:last-child {
      margin-right: 0;
    }
  }

  .person-vip {
    //@include BgImage( 26px,26px, '/user/vip/icon_vip%40person.svg');
    @include IconVip(1, 26, 26);
    margin-top: -2px;
  }

  .business-vip {
    //@include BgImage( 26px,26px, '/user/vip/icon_vip%40business.svg');
    @include IconVip(1, 26, 26);
    margin-top: -2px;
  }

  .company-vip {
    //@include LabelBg('company-vip', 'company-vip@active', 61px, 22px);
    @include BgImage(26px, 26px, '/user/vip/icon_vip%40company.svg');
    position: relative;
  }

  .company-vip-l {
    //@include LabelBg('company-vip-l', 'company-vip-l@active', 69px, 22px);
    margin-top: -3px;

    .txt-vip {
      vertical-align: -1.5px;

    }

    &.active {
      .txt-vip {
        color: #FFE198;
      }
    }
  }
}

.common-vip-item {
  position: relative;

  &:after {
    content: '';
    width: 100%;
    height: 20px;
    background-color: transparent;
    @include Position(1, 4, 0, -19px);

  }

  @include ElementHover('', '') {
    .end-time-tips1 {
      display: block;

      &:before {
        display: block;
      }
    }

    .end-time-tips3 {
      display: block;
    }
  }

  .txt-vip {
    //margin-top: 8px;
    font-weight: normal;
    @include Position(1, 2, 10px, 50%);
    transform: translateY(-40%);
  }

  .end-time-tips {
    //@include Position(1, 4, 3px, -50px);
    @include Position(1, 5, 0px, 30px);
    line-height: 20px;
    //height: 28px;
    background: #424866;
    padding: 4px 8px;
    white-space: nowrap;
    border-radius: 4px;
    display: none;
    //font-size: 0;
    z-index: 3;
    color: #FFFFFF;
    font-size: 12px;

    .vip-name {

    }

    .vip-renew {
      white-space: nowrap;
      display: flex;

      .txt-renew {
        font-size: 12px;
        color: #EFC78C;
        margin-left: 3px;
        @include ElementHover('', '') {
          text-decoration: underline;
        }
      }

    }

    a {
      font-size: 12px;
      color: #EFC78C;
      margin: 0 8px;

      @include ElementHover(#EFC78C, '') {
        text-decoration: underline;
      }
    }

    .icon-cha {
      font-size: 10px;
      color: #FFFFFF;
    }

    &:after {
      content: '';
      width: 6px;
      height: 6px;
      background-color: #424866;
      @include Position(1, 5, 11px, -3px);
      transform: rotate(135deg);
    }

    &:before {
      content: '';
      width: 100%;
      height: 10px;
      //background-color: pink;
      @include Position(1, 5, 0px, -8px);
      display: none;
    }
  }
}
</style>
