export const ASIDE_MENU = [
  {
    label: '首页',
    id: 0
  },
  {
    label: '签约认证',
    id: 0
  },
  {
    label: '创作中心',
    id: 0,
    children: [
      {
        label: '创作作品',
        id: 0
      },
      {
        label: '官方素材库',
        id: 0
      },
    ]
  },
  {
    label: '任务中心',
    id: 0
  },
  {
    label: '作品管理',
    id: 0
  },
  {
    label: '数据中心',
    id: 0
  },
  {
    label: '收益中心',
    id: 0
  },
  {
    label: '作品排行',
    id: 0
  },

  {
    label: '设计学院',
    id: 0
  },

  {
    label: '个人中心',
    id: 0,
    children: [
      {
        label: '认证资料',
        id: 0
      },
      {
        label: '签约合同',
        id: 0
      },
    ]

  },


]
