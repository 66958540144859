<template>

  <div >
    <no-ssr>
      <div
        class="IntroduceDialog"
        v-show="!isLogin && isShow"
      >
        <CgModalMiddle
          :is-show-close="false"
        >
          <div
            slot="modal_content"
            class="modal_content _user-select">

            <div class="swiper-wrap">
              <div
                v-swiper:mySwiper="swiperOption"
                class="swiper">
                <div class="swiper-wrapper">
                  <div
                    v-for="vo in banner"
                    :key="vo.id"
                    class="swiper-slide _pointer"
                  >
                    <img
                      :src="vo.link"
                      class="swiper-img">
                  </div>
                </div>
              </div>

              <div class="swiper-pagination1">
                <div
                  v-for="(vo,idx) in banner"
                  :key="vo.id"
                  class="swiper-pagination-bullet"
                  :class="{ 'swiper-pagination-bullet-active' : idx == swiperOption.initialSlide }"
                  @mouseover.stop="toggleSwiper(idx)"
                  @click.stop="toggleSwiper(idx)"
                ></div>
              </div>
            </div>

            <div class="intro">
              <div class="intro-name">在这里你可以找到：</div>
              <div class="intro-list">
                <div
                  class="intro-item "
                  :class="{ 'ON' : idx == swiperOption.initialSlide }"
                  v-for="(vo,idx) in banner"
                  :key="vo.id"
                  @mouseover="toggleSwiper(idx)"
                  @click.stop="toggleSwiper(idx)"

                >
                  <i class="icon_cur"></i>
                  <span class="name">{{ vo.item.name }}<i>{{ vo.item.bold_name }}</i></span>
                </div>
              </div>

              <div class="intro-go" @click.stop="showLoginDialog">
                登录立享10天免费下载
              </div>

            </div>
            <i class="iconfont icon-cha" @click.stop="closeDialog" />
            <i class="welcome_cg"/>
          </div>
        </CgModalMiddle>
      </div>
    </no-ssr>
  </div>

</template>

<script>
import {mapState} from "vuex";
import localStorage from "@/utils/localStorage";
import {MODEL_INTRODUCE_DIALOG} from "@/constant/MODELS";
import {fnLocalStorage, fnLogin} from "@/fn";

export default {
  name: "IntroduceDialog",
  computed:{
    ...mapState('base', ['isShowIntroduceDialog']),
    ...mapState('user', ['isLogin']),
  },
  watch:{
    isShowIntroduceDialog:{
      handler(n,o){
        if (n){
          this.isShow = n;
        }
      },
      immediate: true
    }
  },
  data(){
    return{
      isShow: false,
      banner:[
        {
          id: 0,
          link:'//js.chaopx.com/chaotu/common/dialog/introduce-dialog/banner%40hb.jpg',
          item:{
            name:'与众不同的',
            bold_name:'营销海报'
          }
        },
        {
          id: 1,
          link:'//js.chaopx.com/chaotu/common/dialog/introduce-dialog/banner%403d.jpg',
          item:{
            name:'高级质感的',
            bold_name:'3D素材'
          }
        },
        {
          id: 2,
          link:'//js.chaopx.com/chaotu/common/dialog/introduce-dialog/banner%40ds.jpg',
          item:{
            name:'豪华精美的',
            bold_name:'电商设计'
          }
        },
        {
          id: 3,
          link:'//js.chaopx.com/chaotu/common/dialog/introduce-dialog/banner%40ch.jpg',
          item:{
            name:'细腻灵动的',
            bold_name:'插画'
          }
        }
      ],
      swiperOption: {
        autoplay: {
          delay: 5000,
          speed: 500,
          disableOninteraction: true
        },
        //配置分页
        pagination: {
          el: '.swiper-pagination'//分页的dom节点
        },
        direction: "vertical",
        initialSlide: 0, // 默认选中
        loop: true, // 在此无效？？？？？
        centeredSlides: true, //活动块居中
        slideToClickedSlide: false, // 点击切换
        lazy: true,
        observer: true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents: true,//修改swiper的父元素时，自动初始化swiper
        on: {
          slideChange: () => {
            const c_swiper = document.getElementsByClassName("swiper");
            this.swiperOption.initialSlide = c_swiper[0].swiper.activeIndex - 1;
            // console.log("轮播图滚动事件 --->",c_swiper[0].swiper.activeIndex)
          }
        }
      },
    }
  },
  methods:{
    toggleSwiper(index) {
      // this.calendarMenuIndex = index;
      this.swiperOption.initialSlide = index;
      if (process.client) {
        this.mySwiper.slideTo(index + 1, 1000);
      }
    },

    closeDialog(){
      this.isShow = false;
      this.$store.commit('base/SET_INTRODUCE_DIALOG', false);
      fnLocalStorage.setItemDate(MODEL_INTRODUCE_DIALOG,1)
    },

    showLoginDialog(){
      this.closeDialog();
      fnLogin.listenPageStart(this.$store)
    }
  }
}
</script>

<style lang="scss" scoped>
.IntroduceDialog{
  .modal_content{
    width: 830px;
    height: 490px;
    background: #FFFFFF;
    border-radius: 100px 14px 14px 14px;
    display: flex;

    .swiper-wrap{
      width: 390px;
      height: 100%;
      //border-radius: 100px 14px 14px 14px;
      position: relative;

      .swiper{
        width: 100%;
        height: 100%;
        border-radius: 100px 14px 14px 14px;
      }

      .swiper-pagination1{
        z-index: 10;
        @include Position(1,3,-22px,24px);
        display: flex;
        flex-direction: column;

        .swiper-pagination-bullet{
          width: 6px;
          height: 6px;
          background: #cccccc;
          margin-bottom: 4px;
          transition: all 0.5s ease;
          cursor: pointer;

          &:last-child{
            margin-bottom: 0 !important;
          }
        }

        .swiper-pagination-bullet-active{
          width: 6px;
          height: 18px;
          background: #E0E3E9;
          border-radius: 4px;
        }
      }

      img{
        width: 100%;
        height: 100%;
      }
    }

    .intro{
      padding: 76px 0 0 80px;
      box-sizing: border-box;

      .intro-name{
        font-size: 16px;
        color: #424866;
        line-height: 21px;
        margin: 0 0 12px 12px;
      }

      .intro-list{
        .intro-item{
          width: 270px;
          height: 52px;
          //background: linear-gradient(90deg, #FBFBFD 0%, #F5F6FA 83%, #F5F6FA 100%);
          border-radius: 8px;
          display: flex;
          align-items: center;
          cursor: pointer;
          transition: all 0.5s ease;

          &.ON{
            background: linear-gradient(90deg, #FBFBFD 0%, #F5F6FA 83%, #F5F6FA 100%);
            .icon_cur{
              @include BgImage(17px,17px,'common/dialog/introduce-dialog/icon%40gz.png');
            }
          }


          .icon_cur{
            @include BgImage(17px,17px,'common/dialog/introduce-dialog/icon%40shou.png');
            transition: all 0.5s ease;
            margin: 0 12px;
          }

          .name{
            font-size: 16px;
            color: #2D2B33;
            letter-spacing: 1px;

            i{
              font-weight: bold;
              margin-left: 4px;
            }
          }
        }
      }

      .intro-go{
        margin: 40px 0 0 8px;
        @include BgImage(290px,59px,'common/dialog/introduce-dialog/go-btn.png');
        padding-left: 18px;
        box-sizing: border-box;
        line-height: 59px;
        font-size: 18px;
        font-weight: bold;
        color: #FFFFFF;
        letter-spacing: 2px;

        @include ElementHover('',''){
          @include BgImage(290px,59px,'common/dialog/introduce-dialog/go-btn@hover.png');
        }
      }
    }

    .welcome_cg{
      @include BgImage(217px,95px,'common/dialog/introduce-dialog/welcome_cg.png');
      @include Position(1,2,-48px,-62px);
    }

    .icon-cha{
      width: 32px;
      height: 32px;
      background: rgba(0,0,26,0.35);
      border-radius: 50%;
      font-size: 14px;
      color: #FFFFFF;
      @include LayoutFlex(1);
      @include Position(1, 2, -55px, 15px);
      @include Position(1,5,-13px,0);
      cursor: pointer;
      z-index: 9999;
      transition: all 0.5s ease-in-out;
      @include ElementHover('', '') {
        transform: rotate(180deg);
      }
    }
  }

}
</style>


<!--
  欢迎介绍弹框
-->
