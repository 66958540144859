<template>
  <!-- 潮流设计主题 start -->
  <div class="inner_center trend-design">
    <div class="trend-design-theme">
      <div class="headline">
        <div class="headline-t">每日更新
          <MaterialCount
            class="MaterialCount"/>
          <i class="icon_free-down"/>
        </div>
      </div>
      <div class="subheading">
        下载可用于网站、APP、印刷物料等多场景的商业作品，每日更新 <br>
        潮国创意是目前国内为设计师、创意机构和创作人员寻找高质量、吸睛创意设计资源的地方
      </div>
      <HomeSearch v-bind="$attrs"/>
    </div>
  </div>
  <!-- 潮流设计主题 end   -->
</template>

<script>
import MaterialCount from '@/components/tupian/material-count'
import HomeSearch from '@/components/home/home-search'

export default {
  name: "TrendDesign",
  components: {
    HomeSearch,
    MaterialCount
  },
}
</script>

<style lang="scss" scoped>
.trend-design {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;

  // 潮流设计主题
  .trend-design-theme {
    transition: all 0.5s ease-in;
    display: flex;
    flex-direction: column;
    align-items: center;

    .headline {
      //padding-top: 11px;

      .headline-t {
        font-size: 50px;
        font-weight: bold;
        color: #FBF7FF;
        line-height: 61px;
        letter-spacing: 4px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;

        .icon_free-down {
          @include BgImage(445px, 76.5px, 'home/trend-design/icon_free-down-new.gif?v=2');
          //margin-left: 7px;
          position: relative;
          left: -10px;
        }

        /deep/ .MaterialCount {
          /* font-size: 37px;
           font-weight: bold;
           color: rgba(251, 247, 255, 1);
           letter-spacing: 3px;*/
          width: 238px;
          font-weight: bold;
          margin-left: 28px;

          span {

          }
        }
      }
    }

    .subheading {
      text-align: center;
      font-size: 14px;
      color: #F4ECFF;
      line-height: 20px;
      margin-bottom: 40px;
    }

    .browse-operation {
      display: flex;

      .browse-operation-item {
        width: 216px;
        height: 68px;
        background: linear-gradient(224deg, #D093FF 1%, #6969FF 100%, #6969FF 100%);
        box-shadow: 0 0 0 4px rgba(251, 247, 255, 0.2);
        border-radius: 34px;
        font-size: 18px;
        color: #FBF7FF;
        line-height: 68px;
        text-align: center;
        letter-spacing: 1px;
        @include LayoutFlex(1);

        &:hover {
          box-shadow: 0 0 0 4px rgba(251, 247, 255, 0.35);
        }

        .icon_browse-works {
          @include BgImage(20px, 20px, 'home/images/icon_browse-works.svg');
          margin-right: 10px;
        }

        .icon_browse-timeline {
          @include BgImage(20px, 20px, 'home/images/icon_browse-timeline.svg');
          margin-right: 10px;
        }

      }

      .browse-works {
        position: relative;

        .recent-new {
          @include Position(1, 2, -18px, -13px);
          display: inline-block;
          //width: 97px;
          height: 26px;
          line-height: 26px;
          text-align: center;
          font-size: 12px;
          font-weight: bold;
          color: #EE5253;
          padding: 0 6px;
          background: linear-gradient(180deg, #FFEEA7 0%, #FFD96E 100%);
          border-radius: 12px 2px 12px 2px;
        }
      }

      .browse-timeline {
        background: #fff;
        color: $color-primary;
        margin-left: 28px;
      }
    }

    @include Screen(1) {
      .headline {
        //width: 490px;
        //font-size: 56px;
        //font-size: 48px;
        //font-weight: bold;
        //color: #FBF7FF;
        //line-height: 74px;
        //letter-spacing: 5px;
      }
      /*.subheading {
        max-width: 402px;
        font-size: 16px;
        color: #F4ECFF;
        line-height: 28px;
        letter-spacing: 1px;
        margin: 33px 0 43px;
      }*/
    }


  }

  // 时间轴
  .timeline-panel {
    @include Position(1, 2, 0, 0);
    width: 678px;
    height: 536px;
    background: linear-gradient(134deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
    box-shadow: 0 2px 40px -1px rgba(0, 17, 144, 0.19);
    border-radius: 40px 100px 40px 100px;

    .bubble-1 {
      width: 144px;
      height: 144px;
      background: linear-gradient(134deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.05) 100%);
      box-shadow: 0 2px 40px -1px rgba(0, 17, 144, 0.19);
      backdrop-filter: blur(50px);
      border-radius: 50%;
      @include Position(1, 2, -56px, -26px);
      z-index: 3;
    }

    .bubble-2 {
      width: 74px;
      height: 74px;
      background: linear-gradient(134deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.05) 100%);
      box-shadow: 0 2px 40px -1px rgba(0, 17, 144, 0.19);
      backdrop-filter: blur(50px);
      border-radius: 50%;
      @include Position(1, 4, 7px, 12px);
    }


    .calendar-box {
      width: 432px;
      height: 318px;
      background: rgba(255, 255, 255, 0.4);;
      box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.4);
      border-radius: 20px;
      overflow: hidden;
      @include Position(1, 5, 44px, 47px);
      z-index: 2;

      .swiper, .swiper-wrapper, .swiper-slide,
      .swiper-img {
        width: 100%;
        height: 100%;
        //border-radius: 20px;
      }
    }

    .calendar-menu-box {
      width: 200px;
      height: 282px;
      background: linear-gradient(224deg, #6969FF 0%, #FF906F 0%, #FF5E9F 100%);
      border-radius: 33px;
      box-sizing: border-box;
      @include Position(1, 2, 44px, 66px);
      z-index: 1;
      text-align: center;
      padding: 50px 0 0 42px;


      .menu-item {
        display: block;
        height: 32px;
        font-size: 18px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.7);
        line-height: 32px;
        letter-spacing: 1px;
        //margin-bottom: 4.5px;
        //margin-bottom: 6px;
        margin-bottom: 20px;
        cursor: pointer;

        span {
          display: inline-block;
          transition: All 0.4s ease-in-out;
        }

        &:hover {
          //span{
          //  transform:scale(1.333);
          //}
        }
      }

      .on {
        height: 32px;
        //font-size: 24px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 32px;
        letter-spacing: 1px;

        span {
          transform: scale(1.333);
        }

        //

      }
    }

  }

  .card-panel {
    //margin-top: 10px;
    @include Position(1, 2, 0, 0);
    z-index: 3;
    width: 632px;

    .card-item-panel {
      transition: all 0.5s ease;

      @mixin cardItemPanel(
        $panelBg: transparent,
        $panelBgHover: transparent,
        $titleColor: transparent,
        $lineColor: transparent,
        $panelDescColor: transparent,
        $iconCircleColor: transparent,
        $panelCaseColor: transparent,
        $vBg:'1'
      ) {
        width: 326px;
        height: 190px;
        background: $panelBg;
        border-radius: 20px;
        padding: 37px 0 0 32px;
        box-sizing: border-box;
        position: relative;
        @include ElementHover('', $panelBgHover);

        .panel-title {
          font-size: 24px;
          font-weight: bold;
          line-height: 28px;
          letter-spacing: 2px;
          color: $titleColor;

        }
        .panel-price {
          font-size: 0;
          color: $titleColor;
          margin: 10px 0 27px;

          .icon_dollar {
            font-size: 12px;
            font-weight: bold;
            line-height: 16px;
          }

          .text-price {
            font-size: 24px;
            font-weight: bold;
            line-height: 31px;
            margin: 0 2px;
          }

          .text-qi {
            font-size: 12px;
            line-height: 16px;
          }
        }

        .panel-line {
          width: 262px;
          height: 1px;
          background: $lineColor;
        }

        .panel-desc {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 262px;
          box-sizing: border-box;
          padding: 0 4px;
          margin-top: 14px;

          .panel-desc-item {
            display: flex;
            align-items: center;

            .icon-circle {
              display: inline-block;
              width: 4px;
              height: 4px;
              background: $iconCircleColor;
              border-radius: 50%;
              margin-right: 8px;
            }

            font-size: 14px;
            color: $panelDescColor;
            line-height: 19px;
            letter-spacing: 2px;
          }
        }

        .panel-case {
          width: 128px;
          height: 40px;
          background: #FFFFFF;
          border-radius: 24px;
          @include Position(1, 2, 32px, 52px);
          font-size: 14px;
          font-weight: bold;
          color: $panelCaseColor;
          line-height: 40px;
          text-align: center;
          letter-spacing: 1px;
          cursor: pointer;
        }
        .v-bg {
          @include BgImage(326px, 190px, 'home/trend-design/#{$vBg}.png');
          @include Position(1, 2, 0, 0);
        }
      }

      &.business {
        @include cardItemPanel(
          //linear-gradient(224deg, #6969FF 0%, #FF906F 0%, #FF5E9F 100%),
            linear-gradient(224deg, #FF5E9F 0%, #FF906F 100%),
            linear-gradient(224deg, #FF71AA 0%, #FF9C7F 100%),
            #FFFFFF,
            rgba(255, 255, 255, 0.8000),
            rgba(255, 255, 255, 0.8),
            rgba(255, 255, 255, 0.8),
            #FF6C75,
            'cardItemPanel@buiness'
        );
        @include Position(1, 2, 306px, 15px);
        z-index: 3;

        .new-year-banner {
          @include BgImage(344px, 60px, 'act/new-year/new-year%40banner.png');
          position: absolute;
          left: 0;
          top: -38px;
          z-index: 10;
        }
      }

      &.company {
        @include cardItemPanel(
            linear-gradient(224deg, #FDBA94 0%, #FFE8D8 100%),
            linear-gradient(224deg, #FFC09D 0%, #FFEEE2 100%),
            #B34D29,
            #CE7C5F,
            #CE7C5F,
            #CE7C5F,
            #B16547,
            'cardItemPanel@company'
        );
        @include Position(1, 2, 0, 49px);
        z-index: 2;

      }
    }

    .timeline-box {
      /*width: 586px;
      height: 97px;
      background: linear-gradient(134deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.2) 100%);
      border-radius: 49px;*/
      //backdrop-filter: blur(10px);
      //transform: translateX(-50%);
      padding: 0 70px 0 3px;
      box-sizing: border-box;
      @include LayoutFlex(3);
      //@include Position(1, 4, 50%, 38px);
      @include Position(1, 2, 0, 272px);
      transition: all 0.5s ease-in-out;
      opacity: 0;

      width: 248px;
      height: 64px;
      background: linear-gradient(134deg, rgba(255, 255, 255, 0.3000) 0%, rgba(255, 255, 255, 0.2000) 100%);
      border-radius: 32px;
      //border: 2px solid;
      backdrop-filter: blur(10px);
      cursor: pointer;


      &.on {
        opacity: 1;
      }

      .timeline-date {
        /*    width: 306px;
            height: 85px;
            background: linear-gradient(182deg, #6969FF 0%, #6969FF 0%, #D093FF 100%);
            border-radius: 43px;*/
        text-align: center;
        flex-shrink: 0;
        width: 124px;
        height: 58px;
        background: linear-gradient(134deg, #6969FF 0%, #B669FF 100%);
        border-radius: 32px;
        padding-top: 6px;
        box-sizing: border-box;
        margin-right: 16px;

        .text-month {
          font-size: 32px;
          font-weight: bold;
          color: #F5EBFF;
          line-height: 42px;
        }

        .text-day {
          font-size: 15px;
          font-weight: bold;
          color: #EEEEFE;
          line-height: 20px;
          letter-spacing: 1px;
        }

      }

      .timeline-time {
        .text-week {
          font-size: 15px;
          font-weight: bold;
          color: rgba(251, 247, 255, 0.7500);
          line-height: 20px;
          letter-spacing: 1px;
          margin-bottom: 4px;

        }

        .text-time {
          font-size: 16px;
          font-weight: bold;
          color: rgba(251, 247, 255, 0.7500);
          line-height: 21px;
          letter-spacing: 1px;
        }

      }

    }


  }


  @include Screen(1) {
    .trend-design-theme {
      .subheading {
        margin: 13px 0 27px;
      }
    }
  }
}
</style>
