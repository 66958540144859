import * as CON_COMMON from '@/constant/_constant';
import {getCookie} from "~/utils/auth";
import {
  PAY_COMPANY,
  PAY_COMPANY_UPGRADE,
  PAY_PAY_BUSINESS_UPGRADE,
  PAY_PAY_COMPANY_UPGRADE,
  PAY_PERSON_UPGRADE, PAY_UPGRADE
} from "@/constant/_constant/CON_BASIC";
import {VIP_MANAGE} from "@/constant/VIP_MANAGE";
import {LOGIN_GUIDE_STATE} from "@/constant/LOGIN_GUIDE";

import {PAY_PERSON} from "@/constant/_Public/Path"


const dayjs = require('dayjs');
import {COMMON} from '@/constant/COMMON'
import {VIP_STATE} from "@/constant/VIP";

class FnUser {

  constructor() {
  }


  /**
   * 获取用户等级最高VIP ( VIP数据隔离情况 )
   * */
  getUserMaxGradeVip() {
    let personVipState = getCookie('personVipState');
    let businessVipState = getCookie('businessVipState');
    let companyVipState = getCookie('companyVipState');

    let {COMPANY, BUSINESS, PERSON, DEFAULT} = CON_COMMON.CON_PAY.VIP_GRADE

    if (companyVipState == 1) {
      return COMPANY;
    }

    if (businessVipState == 1) {
      return BUSINESS;
    }

    if (personVipState == 1) {
      return PERSON;
    }

    return DEFAULT;
  }

  /**
   * 获取用户vip可升级链接
   * */
  getUserVipUpgradeLink() {
    let personVipState = getCookie('personVipState');
    let businessVipState = getCookie('businessVipState');
    let companyVipState = getCookie('companyVipState');


    if (companyVipState == 1) {
      return CON_COMMON.CON_BASIC.PAY_COMPANY_UPGRADE
    }

    /* 非会员 - 默认跳转到商用年 */
    if (personVipState != 1 && businessVipState != 1) {
      return CON_COMMON.CON_BASIC.PAY_PERSON
    }

    /* 仅个人 */
    if (personVipState == 1 && businessVipState != 1) {
      return CON_COMMON.CON_BASIC.PAY_PERSON_UPGRADE
    }

    /* 仅商用 */
    if (personVipState != 1 && businessVipState == 1) {
      return CON_COMMON.CON_BASIC.PAY_PAY_COMPANY_UPGRADE
    }

    /* 个人商用 */
    if (personVipState == 1 && businessVipState == 1) {
      return CON_COMMON.CON_BASIC.PAY_PAY_COMPANY_UPGRADE
    }


    // 非会员
    return CON_COMMON.CON_PAY.VIP_GRADE.DEFAULT;

  }

  /**
   * 根据用户和素材等级获取用户需要升级对应的链接
   * @param picLayer  图片等级
   */
  getUserVipUpgradeLinkByPicLayer(picLayer) {
    let personVipState = getCookie('personVipState');
    let personVipInfo = JSON.parse(getCookie('personVipInfo'));
    let businessVipState = getCookie('businessVipState');
    let businessVipInfo = JSON.parse(getCookie('businessVipInfo'));
    let companyVipState = getCookie('companyVipState');

    let {remaining_days, vip, module, end_time} = personVipInfo

    let person_remaining_days = personVipInfo.remaining_days
    let business_remaining_days = businessVipInfo.remaining_days


    picLayer = parseInt(picLayer)

    if (picLayer === 4) {
      /* 非会员 - 默认跳转企业 */
      if (personVipState != 1 && businessVipState != 1) {
        return CON_COMMON.CON_BASIC.PAY_COMPANY
      }
      /* 仅个人 */
      else if (personVipState == 1 && businessVipState != 1) {
        if (person_remaining_days <= 365) {
          return CON_COMMON.CON_BASIC.PAY_UPGRADE
        } else {
          return CON_COMMON.CON_BASIC.PAY_COMPANY
        }
      }
      /* 仅商用 */
      else if (personVipState != 1 && businessVipState == 1) {
        if (business_remaining_days <= 365) {
          return CON_COMMON.CON_BASIC.PAY_UPGRADE
        } else {
          return CON_COMMON.CON_BASIC.PAY_COMPANY
        }
      }

      /* 个人商用 */
      else if (personVipState == 1 && businessVipState == 1) {

        if (person_remaining_days <= 365 || business_remaining_days <= 365) {
          return CON_COMMON.CON_BASIC.PAY_UPGRADE
        } else {
          return CON_COMMON.CON_BASIC.PAY_COMPANY
        }

      } else {
        return CON_COMMON.CON_BASIC.PAY_COMPANY
      }

    } else if (picLayer == 3 || picLayer == 2) {
      /* 非会员 - 默认跳转企业 */
      if (personVipState != 1 && businessVipState != 1) {
        return PAY_PERSON
      }

      /* 仅个人 */
      else if (personVipState == 1 && businessVipState != 1) {
        // return CON_COMMON.CON_BASIC.PAY_PERSON_UPGRADE
        if (person_remaining_days <= 365) {
          return CON_COMMON.CON_BASIC.PAY_UPGRADE
        } else {
          return PAY_PERSON
        }
      } else if (personVipState != 1 && businessVipState != 1) {
        // return CON_COMMON.CON_BASIC.PAY_PERSON_UPGRADE
        if (business_remaining_days <= 365) {
          return CON_COMMON.CON_BASIC.PAY_UPGRADE
        } else {
          return PAY_PERSON
        }
      }

      /*  */
      else if (personVipState == 1 && businessVipState == 1) {
        // return CON_COMMON.CON_BASIC.PAY_UPGRADE + '/1-' + VIP_MANAGE.COMPANY.All_PURPOSE_NEW.ID
        if (person_remaining_days <= 365 || business_remaining_days <= 365) {
          return CON_COMMON.CON_BASIC.PAY_UPGRADE
        } else {
          return PAY_PERSON
        }

        /* 检查普通终身是否过期，是否是普通/商用体验VIP */

      } else {
        return PAY_PERSON
      }


    } else if (picLayer == 1) {
      return PAY_PERSON
    } else {
      return PAY_PERSON
    }


    function checkIsLarge365() {

    }


    function setPersonLink() {
      if (person_remaining_days <= 365) {
        return CON_COMMON.CON_BASIC.PAY_UPGRADE
      } else {
        return PAY_PERSON
      }
    }

  }

  /* 检查是否是会员 新版 */
  checkUserIsVip() {
    let personVipState = getCookie('personVipState')
    let businessVipState = getCookie('businessVipState')
    let companyVipState = getCookie('companyVipState')
    return personVipState === VIP_STATE.ON || businessVipState === VIP_STATE.ON || companyVipState === VIP_STATE.ON;
  }


  /* 检查企业身份下，企业vip过期或张数使用完毕拦截弹框 */
  checkCompanyVipExpiredModal() {
    let personVipState = getCookie('personVipState');
    let businessVipState = getCookie('businessVipState');
    let companyVipState = getCookie('companyVipState');
    // let companyInfoByPerson = getCookie('companyInfoByPerson');
    let {$store} = $nuxt;

    let companyInfoByPerson = $store.state.user.companyInfoByPerson;
    let bool = false;


    if ((companyInfoByPerson.switch == null ||
      (companyInfoByPerson.switch && companyInfoByPerson.switch.type == COMMON.HEADER.AVATAR_COMBOBOX.ACCOUNT_TYPE.PERSON))
      || companyInfoByPerson.is_company_member === 0) {
    }
    {
      bool = false
    }

    if ((companyInfoByPerson.switch && companyInfoByPerson.switch.type == COMMON.HEADER.AVATAR_COMBOBOX.ACCOUNT_TYPE.COMPANY)) {
      bool = true
    }


    return bool;
  }
}

export default new FnUser();
