<template>
  <!-- 版权下拉框 -->
  <div
    v-if="isShowCombobox"
    class="copyright-combobox">
    <div class="top">
      <div
        class="box"
        @click="login">
        <i class="iconfont icon-shangline"/>
        <span>商用授权随心使用</span>
      </div>
      <div
        class="box box1"
        @click="login">
        <i class="iconfont icon-download"/>
        <span>全站内容不限下载</span>
      </div>
    </div>
    <div
      class="login-btn _pointer"
      @click="login">
      立即登录
    </div>
  </div>
</template>

<script>
export default {
  name: "CopyrightCombobox",
  data() {
    return {
      isShowCombobox: false
    }
  },
  methods: {
    login() {
      let { path } = this.$route;
      if(path == '/' || path == ''){
        // this.$store.commit("user/SET_IS_SHOW_LOGIN_HOME", true);
        this.$store.commit("user/SET_IS_SHOW_LOGIN", true);
      }else{
        this.$store.commit("user/SET_IS_SHOW_LOGIN", true);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes fadeinT{
  0%{opacity:0;transform:translateY(-20px);}
  100%{opacity:1;transform:translateY(0);}
}

.copyright-combobox {
  width: 348px;
  height: 208px;
  background: #FFFFFF;
  box-shadow: 0 0 8px 0 rgba(0, 0, 26, 0.08);
  border-radius: 12px;
  padding: 24px 28px 24px 30px;
  box-sizing: border-box;
  z-index: 10;
  animation: fadeinT 0.5s 1;

  .top {
    @include LayoutFlex(3);

    .box {
      width: 136px;
      height: 94px;
      background: rgba(255, 206, 107, 0.1);
      border-radius: 12px;
      @include LayoutFlex(2);
      color: #2D2B33;
      transition: all 0.3s ease-in-out;

      @include ElementHover('',rgba(255, 206, 107,0.25));

      .iconfont {
        font-size: 26px;
      }

      span {
        font-size: 13px;
        color: #2D2B33;
        line-height: 20px;
        margin-top: 9px;
      }
    }

    .box1 {
      background: rgba(53, 208, 186, 0.1);
      @include ElementHover('',rgba(53, 208, 186,0.25));
    }

  }

  .login-btn {
    width: 100%;
    height: 48px;
    line-height: 48px;
    text-align: center;
    background: rgba(105, 105, 255, 0.05);
    border-radius: 12px;
    font-size: 14px;
    color: $color-primary;
    letter-spacing: 1px;
    margin-top: 18px;
    transition: all 0.3s ease-in-out;
    @include ElementHover('',$color-primary){
      color: #FFFFFF;
    };

  }
}

</style>
