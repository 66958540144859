<template>
  <div class="DesignerPlatformAside">

    <a-menu mode="inline">

      <template v-for="item in ASIDE_MENU">
        <a-menu-item v-if="!item.children">{{ item.label }}</a-menu-item>

        <a-sub-menu
          v-else
          :key="item.id"
          :title="item.label">
          <a-menu-item
            v-for="subItem in item.children"
            :key="subItem.id"
          >
            {{ subItem.label }}
          </a-menu-item>
        </a-sub-menu>
      </template>


    </a-menu>


    <!--    <div class="aside-list">
          <div
            v-for="vo in ASIDE_MENU"
            :key="vo.id"
            class="aside-item">
            {{ vo.label }}
          </div>
        </div>-->

    <!--    <a-menu-->
    <!--      :inlineIndent="inlineIndent"-->
    <!--      :defaultSelectedKeys="[$route.path]"-->
    <!--      :openKeys="openKeys"-->
    <!--      mode="inline"-->
    <!--      :inline-collapsed="collapsed"-->
    <!--      @openChange="onOpenChange"-->
    <!--      @click="menuClick">-->
    <!--      &lt;!&ndash; 菜单遍历的开始 &ndash;&gt;-->
    <!--      <template v-for="item in list">-->
    <!--        &lt;!&ndash; 如果当前遍历项没有children，视为子菜单项，注意所有的key都是path用于路由跳转，以及当前选中记录 &ndash;&gt;-->
    <!--        <a-menu-item v-if="!item.children" :key="item.path">-->
    <!--          <i :class="item.icon" />-->
    <!--          <span>{{ item.title }}</span>-->
    <!--        </a-menu-item>-->
    <!--        &lt;!&ndash; 否则视为子菜单，传入菜单信息并且运用下面定义的函数式组件 &ndash;&gt;-->
    <!--&lt;!&ndash;        <sub-menu v-else :key="item.path" :menu-info="item" />&ndash;&gt;-->

    <!--        <a-sub-menu :key="item.path" v-bind="$props" v-on="$listeners">-->
    <!--        <span slot="title">-->
    <!--          <i :class="item.icon" /><span>{{ item.title }}</span>-->
    <!--        </span>-->
    <!--          <template v-for="item in item.children">-->
    <!--            <a-menu-item v-if="!item.children" :key="item.path">-->
    <!--              <i :class="item.icon" />-->
    <!--              <span>{{ item.title }}</span>-->
    <!--            </a-menu-item>-->
    <!--            <sub-menu v-else :key="item.path" :menu-info="item" />-->
    <!--          </template>-->
    <!--        </a-sub-menu>-->
    <!--      </template>-->
    <!--    </a-menu>-->
  </div>
</template>

<script>

import {ASIDE_MENU} from "./common/module";

export default {
  name: "DesignerPlatformAside",
  data() {
    return {
      ASIDE_MENU
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../common/layout";
</style>

