import request from '@/utils/request'
import qs from 'qs'

/* 全局访问记录，任一页面均记录 */
export function visitorLogs(query) {
  return request({
    url: '/?m=Api&a=stat',
    method: 'get',
    params: query
  })
}