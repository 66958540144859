import { render, staticRenderFns } from "./HeaderCompanyCase.vue?vue&type=template&id=2007f932&scoped=true&"
import script from "./HeaderCompanyCase.vue?vue&type=script&lang=js&"
export * from "./HeaderCompanyCase.vue?vue&type=script&lang=js&"
import style0 from "./HeaderCompanyCase.vue?vue&type=style&index=0&id=2007f932&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2007f932",
  null
  
)

export default component.exports