<template>
  <div
    class="PicItem PicItemRecommend"
    @click="picItemTopicClick(picItemInfo), picItemClick(picItemInfo)"
    @mouseenter="topicMouseover(picItemInfo)"
    @mouseleave="topicMouseleave(picItemInfo)"
  >

    <!-- 外层阴影   -->
    <no-ssr>
      <div class="shadow-box"></div>

      <template v-if="picItemInfo.pictures.length">
        <div class="mask1 ">
          <i
            :style="{backgroundImage:'url('+ IMG_BASE_URL + picItemInfo.pictures[0].proto_pic + '!/fw/10/quality/90/unsharp/true/compress/true' +') '}"
            class="cover"></i>
        </div>
        <div class="mask2 ">
          <i
            :style="{backgroundImage:'url('+ IMG_BASE_URL + picItemInfo.pictures[0].proto_pic + '!/fw/10/quality/90/unsharp/true/compress/true' +') '}"
            class="cover"></i>
        </div>
      </template>

    </no-ssr>

    <div class="pic-cover">
      <a
        class="pic-img-wrap"
        :title="picItemInfo.title"
        :href="picItemInfo.link_url"
        target="_blank"
      >
        <!--        <img
                  v-lazy=" PAGE_PATH.BASE_IMG_URL + picItemInfo.pictures[0].proto_pic + '!/fw/452/quality/90/unsharp/true/compress/true'"
                  :alt="picItemInfo.title"
                  class="pic-img"
                  @contextmenu.prevent=""
                >-->
        <PicImgBox :picItem="picItemInfo"/>

        <div class="img-mask" v-if="isShowImgMask"></div>

        <TupianMask :link="picItemInfo.link_url"
                    :title="picItemInfo.title"
        ></TupianMask>

      </a>
    </div>

    <div class="topic-info">
      <div class="topic-title">
        <a class="title overflow-one"
           :href="picItemInfo.link_url"
           :title="picItemInfo.title"
           target="_blank"
        >{{ picItemInfo.title }}</a>
        <div class="icon_view" @mouseover="isShowImgMask = true" @mouseleave="isShowImgMask = false">
          <div class="topic-pictures-box " :class="{ 'right': idx% 4 == 0 }">
            <a
              v-for="vo in picItemInfo.pictures"
              :key="vo.id"
              class="topic-pictures"
              :href=" TUPIAN_DETAIL + '/' + vo.uniq_id + '.html'"
              :title="vo.title"
              target="_blank"
            >

              <PicImgBox :picItem="vo"/>
<!--              <img-->
<!--                v-if="spider"-->
<!--                :src=" PAGE_PATH.BASE_IMG_URL + vo.proto_pic + '!/fw/176/quality/90/unsharp/true/compress/true'"-->
<!--                :alt="vo.title"-->
<!--                class="swiper-img">-->

<!--              <img-->
<!--                v-else-->
<!--                v-lazy=" PAGE_PATH.BASE_IMG_URL + vo.proto_pic + '!/fw/176/quality/90/unsharp/true/compress/true'"-->
<!--                :alt="vo.title"-->
<!--                class="swiper-img">-->

              <TupianMask :link="picItemInfo.link_url"
                          :title="picItemInfo.title"
              ></TupianMask>
            </a>
          </div>
        </div>
      </div>
      <no-ssr>
        <div class="topic-about">
          {{ picItemInfo.picture_total }}张 ·
          <span class="subscribe" v-if="isShowSubscribe">{{ picItemInfo.subscribe || '0' }}人订阅 ·</span>
          {{ picItemInfo.range_update_days | disposeDay }}更新
        </div>
      </no-ssr>
    </div>
    <div>
      <no-ssr>

        <template v-if="!picItemInfo.isCollect">
          <div
            class="subscription subscription-topic"
            @click.stop.prevent="toggleCollect(picItemInfo)"
          >
            <i class="iconfont icon-sub"/>
            <span class="txt-subscription">订阅</span>
          </div>
        </template>

        <template v-else>
          <div
            class="subscription no-subscription-topic"
            @mouseover.stop="subscriptionStatusTxt = '取消订阅' ;isShowIconSub = false"
            @mouseleave.stop="subscriptionStatusTxt = '已订阅';isShowIconSub = true"
            @click.stop.prevent="toggleCollect(picItemInfo)"
          >
            <i class="iconfont icon-sub" v-if="isShowIconSub"/>
            <span class="txt-subscription">{{ subscriptionStatusTxt }}</span>
          </div>
        </template>
      </no-ssr>

    </div>

    <div class="_PicTagCollectNew"></div>
    <div class="_PicTagLegalCopy"></div>
  </div>
</template>

<script>
import picItemExtend from "@/components/pic/pic-item/common/pic-item-extend";
import SubscriptionBtn from '@/components/topic/subscription-btn';
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import {fnLogin, fnRouter, fnTopic} from "@/fn";
import {toggleTopicCollect} from "@/api/topic";
import {completeTaskByFanClub} from "@/api/fans/club";
import {TUPIAN_DETAIL} from "@/constant/_constant/CON_BASIC";
import {mapState} from "vuex";
import PicImgBox from '@/components/_Privately/Pic/PicImgBox'


export default {
  name: "PicItemSpecial",
  mixins: [picItemExtend],
  components: {
    SubscriptionBtn,
    PicImgBox
  },
  props: {
    idx: {
      type: String | Number,
      default: 0
    },
    isShowSubscribe: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    BASE_IMG_Link() {
      return PAGE_PATH.BASE_IMG_URL
    },
    TUPIAN_DETAIL_Link() {
      return fnRouter.getRouterLink(PAGE_PATH.TU_PIAN.DETAIL);
    },
    ...mapState('base', ['spider']),
  },
  watch: {
    /*picItemInfo: {
      handler(n) {
        if (n) {
          if (n.swiperOption) {
            /!* n.swiperOption = {
               direction: 'vertical',
               slidesPerView: 2.5,
               spaceBetween: 6,
               observer: true, // 改变swiper样式时，自动初始化swiper
               observeParents: true, // 监测swiper父元素，如果有变化则初始化swiper
               initialSlide: 1, // 默认选中
             }*!/
          } else {
            n.swiperOption = {
              direction: 'vertical',
              slidesPerView: 2.5,
              spaceBetween: 6,
              observer: true, // 改变swiper样式时，自动初始化swiper
              observeParents: true, // 监测swiper父元素，如果有变化则初始化swiper
              initialSlide: 0, // 默认选中
              on: {
                /!*slideChange: () => {
                  console.log(111111111)
                  const c_swiper = document.getElementsByClassName("swiper");
                  this.swiperOption.initialSlide = c_swiper[0].swiper.activeIndex -1;
                  // this.swiperOption.initialSlide = c_swiper[0].swiper.activeIndex - 1;
                  // console.log("轮播图滚动事件 --->",c_swiper[0].swiper.activeIndex)
                  if (c_swiper[0].swiper.activeIndex - 1 >  n.pictures.length-1){
                    n.swiperOption.initialSlide = 0
                  }
                  if (c_swiper[0].swiper.activeIndex - 1 <  0){
                    n.initialSlide = n.pictures.length-1
                  }
                }*!/
              }
            }
          }

        }

      },
      immediate: true,
      deep: true
    }*/
  },
  data() {
    return {
      subscriptionStatusTxt: '已订阅',
      isShowImgMask: false,
      isShowIconSub: true,
      TUPIAN_DETAIL
    }
  },
  methods: {
    navTo(picItemInfo) {
      fnRouter.navigationTo(picItemInfo.link_url)
    },
    toggleCollect(vo) {
      if (fnLogin.checkLogin()) {
        let isCollect = vo.isCollect || false
        toggleTopicCollect({
          id: vo.topic_id || vo.id,
          mode: isCollect === false ? 'add' : 'rem'
        }).then(res => {
          let {status} = res.data;
          console.log(status)

          if (status == 200) {
            let msg = isCollect === false ? '订阅成功' : '已取消订阅';
            /* 订阅人数修改 */
            let tempSubscribe = parseInt(vo.subscribe);
            tempSubscribe = isCollect === false ? (tempSubscribe += 1) : (tempSubscribe -= 1)
            this.$set(vo, 'isCollect', !vo.isCollect)
            this.$set(vo, 'subscribe', tempSubscribe)
            this.$myNotify.success(msg);


            /* 检查是否是领取任务 */
            setTimeout(() => {
              let {t_id, task_type} = this.$route.query
              if (task_type == 1) {
                completeTaskByFanClub({id: t_id}).then(res => {
                  let {status, data, message} = res.data;
                  if (status == 200) {
                    this.$myNotify.success("任务已完成")
                    this.$router.push({
                      query: {}
                    })
                  } else {
                    this.$myNotify.error(message)

                  }
                })
              }
            }, 1000)


          } else if (status == 405) {
            this.$myNotify.success('已订阅');
            this.$set(vo, 'isCollect', true)
          }


        })
      } else {
        fnLogin.loginIntercept('请先登录');
      }
    },

    picItemTopicClick(picItemInfo) {
      let id = picItemInfo.topic_id || picItemInfo.id
      this.$emit('picItemTopicClick', id);
    },
    topicMouseover(picItemInfo) {
      let id = picItemInfo.topic_id || picItemInfo.id
      this.$emit('topicMouseover', id);
    },
    topicMouseleave(picItemInfo) {
      fnTopic.closePositionStatMouseLeave();
    }
  },
  filters: {
    disposeDay(day) {
      if (day > 30) {
        return '一个月前'
      } else if (day > 8) {
        return '1个月内'
      } else if (day > 2) {
        return '1周内'
      } else {
        return '昨天'
      }

    }
  }
}
</script>

<style lang="scss" scoped>

.PicItemRecommend {
  width: 336px;
  height: 440px;
  border-radius: 14px;
  margin: 0 32px 40px 0;
  position: relative;
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  background: transparent;

  &:nth-child(4n) {
    margin-right: 0;
  }

  .shadow-box {
    content: '';
    display: inline-block;
    width: 100%;
    height: 399px;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px 0px rgba(213, 221, 236, 0.4);
    border-radius: 14px;
    @include Position(1, 4, 0, 0);
    z-index: 1;
  }


  &:hover {
    .pic-img {
      transform: scale(1) !important;
    }

    /deep/ ._prototype_common {
      img {
        transform: scale(1.1);
      }
    }

  }

  @include ElementHover('', '') {
    box-shadow: none !important;
  }

  .pic-shadow {
    width: 336px;
    height: 399px;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px 0px rgba(213, 221, 236, 0.4);
    border-radius: 14px;
    @include Position(1, 4, 0, 0);
    z-index: 1;
  }

  .mask1 {
    width: 336px;
    height: 302px;
    background-size: cover;
    @include Position(1, 5, 0, 41px);
    z-index: 2;
    border-radius: 14px;
    overflow: hidden;

    .cover {
      display: inline-block;
      width: 100%;
      height: 100%;
      overflow: hidden;
      //backdrop-filter: blur(20px);
      filter: blur(20px);
      border-radius: 14px 14px 0px 0px;
      background-size: 890px 302px;
      width: 890px;
      height: 302px;
      background-position: center top;
      @include Position(1, 5, 50%, 0);
      transform: translateX(-50%);
      transform: translate3d(0, 0, 0) translateX(-50%);
    }

    &:after {
      content: '';
      display: inline-block;
      width: 100%;
      height: 100%;
      @include Position(1, 5, 0, 0);
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, #FFFFFF 100%);
    }
  }

  .mask2 {
    width: 308px;
    height: 320px;
    background-size: 100% 302px;
    @include Position(1, 5, 50%, 20px);
    transform: translateX(-50%);
    z-index: 3;
    border-radius: 14px;
    overflow: hidden;

    .cover {
      display: inline-block;
      //width: 336px;
      //height: 302px;
      overflow: hidden;
      //backdrop-filter: blur(100px);
      filter: blur(20px);
      border-radius: 14px 14px 0px 0px;
      width: 890px;
      height: 302px;
      @include Position(1, 5, 50%, 0);
      transform: translateX(-50%);
      transform: translate3d(0, 0, 0) translateX(-50%);
    }

    &:after {
      content: '';
      display: inline-block;
      width: 100%;
      height: 100%;
      @include Position(1, 5, 0, 0);
      background: rgba(255, 255, 255, 0.65);
    }
  }


  .pic-cover {
    @include Position(1, 5, 50%, 0);
    transform: translateX(-50%);
    z-index: 5;
    width: 278px;
    height: 360px;
    border-radius: 14px;
    overflow: hidden;

    @include ElementHover('', '') {
      .pic-img {
        transform: scale(1.1) !important;
      }
    }

    .pic-img-wrap {
      display: inline-block;
      width: 100%;
      height: 100%;
      border-radius: 14px;


      .pic-img {
        display: inline-block;
        width: 100%;
        height: 100%;
        transition: all 0.5s ease-in-out;
        transform: translate3d(0, 0, 0);

      }

      .img-mask {
        background: rgba(0, 0, 26, 0.30);
        @include Position(1, 5, 0, 0);
        width: 100%;
        height: 100%;
      }

      .pic-link {
        display: inline-block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }


    }
  }

  .topic-info {
    width: 100%;
    width: 336px;
    height: 80px;
    @include Position(1, 4, 0, 0);
    z-index: 10;
    box-sizing: border-box;
    padding: 18px 18px 0;

    .topic-title {
      margin-bottom: 4px;
      display: flex;
      align-items: center;

      .title {
        max-width: 152px;
        font-size: 18px;
        font-weight: bold;
        color: #0A2540;
        line-height: 24px;
        letter-spacing: 1px;
      }

      .icon_view {
        @include BgImage(20px, 20px, 'topic/index/icon_yl2.svg');
        margin-left: 8px;
        position: relative;
        width: 20px;
        height: 20px;
        background-color: #EDF2F5;
        border-radius: 4px;


        &:after {
          content: '';
          height: 8px;
          background: transparent;
          width: 80px;
          @include Position(1, 5, 0, -3px);
          display: none;

        }

        &:hover {
          background-color: #E0E3E9;

          &:after {
            display: block;
          }

          .topic-pictures-box {
            display: block;
          }
        }

        .topic-pictures-box {
          width: 384px;
          //height: 264px;
          background: #FFFFFF;
          box-shadow: 0px 2px 8px 0px rgba(10, 37, 64, 0.1);
          border-radius: 10px;
          @include Position(1, 5, -364px, -262px);
          z-index: 11;
          padding: 10px 0px 6px 10px;
          box-sizing: border-box;
          display: none;

          .topic-pictures {
            display: inline-block;
            width: 88px;
            height: 114px;
            border-radius: 6px;
            overflow: hidden;
            margin: 0 4px 4px 0;
            flex-shrink: 0;

            &:hover {
              img {
                transform: scale(1.1);
              }
            }

            &:nth-child(4n) {
              margin-right: 0;
            }

            img {
              display: inline-block;
              width: 100%;
              height: 100%;
              transition: all 0.5s ease-in-out;
            }
          }

          &.right {
            //@include Position(1,3,-4px, -262px);
            @include Position(1, 5, -4px, -262px);
          }

        }
      }
    }

    .topic-about {
      font-size: 12px;
      color: rgba(10, 37, 64, 0.6);
      line-height: 16px;
    }
  }

  .subscription {
    width: 84px;
    height: 32px;
    border-radius: 24px;
    position: relative;
    z-index: 15;
    @include Position(1, 3, 18px, 27px);
    display: flex;
    align-items: center;
    justify-content: center;


    .txt-subscription {
      font-size: 14px;
      color: #FFFFFF;
    }


    &.subscription-topic {
      background: linear-gradient(44deg, #FE7E7E 0%, #F94D67 100%);
      @include ElementHover('', linear-gradient(44deg, #F94D67 0%, #FE7E7E 100%));

      .icon-sub {
        display: inline-block;
        font-size: 14px;
        color: #FFFFFF;
        margin-right: 6px;
      }

      .txt-subscription {
        font-size: 12px;
        color: #FFFFFF;
        line-height: 16px;
      }

    }

    &.no-subscription-topic {
      background: rgba(245, 246, 250, 1);
      //border: 1px solid #ECEFF6;

      @include ElementHover('', rgba(235, 237, 245, 1));

      &:after {
        background: rgba(66, 72, 102, 0.15);
      }

      .txt-subscription {
        color: #7A7E93;
        font-size: 12px;
      }

      .icon-sub {
        display: inline-block;
        font-size: 14px;
        color: #FFFFFF;
        margin-right: 6px;
        color: rgba(249, 77, 103, 1);
      }
    }
  }


  @include Screen(1) {
    width: 279px;
    height: 364px;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px 0px rgba(213, 221, 236, 0.4);
    border-radius: 14px;
    margin: 0 28px 36px 0;


    .shadow-box {
      height: 332px;

    }
    .mask1 {
      width: 279px;
      height: 251px;
    }
    .mask2 {
      width: 255px;
      height: 265px;
    }
    .pic-cover {
      width: 230px;
      height: 296px;
      border-radius: 14px;
    }

    .topic-info {
      height: 69px;
      padding: 13px 16px 0;

      .topic-title {
        .title {
          max-width: 133px;
          font-size: 16px;
          font-weight: bold;
          color: #0A2540;
          line-height: 21px;
          letter-spacing: 1px;
        }
      }

      .icon_view {
        @include BgImage(13px, 13px, 'topic/index/icon_yl2.svg');
      }
    }

    .subscription {
      width: 80px;
      height: 32px;
      border-radius: 24px;
      position: relative;
      z-index: 15;
      @include Position(1, 3, 16px, 24px);
      display: flex;
      align-items: center;
      justify-content: center;


      .txt-subscription {
        font-size: 14px;
        color: #FFFFFF;
      }


      &.subscription-topic {
        background: linear-gradient(44deg, #FE7E7E 0%, #F94D67 100%);
        @include ElementHover('', linear-gradient(44deg, #F94D67 0%, #FE7E7E 100%));

        .icon-sub {
          display: inline-block;
          font-size: 14px;
          color: #FFFFFF;
          margin-right: 6px;
        }

        .txt-subscription {
          font-size: 12px;
          color: #FFFFFF;
          line-height: 16px;
        }

      }

      &.no-subscription-topic {
        background: #FFFFFF;
        border: 1px solid #ECEFF6;

        @include ElementHover('', #F7F9FD);

        &:after {
          background: rgba(66, 72, 102, 0.15);
        }

        .icon-sub {
          display: inline-block;
          font-size: 14px;
          margin-right: 6px;
          color: rgba(249, 77, 103, 1);
        }

        .txt-subscription {
          color: #7A7E93;
          font-size: 12px;
        }


      }
    }
  }

}


.__form-scrollbar {
  //scrollbar-color: #1890FF #91D5FF; /* 第一个方块颜色，第二个轨道颜色(用于更改火狐浏览器样式) */
  scrollbar-color: #CBD1DE #FFFFFF; /* 第一个方块颜色，第二个轨道颜色(用于更改火狐浏览器样式) */
  scrollbar-width: thin; /* 火狐滚动条无法自定义宽度，只能通过此属性使滚动条宽度变细 */
  -ms-overflow-style: none; /* 隐藏滚动条（在IE和Edge两个浏览器中很难更改样式，固采取隐藏方式） */
  -webkit-border-radius: 8px;


  /* 以下是chrome浏览器自定义滚动条样式方式 */
  &::-webkit-scrollbar { /*滚动条整体样式*/
    width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
    border-radius: 8px;
  }


  &::-webkit-scrollbar-thumb { /*滚动条里面小方块*/
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0);
    //background: #1890FF;
    background: #E0E3E9;
  }

  &::-webkit-scrollbar-track { /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0);
    border-radius: 8px;
    //background: #91D5FF;
    background: #FFFFFF;
  }
}


</style>
