import request from '@/utils/request'
import qs from 'qs'

/* 获取验证码 */
export function getCode(data) {
  return request({
    url: '/?m=Api&a=sendSms',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 验证码登录 */
export function loginByCode(data) {
  return request({
    url: '/?m=Api&a=phoneLogin',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 生成微信登录二维码 */
export function getWxLoginCode(data) {
  return request({
    url: '/?m=WechatApi&a=getWxLoginCode',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 检查用户是否关注公众号 */
export function getAttentionWxState(data) {
  return request({
    url: '/?m=WechatApi&a=isLogin',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 退出登录 */
export function loginOut(query) {
  return request({
    url: '?m=Api&a=logout',
    method: 'get',
    params: query
  })
}


/* 获取用户信息 */
export function getUserInfo(query) {
  return request({
    url: '/?m=Api&a=getUserInfoByUniqId',
    method: 'get',
    params: query
  })
}

/* 绑定手机号码 */
export function bindIphone(data) {
  return request({
    url: '/?m=Api&a=bindPhone',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 获取h5微信登录链接 */
export function getWxLoginLink(query) {
  return request({
    url: '/?m=WechatApi&a=silenceLogin',
    method: 'get',
    params: query
  })
}


export function getWxUserinfo(query) {
  return request({
    url: '/?m=WechatApi&a=silenceLoginCallback',
    method: 'get',
    params: query
  })
}

/* 账号注销 */
export function logOut(query) {
  return request({
    url: '/?m=Api&a=closeAccount',
    method: 'get',
    params: query
  })
}

