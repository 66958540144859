import axios from 'axios';
export default function({req,res}) {

  let isClient = process.client;
  let isServer = process.server;
  // console.log("%c 在服务端 发送接口请求","background:red",isServer,"isClient",isClient);
  // 在服务端
  if (isServer) {
    axios.defaults.timeout = 60000;
    axios.defaults.contentType = "application/json;charset=utf-8";
    axios.defaults.dataType = "text";
    axios.defaults.withCredentials = true;
    // 获取服务端cookie
    console.log("%c 在服务端 发送接口请求","background:red",req.headers.cookie);
    if (req.headers !== undefined) {
      axios.defaults.headers.Cookie = req.headers.cookie || ''
      axios.defaults.headers.Cookies = req.headers.cookie || ''
      // axios.defaults.headers.Cookie = {}
      // axios.defaults.headers.post['Cookie'] = {};
    }
    return ;
  }
  // 在客户端
  if (isClient) {
    // axios.defaults.headers['Content-Type'] = "application/json";
    // axios.defaults.headers['client'] = "pc_web";
    // axios.defaults.timeout = 60000;

    // axios.defaults.contentType = "application/json;charset=utf-8";
    // axios.defaults.dataType = "text";

    // headers: {
    //     "Content-Type": "application/json",
    //     "client": "pc_web"
    //   },
    console.log("%c 在客户端","background:red",axios.defaults);
    // if (req.headers !== undefined) {
    //     axios.defaults.headers.Cookie = req.headers.cookie
    //     // axios.defaults.headers.post['Cookie'] = req.headers.cookie;
    // }
    return ;
  }

}
