

/* 获取图片格式icon */
export function getPicFormat(layoutArr){
  let iconClass = [];
  layoutArr.forEach(vo =>{
    switch (vo.toUpperCase()) {
      case 'PSD':
        iconClass = iconClass.concat(['icon_ps', 'icon_ai'])
        // iconClass = ['icon_ps', 'icon_ai']
        break;
      case 'AI':
        // iconClass = ['icon_ai']
        iconClass = iconClass.concat(['icon_ai'])
        break;
      case 'CDR':
        // iconClass = ['icon_cdr']
        iconClass = iconClass.concat(['icon_cdr'])
        break;
      case 'EPS':
        // iconClass = ['icon_ps', 'icon_ai', 'icon_cdr']
        iconClass = iconClass.concat(['icon_ps', 'icon_ai', 'icon_cdr'])
        break;
      case 'C4D':
        // iconClass = ['icon_c4d', 'icon_3dMax', 'icon_blender']
        iconClass = iconClass.concat(['icon_c4d', 'icon_3dMax', 'icon_blender'])
        break;
      case 'MAX':
        // iconClass = ['icon_c4d', 'icon_3dMax', 'icon_blender']
        iconClass = iconClass.concat(['icon_c4d', 'icon_3dMax', 'icon_blender'])
        break;
      case 'BLEND':
        // iconClass = ['icon_c4d', 'icon_3dMax', 'icon_blender']
        iconClass = iconClass.concat(['icon_c4d', 'icon_3dMax', 'icon_blender'])
        break;
      case 'SKETCH':
        // iconClass = ['icon_sketch']
        iconClass = iconClass.concat(['icon_sketch'])
        break;
      default:
        // iconClass = ['icon_ps', 'icon_ai']
        // iconClass = iconClass.concat(['icon_ps', 'icon_ai'])
    }

  })

  return new Set(iconClass);


}

export function disposeDetailKeyword(keyword){
  if (keyword.length){
    return keyword.split(' ')
  }
  return []
}
