<template>
  <div class="DownloadPic">
    <!-- 下载限制弹框 -->
    <no-ssr>

      <DownloadInterceptDialog
        ref="DownloadInterceptDialog"
        :time="clippingTime"
      />

      <!-- 非盈利弹框 -->
      <ProfitModal
        ref="ProfitModal"
        @navigatorToPay="navigatorToPay"
        @limitTag="limitTag"
      />

      <!--  企业优惠券弹框 -->
      <ReceiveCompanyCouponDialog
        ref="ReceiveCompanyCouponDialog"
        :download-way="downloadWay"
        :couponReceiveState="couponReceiveState"
      />

      <CompanyCertificateDialog
        ref="CompanyCertificateDialog"
        :type="type"
      />

    </no-ssr>

  </div>
</template>

<script>
import {DownloadInterceptDialog} from "@/components/material";
import ProfitModal from '@/components/tupian/detail/profit-modal'
import {fnLogin, fnPic, fnRouter} from "@/fn";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import {tag} from "@/utils/statUtil";
import {downStat} from "@/api/material";
import {mapState} from "vuex";
import ReceiveCompanyCouponDialog from '@/components/tupian/detail/receive-company-coupon-dialog'
import {getCookie} from "@/utils/auth";
import {VIP_STATE} from "@/constant/VIP";
import {getCouponState} from "@/api/coupon";
import * as fnCommon from "@/fn/_fn";
import FnUser from "@/fn/_fn/FnUser";
import {setPicFreeDownCouponModal} from "~/components/_Privately/Pic/PicFreeDownCouponModal/module";
import {BLANK_LIST} from "@/constant/_Privately/User";
import CompanyCertificateDialog from "@/components/company/common/certificate-dialog/index.vue";
import CompanyController from "@/controller/companyController";

export default {
  name: "DownloadPic",
  components: {
    CompanyCertificateDialog,
    DownloadInterceptDialog,
    ProfitModal,
    ReceiveCompanyCouponDialog
  },
  data() {
    return {
      clippingTime: '',
      downloadWay: '', // 当前下载最高vip( 仅用于普通和商用vip判断 )
      couponReceiveState: false,
      type:-1,
    }
  },
  computed: {
    ...mapState('user', ['isLogin', 'downloadCount', 'uniq_id'])
  },
  methods: {
    downloadPic(channel, uniq_id, toPayTrackingFn, form, downloadPicType = 1) {



      if (fnLogin.checkLogin()) {
        /* 灰产账号封禁 */
        if (BLANK_LIST.indexOf(this.uniq_id) > -1) {
          console.log("灰产账号")
          this.$store.commit('Modal/SET_BLANK_MODAL', true)
          return false;
        }


        fnPic.downloadPic(channel, uniq_id, form).then(res => {
          let {status, time, layer, url, msg, d_type, free_down,unauthorized = false, reject_state,reject_reason = ''} = res.data.data;

          // if (reject_state == 0 || reject_state == 2 || reject_state == 3) {
          //   CompanyController.checkMainAuthenticationStatus(reject_state, reject_reason);
          // }
          // if (reject_state == 2){
          //   return false;
          // }

          if ( reject_state == 2 || reject_state == 3){
            CompanyController.checkMainAuthenticationStatus(reject_state,reject_reason);
          }

          /* 拒绝直接执行 */
          if (reject_state == 2){
            return false;
          }

          if (FnUser.checkCompanyVipExpiredModal()) {
            if (status == 0 ) {
              this.$store.commit('Modal/SET_COMPANY_VIP_EXPIRED_MODAL', true)
              return false;
            }
          }

          if (status === 1) {
            if(unauthorized == 1){
              CompanyController.checkMainAuthenticationStatus(0,reject_reason);
            }
            if (reject_state == 2){
              return false;
            }
            /* 下载 1图片 2图片包 */
            downStat({uniq_id, module: downloadPicType})

            if (free_down == 1) {
              setPicFreeDownCouponModal(this.$store, true);
            }

            // if(unauthorized){
            //   this.type = 3
            //   this.$refs.CompanyCertificateDialog.isShow = true
            // }

            /* 如果下载是免费下载次数，弹出非盈利弹框 */
            /*if (parseInt(d_type) === 1) {
              setTimeout(() => {
                if (this.downloadCount.free_remain == 0) {
                  // this.$refs.GuideDownloadCount.init();
                  this.$store.commit('home/SET_IS_SHOW_GUIDE_DOWNLOAD_COUNT', true)
                } else {
                  this.$refs.ProfitModal.isShowFreeDownload = true
                }
              }, 600)
            }*/


          } else if (status === 0 || status === 2 || status === 3) {
            // tag('充值:详情页立即下载按钮:图片:click')
            /*if (layer == 2) {
              fnRouter.navigationTo(PAGE_PATH.PAYMENT.BUSINESS);
            } else if (layer == 3) {
              fnRouter.navigationTo(PAGE_PATH.PAYMENT.BUSINESS);
            } else if (layer == 4) {
              fnRouter.navigationTo(PAGE_PATH.PAYMENT.COMPANY);
            } else {
              fnRouter.navigationTo(PAGE_PATH.PAYMENT.BUSINESS);
            }*/
            let personVipState = getCookie('personVipState')
            let personVipInfo = JSON.parse(getCookie('personVipInfo'))
            if (status === 2){
              this.$store.commit("_Privately/Pay/SET_SHOW_DOWN_LIMIT_BY_PERSON_YEAR_LIMIT_MODAL",true);
            }else{
              let openUrl = fnCommon.fnUser.getUserVipUpgradeLinkByPicLayer(parseInt(layer));
              fnCommon.fnBasic.navigationTo(openUrl, "_blank")
            }



            if (typeof toPayTrackingFn === "function") {
              toPayTrackingFn()
            }
          } else if (status === -200) {
            /*this.clippingTime = time;
            this.$refs.DownloadInterceptDialog.isShow = true;*/

            this.$store.commit('tupian/SET_DOWN_INTERCEPT_DIALOG', {isShow: true, clippingTime: time})
          }else if(status == 4){
            this.type = 3
            this.$refs.CompanyCertificateDialog.isShow = true
          }
        })
      } else {
        fnLogin.loginIntercept(false);
      }
    },

    navigatorToPay() {
      this.$refs.ProfitModal.isShowFreeDownload = false;
      fnRouter.navigationTo(PAGE_PATH.PAY_BUSINESS);
    },

    limitTag() {
      // tag("充值:非盈利用途弹窗:图片:click")
      tag('充值:列表页非盈利弹窗:图片:click')
    },


  }
}
</script>

<style scoped>

</style>
