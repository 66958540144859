/* router 跳转路径配置 */
import {BUSINESS_VIP_INFO, PERSON_VIP_INFO, PERSON_VIP_LIFELONG} from "./VIP";
import {SERVICE_LINK as SERVICELINK} from "@/constant/_Public/Path";
export const HOME = '/';                                  // 首页
/* PATH Config */
// export const ENV_BASE_URL = 'http://chaopx.com:3000';  // 测试环境
export const ENV_BASE_URL = 'https://chaopx.com';   // 正式环境
export const IMG_BASE_URL = 'https://pic.chaopx.com';         // 图片域名前缀
export const DOWNLOAD_COMPANY_PROTOCOL = 'https://api.chaopx.com/?m=Vrf&a=companyBusinessVrf';
export const DOWNLOAD_PERSON_PROTOCOL = 'https://api.chaopx.com/?m=Vrf&a=personalBusinessVrf';
export const DOWNLOAD_COMPANY_AUTH = 'https://api.chaopx.com/?m=Vrf&a=companyVipVrf';
export const DOWNLOAD_LEAFLET_PROTOCOL = 'https://api.chaopx.com/?m=Vrf&a=singlePageVrf';

export const MEMBER_LICENSE_VIP_PATH = '/member/license-vip';  // 授权列表页
export const MATERIAL_LIST_PATH = '/tupian';                     // 素材列表页
export const MATERIAL_DETAIL_PATH = '/detail';          // 素材详情页
export const MATERIAL_DOWNLOAD_PATH = '/download';             // 素材下载页
export const MARKETING_CALENDAR_PATH = '/calendar';           // 营销日历
export const TIMELINE_UPDATE_PATH = '/timeline-update';        // 时间轴更新
export const USER_CENTER_PATH = '/user-center';                // 个人中心
export const INVOICE_WRITE_PATH = '/invoice/write';            // 发票填写
export const PROTOCOL_PATH = '/protocol';                      // 协议集合
export const INVITE_FRIENDS_PATH = '/invite';                   // 邀请活动页面
export const PAY_PATH = '/pay';                                 // 支付页
// export const PIC_PACKAGE_PATH = '/list/pic-package';            // 图片包列表
export const PIC_PACKAGE_PATH = '/pic-package';            // 图片包列表
export const COMPANY_INTRODUCE_PATH = '/introduce/company';     // 会员介绍页
export const FEEDBACK_PATH = '/feedBack';                       // 意见和建议


export const MEMBER_LICENSE_VIP = ENV_BASE_URL + MEMBER_LICENSE_VIP_PATH;                 // 授权列表页 - 充值页面
export const MATERIAL_LIST_CUSTOM = ENV_BASE_URL + MATERIAL_LIST_PATH;                    // 素材列表自定义页
export const MATERIAL_DETAIL = ENV_BASE_URL + MATERIAL_DETAIL_PATH;                       // 素材详情页
export const MATERIAL_DOWNLOAD = ENV_BASE_URL + MATERIAL_DOWNLOAD_PATH;                   // 素材下载页
// export const MATERIAL_LIST = ENV_BASE_URL + MATERIAL_LIST_PATH + '/0-0-0-0-0-0-new-1.html'; // 素材列表默认页
export const MATERIAL_LIST = ENV_BASE_URL + MATERIAL_LIST_PATH; // 素材列表默认页
export const MARKETING_CALENDAR = ENV_BASE_URL + MARKETING_CALENDAR_PATH;                 // 营销日历
export const TIMELINE_UPDATE = ENV_BASE_URL + TIMELINE_UPDATE_PATH;                       // 时间轴更新
export const INVOICE_WRITE = ENV_BASE_URL + INVOICE_WRITE_PATH;                           // 发票填写
export const PROTOCOL_ABOUT_0 = PROTOCOL_PATH + '?type=about&act=0';                      // 关于_关于我们
export const PROTOCOL_ABOUT_1 = PROTOCOL_PATH + '?type=about&act=1';                      // 关于_版权声明
export const PROTOCOL_ABOUT_2 = PROTOCOL_PATH + '?type=about&act=2';                      // 关于_联系我们
export const PROTOCOL_PROBLEM_0 = PROTOCOL_PATH + '?type=problem&act=0';                  // 问题_用户协议
export const PROTOCOL_PROBLEM_1 = PROTOCOL_PATH + '?type=problem&act=1';                  // 问题_版权声明
export const PROTOCOL_PROBLEM_2 = PROTOCOL_PATH + '?type=problem&act=2';                  // 问题_隐私政策
export const PROTOCOL_LOGIN_0 = PROTOCOL_PATH + '?type=login&act=0';                      // 登录_登录注册
export const PROTOCOL_LOGIN_1 = PROTOCOL_PATH + '?type=login&act=1';                      // 登录_支付相关
export const PROTOCOL_PAY_0 = PROTOCOL_PATH + '?type=pay&act=0';                          // 支付_支付协议
export const INVITE_FRIENDS = ENV_BASE_URL + INVITE_FRIENDS_PATH;                         // 邀请活动页面
export const PAY =  PAY_PATH;                                               // 充值页
export const PAY_PERSON = ENV_BASE_URL + PAY_PATH + '/' + PERSON_VIP_LIFELONG.ID;                                 // 普通会员充值页
export const PAY_BUSINESS = ENV_BASE_URL + PAY_PATH + '/' + BUSINESS_VIP_INFO.ID;                               // 商用会员充值页
export const PAY_COMPANY = ENV_BASE_URL + PAY_PATH + '/' + 38;                               // 商用会员充值页
export const COMPANY_INTRODUCE = ENV_BASE_URL + '/introduce/company';     // 会员介绍页

export const PIC_PACKAGE = ENV_BASE_URL + PIC_PACKAGE_PATH;

export const FEEDBACK = ENV_BASE_URL + '/feedBack';                       // 意见和建议

export const SERVICE_LINK =SERVICELINK;
/* 测试页面 */
export const INVITE_FRIENDS_PATH_TEST = '/invite-test';                   // 邀请活动页面
export const INVITE_FRIENDS_TEST = ENV_BASE_URL + INVITE_FRIENDS_PATH_TEST;                         // 邀请活动页面


export const USER_CENTER = ENV_BASE_URL + USER_CENTER_PATH + '/vip-record';                    // 用户个人中心
export const USER_CENTER_VIP_COMPANY = ENV_BASE_URL + USER_CENTER_PATH + '/vip-record?vipType=1';           // 用户个人中心_个人信息
export const USER_CENTER_COLLECT = ENV_BASE_URL + USER_CENTER_PATH + '/collect-record'           // 用户个人中心_收藏
export const USER_CENTER_DOWNLOAD = ENV_BASE_URL + USER_CENTER_PATH +  '/download-record'     // 用户个人中心_下载
export const USER_CENTER_PIC_PACKAGE = ENV_BASE_URL + USER_CENTER_PATH + '?act=4';        // 用户个人中心_下载
export const USER_CENTER_USERINFO = ENV_BASE_URL + USER_CENTER_PATH + '/user-info'          // 用户个人中心_个人信息
export const USER_CENTER_LEAFLET_BUY = ENV_BASE_URL + USER_CENTER_PATH + '/vip-record?vipType=1'          // 用户个人中心_个人信息




