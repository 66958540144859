<template>
  <div class="download-intercept-container" v-if="downInterceptDialog.isShow">
    <cg-modal @close="close">
      <div slot="modal_content" class="modal-content">
        <div class="title">您的操作过于频繁，请休息一下</div>
        <div class="desc">{{ downInterceptDialog.clippingTime }}分钟后再试</div>
        <div class="btn" @click="close">我知道了</div>
      </div>
    </cg-modal>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "DownloadInterceptDialog",
  computed:{
    ...mapState('tupian',
      ['downInterceptDialog']),

  },
  props:{
    time:{
      type: String | Number,
      default: ''
    }
  },
  data(){
    return{
      isShow:false
    }
  },
  methods:{
    close(){
      this.$store.commit('tupian/SET_DOWN_INTERCEPT_DIALOG',{ isShow: false })
    }
  }
}
</script>

<style lang="scss" scoped>
.download-intercept-container{


  /deep/.Modal-content{
    height: 440px !important;
    height: 276px !important;
  }
  .modal-content{
    width: 440px;
    //height: 350px;
    height: 100%;
    background: #FFFFFF;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title,.desc{
      //font-size: 14px;
      //color: #596088;
      //line-height: 36px;
      //text-align: center;

      font-size: 20px;
      font-weight: bold;
      color: #424866;
      line-height: 36px;
    }

    .title{
      //padding-top: 20px;
    }

    .desc{
      margin-bottom: 70px;
    }


    .btn{
      width: 160px;
      height: 44px;
      line-height: 44px;
      text-align: center;
      border-radius: 25px;
      background: #6969FF;
      color: #FFFFFF;
      cursor: pointer;
      @include ElementHover('', #5E5EE8);
    }
  }
}
</style>
