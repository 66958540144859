<template>
  <div class="HeaderVipPerson">
<!--
  tag('充值:商用授权导航:图片')
-->
    <div
      class="copyright-box _pointer _hover-element"
      @click.prevent="toggleShowCombobox(4,false);navigatorTo(COMPANY_INTRODUCE);"
      @mouseover="toggleShowCombobox(4,true)"
      @mouseleave="toggleShowCombobox(4,false)"
    >
      <div class="icon_copyright-box" @click.stop="tag('充值:商用授权导航:图片');navigatorTo(COMPANY_INTRODUCE)">
<!--        <i class="icon_copyright"></i>-->
        全站通VIP授权介绍
      </div>

      <div
        class="roll-msg-button" @click.stop="tag('充值:商用授权导航:图片');navigatorTo(COMPANY_INTRODUCE)">
      </div>
      <no-ssr>
<!--        <VipMandateCombobox ref="VipMandateCombobox"/>-->
      </no-ssr>
    </div>
  </div>
</template>

<script>
import {VipMandateCombobox} from '@/components/home'
import RollMsgButton from '@/components/common/header/header-vip-person/roll-msg-btn'
import {PAY_PERSON} from "@/constant/_Public/Path";
import {COMPANY_INTRODUCE} from "@/constant/PATH";

export default {
  name: "HeaderVipPerson",
  components: {
    // VipMandateCombobox,
    RollMsgButton
  },
  data() {
    return {
      PAY_PERSON,
      COMPANY_INTRODUCE
    }
  },
  methods: {
    navigatorTo(path) {
      this.$emit('navigatorTo', path)
    },
    tag(val) {
      this.$emit('tag', val)
    },
    toggleShowCombobox(type, flag, e, enter = false) {

      return false;
      if (type === 1) {
        setTimeout(() => {
          if (this.$refs.searchComboBox != undefined) {
            this.$refs.searchComboBox.isShowCombobox = flag
          }
        }, 500)
      } else if (type === 2) {
        this.$refs.CopyrightCombobox.isShowCombobox = flag;
      } else if (type === 3) {
        this.$refs.UserCombobox.isShowCombobox = flag;
      } else if (type === 4) {
        this.$refs.VipMandateCombobox.isShowCombobox = flag;
      }

    },
  }
}
</script>

<style lang="scss" scoped>
.HeaderVipPerson {

  .icon_copyright-box {
    display: flex;
    align-items: center;
  }

  .copyright-box {
    letter-spacing: 1px;
    font-size: 15px;
    @include LayoutFlex(1);
    color: #FFFFFF;
    position: relative;
    z-index: 5;

    &:before {
      height: 40px;
    }

    @include ElementHover(rgba(255, 255, 255, 0.75), '') {

      &:before {
        height: 40px;
        bottom: -30px;

      }
    }

    .roll-msg-button {
      @include Position(1, 5, 30px, -16px);
    }

    .icon_copyright {
      @include BgImage(24px, 24px, "home/images/icon-copyright.png");
      margin-right: 8px;

      @include Screen(1) {
        @include BgImage(22px, 22px, "home/images/icon-copyright.png");
      }
    }

    /deep/ .vip-mandate-wrap {
      @include Position(1, 2, 0, 47px);
    }

  }
}
</style>
