<template>
  <div class="DownloadCountGuide DownloadCountGuide1" v-if="showNewTipButton">
    <CgModalMiddle @close="close">
      <div slot="modal_content" class="modal-content tip-dialog">


        <div class="title">
          <span >
            {{ showNewTipButton ? '恭喜获得' : '当前剩余' }}
            {{ downloadCount.free_down_cnt - downloadCount.down_cnt }}次免费下载！<br>
            每日可使用{{ downloadCount.day_free_limit }}次~
          </span>

          <div class="tips">
            Tips:10次下载仅注册10天内有效
          </div>
        </div>

        <div class="desc _hide">
          邀请好友注册免费获得下载次数
        </div>
        <div class="btn-group">
          <div
            class="btn-item btn-item-dl"
            @click="inviteFriendEntry"
          >
            获得更多免费下载
          </div>
          <div
            class="btn-item btn-item-sy"
            @click="navigatorToPay(2);tag('充值:首页新人次数礼包:图片')"
          >
            <i class="icon_hj"/>
            开通VIP海量下载
<!--            <RollMsgButton class="RollMsgButton"/>-->

          </div>
        </div>
      </div>
    </CgModalMiddle>
  </div>
</template>

<script>
import {fnInviteFriend, fnLogin, fnUser} from "@/fn";
import {PAY} from "@/constant/PATH";
import localStorage from "@/utils/localStorage";
import {MODEL_DOWNLOAD_COUNT_GUIDE, MODEL_NEW_USER_GIFT, MODEL_NEW_USER_GIFT_DAY} from "@/constant/MODELS";
import {mapGetters, mapState} from "vuex";
import {tag} from "@/utils/statUtil";
import RollMsgButton from '@/components/common/header/header-vip-person/roll-msg-btn'
import {toolDate} from "@/tools";

export default {
  name: "HintDialog",
  data() {
    return {
      showNewTipButton: null
    }
  },
  computed: {
    ...mapGetters('user',
      ['name', 'uid', 'avatar', 'uniq_id', 'vipIsPerson', 'vipIsPersonData', 'vipIsBusiness', 'vipIsBusinessData', 'downloadCount']),
    ...mapState('user', [ 'userInfo']),
  },

  mounted() {
    this.init();
  },
  components:{
    RollMsgButton
  },
  methods: {
    init() {
      // this.checkIsShow();
      this.checkIsNewUser();
    },
    /* 判断创建时间和当天是不是统一时间 */
    checkIsNewUser() {
      if (fnLogin.checkLogin()) {
        setTimeout(() => {
          this.showNewTipButton = toolDate.isSameDay(this.userInfo.create_time)
        }, 600);
      }
    },
    checkIsShow() {
      let val =  localStorage.getItemDate(MODEL_NEW_USER_GIFT_DAY);
     setTimeout(()=>{
       if (fnLogin.checkLogin() && !fnUser.checkUserIsVip() && val == 1) {
         this.isShow = true;
       }else{
         this.isShow = false;
       }
     },500)

    },

    getState() {
      return localStorage.getItemDate(MODEL_DOWNLOAD_COUNT_GUIDE);
    },
    updateState(value = 1) {
      localStorage.setItemDate(MODEL_DOWNLOAD_COUNT_GUIDE, value)
    },


    inviteFriendEntry() {
      fnInviteFriend.inviteFriendEntry(13);
      this.close();
    },
    navigatorToPay(id = 2) {

      if (fnLogin.checkLogin()) {
        let path = `${PAY}/${id}`
        window.open(path, '_blank')
      } else {
        this.$store.commit("user/SET_IS_SHOW_LOGIN", 1);
      }

      this.close();

    },
    close() {
      this.isShow = false;
      // this.updateState()
      // localStorage.removeItemDate(MODEL_NEW_USER_GIFT_DAY)
      // localStorage.setItemDate(MODEL_NEW_USER_GIFT_DAY, -1)
      this.$emit('close')
    },
    tag(stat) {
      tag(stat)
    },
  }
}
</script>

<style lang="scss" scoped>
.icon_hj {
  @include BgImage(18px, 24px, 'tupian/icon_hj.png');
  margin-right: 3px;
}

.tip-dialog {
  width: 516px;
  height: 318px;
  background: #FFFFFF;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .title {
    font-size: 24px;
    font-weight: bold;
    color: #424866;
    line-height: 40px;
    margin: 80px 68px 12px;
    text-align: center;

    .tips{
      font-size: 14px;
    }
  }

  .desc {
    font-size: 14px;
    color: #596088;
    line-height: 20px;
    text-align: center;
    margin-bottom: 72px;
  }

  .btn-group {
    display: flex;
    @include Position(1, 4, 50%, 50px);
    transform: translateX(-50%);

    .btn-item {
      height: 44px;
      line-height: 44px;
      text-align: center;
      border-radius: 25px;
      cursor: pointer;
      width: 180px;
      background: #E0E3E9;
      color: #596088;
    }

    .btn-item-dl {
      margin-right: 40px;
      color: #596088;
      @include ElementHover('', rgba(224, 227, 233, 0.8));
    }

    .btn-item-sy {
      background: #6969FF;
      border-radius: 25px;
      color: #FFFFFF;

      @include ElementHover('', #5E5EE8);

      .RollMsgButton{
        @include Position(1,2,16px,-21px);
      }
    }
  }
}
</style>
