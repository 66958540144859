<template>
  <div class="DesignerPlatformHeader">
    DesignerPlatformHeader module
  </div>
</template>

<script>
export default {
  name: "DesignerPlatformHeader"
}
</script>

<style lang="scss" scoped>
@import "../common/layout";
</style>
