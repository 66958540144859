/* 公共变量 */
import {USER_CENTER_COLLECT, USER_CENTER_DOWNLOAD, USER_CENTER_USERINFO} from "@/constant/PATH";
import {PAGE_PATH} from "@/constant/PAGE_PATH";

export const COMMON = {

  HEADER:{

    AVATAR_COMBOBOX:{

      /* 会员卡片切换类型 个人和企业 */
      ACCOUNT_TYPE:{
       /* PERSON : 'person',  // 个人
        COMPANY: 'company',  // 企业
        DEFAULT: 'person',*/
        PERSON : 2,  // 个人 -> 企业
        COMPANY: 1,  // 企业 -> 个人
        DEFAULT: 0
      },

      /* 企业导航 */
      COMPANY_NAV: {
        ADMIN: [
          {
            label: '企业首页',
            iconClass: 'icon-qy-home-line',
            path: PAGE_PATH.COMPANY.HOME
          },
          {
            label: '收藏记录',
            iconClass: 'icon-qy-collect4',
            path: PAGE_PATH.COMPANY.TEAM.COLLECT
          },
          {
            label: '授权记录',
            iconClass: 'icon-qy-download-file-line',
            path: PAGE_PATH.COMPANY.MANDATE_RECORD
          },
          {
            label: '续费/加购',
            iconClass: 'icon-qy-pay-line',
            path: PAGE_PATH.COMPANY.PAY
          }
        ],
        DEFAULT: [
          {
            label: '企业首页',
            iconClass: 'icon-qy-home-line',
            path: PAGE_PATH.COMPANY.HOME
          },
          {
            label: '收藏记录',
            iconClass: 'icon-qy-collect4',
            path: PAGE_PATH.COMPANY.TEAM.COLLECT
          },
          {
            label: '授权记录',
            iconClass: 'icon-qy-download-file-line',
            path: PAGE_PATH.COMPANY.MANDATE_RECORD
          }
        ]
      },



      /* 个人中心导航列表 */
      USER_CENTER_NAV_LIST:[
        {
          iconClass: "icon_collect",
          name: "收藏记录",
          path: USER_CENTER_COLLECT,
        },
        {
          iconClass: "icon_download",
          name: "下载记录",
          path: USER_CENTER_DOWNLOAD
        },
        {
          iconClass: "icon_user",
          name: "个人信息",
          path: USER_CENTER_USERINFO
        },
        /*{
          iconClass: "icon_free_down",
          name: "免费下载",
          path: PAGE_PATH.NAV.FREE
        },*/
        {
          iconClass: "icon_command",
          name: "口令兑换",
          path: 'https://chaopx.com/exchange-code'
        },
        {
          iconClass: "icon_coupon",
          name: "领优惠券",
          path: PAGE_PATH.USER_CENTER.COUPON
        },
        {
          iconClass: "icon_vip",
          name: "宠粉专区",
          path: PAGE_PATH.FANS.DEFAULT
        }
      ]
    },

    NAV:{
      TYPE:{
        HOME_HEADER: 'homeHeader',
        OTHER:'other'
      }
    }
  },

  /* 底部 */
  Footer:{
    /* 渠道图标列表 */
    CHANNEL_LIST: [
      {
        label: '微博',
        id: 1,
        iconClass:'icon_weibo',
        iconHoverClass:'icon_weibo@hover',
        qrCodeImg:'/common/footer/channel/weibo.png',
        qrCodeDesc:'扫码关注 官方微博',
      },
      {
        label: '微信',
        id: 2,
        iconClass:'icon_weixin',
        iconHoverClass:'icon_weixin@hover',
        qrCodeImg:'/common/footer/channel/weixin.png',
        qrCodeDesc:'扫码关注 微信公众号'
      },
      {
        label: '抖音',
        id: 3,
        iconClass:'icon_douyin',
        iconHoverClass:'icon_douyin@hover',
        qrCodeImg:'/common/footer/channel/douyin.png',
        qrCodeDesc:'扫码关注 官方抖音'
      },
      {
        label: '小红书',
        id: 4,
        iconClass:'icon_xhs',
        iconHoverClass:'icon_xhs@hover',
        qrCodeImg:'/common/footer/channel/xhs.png',
        qrCodeDesc:'扫码关注 官方小红书'
      },
    ]
  }
}
