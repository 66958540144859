<template>
  <div class="layoutEmpty">
    <nuxt/>
    <no-ssr>
      <Login/>
      <ActiveBackHome />
<!--      <CommonSlot/>-->
      <PublicSlot/>
    </no-ssr>
  </div>
</template>

<script>
import {Login} from "@/components/common/index.js";
import ActiveBackHome from "@/components/_components/active/ActiveBackHome"
// import CommonSlot from "@/components/_components/_common/CommonSlot";
import PublicSlot from "@/components/_Public/PublicSlot";


export default {
  name: "layoutActive",
  components: {
    Login,
    ActiveBackHome,
    PublicSlot
    // CommonSlot
  },
}
</script>

<style scoped>

</style>
