<template>
  <div class="about-company __box-wrap">
    <div class="__layout-wrap">
      <ul class="__layout-tab">
        <li
          v-for="(vo,idx) in tab"
          :key="vo"
          :class="{ 'on': idx == act }"
          @click="toggleTab(vo,idx)"
        >{{ vo }}
        </li>
      </ul>
      <div class="__layout-container">
        <div class="__layout-headline">{{ headline }}</div>
        <div class="__layout-content">
          <div>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;欢迎使用上海图魂网络科技有限公司-潮国创意（以下简称“潮国创意”）的VIP会员服务，为了保障您的权益，请在进行下一步操作前，务必审慎阅读、充分理解各条款内容，特别是加粗部分的条款，以及开通或使用某项服务的单独协议，并选择接受或不接受。除非您已阅读并接受本协议所有条款，否则您无权使用本服务。当您勾选“同意《VIP会员服务协议》”、“立即开通”按钮或其他具有类似含义的按钮时，或您对本服务的任何购买或接受赠与等获取行为及登录、查看等任何使用行为即视为您已阅读并同意本协议的约束。该协议构成您与潮国创意达成的协议，具有法律效力。</p>
            <p>本协议是《潮国创意用户协议》、《潮国创意隐私政策》、《潮国创意版权声明》、《潮国创意会员授权协议》的补充协议，是其不可分割的组成部分，与其构成统一整体。本协议与《潮国创意用户协议》、《潮国创意隐私政策》、《潮国创意版权声明》、《潮国创意会员授权协议》存在冲突的，以本协议为准。本协议内容包括本协议正文、潮国创意已经发布和/或将来可能发布的与潮国创意VIP会员服务相关的各类规则。所有规则为本协议不可分割的组成部分，与本协议具有同等法律效力。潮国创意有权随时对服务条款进行修改，一旦相关内容发生变动，潮国创意将会通过电子邮件或网站公告等方式提示您。如果您不同意对本协议内容所做的修改，则应立即停止使用本服务；如果您继续使用本服务的，则视为您同意对本协议内容所做的修改。</p>
            <p>如果您未满18周岁，请在法定监护人的陪同下阅读本协议，并特别注意未成年人使用条款。</p>
            <p>一、定义</p>
            <p>1、潮国创意VIP会员：指注册用户在签署本协议并根据潮国创意公布的收费标准支付相应的费用后获取的特定资格，具体资格名称以潮国创意公示为准。以下简称“会员”。</p>
            <p>2、会员服务：指会员所享有的依据所购买的会员种类所享有的特定服务，具体以潮国创意公布的服务内容为准。</p>
            <p>3、潮国创意：指 https://chaopx.com/，主要为用户提供高质量且有前沿设计感的营销物料，由上海图魂网络科技有限公司所有及运营。</p>
            <p>4、潮国创意VIP：仅指潮国创意网站VIP服务。</p>
            <p>二、使用规范</p>
            <p>1、您申请开通会员服务时，需提交包括但不限于您本人的手机号码、邮箱及第三方支付账户等个人资料或企业身份证明等资料，并保证您提供的个人资料真实、准确、完整、合法且有效，如上述资料有所变动，您应及时更新。如您提供的资料缺乏真实性、合法性、准确度，或您于上述资料变动时未及时更新引起的法律责任及相关损失将由您自行承担，如您的上述行为造成潮国创意损失的，潮国创意保留向您主张损害赔偿请求的权利。</p>
            <p>2、潮国创意有权单方就提供的虚拟产品(包括但不限于VIP会员服务)予以定价，并有权依据自身的运营需要予以变更，且潮国创意在此获得您的授权可进行潮国创意平台无差别待遇地调整您已经获取的虚拟产品；基于虚拟产品的性质和特征，您所获取的虚拟产品一经使用即无法进行退货、换货、兑换现金，亦不得用于商业领域如买卖、置换、抵押等。一切通过非官方公布渠道取得的虚拟产品及其衍生服务均不获保护，潮国创意有权中止或终止为您提供的服务。</p>
            <p>3、会员服务有效期根据您选择购买的会员选项的具体时长为准，自您成功支付会员服务费用之日起计算。每一个收费项目的服务周期以页面标注期限为准。如您未在有效期限内使用已获取的服务，视为您已使用，上述服务款项概不退还。若您希望在有效期限届满后继续享受会员服务，则您需要重新购买相关服务。</p>
            <p>4、若您购买了多个收费服务，其服务范围相同的，您所获得的服务期限自动顺延；若您购买的多个收费服务 服务范围存在差异的，高等级套餐会自动覆盖低等级套餐，同时潮国创意就您已支付的低等级套餐费用概不退款。例如您在2022年1月1日获得个人下载VIP，2022年1月7日购获得个人商用VIP，您所支付的个人下载VIP会员费用不予退还，您自2022年1月7日享有个人商用会员服务。</p>
            <p>5、在使用会员服务过程中，您应当是具备与您行为相适应的民事行为能力的自然人、法人或其他组织。若您不具备前述主体资格，请务必在您的监护人的帮助下共同完成，若因此使得潮国创意或任何第三方权益受损，您及您的监护人将承担相应的责任。</p>
            <p>6、您知悉并同意，潮国创意有权通过邮件、短信或电话等形式，向您发送会员活动相关信息。</p>
            <p>7、您已知悉并同意，您所享有的会员权限仅限您开通之日潮国创意已存在的各分类会员服务内容；若潮国创意于后续新增分类会员服务，此等服务您需另行获取，但新增会员服务中另有约定的除外。</p>
            <p>8、您确认会员服务仅限您本人使用，同时，您保证您将合理使用会员服务，不利用会员服务非法获利，不以任何形式转让您所享有的会员服务，不以任何形式将您所享有的会员服务借予他人使用，如潮国创意有合理理由怀疑您存在不当使用会员服务时，潮国创意有权取消您的会员资格且您已支付的会员服务费用不予退还，因此产生的相关责任及损失均由您自行承担，如您的上述行为给潮国创意造成损失的，潮国创意保留向您追偿的权利。</p>
            <p>9、潮国创意保留在法律法规允许的范围内自行决定是否接受您的会员申请、调整会员服务内容、取消会员资格等相关权利。</p>
            <p>10、您理解并保证，您在使用会员服务过程中遵守诚实信用原则。如潮国创意发现或有合理理由怀疑您存在以下任一情形的：</p>
            <p>（1）发布、传送、传播、储存违反国家法律、危害国家安全统一、社会稳定、公序良俗、社会公德以及侮辱、诽谤、淫秽、暴力的内容；</p>
            <p>（2）发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；</p>
            <p>（3）虚构事实、隐瞒真相以误导、欺骗他人；；</p>
            <p>（4）发表、传送、传播广告信息及垃圾信息；</p>
            <p>（5）从事其他违反法律法规、政策及公序良俗、社会公德等的行为。</p>
            <p>（6）通过任何方式搜集本服务中其他用户的用户名、电子邮件等相关信息，并以发送垃圾邮件、连锁邮件、垃圾短信、即时消息等方式干扰、骚扰其他用户；</p>
            <p>（7）未经接收方许可通过本服务发布包含广告、宣传、促销等内容的信息；</p>
            <p>（8）将本服务再次许可他人使用；</p>
            <p>（9）通过任何机器人软件、蜘蛛软件、爬虫软件、刷屏软件等任何作弊程序、软件方式为自己或他人开通或使用本服务；</p>
            <p>（10）通过任何不正当手段或以违反诚实信用原则的方式为自己或他人开通或使用本服务；</p>
            <p>（11）通过非潮国创意指定的方式为自己或他人开通本服务；</p>
            <p>（12）通过侵犯潮国创意或他人合法权益的方式为自己或他人开通本服务；</p>
            <p>（13）通过其他违反相关法律、行政法规、国家政策等的方式为自己或他人开通本服务。</p>
            <p>（14）为了交换、转让或散发的目的，使潮国创意服务所提供的内容在共享的驱动器、 服务、软件或网站上可供免费下载 ；</p>
            <p>（15）转让、转售、授予分许可、出租、捐赠或以其它方式转让开通服务获得的内容给第三方；</p>
            <p>（16）其他未经潮国创意书面许可的行为。</p>
            <p>11、您理解并同意潮国创意有权根据实际业务需要不时地调整其网站内容而无需另行通知您，包括但不限于内容增减、授权期限变更、收费模式变更等。</p>
            <p>三、账号独立</p>
            <p>1、如潮国创意存在QQ账号或微信账号登录的，同一主体拥有的QQ帐号及微信帐号，在进行潮国创意会员登录时，将被识别为两个独立的帐号；</p>
            <p>2、如潮国创意存在QQ账号登录的，同一主体拥有的多个QQ帐号并进行潮国创意VIP会员登录的，每一个QQ帐号将被识别为一个独立的帐号；</p>
            <p>3、如潮国创意存在微信账号登录的，同一主体拥有多个微信帐号并进行潮国创意会员登录的，每一个微信帐号将被识别为一个独立的帐号；</p>
            <p>4、每个帐号之间的充值记录、VIP权限无法进行迁徙、转让、赠与、售卖、租借、分享，无论该等帐号是否由同一使用人拥有，请您在登录/充值/参加活动时注意区分，避免造成损失。由此造成的损失由您自行承担。</p>
            <p>四、会员服务售后</p>
            <p>1、您知悉并确认，开通会员服务后，若您中途主动取消服务或终止资格或被潮国创意根据《潮国创意用户协议》、本协议及相关规则注销账号、终止会员资格的，您已支付的会员服务费用将不予退还。</p>
            <p>2、如您有其他与会员服务售后相关的问题，可以通过潮国创意公布的联系方式联系客服进行反馈。</p>
            <p>五、其他约定</p>
            <p>1、通知：所有发给您的通知都可通过电子邮件、常规的信件或在网站显著位置公告的方式进行传送。</p>
            <p>2、如您有其他与会员服务售后相关的问题，可以通过潮国创意公布的联系方式联系客服进行反馈。</p>
            <p>3、本协议适用中华人民共和国的法律。当本协议的任何内容与中华人民共和国法律相抵触时，应当以法律规定为准，同时相关条款将按法律规定进行修改或重新解释，而本协议其他部分的法律效力不变。</p>
            <p>4、如使用会员服务过程中出现纠纷，您应与潮国创意首先通过友好协商解决，若协商不成的，您同意双方约定管辖法院为上海市浦东新区人民法院。</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import indexExtend from "../common/protocol-extend";

export default {
  name: "VipProtocol",
  mixins: [indexExtend],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    act: 0
  },
  data() {
    return {

      tab: [
        "VIP服务协议",
      ]
    }
  },
  mounted() {
    this.headline = this.tab[this.act];
  }

}
</script>

<style lang="scss" scoped>
@import "pages/protocol/common/common";


</style>
