import {HOME, MATERIAL_DETAIL_PATH, MATERIAL_LIST_PATH} from "@/constant/PATH";

class FnPages {
  constructor() {
  }

  setIsShowSearch(store){
    let whiteList = [HOME, MATERIAL_LIST_PATH];
    let blackList = [MATERIAL_DETAIL_PATH]

     this.whiteListSet(whiteList,true,store)
     this.whiteListSet(blackList,false,store)

    /*function listSet(list,bool){
      blackList.forEach(vo => {
        if (path.indexOf(vo) >-1){
          store.commit('layout/SET_IS_SHOW_SEARCH', false)
        }
      })
    }*/
  }
  whiteListSet(list,bool,store){
    let path = store.state.user.path
    list.forEach(vo => {
      if (path.indexOf(vo) >-1){
        store.commit('layout/SET_IS_SHOW_SEARCH', bool)
      }
    })
  }


}

export default new FnPages();



