/**
 * @Author: wulizhou
 * @Description:  创作者中心
 * @Date: 2023.4.27
 **/
import Cookie from 'js-cookie'
/* 引入公共方法和常量 */
import * as CON_COMMON from '@/constant/_constant';

const {CREATOR_AUTH} = CON_COMMON.CON_CREATOR

export default {
  namespaced: true,

  state() {
    return {
      creatorAuth: false     // 是否签约成功，签约状态为7则不在查询
    }
  },

  mutations: {
    SET_CREATOR_AUTH(state, payload) {
      console.log('payload' + payload)
      state.creatorAuth = payload;
      Cookie.set(CREATOR_AUTH, payload)
    }
  }


  , actions: {

    /* 获取签约状态 */
    async nuxtServerInit(store, {app}) {
      // 可以在这里初始一些内容到store中
      console.log('111---------nuxtServerInit!!!');
      console.log('222---------res')

      let [res] = await Promise.all([app.$api.getSignStatus()])

      console.log(app)

      console.log(res)
    }


  }
}
