
/* 登录设备 */
export const DEVICE = {
  'wx': 1,
  'iphone': 2,
  'qq': 3
}


export const BROWSERS = {
  IE : 'IE',
  Opera : 'Opera',
  Edge : 'Edge',
  Firefox : 'Firefox',
  Safari : 'Safari',
  Chrome : 'Chrome',
}
