/**
 * @Author: wulizhou
 * @Description:  创作者中心
 * @Date: 2023.4.27
 **/
import Cookie from 'js-cookie'
/* 引入公共方法和常量 */
import * as CON_COMMON from '@/constant/_constant';

const {CREATOR_AUTH} = CON_COMMON.CON_CREATOR

export default {
  namespaced: true,

  state() {
    return {
      creatorAuth: false     // 是否签约成功，签约状态为7则不在查询
      , signType: ""       // 签约类型 个人 OR 工作室
      , isShowSignExpireModal: false
      , signRenewLink: ""

    }
  },

  mutations: {
    SET_CREATOR_AUTH(state, payload) {
      state.creatorAuth = payload;
      Cookie.set(CREATOR_AUTH, payload)
    }

    ,SET_SIGN_TYPE(state, payload) {
      state.signType = payload;
    }

    ,SET_IS_SHOW_SIGN_EXPIRE_MODAL(state, payload) {
      state.isShowSignExpireModal = payload;
    }

    ,SET_SIGN_RENEW_LINK(state, payload) {
      state.signRenewLink = payload;
    }
  }


  , actions: {

    /* 获取签约状态 */
    /*nuxtServerInit(store, context) {
      // 可以在这里初始一些内容到store中
      console.log('---------nuxtServerInit!!!');
    }*/


  }
}
