<template>
  <div class="wrap" v-show="isHasUserCoupon && isLogin">
    <ul class="new-list" :class="{anim:animate}" @mouseenter="Stop()" @mouseleave="Up()">
      <li v-for="item in noticeList">
        {{ item.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import {fnLogin, fnUser} from "@/fn";
import {getCouponList} from "@/api/pay";
import localStorage from "@/utils/localStorage";
import {MODEL_NEW_USER_GIFT_DAY} from "@/constant/MODELS";
import {mapState} from "vuex";
import {toolDate} from "@/tools";

export default {
  data() {
    return {
      // noticeList: [],
      animate: false,
      intNum: undefined,
      showNewTipButton: false
    }
  },
  props: {
    noticeList: {
      type: Array,
      default: () => [
        {
          name: "新人福利"
        },
        {
          name: "限时优惠"
        },
      ]
    }
  },
  computed: {
    ...mapState('user', ['phone', 'userInfo', 'personVipInfo','isHasUserCoupon']),
    isLogin() {
      return this.$store.state.user.isLogin
    }
  },
  created: function () {
    // this.getCouponList();
    this.ScrollUp();
    this.checkIsNewUser();


  },
  watch: {
    isLogin(n, o) {
      if (n == true) {
        this.checkIsNewUser();
      }
      if (n == false) {
        this.showNewTipButton = false;
      }
    }
  },

  methods: {

    /* 判断创建时间和当天是不是统一时间 */
    checkIsNewUser() {
      if (fnLogin.checkLogin()) {
        setTimeout(() => {
          this.showNewTipButton = toolDate.isSameDay(this.userInfo.create_time)
        }, 800);
      }
    },

    ScrollUp() {
      this.intNum = setInterval(() => {
        this.animate = true;// 向上滚动的时候需要添加css3过渡动画
        setTimeout(() => {
          this.noticeList.push(this.noticeList[0]);// 将数组的第一个元素添加到数组的
          this.noticeList.shift(); //删除数组的第一个元素
          this.animate = false;
        }, 800)
      }, 2000);
    },
    //鼠标移上去停止
    Stop() {
      clearInterval(this.intNum);
    },
    Up() {
      this.ScrollUp();
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  overflow: hidden;
  width: 65px;
  height: 20px;
  background: linear-gradient(318deg, #FFE089 0%, #FFEEA7 100%);
  border-radius: 12px 2px 12px 2px;

  font-size: 12px;
  font-weight: bold;
  color: #F34D12;


}

.new-list {
  //height: 28px;
  //overflow: hidden;
  transition: top 0.5s;


  li {
    line-height: 20px;
    text-align: center;
    font-weight: normal;

  }
}

.anim {
  transition: all 0.5s;
  margin-top: -20px; //高度等于行高
}
</style>
