<template>
  <div
    class="invite-friends-bar _pointer"
    @click="navToInviteAct"/>
</template>

<script>

import {  navToInviteAct } from "./module"

export default {
  name: "InviteBanner",
  methods: {
    navToInviteAct() {
      navToInviteAct(1);
    }
  }
}
</script>

<style lang="scss" scoped>

.invite-friends-bar {
  width: 100%;
  display: block;
  transition: all 0.8s ease;
  @include BgImage(100%,51px,'act/invite-friends/invite-bar.jpg',1) ;
  vertical-align: middle;

  img {
    width: 100%;
    display: block;
    transition: all 0.8s ease;
  }
}


</style>
