import {getCookie, setCookie} from "@/utils/auth";
import {updateMaterialNum} from "~/api/material";
import {fnLocalStorage} from "~/fn/index";
import {getCode} from "~/api/login";
import myNotify from "~/utils/notify";
import {MODEL_UPDATE_BY_DAY} from "@/constant/MODELS";
import {getPicFormat} from "../components/tupian/detail/module";
import {setPicViewType} from "@/components/_Privately/Pic/Common/module";
import {BASE_IMG_URL} from "@/constant/_Public/Path";

class FnMaterial {
  constructor() {


  }


  /* 更新每日素材 */
  async updateMaterial() {
    let cookieMaterial = getCookie('MODEL_updateMaterialNum')
    if (!cookieMaterial || !fnLocalStorage.getItemDate(MODEL_UPDATE_BY_DAY)) {
      let res = await updateMaterialNum()
      let {picTotal} = res.data.data
      picTotal = parseInt(picTotal) + 100000 + '+'
      setCookie('MODEL_updateMaterialNum', picTotal)
    }
  }

  getMaterialNum() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        let code = parseInt(getCookie('MODEL_updateMaterialNum'));
        // this.searchPlaceholder =  `搜索全站${temStr}创意设计`
        resolve(code.toLocaleString())
      }, 300)
    })

  }


  /* 处理图片详情 */
  disposeTupianDetail(data) {

    console.log({data});

    /* 处理格式 */
    if (data.layout_name) {
      data.iconClass = getPicFormat(data.layout_name);  // 图片格式
    } else {
      data.iconClass = [];
    }

    /* 处理字体 */
    if ((data.fonts && data.fonts.length)) {
      data.fontStr = data.fonts.toString();
    }

    /* 处理关键词 检查是否有标签，面包屑增加随机标签/*/
    if (data.keyword_pinyin && data.keyword_pinyin.length) {
      let tempKeyword = [];
      data.keyword_pinyin.forEach((vo, idx) => {
        if (vo.pinyin != null) {
          tempKeyword.push(vo)
          // picDetail.data.keyword_pinyin.splice(idx,1)
        }
      })
      data.keyword_pinyin = tempKeyword;
      let _randomKeyword = parseInt(Math.random() * tempKeyword.length);
      data._tempTag = data.keyword_pinyin[_randomKeyword]

      data._keyword_pinyin = data.keyword_pinyin.length > 15 ? data.keyword_pinyin.slice(0, 15) : data.keyword_pinyin;

    }

    /* 处理根据图片等级设置邀请好友人数 */
    if (data.layer) {
      switch (parseInt(data.layer)) {
        case 1:
          data._inviteNumber = 1;
          break;
        case 2:
          data._inviteNumber = 1;
          break;
        case 3:
          data._inviteNumber = 2;
          break;
        case 4:
          data._inviteNumber = 3;
          break;
        /*default:
          picDetail.data._inviteNumber = 1;*/

      }

    }

    if (!!data.ppt_slide_show){
      data.ppt_slide_show = data.ppt_slide_show.filter(item => !!item.proto_pic )
    }

    data._proto_pic = BASE_IMG_URL + (data.preview_water_pic || data.preview_pic)
    data = setPicViewType(data);

    return data;


  }

}

export default new FnMaterial();
