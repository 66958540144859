import {BROWSERS} from "@/constant/SYSTEM";

class ToolDevice {

  constructor(props) {

  }

  getCurrentBrowser() {
    let userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
    let isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器
    let isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera; //判断是否IE浏览器
    let isEdge = userAgent.indexOf("Edge") > -1; //判断是否IE的Edge浏览器
    let isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器
    let isSafari = userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") == -1; //判断是否Safari浏览器
    let isChrome = userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Safari") > -1; //判断Chrome浏览器

    if (isIE) {
      return BROWSERS.IE;
    }
    if (isOpera) {
      return BROWSERS.Opera;
    }
    if (isEdge) {
      return BROWSERS.Edge;
    }
    if (isFF) {
      return BROWSERS.Firefox;
    }
    if (isSafari) {
      return BROWSERS.Safari;
    }
    if (isChrome) {
      return BROWSERS.Chrome;
    }
  }


  //判断是否微信浏览器
  isWxBrowser() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      return true;
    } else {
      return false;
    }
  }

  /**
   * 解析 URL 参数
   * http://localhost:8080/?id=123&key=vaule#/restaurant/seller
   * "?id=123&key=vaule"
   * {id: "123", key: "vaule"}
   */
  urlParse(url = window.location.search) {
    // window.location.href 当前文件的绝对地址
    // window.location.search 当前文件的哈希地址
    const obj = {};
    const reg = /[?&][^?&]+=[^?&]+/g;
    const arr = url.match(reg); // ["?id=123", "&key=vaule"]

    if (arr) {
      arr.forEach((item) => {
        // 删除 ? 和 &，以 = 为标志分割数组
        const tempArr = item.substring(1).split('='); // ["id", "123"]  ["key", "vaule"]
        // 使用 decodeURIComponent() 对编码后的 URI 进行解码
        const key = decodeURIComponent(tempArr[0]);
        const value = decodeURIComponent(tempArr[1]);
        obj[key] = value;
      });
    }
    return obj;
  }

}

export default new ToolDevice();
