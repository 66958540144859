<template>
  <div class="SignExpireModal" v-if="isShowSignExpireModal">
    <ModalMiddle @close="close">
      <div slot="ModalContent" class="ModalContent">
        <div class="title">签约过期提醒</div>
        <div class="tips ">
          非合约期内的作品无法打款结算，请及时续签合约！
        </div>

        <el-button type="danger"
                   @click="navigationToSignRenew"
        >去续签
        </el-button>
      </div>
    </ModalMiddle>
  </div>
</template>

<script>

/* 引入公共方法和常量 */
import * as fnCommon from '@/fn/_fn';
import * as CON_COMMON from '@/constant/_constant';

/* 引入组件 */

/* 引入VUEX */
import {mapState} from "vuex";

/* 引入API */

export default {
  name: "SignExpireModal"

  , components: {}

  , props: {
    signRenewLink:{
      type: String,
      default: ''
    }
  }

  , watch: {
    /*isShowSignExpireModal: {
      handler(n, o) {
        if (n || !n) {
          this.isShow = n;
        }
      },
      immediate: true
    },*/
  }

  , computed: {
    // ...mapState("Creator", ['isShowSignExpireModal', 'signRenewLink'])
  }

  , data() {
    return {
      isShow: false,
      isShowSignExpireModal: false
    }
  }

  , methods: {
    navigationToSignRenew() {
      if (this.signRenewLink != '') {
        fnCommon.fnBasic.navigationTo(this.signRenewLink, '_self');
      }
    }

    , close() {
      this.isShow = false
      this.$store.commit("Creator/SET_IS_SHOW_SIGN_EXPIRE_MODAL", false)

    }
  }

}
</script>

<style lang="scss" scoped>
.ModalContent {
  width: 440px;
  height: 304px;
  background: #FFFFFF;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    height: 26px;
    font-size: 20px;
    font-weight: bold;
    color: #424866;
    line-height: 26px;
    margin: 40px 0;
  }

  .tips {
    //width: 340px;
    padding: 0 50px;
    height: 60px;
    font-size: 14px;
    color: #EE5253;
    line-height: 20px;
    margin-bottom: 40px;

  }

  .form {
    /deep/ .el-input {
      width: 340px;
      height: 50px;

      .el-input__inner {
        height: 100%;
        border: none;
        background: rgba(245, 246, 250, 1);
      }

      input {
        &::placeholder {
          color: rgba(0, 16, 19, 0.40);
        }
      }
    }
  }

  /deep/ .el-button {
    width: 140px;
    height: 44px;
    //background: #EE5253;
    border-radius: 25px;
    font-size: 14px;
    color: #FFFFFF;
    margin-top: 10px;
  }
}
</style>
