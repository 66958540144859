<template>
  <div class="PicItem PicItemRecommend">
    <div class="pic-img-wrap">

      <a class="pic-img-item"
         :title="picItemInfo.title"
         :href="picItemInfo.link_url"
         target="_blank"
      >
        <img
          @click="picItemClick(picItemInfo)"
          :src=" IMG_BASE_URL + picItemInfo.proto_pic + '!/fw/672/quality/90/unsharp/true/compress/true'"
          v-lazy=" IMG_BASE_URL + picItemInfo.proto_pic + '!/fw/672/quality/90/unsharp/true/compress/true'"
          :alt="picItemInfo.title"
          class="pic-img"
          @contextmenu.prevent=""
        >
        <TupianMask :link="picItemInfo.link_url" ></TupianMask>

      </a>
    </div>
    <!-- 收藏 -->
    <!--    <PicTagCollect :pic-item-info="picItemInfo"/>-->
    <!-- 标题 -->
<!--    <NavigationTo :path="picItemInfo.link_url">
      <div slot="content">

      </div>
    </NavigationTo>-->

    <PicTagTitle  :pic-item-info="picItemInfo"/>
    <!-- 标签 -->
    <!--    <PicTagLegalCopy :pic-item-info="picItemInfo"/>-->
    <!--    -->
    <div class="_PicTagCollectNew"></div>
    <PicTagLegalCopyRecommend class="_PicTagLegalCopy" :pic-item-info="picItemInfo"/>
  </div>
</template>

<script>
import picItemExtend from "@/components/pic/pic-item/common/pic-item-extend";
import {mapState} from "vuex";

export default {
  name: "PicItemRecommend",
  mixins: [picItemExtend],
  computed: {
    ...mapState('favorites',
      [
        "showDialog",
        "showDialogByCompany",
        "currentTupianInfo",
        "favoriteList",
        "favoriteHistory"
      ]
    ),
    ...mapState('base', ['spider']),
  },
}
</script>

<style lang="scss" scoped>
@import "./common/pic-item";
</style>

