import {permission} from "@/constant/VIP";

/**
 * 企业Vip变量
 * 3是线上 5是全用途
 */

export const COMPANY_VIP = {
  NAME: '线上用途',
  ID: 1,
  PARENT_ID: 3,
  MODULE: 3,
  PRICE: 199,
  VALID_TIME: '年',
  SCENE_LIST: [
    '微博/微信',
    '网站设计/APP设计',
    '百度/360推广',
    '淘宝/天猫/京东等',
  ]
}


/* 会员权限 充值页权限 */
export const comany_permission = [
  {
    isPer: true,
    desc: '企业商用授权',
    iconClass: 'icon-a-1shang'
  },
  {
    isPer: true,
    desc: '仅限线上用途',
    iconClass: 'icon-a-2img'
  },
  {
    isPer: true,
    desc: '在线申请发票',
    iconClass: 'icon-a-3ticket'
  },
  {
    isPer: true,
    desc: '图片版权保障',
    iconClass: 'icon-a-4copyright'
  },
  {
    isPer: true,
    desc: '图片包一键下载',
    iconClass: 'icon-a-12pack'
  },
  {
    isPer: true,
    desc: '新媒体曝光5万次',
    iconClass: 'icon-a-5media'
  },
  {
    isPer: true,
    desc: '电商曝光5万次',
    iconClass: 'icon-a-7dianshang'
  },
  {
    isPer: true,
    desc: '线上推广',
    iconClass: 'icon-a-6xianshang'
  },
  {
    isPer: true,
    desc: '户外广告',
    iconClass: 'icon-a-8huwai'
  },
  {
    isPer: true,
    desc: '传媒影视',
    iconClass: 'icon-a-9film'
  },
  {
    isPer: true,
    desc: '线下印刷',
    iconClass: 'icon-a-10printer'
  },
  {
    isPer: true,
    desc: '图书出版',
    iconClass: 'icon-a-11book'
  },
]


export const COMPANY_ONLINE = {
  NAME: '企业VIP',
  FULL_NAME: '线上用途',
  FULL_NAME2: '线上用途版',
  FULL_NAME3: '企业VIP线上用途',
  USER_CROWD: '全企业授权 · 商用授权保障',
  ID: 9,
  PARENT_ID: 3, // module字段
  PRICE: 199,
  VALID_TIME: '年',
  CLASS_NAME: 'company',
  PERMISSION: comany_permission,
  PERMISSION_ARR: [1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0],
  DESC1: '线上用途 1人使用',
  PERSON: {
    max: '¥699/人'
  },
  DESC2: '「线上用途」商用授权书',
  DESC: '1111',
  SCENE_LIST: [
    '微博/微信',
    '网站设计/APP设计',
    '百度/360推广',
    '淘宝/天猫/京东等',
  ],
  DETAIL_URL: '/introduce/company',
  ACCOUNT_NUMBER: 1
}
export const COMPANY_ONLINE_HALF = {
  NAME: '企业VIP',
  FULL_NAME: '线上用途',
  FULL_NAME2: '线上用途版',
  FULL_NAME3: '企业VIP线上用途',
  USER_CROWD: '全企业授权 · 商用授权保障',
  ID: 33,
  PARENT_ID: 3, // module字段
  PRICE: 129,
  VALID_TIME: '6个月',
  CLASS_NAME: 'company',
  PERMISSION: comany_permission,
  PERMISSION_ARR: [1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0],
  DESC1: '线上用途 1人使用',
  DESC2: '「线上用途」商用授权书',
  DESC: '1111',
  PERSON: {
    max: '¥399/人'
  },
  SCENE_LIST: [
    '微博/微信',
    '网站设计/APP设计',
    '百度/360推广',
    '淘宝/天猫/京东等',
  ],
  DETAIL_URL: '/introduce/company',
}
export const COMPANY_All_PURPOSE = {
  NAME: '企业VIP',
  FULL_NAME: '线上线下全用途',
  FULL_NAME2: '全用途版',
  FULL_NAME3: '企业VIP全用途',
  USER_CROWD: '全企业授权 · 商用授权保障',
  ID: 38,
  PARENT_ID: 3, // module字段
  PRICE: 399,
  VALID_TIME: '年',
  CLASS_NAME: 'company',
  PERMISSION: comany_permission,
  PERMISSION_ARR: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  DESC1: '全用途1人使用',
  DESC2: '「全用途」商用授权书',
  DESC: '222',
  PERSON: {
    max: '¥999/人'
  },
  SCENE_LIST: [
    '微博/微信',
    '网站设计/APP设计',
    '百度/360推广',
    '淘宝/天猫/京东等',
  ],
  DETAIL_URL: '/introduce/company',
  ACCOUNT_NUMBER: 1
}
export const COMPANY_All_PURPOSE_HALF = {
  NAME: '企业VIP',
  FULL_NAME: '线上线下全用途',
  FULL_NAME2: '全用途版',
  FULL_NAME3: '企业VIP全用途',
  ID: 34, // 4
  PARENT_ID: 3, // module字段
  PRICE: 229,
  VALID_TIME: '6个月',
  CLASS_NAME: 'company',
  PERMISSION: comany_permission,
  PERMISSION_ARR: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  DESC1: '全用途1人使用',
  DESC2: '「全用途」商用授权书',
  DESC: '222',
  PERSON: {
    max: '¥599/人'
  },
  SCENE_LIST: [
    '微博/微信',
    '网站设计/APP设计',
    '百度/360推广',
    '淘宝/天猫/京东等',
  ],
  DETAIL_URL: '/introduce/company',
}

/* 企业升级版 */
export const COMPANY_UPGRADE = {
  NAME: '企业VIP',
  FULL_NAME: '企业升级版',
  ID: 35, // 4
  PARENT_ID: 3, // module字段
  PRICE: 990,
  ACCOUNT_NUMBER: 4,
  CLASS_NAME: 'company',
}
/* 企业高级版 */
export const COMPANY_SENIOR = {
  NAME: '企业VIP',
  FULL_NAME: '企业高级版',
  ID: 36, // 4
  PARENT_ID: 3, // module字段
  PRICE: 2600,
  ACCOUNT_NUMBER: 10,
  CLASS_NAME: 'company',
}
/* 企业转售版 */
export const COMPANY_RESALE = {
  NAME: '企业VIP',
  FULL_NAME: '企业转售版',
  ID: 37, // 4
  PARENT_ID: 3, // module字段
  PRICE: 5600,
  ACCOUNT_NUMBER: 20,
  CLASS_NAME: 'company',
}

/* 企业授权范围 */
export const COMPANY_AUTHORIZED_SCOPE = {
  /* 企业保障 */
  COMPANY_SECURITY: {
    label: '企业权益保障',
    list: [
      {
        label: '企业权益保障',
        // value: '授权主体数量',
      },
      {
        label: '企业授权主体',
        // value: '授权主体数量',
      },
      {
        label: '企业规模',
        // value: '授权主体数量'
      },
      {
        label: '下载帐号',
        // value: '为保障企业用户版权使用，如发生版权纠纷，赔偿由潮国创意赔付'
      },
      {
        label: '侵权赔付保障',
        // value: '为保障企业用户版权使用，如发生版权纠纷，赔偿由潮国创意赔付'
      },
      {
        label: '企业商用授权书',
        // value: '为保障企业用户版权使用，如发生版权纠纷，赔偿由潮国创意赔付'
      },
      {
        label: '全站素材+图片包',
        // value: '为保障企业用户版权使用，如发生版权纠纷，赔偿由潮国创意赔付'
      },
      {
        label: '授权地域',
        // value: '为保障企业用户版权使用，如发生版权纠纷，赔偿由潮国创意赔付'
      },
      {
        label: '授权期限',
        // value: '为保障企业用户版权使用，如发生版权纠纷，赔偿由潮国创意赔付'
      },
      {
        label: '全站素材版权保障无忧',
        // value: '为保障企业用户版权使用，如发生版权纠纷，赔偿由潮国创意赔付'
      },
      {
        label: '在线申请官方正规发票',
        // value: '为保障企业用户版权使用，如发生版权纠纷，赔偿由潮国创意赔付'
      },
    ]
  },

  /* 线上用途 */
  ONLINE_USE: {
    label: '线上用途',
    list: [
      {
        label: '线上用途',
        isSetTime: true,
        isExposure: true
      },
      {
        label: '新媒体用途',
        value: '微博、微信、小红书、今日头条、知乎等新媒体平台',
        isSetTime: true,
        isExposure: true
      },
      {
        label: '电商平台',
        value: '淘宝、天猫、京东、拼多多、抖音、美团、饿了么、小红书等电商交易平台用图',
        isSetTime: true
      },
      {
        label: '企业办公',
        value: 'VI设计、营销策划方案、商业提案、项目计划书、年度报告等配图（非转授权）',
        isSetTime: true,
        isExposure: true
      },
    ]
  },
  /* 线上用途 */
  ONLINE_USE2: {
    label: '广告宣传',
    list: [
      {
        label: '广告宣传',
        isSetTime: true,
        isExposure: true
      },
      {
        label: '网络宣传推广',
        value: '官方网站、H5、APP、小程序、百度、360等宣传配图使用',
        isSetTime: true,
        isExposure: true
      },
      {
        label: '流媒体平台宣传',
        value: 'B站、抖音、快手、腾讯视频、爱奇艺、优酷等媒体播放平台广告宣传',
        isSetTime: true
      },
      {
        label: '户外广告',
        value: '楼宇、车身、灯箱、围挡、橱窗、户外广告牌、公共场所陈列',
        isSetTime: true,
        isExposure: true
      },
    ]
  },
  /* 线下用途 */
  OFFLINE_USE: {
    label: '线下用途',
    list: [
      {
        label: '线下用途',
        value: ''
      },
      {
        label: '物料印刷',
        value: '海报、宣传单、易拉宝、宣传画册、优惠券、会员卡等宣传物料'
      },
      {
        label: '线下活动',
        value: '公司年会、颁奖活动、启动仪式、演出、展会、发布会等线下活动背景及屏幕',
      },
    ]
  },
  /* 传媒影视 */
  MEDIA_FILM: {
    label: '传媒影视',
    list: [
      {
        label: '传媒影视',
      },
      {
        label: '影视制作',
        value: '电影、电视剧、新闻、纪录片、微电影、自制剧、广播等制作使用'
      },
    ]
  },
  /* 转售商品 */
  RESELL_GOODS: {
    label: '转售商品',
    list: [
      {
        label: '转售商品',
        value: ' '
      },
      {
        label: '电子出版物',
        value: '电子图书类、电子期刊类、电子报纸等'
      },
      {
        label: '实物印刷',
        value: '图书、期刊、杂志、报纸等'
      },
      {
        label: '商品生产',
        value: '产品外包装、服装服饰、艺术品、工艺品（装饰画、手机壳、贺卡、明信片、日历、杯子）等实物商品及赠品'
      }

    ]
  },

  /* 产品内置 */
  PRODUCT_IN: {
    label: '产品内置',
    list: [
      {
        label: '产品内置',
      },
      {
        label: '软件主题/皮肤/背景、软件内置、H5应用、小程序、网络课程、付费音视频、游戏内置、智能硬件等',
        value:' '
      }
    ]
  },

  /* 敏感用途 */
  SENSITIVE_USE: {
    label: '敏感用途',
    list: [
      {
        label: '敏感用途',
      },
      {
        label: '医疗、美容、化妆品、整形、养生 等行业',
        value: ' '
      },
      {
        label: '敏感行业肖像权用途：药物、保健品、烟草、交友等行业',
        value: ' '
      }
    ]
  },

  /* 商标LOGO */
  BRAND_LOGO: {
    label: '商标LOGO',
    list: [
      {
        label: '商标LOGO'
      },
      {
        label: '企业、品牌商标宣传'
      }
    ]
  },

  /* 内置转售 */
  BUILT_RESALE:{
    label: '内置转售',
    list: [
      {
        label: '内置转售'
      },
      {
        label: '数字内置',
        value: '修图软件、视频剪辑编辑、在线编辑内置、DIY印刷定制等'
      },
      {
        label:'二次售卖',
        value:'在软件程序、网站等渠道二次售卖设计作品，包含但不限于海报、元素、H5、插画、PPT、视频、屏幕保护程序等作品类型。'
      }

    ]
  },

  /* 其他用途 */
  OTHER_USES: {
    label: '其他用途',
    list: [
      {
        label: '其他用途'
      },
      {
        label: '其他用途'
      }
    ]
  },

  /* 增值服务 */
  BASIC_SERVICES: {
    label: '增值服务',
    list: [
      {
        label: '增值服务',
      },
      {
        label: '专属客服',
        value: '1对1专属客服，及时在线咨询'
      },
      {
        label: '专属身份',
        value: '专属身份图标，彰显VIP身份'
      }
    ]
  }
}


export const COMPANY_PLUS_AUTHORIZED_SCOPE = {
  /* 企业保障 */
  COMPANY_SECURITY: {
    label: '企业权益保障',
    list: [
      {
        label: '企业权益保障',
      },
      {
        label: '可授权企业规模',
        value: '公司注册资本＞2000万',
      },
      {
        label: '企业授权主体',
        value: '按需定制',
      },
      {
        label: '下载范围',
        value: '全站海量下载+图片包'
      },
      {
        label: '下载子账号',
        value: '按需定制'
      },
      {
        label: '企业授权书',
        value: '「定制版企业授权书」'
      },
      {
        label: '侵权赔付保障',
        value: '最高全额赔付'
      },
      {
        label: '授权地域',
        value: '全球'
      },
      {
        label: '授权期限',
        value: '永久'
      }
    ]
  },

  /* 线上用途 */
  ONLINE_USE: {
    label: '线上用途',
    list: [
      {
        label: '线上用途',
        isSetTime: true,
        isExposure: true
      },
      {
        label: '新媒体用途：微博、微信、小红书、今日头条',
        value: '',
        isSetTime: true,
        isExposure: true
      },
      {
        label: '电商用途：淘宝、天猫、京东、拼多多、抖音、美团、饿了么、小红书等电商交易平台用图',
        value: '',
        isSetTime: true
      },
      {
        label: '企业办公：VI设计、营销策划方案、商业提案、项目计划书、年度报告等配图（非转授权）',
        value: '',
        isSetTime: true
      },
    ]
  },
  ONLINE_USE2: {
    label: '广告宣传',
    list: [
      {
        label: '广告宣传',
        isSetTime: true,
        isExposure: true
      },
      {
        label: '网络宣传推广：官方网站、H5、APP、小程序、百度、360等宣传配图使用',
        value: '',
        isSetTime: true,
        isExposure: true
      },
      {
        label: '流媒体平台宣传：B站、抖音、快手、腾讯视频、爱奇艺、优酷等媒体播放平台广告宣传',
        value: '',
        isSetTime: true
      },
      {
        label: '户外广告：楼宇、车身、灯箱、围挡、橱窗、户外广告牌、公共场所陈列',
        value: '',
        isSetTime: true
      },
    ]
  },
  /* 线下用途 */
  OFFLINE_USE: {
    label: '线下用途',
    list: [
      {
        label: '线下用途',
        value: ''
      },
      {
        label: '物料印刷：海报、宣传单、易拉宝、宣传画册、优惠券、会员卡等宣传物料',
        value: ''
      },
      {
        label: '线下活动：公司年会、颁奖活动、启动仪式、演出、展会、发布会等线下活动背景及屏幕',
        value: '',
      },
    ]
  },
  /* 传媒影视 */
  MEDIA_FILM: {
    label: '传媒影视',
    list: [
      {
        label: '传媒影视',
      },
      {
        label: '影视制作：电影、电视剧、新闻、纪录片、微电影、自制剧、广播等制作使用',
        value: ''
      },
    ]
  },
  /* 转售商品 */
  RESELL_GOODS: {
    label: '转售商品',
    list: [
      {
        label: '转售商品',
        value: ' '
      },
      {
        label: '电子出版物：电子图书类、电子期刊类、电子报纸等',
        value: ''
      },
      {
        label: '实物印刷：图书、期刊、杂志、报纸等',
        value: ''
      },
      {
        label: '商品生产：产品外包装、服装服饰、艺术品、工艺品（装饰画、手机壳、贺卡、明信片、日历、杯子）等实物商品及赠品',
        value: ''
      }
    ]
  },

  /* 产品内置 */
  PRODUCT_IN: {
    label: '产品内置',
    list: [
      {
        label: '产品内置',
      },
      {
        label: '软件主体/皮肤/背景、软件内置、H5应用、小程序、网络课程、付费音视频、游戏内置、智能硬件等',
      }
    ]
  },

  /* 敏感用途 */
  SENSITIVE_USE: {
    label: '敏感用途',
    list: [
      {
        label: '敏感用途',
      },
      {
        label: '医疗、美容、化妆品、整形、养生 等行业',
      },
      {
        label: '敏感行业肖像权用途：药物、保健品、烟草、交友等行业',
      }
    ]
  },

  /* 商标LOGO */
  BRAND_LOGO: {
    label: '商标LOGO',
    list: [
      {
        label: '商标LOGO'
      },
      {
        label: '企业、品牌商标宣传'
      }
    ]
  },

  /* 内置转售 */
  BUILT_RESALE:{
    label: '内置转售',
    list: [
      {
        label: '内置转售',
      },
      {
        label: '数字内置：修图软件、视频剪辑编辑、在线编辑内置、DIY印刷定制等',
      },
      {
        label:'二次售卖：在软件程序、网站等渠道二次售卖设计作品，包含但不限于海报、元素、H5、插画、PPT、视频、屏幕保护程序等作品类型。',
      }

    ]
  },

  /* 其他用途 */
  OTHER_USES: {
    label: '其他用途',
    list: [
      {
        label: '其他用途'
      },
      {
        label: '其他用途',
        value: "按需定制"
      }
    ]
  },
}
