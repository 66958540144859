<!--
    全局卡槽
-->
<template>
  <no-ssr>
    <div class="GlobalSlot">
      <!--    <GuideDownloadCount />-->

      <!-- 企业帐户未认证提示  -->
      <!--    <NewUserCouponFooterBar />-->

      <!--    <IntroduceDialog />-->

      <!--    <NoticeVipUpgrade />-->

      <!-- 下载拦截弹框 -->
      <DownloadInterceptDialog/>

      <PicPackagePayModal />

      <!-- 非盈利弹框 -->
      <ProfitModal
        ref="ProfitModal"
        @navigatorToPay="navigatorToPay"
        @limitTag="limitTag"
      />

      <!--  新春活动  -->
      <!--    <NewYearBar />-->

      <!--  用户画像  -->
      <SurveyUserDialog
        ref="SurveyUserDialog"
      />

      <!-- 绑定手机号码 -->

      <!--    <ActCompanyPayDialog />-->
    </div>
  </no-ssr>
</template>

<script>
import GuideDownloadCount from '@/components/home/guide/download-count';
import NewUserCouponFooterBar from '@/components/act/top-up/new-user-coupon-bar.vue'
import IntroduceDialog from '@/components/common/dialog/introduce-dialog'
import NoticeVipUpgrade from '@/components/notice/vip-upgrade';
import {DownloadInterceptDialog} from "@/components/material";
import PicPackagePayModal from '@/components/pic-package/pic-package-pay-modal'
import {fnRouter} from "@/fn";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import {tag} from "@/utils/statUtil";
import ProfitModal from '@/components/tupian/detail/profit-modal';
import SurveyUserDialog from '@/pages/survey/user'


// import NewYearBar from '@/components/act/new-year/bar'
// import ActCompanyPayDialog from '@/components/act/company-pay'

export default {
  name: "GlobalSlot",
  components:{
    GuideDownloadCount,
    NewUserCouponFooterBar,
    IntroduceDialog,
    NoticeVipUpgrade,
    DownloadInterceptDialog,
    PicPackagePayModal,
    ProfitModal,
    SurveyUserDialog,


    // ActCompanyPayDialog,
    // NewYearBar,

  },
  methods:{
    navigatorToPay() {
      this.$refs.ProfitModal.isShowFreeDownload = false;
      fnRouter.navigationTo(PAGE_PATH.PAY_BUSINESS);
    },

    limitTag() {
      // tag("充值:非盈利用途弹窗:图片:click")
      tag('充值:列表页非盈利弹窗:图片:click')
    },
  }
}
</script>

<style scoped>

</style>
