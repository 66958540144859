import {visitorLogs} from "~/api/buried-point.js"
import {fnCompany, fnLogin, fnPages, fnPay} from '~/fn'
import {fnBasic} from "@/fn/_fn";

export default ({app, route, store}) => {
  app.router.beforeEach(async (to, from, next) => {

    /* if (to.path == '/' || to.path == '') {
       let bodyStyle = document.body.style
       let htmlStyle = document.getElementsByTagName('html')[0].style;
       htmlStyle['filter'] = `grayscale(1)`
     }
 */
    /* 延迟设置头部 meta标签 */
    fnBasic.setMeta();
    store.commit('user/SET_PATH', to.path)
    store.commit('layout/SET_IS_SHOW_SEARCH', false)
    store.commit('layout/SET_SCREEN_WIDTH', document.body.clientWidth);
    fnLogin.listen(app);

    console.log(to.path)

    if (to.path.indexOf('/detail/') > -1){
      fnLogin.setHbScript();
    }

    await visitorLogs({http_referer: document.referrer || '', url_params: window.location.href || ''}); // 埋点来源
    await fnPay.interceptPayUpgrad(app, store, route, next);
    await fnCompany.intercept(app, store, route, next);

    if (window.navigator && navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations()
        .then(function (registrations) {
          for (let registration of registrations) {
            registration.unregister();
          }
        });
    }


    /* 动态修改样式 */

    await next();
  })
}
