import Vue from 'vue'
import ModalMiddle from '@/components/_Public/ModalMiddle';


const components = {
  ModalMiddle
}

Object.keys(components).forEach(key => {
  Vue.component(key, components[key])
})
