<template>
  <div
    :class="[ {'on': NAV.STYLE.id == navIndex} ]"
    class="NavItemHot menu-item"
    @mouseover="isShow = true"
    @mouseleave="isShow = false"
  >
    <a
      :href="styleNavLink"
      target="_blank"
      @click="updateNavId(NAV.STYLE.id)"
    >
      设计风格
    </a>

    <!--  下拉
  -->
    <div
      v-show="isShow"
      class="d-combobox">
      <div
      >
        <div class="head-box">
          <span class="title">热门设计风格</span>
          <span class="desc">潮流创意风格，探索设计前沿</span>
        </div>
        <a class="seek-design"
           :href="PAGE_PATH.BASE_URL + PAGE_PATH.NAV.STYLE"
           target="_blank"
        >
          前往发现
        </a>

        <div class="classify-wrap">
          <div class="classify-list">
            <div
              class="classify-item" v-if="styleCategoryList.length">
              <a
                v-for="(item,idx) in styleCategoryList.slice(0,9)"
                :key="idx"
                :title="item.name"
                :href="PAGE_PATH.BASE_URL + PAGE_PATH.TU_PIAN.LIST + '/0-0-0-0-0-0-0-new-1-0-' + item.id + '-1.html' "
                target="_blank"
              >
                {{ item.name }}
              </a>
            </div>

            <div class="line"  v-if="styleCategoryList.length"></div>

            <div
              class="classify-item" v-if="styleCategoryList.length > 9">
              <a
                v-for="(item,idx) in styleCategoryList.slice(9)"
                :key="idx"
                :title="item.name"
                :href="PAGE_PATH.BASE_URL + PAGE_PATH.TU_PIAN.LIST + '/0-0-0-0-0-0-0-new-1-0-' + item.id + '-1.html' "
                target="_blank"
              >
                {{ item.name }}
              </a>
            </div>

          </div>

        </div>
        <div class="pics">
          <a
            class="pic-item"
            v-for="(vo,idx) in styleRecommendList"
            :key="idx"
            :title="vo.title"
          >
            <img v-lazy="IMG_BASE_URL + '/' + vo.pic_url">
            <TupianMask :link="vo.link_url"/>
          </a>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import {IMG_BASE_URL} from "@/constant/PATH";
import {topbarMenuList} from "@/components/common/header/module";
import {PAGE_PATH} from '@/constant/PAGE_PATH';
import {THREE_D} from "@/constant/3d/3D";
import {getDropdownByDesignStyle, getDropdownByHot, getDropdownByThreeD} from "@/api/nav";
import {fnRouter} from "@/fn";
import {NAV} from "~/components/common/header/header-nav/common/module";
import headerNavMixin from "~/components/common/header/header-nav/common/header-nav-mixin";


export default {
  name: "NavItemFestival",
  props: {
    navIndex: {
      type: String | Number,
      default: -1
    },

    designStyle:{},
    styleCategoryList:[],
    styleRecommendList:[]

  },
  mixins: [headerNavMixin],
  data() {
    return {
      IMG_BASE_URL,
      isShow: false,
      monthIdx: 1,    // 默认显示月份
      currentMonth: [],  // 当月
      headerBgColor: '', // 头部背景色
      topbarMenuList,
      PAGE_PATH,
      THREE_D,
      classifyList: [],
      picList: [],
      categoryList: [],
      recommendList: [],
      NAV
    }
  },
  computed: {
    threeDLink() {
      return fnRouter.getRouterLink(PAGE_PATH.THREE_D.DEFAULT);
    },
    styleNavLink() {
      return fnRouter.getRouterLink(PAGE_PATH.NAV.STYLE);
    },
  },
  watch: {
    isShow: {
      handler(n, o) {
        if (n == true) {
          // this.init();
        }
      },
    },
  },
  /*async fetch() {
    const {data} = await getDropdownByDesignStyle();
    let {design, recommendPic} = data.data;
    this.categoryList = design;
    this.recommendList = recommendPic;
  },*/
  methods: {

    init() {
      this.getData();
    },

    navigateToList() {
      let findItem = this.topbarMenuList.find(vo => vo.id === NAV.STYLE.id)
      this.$emit('navigateToList', findItem)
    },


  },
}
</script>

<style lang="scss" scoped>
@import "../common/nav";
@import "../common/icon";


.NavItemHot {
  position: relative;
  z-index: 99;

  .hot-combobox {
    @include Position(1, 5, -35px, 40px);
    border-radius: 12px;
    box-sizing: border-box;
    animation: fadeinT 0.5s 1;

    width: 730px;
    max-height: 402px;
    background: #FFFFFF;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.06), 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
    overflow: hidden;


    .festival-header-bar {
      @include BgImage(730px, 56px, 'header/nav/festival-bg.png');
      position: absolute;
      left: 0;
      top: 0;
    }

    /* 图片集合 */
    .festival-month {
      width: 100%;
      border-radius: 12px 12px 0px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 19px;
      padding-left: 4px;


      .festival-month-item {
        //margin-right: 28px;
        //margin: 0 14px;
        padding: 0 14px;
        font-size: 14px;
        color: #FFFFFF;
        cursor: pointer;
        position: relative;
        z-index: 6;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          transition: all 0.5s ease;

          .festival-month-item-bar {
            @include BgImage(84px, 37px, 'header/nav/icon_festival-bg.svg');
            position: absolute;
            z-index: -1;
            left: 50%;
            transform: translateX(-50%);
          }

          .festival-month-txt {
            position: relative;
            z-index: 6;
            font-size: 14px;
            font-weight: bold;
            color: #424866;
          }
        }

      }
    }

    .festival-pics {
      display: flex;
      padding: 32px 34px 0;
      flex-wrap: wrap;

      .festival-pics-item {
        width: 202px;
        height: 124px;
        position: relative;
        margin: 0 27px 32px 0;
        border-radius: 4px;

        &:nth-child(3n) {
          margin-right: 0;
        }

        .img-box {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          overflow: hidden;

          img {
            //width: 100%;
            height: 100%;
            vertical-align: middle;
            transition: all 0.5s ease;
          }
        }

        &:hover {
          img {
            transform: scale(1.1);
          }
        }

        .icon_date {
          display: inline-block;
          width: 58px;
          line-height: 19px;
          text-align: center;
          //border-radius: 4px 4px 4px 0px;
          font-size: 12px;
          color: #0A2540;
          @include Position(1, 5, -3px, -8px);

          @include BgImage(58px, 21px, 'header/nav/icon_date.svg');
        }
      }
    }


    .icon_triangle {
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: #FFFFFF;
      @include Position(1, 5, 56px, -4px);
      transform: rotate(135deg);
    }
  }

  .d-combobox {
    display: inline-block;
    width: 523px;
    //height: 421px;
    background: #FFFFFF;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.06), 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
    @include Position(1, 5, -15px, 40px);
    border-radius: 12px;
    //padding: 15px 32px 34px 34px;
    box-sizing: border-box;
    text-align: left;
    animation: fadeinT 0.5s 1;

    width: 642px;
    height: 294px;
    background: #FFFFFF;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.06), 0px 0px 12px 0px rgba(0, 0, 0, 0.08);

    > div {
      display: inline-block;
      width: 100%;
      height: 100%;
      padding: 24px 32px 27px 34px;
      box-sizing: border-box;
    }

    &:after {
      content: '';
      width: 10px;
      height: 10px;
      background-color: #FFFFFF;
      @include Position(1, 5, 56px, -4px);
      transform: rotate(135deg);
    }

    .head-box {

      color: #0A2540;
      line-height: 40px;
      height: 40px;
      letter-spacing: 1px;
      font-size: 0;

      .title {
        font-size: 17px;
        font-weight: bold;
        color: #0A2540;
        line-height: 22px;
        letter-spacing: 1px;

      }

      .desc {
        font-size: 13px;
        color: #0A2540;
        line-height: 17px;
        margin-left: 12px;
      }

    }

    .seek-design {
      width: 160px;
      line-height: 38px;
      text-align: center;
      background: linear-gradient(44deg, #FE7E7E 0%, #F94D67 100%);
      box-shadow: 0px 2px 4px 0px rgba(10, 37, 64, 0.1);
      border-radius: 20px;
      @include Position(1, 2, 32px, 16px);
      font-size: 13px;
      font-weight: bold;
      color: #FFFFFF;
      letter-spacing: 1px;
      @include ElementHover('', linear-gradient(44deg, #F94D67 0%, #FE7E7E 100%))
    }

    .classify-wrap {
      @include Position(1,4,0,0);
      width: 278px;
      height: 230px;
      background: linear-gradient(360deg, #F5F6FA 0%, #FFFFFF 100%);
      border-radius: 2px 2px 2px 12px;
      padding: 9px 0 0 34px;
      box-sizing: border-box;

      .classify-list {
        .classify-item {
          display: flex;
          flex-wrap: wrap;
          a{
            width: 33.33%;
            font-size: 14px;
            color: #0A2540;
            line-height: 20px;
            margin-bottom: 10px;

            @include ElementHover($color-primary,'');
          }


          &:last-child {
            margin-bottom: 0;
          }

          &:first-child {
            .three-level {
              padding-top: 0;
            }
          }

          .three-level {
            width: 246px;
            display: inline-flex;
            flex-wrap: wrap;
            padding-top: 1px;
            text-align: center;

            a {
              display: inline-block;
              //width: 33.3%;
              //min-width: 56px;
              min-width: 72px;
              margin-right: 10px;
              margin-bottom: 8px;
              font-size: 14px;
              color: rgba(10, 37, 64, 0.8);
              line-height: 19px;
              @include ElementHover($color-primary, '');
              text-align: left;

              &:nth-child(3n) {
                margin-right: 0;
              }
            }
          }
        }

        .line{
          width: 220px;
          height: 1px;
          background: #ECEFF6;
          margin: 4px 0 14px;
        }
      }
    }

    .pics {
      width: 308px;
      height: 189px;
      @include Position(1, 3, 32px, 27px);

      .pic-item {
        display: inline-block;
        width: 91px;
        height: 118px;
        box-shadow: 0px 0px 4px 0px rgba(213, 221, 236, 0.4);
        border-radius: 8px;
        overflow: hidden;
        width: 146px;
        height: 189px;
        box-shadow: 0px 0px 4px 0px rgba(213, 221, 236, 0.4);
        border-radius: 8px;
        margin-right: 12px;
        position: relative;

        &:last-child {
          margin-right: 0;
        }


        img {
          display: inline-block;
          width: 100%;
          height: 100%;
          vertical-align: middle;
          transition: all 0.5s ease;
        }

        &:hover {
          img {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}

</style>
