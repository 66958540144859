/* 需求池接口Api */
import request from "@/utils/request";
import qs from "qs";

/* 需求列表 */
export function getDemandList(query) {
  return request({
    url: '/?m=VipDemand&a=index',
    method: 'get',
    params: query
  })
}

/* 需求滚动消息列表 */
export function getNoticeList(query) {
  return request({
    url: '/?m=VipDemand&a=ad',
    method: 'get',
    params: query
  })
}

/* 需求精品榜 */
export function getDemandListByRanking(query) {
  return request({
    url: '/?m=VipDemand&a=hot',
    method: 'get',
    params: query
  })
}

/* 需求详情 */
export function getDemandDetail(query) {
  return request({
    url: '/?m=VipDemand&a=detail',
    method: 'get',
    params: query
  })
}

/* 需求详情完成时间线 */
export function getDemandDetailTimeline(query) {
  return request({
    url: '/?m=VipDemand&a=timeLine',
    method: 'get',
    params: query
  })
}

/* 需求收藏 */
export function toggleDemandCollect(query) {
  return request({
    url: '/?m=VipDemand&a=collect',
    method: 'get',
    params: query
  })
}

/* 需求查询收藏状态 */
export function getDemandCollectStatus(query) {
  return request({
    url: '/?m=VipDemand&a=isCollected',
    method: 'get',
    params: query
  })
}


/* 需求提交表单那 */
export function submitDemandForm(data) {
  return request({
    url: '/?m=VipDemand&a=submit',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 又拍云获取签名 */
export function getUpyunSign(data) {
  return request({
    url: '/?m=Auxfun&a=generateUYSignature',
    method: 'post',
    data: qs.stringify(data)
  })
}



/* 需求个人信息 */
export function getDemandUserInfo(query) {
  return request({
    url: '/?m=VipDemand&a=oneSelf',
    method: 'get',
    params: query
  })
}

