<!--
  企业后台按钮组件
    1. 类型 蓝色和白色镂空按钮等
    2. 是否带icon ( 一种设置 slot，另一种传入iconClassName )
-->
<template>
  <div :class="['HtButton',type]"
       @click="click"
  >
    <slot name="iconClass"/>
    <i :class="['iconfont',iconClassName]"/>
    <span class="btn-txt">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: "HtButton",
  props: {
    /* icon字体名 */
    iconClassName: {
      type: String,
      default: '',
    },
    /* 按钮内文本 */
    text: {
      type: String,
      default: '按钮',
    },
    /* 按钮className */
    type: {
      type: String,
      default: '',
    }
  },
  methods:{
    click(){
      this.$emit('click');
      this.$emit('handleclick');
    }
  }
}
</script>

<style lang="scss" scoped>

/* 按钮基础样式 */
@mixin Button(
  $txtColor: #333333,               // 文本色
  $txtHoverColor:transparent,       // 文本hover色
  $bgColor: transparent,            // 按钮背景色
  $btnHoverBgColor:transparent,     // 按钮hover背景色
  $isBorder: false,                 // 是否有border
  $borderColor:transparent,         // border 颜色
  $borderHoverColor:transparent,    // borer hover颜色
) {
  /* 固定样式 */
  padding: 0 18px;
  box-sizing: border-box;
  line-height: 44px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  .iconfont {
    margin-right: 4px;
    font-size: 16px;
  }

  /* 动态样式 */
  color: $txtColor;
  background: $bgColor;
  @include ElementHover($txtHoverColor, $btnHoverBgColor);

  @if ($isBorder) {
    border: 1px solid $borderColor;
    @include ElementHover($txtHoverColor, $btnHoverBgColor) {
      border-color: $borderHoverColor;
    }
  ;
  } @else {

  }


}

.HtButton {
  @include Button();
}

/* 目前按钮类型 */
/* 蓝色镂空 hover浅蓝色 */
._blue-plain {
  @include Button(
      $color-company-server, #FFFFFF,
      #FFFFFF, $color-company-server,
      true, $color-company-server
  );
}

/* 蓝色 hover浅蓝色 */

._blue {
  @include Button(
      #FFFFFF, #FFFFFF,
      $color-company-server, #3B6BFF,
  );
}

/* 白色镂空 hover灰色 */
._white-plain {
  @include Button(
      #131519, #131519,
      #FFFFFF, #F2F3F7,
      true, #CBD1DE
  );
}

/* 红色 hover浅红色*/
._red {
  @include Button(
      #FFFFFF, #FFFFFF,
      #EE5253, #EF6364,
  );
}

._red-plain {
  @include Button(
      #EE5253, #EE5253,
      '', #E4E6EF,
  );
}


</style>
