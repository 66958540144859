import {mapState} from "vuex";
import {fnFavorites} from "@/fn";

let mixin = {
  computed: {
    ...mapState('favorites', [
      "showDialog",
      "showCreateDialog",
      "showUpdateDialog",
      "showCancelDialog",
      "showMoveDialog",
      "showCollectSuccessDialog",
      "showCreateErrDialog",
      "currentTupianInfo",
      "lastFavorite",
    ])
  },
}

export default mixin;
