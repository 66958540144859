<template>
  <div class="fans-layout">
    <FansHeader class="FansHeader" nav-color="_black"
                :class="{ 'ON':isShow}"
                ref="FansHeader"
    />
    <nuxt/>
    <no-ssr>
      <Login />
      <GlobalSlot/>
      <Footer/>
    </no-ssr>
  </div>
</template>

<script>
import {PublicHeader, Footer, Login,LoginHome} from "@/components/common/index.js"
import { InviteBanner } from "@/components/act/invite/index.js"
import FansHeader from '@/components/fans/header';
import GlobalSlot from '@/components/common/global-slot'


export default {
  name: 'LayoutsFansFixedHeader',
  components: {
    PublicHeader,
    Footer,
    Login,
    LoginHome,
    InviteBanner,
    FansHeader,
    GlobalSlot,
  },
  data() {
    return {
      isShow: false
    }
  },
  watch:{
    isShow(n){
      if (n == true){
        if (this.$refs.FansHeader) {
          this.$nextTick(() => {
            // this.headerHeight = this.$refs.FansHeader.offsetHeight
          })
        }
      }
    }
  },
  mounted() {
    this.handleScroll()
    window.addEventListener("scroll", this.handleScroll,true);
  },
  destroyed() {
  },

  methods: {

    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      this.isShow = scrollTop > 300;
    },

    topTop() {
      window.scrollTo(0, 0);
    },

  },
}
</script>

<style lang="scss" scoped>
.fans-layout{
  position: relative;
}
.FansHeader{
  @include Position(1,5,50%,-88px);
  transform: translateX(-50%);
  z-index: 99;
  box-shadow: 0px 2px 19px 0px rgba(0,17,144,0.2);
  margin-top: 0;
  width: 100%;
  top: 0;
  position: fixed;
  background-color: #fff;
}

</style>



