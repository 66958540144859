<!--
   图片封装 分为默认图片 元素 摄影图等等
    - 元素 不考虑横竖图，背景色黑灰 图片居中展示
    - 背景 摄影 插画 背景统一 横图上下展示 竖图图片80%居中展示

   - 跳过分类，直接设置样机类型( 封装函数判断设置图片展示类型 )
      - 默认 _prototype_default
      - 元素 _prototype_white _prototype_gray
      - 背景 摄影 插画  _prototype_vertical _prototype_horizontal
-->
<template>
  <div class="PicImgBox" ref="PicImgBox">
    <!--    {{ picItem._view_type }}-->
    <!-- 默认图片  -->
    <div class="_prototype_common _prototype_default"
         v-if="picItem._view_type === PIC_VIEW_TYPE.DEFAULT || picItem._view_type == undefined">
      <CustomImage
          v-if="(picItem._proto_pic == undefined || picItem._proto_pic == '' || picItem._proto_pic == BASE_IMG_URL ) && picItem.pictures "
          :src="BASE_IMG_URL + picItem.pictures[0].proto_pic + picSize"
          :alt="picItem.title"
          v-bind="$attrs"
          v-on="$listeners"
      />
      <CustomImage v-else
                   :src="picItem._proto_pic  + picSize"
                   :alt="picItem.title"
                   v-bind="$attrs"
                   v-on="$listeners"
      />
    </div>

    <div class="_prototype_common _prototype_black"
         :class="{'_prototype_elementScale80': elementPicIsScale80 == true}"
         v-if="picItem._view_type === PIC_VIEW_TYPE.BLACK">
      <CustomImage :src="picItem._proto_pic + picSize"
                   :alt="picItem.title"
                   v-bind="$attrs"
                   v-on="$listeners"
      />
    </div>

    <div class="_prototype_common _prototype_gray"
         :class="{'_prototype_elementScale80':  elementPicIsScale80 == true}"
         v-if="picItem._view_type === PIC_VIEW_TYPE.GRAY">
      <CustomImage :src="picItem._proto_pic + picSize"
                   :alt="picItem.title"
                   v-bind="$attrs"
                   v-on="$listeners"
      />
    </div>

    <div class="_prototype_common _prototype_vertical" v-if="picItem._view_type === PIC_VIEW_TYPE.VERTICAL">
      <CustomImage class="img1" :src="picItem._proto_pic + picSize"
                   :alt="picItem.title"
                   v-bind="$attrs"
                   v-on="$listeners"/>
      <CustomImage class="img2" :src="picItem._proto_pic + picSize"
                   :alt="picItem.title"
                   v-bind="$attrs"
                   v-on="$listeners"/>
    </div>

    <div class="_prototype_common _prototype_horizontal"
        :class="{'_prototype_horizontal_stretch': (picItem.image_width/picItem.image_height) < 0.6}"
         v-if="picItem._view_type === PIC_VIEW_TYPE.HORIZONTAL">
      <CustomImage :src="picItem._proto_pic + picSize"  :alt="picItem.title"
                   v-bind="$attrs"
                   v-on="$listeners"/>
    </div>

  </div>
</template>

<script>
import {PIC_VIEW_TYPE} from "@/constant/_Privately/Pic";
import {BASE_IMG_URL} from '@/constant/_Public/Path'
import {mapState} from "vuex";
import CustomImage from "@/components/_Public/CustomImage"

export default {
  name: "index",
  components: {
    CustomImage
  },

  props: {
    picItem: {},
    picSize: {
      type: String,
      default: '!/fw/452/quality/90/unsharp/true/compress/true'
    }
  },
  computed: {
    ...mapState('base', ['spider']),


    /*/!* 元素图片是否需要吗缩放80% *!/
    elementPicIsScale80() {
      const {image_width = 0, image_height = 0} = this.picItem;
      this.$nextTick(() => {
        if (this.$refs.PicImgBox) {
          console.log('--------11111')
          let imgboxWidth = this.$refs.PicImgBox.offsetHeight;
          console.log({
            imgboxWidth
          })
          console.log(image_height)
          let flag = image_height > imgboxWidth;
          console.log({flag})
          return flag
        }
      })
    }*/

  },

  watch: {
    picItem: {
      handler(n, o) {
        const {image_width = 0, image_height = 0} = this.picItem;
        this.$nextTick(() => {
          if (this.$refs.PicImgBox) {
            let imgboxWidth = this.$refs.PicImgBox.offsetHeight;
            this.elementPicIsScale80 = image_height > imgboxWidth;
          }
        })
      },
      immediate: true,
      deep: true,
    }
  },
  data() {
    return {
      PIC_VIEW_TYPE,
      BASE_IMG_URL,
      elementPicIsScale80: false,
    }
  },
  mounted() {
    console.log(this.elementPicIsScale80)
  }
}
</script>

<style lang="scss" scoped>
.PicImgBox {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 12px;
  overflow: hidden;

  img {
    transition: all 0.5s ease-in-out;
  }

  &:hover {

    img {
      transform: scale(1.1);
    }
  }

  ._prototype_common {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease-in-out;

    &:hover {
      //transform: scale(1.1);
    }
  }

  ._prototype_default {
    img {
      width: 100%;
    }
  }


  ._prototype_vertical {
    display: flex;
    flex-direction: column;
    @include BgImage(100%, 100%, 'test/3.jpg');

    .img1 {
      display: inline-block;
      padding: 30px 20px 0;
      width: 100%;
      height: auto !important;
      box-sizing: border-box;
    }

    .img2 {
      width: 100%;
      height: auto !important;
      margin-top: 30px;
    }

  }

  ._prototype_black {
    @include BgImage(100%, 100%, 'test/1.jpg');
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
    }
  }

  ._prototype_gray {
    @include BgImage(100%, 100%, 'test/2.jpg');
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
    }
  }

  ._prototype_elementScale80 {
    img {
      width: 80% !important;
    }

    /deep/ .CustomImage{
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 80% !important;
        height: auto !important;
      }
    }
  }

  ._prototype_horizontal {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include BgImage(100%, 100%, 'test/3.jpg');

    img {
      width: 80%;
    }

    /deep/ .CustomImage{
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 80% !important;
        height: auto !important;
      }
    }

    &._prototype_horizontal_stretch{
      /deep/ .CustomImage{
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: auto !important;
          height: 88% !important;
        }
      }
    }


  }

}

</style>
