<template>
  <div class="NavItemHot menu-item"
       :class="[ {'on': NAV.HOT.id == navIndex} ]"
       @mouseover="toggleHeaderAllDropDown(1)"
       @mouseleave="toggleHeaderAllDropDown(false)"
  >
    <a
      :href="tupianLink "
      target="_blank"
      @click="listenStatistics;updateNavId(NAV.HOT.id);trackingNavDrop(1,0,'show')"
      @mouseover="toggleHeaderAllDropDown(1,0);trackingNavDrop(1,0,'show')"
    >
      <i class="iconfont icon-a-14quanbu"/>
      全部
      <i
        v-for="(vo,idx) in HEADER.NAV.HOT.icons"
        :key="idx"
        class="icon_NavItemHot"
        :class="vo.type"
        :style="{background:'url('+ PAGE_PATH.BASE_IMG_UPLOAD + vo.url+') no-repeat center/100% '}"
      />
    </a>
    <!-- 下拉   v-show="isShow" -->
    <no-ssr>
      <div v-show="isShow"
           class="dropbox">
        <HeaderAllDropDown ref="HeaderAllDropDown"/>
      </div>
    </no-ssr>
  </div>
</template>

<script>
import {getDropdownByHot} from "@/api/nav";
import {IMG_BASE_URL, MATERIAL_LIST} from "@/constant/PATH";
import {topbarMenuList} from "@/components/common/header/module";
import {TrackingNav} from "@/tracking";
import {MATERIALS, PIC_TOTAL_NUM} from "@/constant/MATERIALS";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import {fnLocalStorage, fnMaterial, fnRouter, fnTopic} from "@/fn";
import {getTopicListByHot} from "@/api/topic";
import {getCookie} from "~/utils/auth";
import headerNavMixin from "~/components/common/header/header-nav/common/header-nav-mixin";
import {TOPIC} from "@/constant/tupian/TOPIC";
import HeaderAllDropDown from "@/components/_Public/Header/HeaderAllDropDown";
import _ from 'lodash'

export default {
  name: "NavItemHot",
  filters: {
    repairZero(val) {
      return val < 10 ? ('0' + Number(val)) : val;
    }
  },
  mixins: [headerNavMixin],
  components: {
    HeaderAllDropDown
  },
  data() {
    return {
      IMG_BASE_URL,
      // hotData: {},
      isShow: false,
      // picList: [],
      // festivalList: [],
      // solarTermsList: [],
      sceneList: [],
      // recommendList: [],
      topicList: [],
      topbarMenuList,
      MATERIALS,
      PAGE_PATH,
      PIC_TOTAL_NUM,
      materialNum: 0
    }
  },
  props: {
    picList: [],
    festivalList: [],
    solarTermsList: [],
    recommendList: [],
  },
  computed: {
    /* 全部热点 */
    tupianLink() {
      return fnRouter.getRouterLink(PAGE_PATH.TU_PIAN.LIST);
    },
    /* vip介绍页 */
    vipIntroLink() {
      return fnRouter.getRouterLink(PAGE_PATH.INTRODUCE.VIP);
    },
  },

  watch: {
    isShow: {
      handler(n, o) {
        if (n == true) {
          // this.hotMouseOver();
        }
      },
      immediate: true
    },
  },
  created() {
    /*Bus.$on("navMouseover", (val) => {    //获取 Bus.$on
      this.toggleHeaderAllDropDown(true, val)
    });

    Bus.$on("navMouseleave", (val) => {    //获取 Bus.$on
      this.toggleHeaderAllDropDown(false)
    });*/
  },
  mounted() {
    // this.init();
    this.initMaterialNum();
  },
  async fetch() {
    /*console.log('HOT menu')

    const {data} = await getDropdownByHot();
    let {pic, marketing_festival, twenty_festival, content} = data.data;
    this.picList = this.sliceList(pic)
    this.festivalList = this.sliceList(marketing_festival)
    this.solarTermsList = this.sliceList(twenty_festival)
    this.recommendList = this.averageRecommendList(content)*/
  },
  methods: {

    toggleHeaderAllDropDown(flag = true, val) {
      if (flag) {
        setTimeout(()=>{
          this.isShow = true;
          if (val >-1){
            this.$refs.HeaderAllDropDown.menuIndex = val
          }
        },0)
      } else {
        this.isShow = false;
      }
    },


    async initMaterialNum() {
      this.materialNum = await fnMaterial.getMaterialNum() + '+';
    },

    listenStatistics() {
      let findItem = this.topbarMenuList.find(vo => vo.id === 0)
      this.$emit('listenStatistics', findItem.id)
    },
    navigateToList() {
      return
      let findItem = this.topbarMenuList.find(vo => vo.id === 0)
      this.$emit('navigateToList', findItem)
    },
    init() {
    },
    trackingNavDrop: _.throttle(function (act, pos, e) {
      TrackingNav.navDrop(act, pos, e)
    },3000),

    navigateTo(path) {
      fnRouter.navigationTo(path)
    },

    hotMouseOver() {
      console.log(this.recommendList)
      /* 合并picList 和 热搜专题数组，过滤非专题项，并遍历通过link_url取出专题id */
      let picList = this.picList;
      let recommendTopicList = this.recommendList.slice(-1) || []; // 热搜专题
      let tempTopicList = picList.concat(recommendTopicList[0])

      let filterTopicList = tempTopicList.filter(item => {
        return item.link_url.indexOf('/collection/') > -1;
      })
      let ids = [];
      if (filterTopicList.length) {
        filterTopicList.forEach(vo => {
          let linkUrlArr = vo.link_url.split('/');
          let linkUrlArrLastEle = linkUrlArr.slice(-1);
          let topicId = parseInt(linkUrlArrLastEle)
          ids.push(topicId)
        })
      }
      this.topicPositionStat(ids);
    },

    /* 首页专题位置统计 */
    topicPositionStat(ids) {
      fnTopic.positionStat(
        ids.toString(),
        TOPIC.POSITION_STAT.EVENT.EXPOSE,
        TOPIC.POSITION_STAT.POSITION.HOT_DROP_RECOMMEND,
      )
    },

    parseTopicLinkUrl(vo) {
      let linkUrlArr = vo.link_url.split('/');
      let linkUrlArrLastEle = linkUrlArr.slice(-1);
      return parseInt(linkUrlArrLastEle)
    },

    picItemTopicClick(vo) {

      if (vo.link_url.indexOf('/collection/') > -1) {
        let topicId = this.parseTopicLinkUrl(vo)

        fnTopic.positionStat(
          topicId,
          TOPIC.POSITION_STAT.EVENT.CLICK,
          TOPIC.POSITION_STAT.POSITION.HOT_DROP_RECOMMEND,
        )
      }
    },

    topicMouseover(vo) {
      if (vo.link_url && vo.link_url.indexOf('/collection/') > -1) {
        let topicId = this.parseTopicLinkUrl(vo)

        fnTopic.positionStatMouseOver(
          topicId, TOPIC.POSITION_STAT.POSITION.HOT_DROP_RECOMMEND,
        )
      }


    },
    topicMouseleave() {
      fnTopic.closePositionStatMouseLeave();
    }

  },
}
</script>

<style lang="scss" scoped>
@import "../common/nav";
@import "../common/icon";

.NavItemHot {
  position: relative;
  z-index: 99;

  > a {
    position: relative;

    .icon_NavItemHot {
      @include Position(1, 2, -2px, -10px);

      &.icon_kxj {
        @include BgImage(50px, 16px, 'header/nav/icons/icon_kxj.gif?v=1');
      }
    }
  }

  .dropbox {
    @include Position(1, 5, -38px, 47px);
  }
}
</style>
