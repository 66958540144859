<template>
  <div class="loading">
    <i class="icon_loading"/>
    正在加载中......
  </div>
</template>

<script>
export default {
  name: "Loading"
}
</script>

<style lang="scss" scoped>
.loading{
  height: 500px;
  @include LayoutFlex(2);
  color: #596088;

  .icon_loading{
    @include BgImage(68px,68px,'public/loading-qq.gif');
    margin-bottom: 10px;
  }
}
</style>
