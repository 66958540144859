import Notification from '@/components/common/notification/instance.js'

const myNotify = {
  /**
   * 全局消息提示
   * @param c
   * @param fn Function 回调函数
   * @param d
   */
  show(c, fn, d = 1500) {
    Notification({
      content: c || '提示',
      autoClose: d,
      type: 0
    })

    if (typeof fn === "function") {
      setTimeout(() => {
        fn()
      }, d)
    }
  },


  success(c, fn, d = 1500) {
    Notification({
      content: c || '成功了',
      autoClose: d,
      type: 1
    })

    if (typeof fn === "function") {
      setTimeout(() => {
        fn()
      }, d)
    }
  },
  error(c, fn, d = 1500) {
    Notification({
      content: c || '失败了',
      autoClose: d,
      type: 2
    })

    if (typeof fn === "function") {
      setTimeout(() => {
        fn()
      }, d)
    }
  },

  warning(c, fn, d = 1500) {
    Notification({
      content: c || '警告',
      autoClose: d,
      type: 3
    })

    if (typeof fn === "function") {
      setTimeout(() => {
        fn()
      }, d)
    }
  },


}

export default myNotify;
