import Cookies from 'js-cookie'
import myNotify from "@/utils/notify";
import myNotifyIphone from "@/utils/notify-h5";
import {getCode, loginByCode, loginOut, getUserInfo} from '~/api/login'
import {setCustomCookie} from '@/utils/auth.js'
import {getMaterialFreeDownloadNum} from "~/api/material";

import {fnLogin, fnInviteFriend, fnUserSurvey, fnBindIphone, fnUser, fnCoupon} from '~/fn'

import {DOWNLOAD_COUNT, downloadCount} from "~/constant/USER";
import localStorage from "@/utils/localStorage";
import {MODEL_LOGIN_PV_COUNT} from "@/constant/MODELS";
import {VIP_CONFIG, VIP_STATE, vipType} from "@/constant/VIP";
import {getCompanyInfoByPerson} from "@/api/company/home";
import {getCouponList} from "@/api/pay";
import {getAccountIsVip} from "@/api/user";

export default {
  namespaced: true,
  state() {
    return {
      name: Cookies.get('name') || "测试用户名",
      uid: Cookies.get('uid') || 0,
      avatar: "https://js.chaopx.com/chaotu/user/chaopx_avatar_new.png",
      isShowLogin: false, // 是否登录,控制 登录弹窗
      isShowLoginHome: false, // 是否登录,控制 登录弹窗
      // isShowLoginCloseBtn: Cookies.get('isShowLoginCloseBtn') || true,
      isShowLoginCloseBtn: true,
      phone: Cookies.get('phone') || '',
      /* Cookie 跨页面更新 */
      isLogin: Cookies.get('isLogin') || false,
      loginWay: -1,  // 登录方式 0手机号码 1微信登录 2 QQ
      uniq_id: Cookies.get('user_uniq_id') || '',

      isShowLoginByIphone: false,  // 控制移动端登录弹框
      isHasUserCoupon: false, // 是否拥有新人优惠券
      isSwitchAccountType: false,  // 是否点击切换账户类型
      isSwitchCompanyMain: false,  // 是否点击切换企业主体账户


      path: '',
      isNewUser: false, // 是否新用户
      downloadCount: Cookies.get('downloadCount') || DOWNLOAD_COUNT,

      personVipState: Cookies.get(VIP_CONFIG.PERSON_VIP_STATE) || VIP_STATE.NO,      // 个人vip状态 -1非会员  0过期会员 1使用会员
      personVipInfo: Cookies.get(VIP_CONFIG.PERSON_VIP_STATE) || {},  // 个人vip信息
      businessVipState: Cookies.get(VIP_CONFIG.BUSINESS_VIP_STATE) || VIP_STATE.NO,  // 商用vip状态 -1非会员  0过期会员 1使用会员
      businessVipInfo: Cookies.get(VIP_CONFIG.BUSINESS_VIP_INFO) || {},// 商用vip信息
      companyVipState: Cookies.get(VIP_CONFIG.COMPANY_VIP_STATE) || VIP_STATE.NO,     // 企业vip状态 -1非会员  0过期会员 1使用会员
      companyVipInfo: Cookies.get(VIP_CONFIG.COMPANY_VIP_INFO) || {}, // 企业vip信息
      userInfo: Cookies.get('userInfo') || {},
      companyInfoByPerson: Cookies.get('companyInfoByPerson') || {},
      companyAccountStatus: 0,


      /* 该模块已弃用，后逐渐迁移 start */
      vipIsPerson: Cookies.get('vipIsPerson') || 0,  // 0非会员 / 1会员
      vipIsPersonData: Cookies.get('vipIsPersonData') || {},
      vipIsBusiness: Cookies.get('vipIsBusiness') || 0, // 0非会员 / 1会员
      vipIsBusinessData: Cookies.get('vipIsBusinessData') || {},
      /* 该模块已弃用，后逐渐迁移 end */

      isShowBindIphone: false,
      isShowBindIphoneModal: false,

      /* 会员穿透会员信息 */
      userVipInfo: Cookies.get('userVipInfo') || {
        business_vip_id: 0,
        company_vip_id: 0,
        personal_vip_id: 0
      },

    }
  },
  getters: {
    isShowLoginCloseBtn: (state) => {
      return state.isShowLoginCloseBtn;
    },
    downloadCount: (state) => {
      return state.downloadCount;
    },
    name: (state) => {
      return state.name;
    },
    uid: (state) => {
      return state.uid;
    },
    avatar: (state) => {
      return state.avatar;
    },
    isLogin: (state) => {
      return state.isLogin;
    },
    isShowLogin: (state) => {
      return state.isShowLogin;
    },
    isShowLoginHome: (state) => {
      return state.isShowLoginHome;
    },
    uniq_id: (state) => {
      return state.uniq_id;
    },
    phone: (state) => {
      return state.phone;
    },
    vipIsPerson: (state) => {
      return state.vipIsPerson;
    },
    vipIsBusiness: (state) => {
      return state.vipIsBusiness;
    },
    vipIsPersonData: (state) => {
      return state.vipIsPersonData;
    },
    vipIsBusinessData: (state) => {
      return state.vipIsBusinessData;
    },

  },
  mutations: {
    SET_IS_SHOW_BIND_IPHONE: (state, payload) => {
      state.isShowBindIphone = payload
    },
    SET_IS_SHOW_BIND_IPHONE_MODAL: (state, payload) => {
      state.isShowBindIphoneModal = payload
    },

    SET_SWITCH_ACCOUNT_TYPE: (state, payload) => {
      state.isSwitchAccountType = payload
    },
    SET_SWITCH_COMPANY_MAIN: (state, payload) => {
      state.isSwitchCompanyMain = payload
    },
    SET_IS_HAS_USER_COUPON: (state, payload) => {
      state.isHasUserCoupon = payload;
      setCustomCookie('isHasUserCoupon', payload);
    },
    SET_USER_INFO: (state, payload) => {
      state.userInfo = payload;
      setCustomCookie('userInfo', JSON.stringify(payload));
    },
    SET_PATH: (state, payload) => {
      state.path = payload
    },
    SET_DOWNLOAD_COUNT: (state, payload) => {
      state.downloadCount = payload;
      setCustomCookie('downloadCount', JSON.stringify(payload));
    },
    SET_IS_NEW_USER: (state, payload) => {
      state.isNewUser = payload
    },
    SET_IS_NEW_USER_SIGN: (state, payload) => {
      state.isNewUserSign = payload
    },
    SET_NAME: (state, payload) => {
      state.name = payload;
      setCustomCookie('name', payload);
    },
    SET_UID: (state, payload) => {
      state.uid = payload;
      setCustomCookie('uid', payload);
    },
    SET_LOGIN: (state, payload) => {
      state.isLogin = payload;
      setCustomCookie('isLogin', payload);

    },
    SET_LOGIN_WAY: (state, payload) => {
      state.loginWay = payload;
    },
    SET_IS_SHOW_LOGIN: (state, payload) => {
      state.isShowLogin = payload;
    },
    SET_IS_SHOW_LOGIN_HOME: (state, payload) => {
      state.isShowLoginHome = payload;
      var data = sessionStorage.getItem('joinTime');
      if (data != undefined && payload == false) {
        let date = new Date();
        sessionStorage.setItem('joinTime', date.getTime());
      }
    },
    SET_IS_SHOW_LOGIN_CLOSE_BTN: (state, payload) => {
      state.isShowLoginCloseBtn = payload;
      setCustomCookie('isShowLoginCloseBtn', payload);
    },
    SET_UNIQ_ID: (state, payload) => {
      state.uniq_id = payload;
    },
    SET_PHONE: (state, payload) => {
      state.phone = payload;
      setCustomCookie('phone', payload);
    },
    SET_vipIsPerson: (state, payload) => {
      state.vipIsPerson = payload;
      setCustomCookie('vipIsPerson', payload);
    },
    SET_vipIsBusiness: (state, payload) => {
      state.vipIsBusiness = payload;
      setCustomCookie('vipIsBusiness', payload);
    },
    SET_vipIsPersonData: (state, payload) => {
      state.vipIsPersonData = payload;
      setCustomCookie('vipIsPersonData', JSON.stringify(payload));
    },
    SET_vipIsBusinessData: (state, payload) => {
      state.vipIsBusinessData = payload;
      setCustomCookie('vipIsBusinessData', JSON.stringify(payload));
    },
    SET_VIP(state, payload) {

      console.log({
        payload
      })

      let {TYPE, VIP_INFO = {}, _VIP_STATE = VIP_STATE.NO} = payload;
      let _vipType = [VIP_CONFIG.PERSON_VIP_STATE, VIP_CONFIG.BUSINESS_VIP_STATE, VIP_CONFIG.COMPANY_VIP_STATE];
      let _vipData = [VIP_CONFIG.PERSON_VIP_INFO, VIP_CONFIG.BUSINESS_VIP_INFO, VIP_CONFIG.COMPANY_VIP_INFO];
      // let index = TYPE === vipType.person ? 0 : vipType.business ? 1 : 2;
      let index;

      if (TYPE === vipType.person) {
        index = 0
      } else if (TYPE === vipType.business) {
        index = 1
      } else {
        index = 2
      }
      state[_vipType[index]] = _VIP_STATE;
      state[_vipData[index]] = VIP_INFO;
      setCustomCookie(_vipType[index], _VIP_STATE);
      setCustomCookie(_vipData[index], JSON.stringify(VIP_INFO));

    },

    SET_IS_SHOW_LOGIN_BY_IPHONE(state, payload) {
      state.isShowLoginByIphone = payload
    },

    SET_COMPANY_INFO_BY_PERSON(state, payload) {
      state.companyInfoByPerson = payload;
      /* 是否是可下载的企业状态 */
      let status = 0
      if (payload.is_company_member == 1) {
        if (payload.switch && payload.switch.type == 1) {
          status = 1
        }
      }
      state.companyAccountStatus = status;
      setCustomCookie('companyAccountStatus', status);
      setCustomCookie('companyInfoByPerson', JSON.stringify(payload));
    },
    SET_USER_VIP_INFO(state, payload) {
      state.userVipInfo = payload;
      setCustomCookie('userVipInfo', JSON.stringify(payload));
    }
  },
  actions: {
    /* 获取验证码 */
    GetVerificationCode({}, params) {
      return new Promise((resolve, reject) => {
        getCode(params).then(res => {
          let response = res.data;
          let msg = response.status === 1 ? '发送成功' : `${response.info}`;

          if (response.status === 1) {
            myNotify.success(msg);
          } else {
            // myNotify.error(msg);
          }
          resolve(res)
        }).catch(error => {
          myNotify.error(`发送失败，请稍后重试`);
          reject(error);
        })
      })
    },

    /* 验证码登录 */
    LoginByVerificationCode({dispatch, commit, state}, userInfo) {
      let {phone, code} = userInfo
      return new Promise((resolve, reject) => {
        loginByCode({phone, code}).then(res => {
          let response = res.data;
          if (response.status === 1) {
            myNotify.success('登录成功', () => {
              dispatch('LoginSuccessHandle')
            });
          } else if (response.status === 2) {
            myNotify.show(`账户已登录`, () => {
              window.location.href = state.path;
            }, 2500);
          } else {
            // myNotify.error(`${response.msg}`);
          }
          resolve(res)
        }).catch(error => {
          myNotify.error(`登录失败，请稍后重试`);
          reject(error)
        })
      })
    },

    /* 登录成功操作 */
    LoginSuccessHandle({dispatch, commit, state, rootGetters}) {
      /* 更新部分信息 */
      commit('SET_LOGIN', true);
      commit('SET_IS_SHOW_LOGIN', false);
      commit('SET_IS_SHOW_LOGIN_HOME', false);
      const tem_user_uniq_id = Cookies.get('user_uniq_id').replace(/"/g, '')
      commit('SET_UNIQ_ID', tem_user_uniq_id);
      /* 更新用户信息 */
      dispatch('getUserInfo');
      dispatch('getCompanyInfoByPerson');
      dispatch('getMaterialFreeDownloadNum');
      dispatch('getCouponList');
      // dispatch('GetUserVipInfo')
      fnInviteFriend.checkIsNewUser();
      fnLogin.checkUserAgreeUpdateProtocol($nuxt.$store);

      setTimeout(() => {
        commit('home/SET_IS_SHOW_GUIDE_DOWNLOAD_COUNT', true, {root: true})
        fnLogin.listenNewUserFreeDownGuideModal();
      }, 600)
    },

    /* 获取用户真实VIP信息( 原始用户信息有会员隔离 ) */
    async getUpdateVipAllStatus({commit, state}){
      return new Promise((resolve, reject) => {
        getAccountIsVip().then(r => {
          let {data, status} = r.data;
          if (status == 200) {
            console.log(data)
            commit('SET_USER_VIP_INFO', data);
            resolve(r);
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    /* 获取用户信息 */
    async getUserInfo({dispatch, commit, state, rootGetters}) {
      return new Promise((resolve, reject) => {
        let user_uniq_id = Cookies.get('user_uniq_id')
        getUserInfo({user_uniq_id}).then(r => {
          let {data, code} = r.data;
          if (code == 200) {
            console.log(data)
            commit('SET_PHONE', data.phone);
            commit('SET_USER_INFO', {
              create_time: data.create_time,
              username: data.username,
              uniq_id: data.uniq_id,
              phone: data.phone,
              is_inviter: data.is_inviter || -1,
              is_company_member: data.is_company_member,
            });
            fnUser.disposeVip(data,commit);
            fnUser.saveVipInfo(data,commit);
            dispatch('getUpdateVipAllStatus');
            // fnBindIphone.checkIsBind()
            resolve(r);
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    /* 获取用户企业信息 */
    async getCompanyInfoByPerson({commit, state}) {
      return new Promise((resolve, reject) => {
        getCompanyInfoByPerson().then(r => {
          console.log('--getCompanyInfoByPerson--')
          let {data, status} = r.data;
          if (status == 200) {
            commit('SET_COMPANY_INFO_BY_PERSON', data)
            resolve(r);
          }
        }).catch(error => {
          reject(error)
        })
      })
    },


    /* 获取用户红包信息 */
    getCouponList({commit, state}) {
      return new Promise((resolve, reject) => {
        getCouponList().then(res => {
          let {get_new_user_coupon, status, data} = res.data;
          if (status == 1) {
            fnCoupon.saveNewUserCoupon(data, this.$store)
            resolve(res);
          }
        }).catch(error => {
          reject(error)
        })
      })
    },


    /* 获取用户下载次数 */
    getMaterialFreeDownloadNum({dispatch, commit, state}) {
      return new Promise((resolve, reject) => {
        getMaterialFreeDownloadNum().then(response => {
          let {status} = response.data;
          if (status === 1) {
            fnUser.disposeDownload(response.data)
          }
        })
      })
    },

    /* 退出登录 */
    loginOut({commit}) {
      return new Promise((resolve, reject) => {
        loginOut().then(res => {
          /*myNotify.success("退出成功", () => {

          })*/

          commit('SET_LOGIN', false);
          commit('SET_PHONE', '');
          commit('SET_UNIQ_ID', '');
          fnLogin.clearCookies($nuxt.$store);
          localStorage.setItemDate(MODEL_LOGIN_PV_COUNT, 1)

          commit('SET_VIP', {
            TYPE: vipType.person,
            _VIP_STATE: 0,
            VIP_INFO: {}
          });
          commit('SET_VIP', {
            TYPE: vipType.business,
            _VIP_STATE: 0,
            VIP_INFO: {}
          });
          commit('SET_VIP', {
            TYPE: vipType.company,
            _VIP_STATE: 0,
            VIP_INFO: {}
          });

          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },


    /* 移动端 */
    /* 获取验证码 */
    getCodeByIphone({}, phone) {
      return new Promise((resolve, reject) => {
        getCode({phone}).then(res => {
          let response = res.data;
          let msg = response.status === 1 ? '发送成功' : `${response.info}`;

          if (response.status === 1) {
            myNotifyIphone.success(msg);
          } else {
            myNotifyIphone.error(msg);
          }
          resolve(res)
        }).catch(error => {
          myNotifyIphone.error(`发送失败，请稍后重试`);
          reject(error);
        })
      })
    },

    /* 验证码登录 */
    LoginByVerificationCodeByIphone({dispatch, commit, state}, userInfo) {
      let {phone, code} = userInfo
      return new Promise((resolve, reject) => {
        loginByCode({phone, code}).then(res => {
          let response = res.data;
          if (response.status === 1) {
            myNotifyIphone.success('登录成功', () => {
              dispatch('LoginSuccessHandle')
            });
          } else if (response.status === 2) {
            myNotifyIphone.show(`账户已登录`, () => {
              window.location.href = state.path;
            }, 2500);
          } else {
            // myNotify.error(`${response.msg}`);
          }
          resolve(res)
        }).catch(error => {
          myNotifyIphone.error(`登录失败，请稍后重试`);
          reject(error)
        })
      })
    },

    /* 获取用户真实VIP信息( 原始用户信息有会员隔离 ) */
    GetUserVipInfo({dispatch, commit, state}) {
      return new Promise((resolve, reject) => {

        getAccountIsVip().then(res => {
          console.log(res)
          let { data,message,status } = res.data;
          if (status == 200){
            console.log(data)
            commit('SET_USER_VIP_INFO', data)
          }
          resolve(res)

        }).catch(error => {
          reject(error)
        })

      })
    }
  }
}

