var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowCombobox),expression:"isShowCombobox"}],staticClass:"search-combobox"},[(_vm.historyList.length)?_c('div',{staticClass:"search-box search-history"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"title"},[_vm._v("历史搜索")]),_vm._v(" "),(_vm.historyList.length)?_c('i',{staticClass:"iconfont icon-trash _pointer",on:{"mousedown":_vm.clearSearchHistory}}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"content"},_vm._l((_vm.historyList.slice(0,12)),function(vo){return _c('div',{key:vo,staticClass:"keyword-item _pointer",on:{"mousedown":function($event){return _vm.selectSearchKeyword(vo)}}},[_vm._v("\n        "+_vm._s(vo)+"\n      ")])}),0)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"search-box search-recommend"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"content"},_vm._l((_vm.recommendList),function(vo,index){return _c('div',{key:index,staticClass:"recommend-item",on:{"click":function($event){return _vm.listenHotWordClick(vo)}}},[_c('span',{class:[ 'index', { 'index-first': index === 0 },
                    { 'index-second': index === 1 },
                    { 'index-third': index === 2 },
        ]},[_vm._v(_vm._s(index + 1))]),_vm._v(" "),_c('span',{staticClass:"keyword",style:({ color: vo.font_color })},[_vm._v(_vm._s(vo.keyword))]),_vm._v(" "),_c('span',{class:[ 'tag', { 'tag-third': vo.mark == 1 },
                    { 'tag-second': vo.mark == 2 },
                    { 'tag-first': vo.mark == 3 },
        ]},[_vm._v(_vm._s(vo.mark == 1 ? '新' : vo.mark == 2 ? "火" : vo.mark == 3 ? "爆" : ''))])])}),0)]),_vm._v(" "),_c('div',{staticClass:"search-box search-recommend search-recommend2"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"pic-list"},[_vm._l((_vm.recommendList2),function(vo,index){return _c('div',{key:index,staticClass:"pic-item",on:{"click":function($event){return _vm.listenHotWordClick(vo)}}},[_c('img',{staticClass:"hot-pic-item-img",attrs:{"src":_vm.IMG_BASE_URL + '/' + vo.keyword}})])}),_vm._v(" "),_vm._m(2)],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"title"},[_vm._v("热搜推荐")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"title"},[_vm._v("精选推荐")]),_vm._v(" "),_c('a',{staticClass:"more MORE",attrs:{"href":"https://chaopx.com/topic/0-1-0-new.html","target":"_blank"}},[_vm._v("\n        查看更多\n        "),_c('i',{staticClass:"iconfont icon-a-arrowright"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"pic-item pic-item-more",attrs:{"href":"https://chaopx.com/topic/0-1-0-new.html","target":"_blank"}},[_vm._v("\n        查看更多 "),_c('i',{staticClass:"iconfont icon-a-arrowright"})])
}]

export { render, staticRenderFns }