<template>
  <div>
    <div class="topbar">
      <div class="topbar-inner inner_center">
        <div class="topbar-left">
          <!--                      v-bind="$attrs"
          -->
          <HeaderLogo
            :logoWebsitePosition="logoWebsitePosition"
            :iconType="iconType"
            :showCollect="showCollect"
            :logoColor="logoColor"
            v-on="$listeners"
          />
          <HeaderNav
            :d="d"
            :navType="navType"
            :picList="picList"
            :festivalList="festivalList"
            :solarTermsList="solarTermsList"
            :recommendList="recommendList"
            :threeDCategoryList="threeDCategoryList"
            :threeDRecommendList="threeDRecommendList"
            :festivalData="festivalData"
            :designStyle="designStyle"
            :styleCategoryList="styleCategoryList"
            :styleRecommendList="styleRecommendList"
            :isFixedHeader="isFixedHeader"
          />
        </div>
        <div class="topbar-right">
          <HeaderSearch v-if="isShowSearch"/>
          <HeaderSubmitPic @navigatorTo="navigatorTo"/>
          <HeaderVipPerson
            @navigatorTo="navigatorTo"
            @tag="tag"
          />
          <HeaderCompanyLicense
            :isFixedHeader="isFixedHeader"
          />
          <HeaderCompanyCase v-if="isShowCompanyCase"/>
          <HeaderLogin/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import indexExtend from "./indexExtend";
import {tag} from "@/utils/statUtil";
import {mapGetters} from "vuex"
import {
  MATERIAL_LIST_CUSTOM,
  MATERIAL_LIST,
  USER_CENTER, PIC_PACKAGE,
} from "@/constant/PATH"
import {MARKETING_CALENDAR, TIMELINE_UPDATE} from "@/constant/PATH";
import {fnRouter} from "@/fn";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import ComponentMixin from "@/components/common/header/common/componentMixin";
import {COMMON} from "@/constant/COMMON";
import HeaderCompanyCase from './HeaderCompanyCase'

export default {
  // extends: indexExtend,
  name: "PublicHeader",
  mixins: [indexExtend, ComponentMixin],
  props: {

    logoWebsitePosition: {
      type: String,
      default: 'blue'
    },
    iconType: {
      type: String,
      default: 'blue'
    },
    showCollect: {
      type: Boolean,
      default: true
    },
    logoColor: '',

    params: {
      type: Object,
      default: () => {
      }
    },
    // eslint-disable-next-line vue/require-default-prop
    isShowIconClose: {
      type: Boolean,
      default: false
    },
    /*isShowCollectWebsite:{
      type: Boolean,
      default: false
    },*/
    isShowLogo: {
      type: Boolean,
      default: false
    },
    showHeaderCompanyCase:{
      type: Boolean,
      default: true
    },

    isFixedHeader:{
      type: Boolean,
      default: false
    },
    isShowCompanyCase:{
      type: Boolean,
      default: false
    },

    showNewTipButton: false,
    isShowSearch: false,
    navType: {
      type: String,
      default: COMMON.HEADER.NAV.TYPE.OTHER
    },
    picList: [],
    festivalList: [],
    solarTermsList: [],
    recommendList: [],
    threeDCategoryList: [],
    threeDRecommendList: [],
    festivalData: {},
    designStyle: {},
    styleCategoryList: [],
    styleRecommendList: []

  },
  components: {HeaderCompanyCase},
  computed: {
    ...mapGetters('user', ['isLogin', 'avatar']),
    searchVal() {
      return this.$store.state.base.searchVal
    },
  },

  watch: {
    searchVal: {
      handler(n, o) {
        if (n || n == true || n == false) {
          this.searchKeyword = n
        }
      },


    },
    isLogin(n, o) {
      if (n == true) {
        this.isShowCollectWebsite = true;
        setTimeout(() => {
          this.isShowCollectWebsite = false;
        }, 5000)
        // this.getCouponList();
        /* 更新用户是否购买图片包 */
      } else {
        // this.showNewTipButton = false
      }
    },
  },

  // eslint-disable-next-line vue/order-in-components
  data() {

    return {
      menuNavIndex: -1,  // 菜单导航默认选中
      calendarMenuIndex: 1,
      searchKeyword: "",  // 搜索词
      historyList: [],
      MATERIAL_LIST_CUSTOM,
      MATERIAL_LIST,
      USER_CENTER,
      TIMELINE_UPDATE,
      MARKETING_CALENDAR,
      PIC_PACKAGE,
      isShowCollectWebsite: false,
      d: 0,
      PAGE_PATH,
      msgList: [
        {
          name: "新人福利"
        },
        {
          name: "限时优惠"
        },
      ],
    }
  },


  methods: {
    navigatorTo(path, target = '_blank') {
      fnRouter.navigationTo(path, target)
    },
    tag(stat) {
      tag(stat)
    }
  }
}
</script>


<style lang="scss" scoped>
@import "components/common/header/common/header";

.search-box {
  transition: all 0.2s ease;

  &.no {
    opacity: 0;
  }

  &.on {
    opacity: 1;
  }
}


</style>
