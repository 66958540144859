<template>
  <!-- act 公共页   -->
  <div>
    <nuxt/>
    <GlobalSlot />
    <no-ssr>
      <Login />
    </no-ssr>
  </div>
</template>

<script>
import {Login} from "@/components/common/index.js"
import GlobalSlot from '@/components/common/global-slot';

export default {
  name: "ActApp",
  components: {
    Login,
    GlobalSlot
  },
}
</script>
