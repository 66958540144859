
export const TU_PIAN = {


  RECEIVE_COMPANY_COUPON:{

    EQUITY_LIST:[
      {
        label: '企业授权'
      },
      {
        label: '海量下载'
      },
      {
        label: '专属后台'
      },
      {
        label: '侵权赔付'
      }
    ]
  }
}
