<!--
/**
* @Author: wulizhou
* @Description: 倒计时气泡
* @Date:
**/
-->

<template>
  <div class="CountdownBubble" v-if="isShow">

    <template v-if="countdownTime != 0">
      <span class="time-seconds">{{ countdownTime }}</span>
      <span class="txt-seconds">s</span>
    </template>

    <div v-else class="txt-complete">已完成</div>

    <div class="task-progress" v-if="countdownTime != 0">浏览任务</div>
    <div class="task-progress task-progress-complete"
         v-else
         @click="completeTaskAct"
    >领取奖励</div>
  </div>
</template>

<script>

/* 引入公共方法和常量 */
import * as fnCommon from '@/fn/_fn';
import * as CON_COMMON from '@/constant/_constant';

/* 引入组件 */

/* 引入VUEX */
import {mapState} from "vuex";
import {completeTaskByFanClub} from "@/api/fans/club";

/* 引入API */

export default {
  name: "CountdownBubble"

  , components: {}

  , props: {}

  , watch: {}

  , computed: {}

  ,mounted() {
    // this.countDown(6)

    if (this.$route.query.task_type != 2) {
      return false;
    }


    if (this.$route.path.indexOf('venue') > -1){
      this.isShow = true;
      this.countDown(6)
    }else{
      this.isShow = true;
    }

    this.handleScroll()
    window.addEventListener("scroll", this.handleScroll);

  }

  , data() {
    return {
      countdownTime: 6,
      isShow: false
    }
  }

  , methods: {

    handleScroll() {

      if (!this.$route.query.t_id  || this.countdownTime <= 0){
        return false;
      }

      this.isShow = true;

      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      // this.isShow = scrollTop > 300;
      if (scrollTop > 10){
        this.countDown(6)
      }
    }

    , countDown(){
      fnCommon.fnBasic.countdown(this.countdownTime,(time)=>{
        this.countdownTime = time;
      })
        .then((res) => {
          console.log(res)
          console.log("倒计时完成")
        })
        .catch(error => console.log("发生错误:", error));
    }

    ,completeTaskAct(){

      console.log(this.$route)

      let { t_id } = this.$route.query

      completeTaskByFanClub({id: t_id}).then(res =>{
        let { status, data, message } = res.data;
        if (status ==200){
          this.$myNotify.success("领取成功")
          this.$router.push({
            query:{}
          })
        }else{
          this.$myNotify.success(message)

        }
      })
    }
  }

}
</script>

<style lang="scss" scoped>
  .CountdownBubble{
    @include BgImage(70px,70px,"_chaotu/fan-club/countdown-bubble%40bg.png");
    position: fixed;
    left: 10px;
    top: 100px;
    //display: flex;
    //align-items: center;
    //justify-content: center;
    z-index: 9999;
    text-align: center;
    line-height: 70px;
    font-size: 0;

    .time-seconds{
      font-size: 24px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 31px;
      text-shadow: 0 2px 4px #6069EF;
    }
    .txt-seconds{
      font-size: 18px;
      font-weight: bold;
      color: #FFFFFF;
      text-shadow: 0 2px 4px #6069EF;
    }

    .txt-complete{
      font-size: 16px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 70px;
      text-shadow: 0px 2px 4px #6069EF;

    }

    .task-progress{
      width: 70px;
      height: 22px;
      line-height: 22px;
      text-align: center;
      background: rgba(122, 118, 246, 1);
      border-radius: 12px;
      border: 1px solid #FFFFFF;
      font-size: 12px;
      font-weight: bold;
      color: #FFFFFF;
      cursor: pointer;
      @include Position(1,5,50%,80px);
      transform: translateX(-50%);

      &:hover{
        background: rgba(120, 120, 255, 1);
      }

      &.task-progress-complete{
        background: rgba(252, 85, 144, 1);
        &:hover{
          background: rgba(255, 124, 170, 1);
        }
      }
    }
  }
</style>
