<template>
  <div class="hot-pic">
    <a
      :href="vo.link_url"
      :title="vo.title"
      target="_blank"
      v-for="(vo,idx) in picList"
      :key="vo.link_url + 'pic'"
      class="hot-pic-item"
      @click="picListClick(vo)"
    >
      <img :src="BASE_IMG_URL + '/' + vo.pic_url + '!/progressive/true' "
          :alt="vo.title"
           class="hot-pic-item-img">
      <!--            <TupianMask :link="vo.link_url"/>-->
      <div class="hot-pic-mask overflow-one">
        <!--              {{ vo.title }}-->
        {{ vo.title }}
      </div>
    </a>
  </div>
</template>

<script>
import {BASE_IMG_URL} from "@/constant/_constant/CON_BASIC";

export default {
  name: "HeaderPicList",
  data() {
    return {
      BASE_IMG_URL
    }
  },
  props: {
    picList: []
  },
  methods: {
    picListClick() {
      this.$emit('picListClick')
    }
  }
}
</script>

<style lang="scss" scoped>
.hot-pic {
  display: flex;
  margin-bottom: 20px;
  height: 172px;

  .hot-pic-item {
    display: inline-block;
    width: 132px;
    height: 172px;
    box-shadow: 0px 0px 4px 0px rgba(213, 221, 236, 0.4);
    border-radius: 8px;
    margin-right: 20px;
    position: relative;
    overflow: hidden;

    &:last-child {
      margin-right: 0;
    }

    img {
      width: 100%;
      vertical-align: middle;
      transition: all 0.5s ease;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }


    .hot-pic-mask {
      @include Position(1, 4, 0, 0px);
      width: 100%;
      height: 32px;
      background: linear-gradient(180deg, rgba(0, 0, 26, 0) 0%, rgba(0, 0, 26, 0.2) 20%, rgba(0, 0, 26, 0.5) 100%);
      border-radius: 0px 0px 8px 8px;
      font-size: 13px;
      color: #FFFFFF;
      line-height: 32px;
      text-align: center;
      padding: 0 15px;
      box-sizing: border-box;
      transition: all 0.5s ease;
    }

  }
}

</style>
