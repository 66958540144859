/* nav api */

import request from "@/utils/request";

export function getDropdownByHot(query) {
  return request({
    url: '?m=Dropdown&a=hot',
    method: 'get',
    params: query
  })
}

export function getDropdownByDesignStyle(query) {
  return request({
    url: '?m=Dropdown&a=design',
    method: 'get',
    params: query
  })
}


export function getDropdownBySolarTerms(query) {
  return request({
    url: '?m=Dropdown&a=solarTerms',
    method: 'get',
    params: query
  })
}

export function getDropdownByFestival(query) {
  return request({
    url: '?m=Dropdown&a=festival',
    method: 'get',
    params: query
  })
}

export function getDropdownByThreeD(query) {
  return request({
    url: '?m=Dropdown&a=navigate3D',
    method: 'get',
    params: query
  })
}

