import axios from 'axios'
import store from '@/store'
// import {getToken} from '@/utils/auth'
import qs from 'qs';

import env from '../env'


axios.defaults.withCredentials = true

// create an axios instance
const service = axios.create({
  baseURL: env[process.env.NODE_ENV].APP_BASE_API, // api 的 base_url
  timeout: 15000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {

    // Do something before request is sent
    // if (store.getters.token) {
    //   // 让每个请求携带token-- ['X-Litemall-Admin-Token']为自定义key 请根据实际情况自行修改
    //   // config.headers['X-Litemall-Admin-Token'] = getToken();
    //   // "X-Requested-With": "XMLHttpRequest",
    // }

    //只针对get方式进行序列化
    if (config.method === 'get') {
      config.paramsSerializer = function (params) {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    }

    if (config.method === 'post') {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }

    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const status = response.status
    if (status === 200) {
      return response;
    } else {
      return Promise.reject(response);
    }

  }, error => {
    console.log('err' + error)// for debug
    // Message({
    //   message: '登录连接超时（后台不能连接，请联系系统管理员）',
    //   type: 'error',
    //   duration: 5 * 1000
    // })

    return Promise.reject(error)
  })

export default service
