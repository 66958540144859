import myNotify from "@/utils/notify";
import axios from "axios";
import {toolDevice} from "@/tools";
import {BROWSERS} from "@/constant/SYSTEM";
import {downStat} from "@/api/material";
import {packageStat} from "@/api/stat";
import fnCollection from "@/fn/topic/fnCollection";

class FnPic {
  constructor() {
  }

  /**
   * 免费下载图片
   * @param channel 下载类型 2源文件
   * @param uniq_id 下载图片 uniq_id
   * @param downloadType 下载图片类型 pic图片 picPackage图片包
   * @returns {Promise<unknown>}
   */
   downloadPic(channel = 2, uniq_id,from='', downloadType = 'pic', ) {
    let downloadLink = `https://dl.chaopx.com/down/download?channel=${channel}&uniq_id=${uniq_id}&from=${from}`;

    /* 检查是否是大小专题 */
    let { $router, $route } = $nuxt;

    if($route.path.indexOf('/collection') > -1){
      let temp = fnCollection.parseCollectionId($route.params.id);
      let _params = `&topic_id=${temp.collectionId}&refererUrl=${window.location.href}`
      downloadLink += _params;
    }


   /* /!* 测试 *!/
    return new Promise((resolve, reject) => {
      const res = {
        data:{
          data:{
            status: -1
          }
        }
      }
      resolve(res)
    })*/


    return new Promise((resolve, reject) => {
      axios.get(downloadLink).then(res => {
        let {status, url, msg} = res.data.data;

        if(status == 1){
          let {$store} = $nuxt;
          $store.dispatch('user/getMaterialFreeDownloadNum');
          // downStat({uniq_id})
          if (toolDevice.getCurrentBrowser() === BROWSERS.Safari) {
            setTimeout(() => {
              window.location.href = url;
            }, 200)
          } else {
            window.location.href = url;
          }

        }else if (status == 0 || status == 2) {

        }else if (status == -200){
        }

        resolve(res)
      }).catch(err =>{
      })
    })
  }
  downloadTopicPic(topicDetail,channel = 2, uniq_id,from='', downloadType = 'pic', ) {
    const downloadLink = `https://dl.chaopx.com/down/download?channel=${channel}&uniq_id=${uniq_id}&from=${from}&topic_id=${topicDetail.id}`;
    return new Promise((resolve, reject) => {
      axios.get(downloadLink).then(res => {
        let {status, url, msg} = res.data.data;

        if(status == 1){
          let {$store} = $nuxt;
          $store.dispatch('user/getMaterialFreeDownloadNum');
          // downStat({uniq_id})
          if (toolDevice.getCurrentBrowser() === BROWSERS.Safari) {
            setTimeout(() => {
              window.location.href = url;
            }, 200)
          } else {
            window.location.href = url;
          }

        }else if (status === 0 || status === 2) {

        }else if (status === -200){

        }

        resolve(res)
      }).catch(err =>{
      })
    })
  }
  downloadPicPackage(channel = 2, uniq_id, downloadType = 'package') {
    // const downloadLink = `https://dl.chaopx.com/down/download?channel=${channel}&uniq_id=${uniq_id}`;
    let downloadLink = `https://dl.chaopx.com/down/download?channel=${channel}&uniq_id=${uniq_id}&module=2`
    /* 检查是否是大小专题 */
    let { $router, $route } = $nuxt;

    if($route.path.indexOf('/collection') > -1){
      let temp = fnCollection.parseCollectionId($route.params.id);
      let _params = `&topic_id=${temp.collectionId}&refererUrl=${window.location.href}`
      downloadLink += _params;
    }

    return new Promise((resolve, reject) => {
      axios.get(downloadLink).then(res => {
        let {status, url, msg} = res.data.data;

        if(status === 1){
          packageStat({event: 'down', channel: channel})
          downStat({uniq_id: uniq_id, module: 2})
          if (toolDevice.getCurrentBrowser() === BROWSERS.Safari) {
            setTimeout(() => {
              window.location.href = url;
            }, 200)
          } else {
            window.location.href = url;
          }

        }else if (status === 0 || status === 2) {

        }else if (status === -200){

        }

        resolve(res)
      }).catch(err =>{
        // myNotify.show(msg)
        // reject(res)
      })
    })
  }
}

export default new FnPic();
