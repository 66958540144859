<template>
  <div
    class="HeaderCompanyLicense"
    :class="{ 'on': isFixedHeader }"
    @mouseover="()=>{
      this.initTimer()
      this.isShow = true;
    }"
    @mouseleave="()=>{
      this.clearTimer()
      this.isShow = false;
    }"
    @click="navigatorTo(PAY_COMPANY_NEW)"
  >
    <i class="icon_company"/>
    <!--   去支付页埋点tag('充值:商用授权下拉:图片'); -->
    <div
      class="CompanyLicense-title _pointer"
      >开通全站通VIP
    </div>
    <no-ssr>
      <span class="vip-tip">买即加赠1年</span>
    </no-ssr>
    <!-- 下拉 @click="navigatorTo(COMPANY_INTRODUCE)"

-->
    <no-ssr>
      <div
        v-show="isShow"
           class="company-combobox"
      >
        <div class="content">
          <div class="content-left" @click="navigatorTo(PAY_COMPANY_NEW)">
            <div class="title">全站通VIP会员</div>
            <div class="list-1">
              <div class="list-item-1">
                <i class="icon_bz icon_bz4"/>
                <div class="list-item-content-1">
                  <div class="list-item-title color-orange">全站海量下载</div>
                  <div class="list-item-desc">畅享全站海量下载+图片包</div>
                </div>
              </div>

              <div class="list-item-1">
                <i class="icon_bz icon_bz1"/>
                <div class="list-item-content-1">
                  <div class="list-item-title">授权范围多样</div>
                  <div class="list-item-desc">根据用户需求灵活定制授权范围</div>
                </div>
              </div>
              <div class="list-item-1">
                <i class="icon_bz icon_bz2"/>
                <div class="list-item-content-1">
                  <div class="list-item-title">商用授权书</div>
                  <div class="list-item-desc">定制版授权书，版权保障无忧</div>
                </div>
              </div>
              <div class="list-item-1">
                <i class="icon_bz icon_bz3"/>
                <div class="list-item-content-1">
                  <div class="list-item-title">子账号授权</div>
                  <div class="list-item-desc">
                    团队协作多子账号赋权，灵活便捷</div>
                </div>
              </div>


            </div>
          </div>
          <div class="content-right card-list">

            <div class="card-item company" @click.stop="
            navigatorTo(PAY_COMPANY_NEW)">
              <div class="title">全站通VIP</div>
              <div class="desc">适用于个人/组织 <br>
                /企业(≤1000万)</div>
              <div class="down-pic">海量下载 授权范围广 版权商用无忧</div>
<!--              <div class="price">-->
<!--                <span class="icon_dollar">￥</span>-->
<!--                <span class="txt-price">300</span>-->
<!--                <span class="txt-unit">/起</span>-->
<!--              </div>-->
              <a class="buy" @click.stop="
                  navigatorTo(PAY_COMPANY_NEW);
                  tag('充值:商用授权导航:图片');
                  "
                 target="_blank">
                <div class="buy-left">
                  <span>¥</span>
                  <span class="text-price">0.01</span>
                  <span class="text-zs">/起</span>
                </div>
                <div class="buy-right">
                  立即开通
                  <i class="iconfont icon-a-arrowright"></i>
                </div>

              </a>

              <div class="time-box">
                <span>{{ times.h }}</span>时<span>{{ times.m }}</span>分<span class="text-miao">{{ times.s }}.{{ times.hm }}</span>秒
              </div>
            </div>

            <div class="card-item large-company" @click.stop.prevent="navigatorTo(INTRODUCE_COMPANY_VIP_PLUS)">
              <div class="title">企业PLUS授权</div>
              <div class="desc">适用于大型企业 | 国企 | 事业单位</div>
              <div class="down-pic">海量下载 高额赔付保障</div>
              <div @click.stop.prevent="navigatorTo(SERVICE_LINK)" class="btn">立即咨询</div>
              <!--              <div class="price">
                              <span class="icon_dollar">￥</span>
                              <span class="txt-price">8,800</span>
                              <span class="txt-unit">/起</span>
                            </div>-->
            </div>

            <div class="card-item more" @click.stop.prevent="navigatorTo(INTRODUCE_MULTIPLE)">
              <div class="title">单/多张套餐</div>
              <div class="desc">适用于多甲方公司 单张授权更便捷</div>
              <div class="down-pic">套餐不限使用时长 多张购买更划算</div>
              <!--              <div class="btn">敬请期待</div>-->
              <div class="price">
                <span class="icon_dollar color-orange">￥</span>
                <span class="txt-price color-orange">22.8</span>
                <span class="txt-unit color-orange"> /起</span>
              </div>
            </div>


            <!--            <div class="list">
                          <div class="list-item">
                            <div class="list-item-header">
                              <span class="headline">适用于公司人数≤1000人 | 线上线下全授权</span>
                              <span class="subhead" @click.stop="navigatorTo(COMPANY_INTRODUCE)">查看详细特权 <i
                                class="iconfont icon-a-arrowright"/></span>
                            </div>

                            <div class="list-item-meal-list">

                              <div class="list-item-meal-list-item"
                                   @click.stop="navToPay2(item.payId);tag('充值:企业下拉:图片')"
                                   :class="{ 'active': index == versionIndex }"
                                   @mouseover=" versionIndex = index "
                                   v-for="(item,index) in CompanyVip.version1"
                                   :key="item.name"
                              >
                                <div class="meal-content">
                                  <span class="name">{{ item.name }}</span>
                                  <div class="mandate-scope">
                                  <span class="mandate-scope-item"
                                        v-for="vo in item._scope"
                                        :key="vo"
                                  >{{ vo }}</span>
                                  </div>
                                </div>
                                <div class="meal-info">
                                  <div class="price-box">
                                    <span class="dollar">￥</span>
                                    <span class="price">{{ item.price }}</span>
                                    <span class="time">/年</span>
                                  </div>

                                  <div class="account-info">
                                    <div class="account-item">{{ item.accountNum }}</div>
                                  </div>

                                  <div class="buy-btn">立即购买</div>
                                </div>

                              </div>


                            </div>

                          </div>
                          <div class="list-item"></div>
                        </div>

                        <div class="list">
                          <div class="list-item">
                            <div class="list-item-header">
                              <span class="headline">适用于大型企业 | 国企 | 事业单位 | 定制版授权</span>
                              <span class="subhead" @click.stop="toIntroduceCompanyPlus">查看详细特权 <i
                                class="iconfont icon-a-arrowright"/></span>
                            </div>

                            <div class="list-item-meal-list">

                              <div class="list-item-meal-list-item"
                                   :class="{ 'active': index == versionIndex2 }"
                                   @mouseover=" versionIndex2 = index "
                                   v-for="(item,index) in CompanyVip.version2"
                                   :key="item.name"
                                   @click.stop="navToPay3(item.payId);tag('充值:企业下拉:图片')"
                              >
                                <div class="meal-content">
                                  <span class="name">{{ item.name }}</span>
                                  <div class="mandate-scope">
                                  <span class="mandate-scope-item"
                                        v-for="vo in item._scope"
                                        :key="vo"
                                  >{{ vo }}</span>
                                  </div>
                                </div>
                                <div class="meal-info">
                                  <div class="price-box">
                                    <span class="dollar">￥</span>
                                    <span class="price">{{ item.price }}</span>
                                    <span class="time">/年</span>
                                  </div>

                                  <div class="account-info">
                                    <div class="account-item">{{ item.accountNum }}</div>
                                  </div>

                                  <div class="buy-btn">立即购买</div>
                                </div>

                              </div>


                            </div>

                          </div>
                          <div class="list-item"></div>
                        </div>-->

          </div>

        </div>

        <div class="explain-box">
          <span>为促进正版设计的发展，即日起，凡主动购买潮国创意正版内容商业授权的用户，方可享有超惠价格。</span>
          <span class="color-brown">若未经潮国创意授权使用，不仅全额支付最高版权使用费，并按侵权责任赔偿损失，且承担一切法律后果。</span>
        </div>
      </div>
    </no-ssr>
  </div>
</template>

<script>

import {equityList, packageList} from '../common/company-license-module'
import {serviceLink} from "@/constant/CONFIG";
import {COMPANY_INTRODUCE, PAY} from "@/constant/PATH";
import {fnBrowser} from "~/fn";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import {tag} from "@/utils/statUtil";
import {COMPANY_LICENSE} from "@/constant/company/COMPANY_LICENSE";
import {COMPANY_VIP_MANDATE, MANDATE_SCOPE_COMPANY} from "@/constant/_constant/CON_CONFIG";
import {INTRODUCE_COMPANY_VIP_PLUS, SERVICE_LINK, INTRODUCE_MULTIPLE, PAY_COMPANY_NEW} from "@/constant/_Public/Path"
import mixinCountDown from "@/mixins/mixinCountDown";

/* 默认企业授权范围 */
const MANDATE_SCOPE_DEFAULT = [
  "新媒体用途",
  "转售商品",
  "电商用途",
  "产品内置",
  "企业办公",
  "敏感用途",
  "广告宣传",
  "商标LOGO",
  "线下用途",
  "传媒影视",
]

const CompanyVip = {
  version1: [
    COMPANY_VIP_MANDATE["8"],
    COMPANY_VIP_MANDATE["5"],
    COMPANY_VIP_MANDATE["6"],
    COMPANY_VIP_MANDATE["7"],
  ],
  version2: [
    COMPANY_VIP_MANDATE["10"],
    COMPANY_VIP_MANDATE["11"],
    COMPANY_VIP_MANDATE["12"],
    COMPANY_VIP_MANDATE["13"],
  ]
}

export default {
  name: "HeaderCompanyLicense",
  mixins: [mixinCountDown],
  props:{
    isFixedHeader: false
  },
  data() {
    return {
      equityList,
      packageList,
      serviceLink,
      PAY,

      isShow: false,
      COMPANY_INTRODUCE,
      INTRODUCE_COMPANY_VIP_PLUS,
      INTRODUCE_MULTIPLE,
      PAGE_PATH,
      COMPANY_LICENSE,
      MANDATE_SCOPE_COMPANY,
      MANDATE_SCOPE_DEFAULT,
      CompanyVip,
      versionIndex: 0,
      versionIndex2: 0,
      SERVICE_LINK,
      PAY_COMPANY_NEW
    }
  },
  methods: {
    navToPay(vo) {
      if (vo.ID !== -1) {
        let path = PAY + '/' + vo.ID
        window.open(path, '_blank');
      } else {
        let path = serviceLink
        window.open(path, '_blank');
      }
    },

    navToCompanyIntro() {
      let path = this.COMPANY_INTRODUCE
      window.open(path, '_blank');
    },

    navigatorTo(path) {
      fnBrowser.navTo(path)
    },
    tag(stat) {
      tag(stat)
    },

    navToPay2(id) {
      let path = PAY + '/' + id
      window.open(path, '_blank');

    },
    navToPay3(id) {
      let path = '/pay-company-plus/' + id
      window.open(path, '_blank');
    },

    toIntroduceCompanyPlus() {
      window.open('/introduce/company-plus', '_blank');
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin IconItem($url,$type:'png') {
  @if ($type == 'png') {
    @include BgImage(32px, 32px, 'header/company-license/#{$url}.png');
  } @else {
    @include BgImage(32px, 32px, 'header/company-license/#{$url}.#{$type}');
  }
}

@keyframes fadeinT {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.color-orange{
  color: rgba(255, 66, 22, 1) !important;
}


.HeaderCompanyLicense {
  @include LayoutFlex(1);
  position: relative;
  z-index: 5;
  flex-wrap: nowrap;

  //width: 108px;
  padding: 0 12px;
  height: 34px;
  background: linear-gradient( 219deg, #FAF3FF 0%, #E7E3FF 100%);
  border-radius: 18px;

.vip-tip{
  display: none;
}


  &.on{
    height: 34px;
    background: rgba(105, 105, 255, 1);
    color: #FFFFFF;
    border-radius: 18px;
    padding: 0 12px 0 11px;
    box-sizing: border-box;
    .CompanyLicense-title{
      color: #FFFFFF !important;
      &:hover{
        color: #FFFFFF !important;
    }
    }

    .icon_company {
      @include BgImage(16px, 14px, "home/images/icon_zuan2.svg");
    }

    .vip-tip{
      display: block;
      height: 15px;
      background: linear-gradient(-50deg, #EA4B25, #F87B44);
      border-radius: 2px;
      font-weight: 500;
      font-size: 10px;
      color: #FFFFFF;
      padding: 0 8px;
      position: absolute;
      top: -15px;
      right: 12px;
      box-sizing: border-box;
      opacity: 1;
    }
  }

  .CompanyLicense-title {
    font-size: 15px;
    letter-spacing: 1px;
    color: #FFFFFF;
    color: rgba(66, 66, 235, 1);
    position: relative;


    //@include ElementHover(rgba(255, 255, 255, 0.75),'')
  }


  &:before {
    content: '';
    width: 100%;
    height: 52px;
    @include Position(1, 2, 0, 0);
    //background-color: pink;
  }


  @include ElementHover(rgba(255, 255, 255, 0.75), '') {
    .company-combobox {
      top: 50px;
    }

    .CompanyLicense-title {
      // @include ElementHover(rgba(255, 255, 255, 0.75),'')
      //@include ElementHover(rgba(255, 255, 255, 0.75), '')
    }
  }

  .icon_company {
    //@include BgImage(24px, 24px, 'header/icon-company.png');
    @include BgImage(16px, 14px, "home/images/icon_zuan.svg");
    margin-right: 8px;
    margin-top: 2px;
  }


  .company-combobox {
    //width: 435px;
    //height: 432px;
    //padding: 25px 24px 24px 24px;
    box-sizing: border-box;
    border-radius: 12px;
    @include Position(1, 2, 0, 50px);
    //transition: all 0.5s ease-in-out;
    animation: fadeinT 0.5s 1;

    //width: 785px;
    //height: 547px;
    background: #FFFFFF;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.06), 0px 0px 12px 0px rgba(0, 0, 0, 0.08);

    .company-bar {
      width: 100%;
      height: 40px;
      background: rgba(36, 57, 120, 1);
      display: flex;
      color: #FFFFFF;
      border-radius: 12px 12px 0 0;
      overflow: hidden;


      .company-bar-item {
        display: flex !important;
        align-items: center;
        width: 50%;

        .title {
          font-size: 13px;
          font-weight: bold;
          color: rgba(255, 255, 255, 0.85);
          margin-right: 10px;
          margin-left: 20px;
        }

        .desc {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.85);
          line-height: 12px;
        }

        .icon-a-arrowright {
          margin-left: 4px;
          margin-top: 2px;
        }
      }

      .company-bar-item-on {

        @include BgImage(394px, 40px, 'header/company-license/company-bar-item-on.png');

      }
    }

    .explain-box {
      height: 48px;
      background: #F7F7F9;;
      border-radius: 0px 0px 2px 2px;
      font-size: 11px;
      color: rgba(88, 90, 92, 1);
      line-height: 16px;
      letter-spacing: 1px;
      padding: 9px 24px;
      box-sizing: border-box;

      span {
        margin-bottom: 5px;

        &.color-brown {
          color: rgba(130, 73, 35, 1);
        }
      }
    }


    .content {
      display: flex;

      padding: 24px 32px 0 32px;

      .content-left {
        width: 258px;
        box-sizing: border-box;

        .title {
          height: 26px;
          font-size: 20px;
          font-weight: bold;
          color: #2D2B33;
          line-height: 26px;
          letter-spacing: 1px;
          margin-bottom: 20px;
        }

        .list-1 {


          .list-item-1 {

            color: #0A2540;
            display: flex;
            flex-direction: column;

            .color-orange{
              font-weight: bold;
            }

            .icon_bz {

              margin-bottom: 9px;
            }

            .icon_bz1 {
              @include BgImage2(20px, 20px, "header/icon_bz1.png");
            }

            .icon_bz2 {
              @include BgImage2(20px, 20px, "header/icon_bz2.png");
            }

            .icon_bz3 {
              @include BgImage2(20px, 20px, "header/icon_bz3.png");

            }

            .icon_bz4 {
              @include BgImage2(20px, 20px, "header/icon_bz4.png");

            }

            .list-item-content-1 {
              .list-item-title {
                margin-bottom: 3px;
                font-size: 14px;
                color: #0A2540;
                line-height: 19px;
                letter-spacing: 1px;

                .color-orange{
                  font-weight: bold;
                }

              }

              .list-item-list-1 {
                display: flex;
                flex-wrap: wrap;


                .list-item-1 {
                  width: 104px;
                  margin-bottom: 8px;
                  display: flex;
                  align-items: center;

                }
              }

              .list-item-desc {
                line-height: 16px;
                margin-bottom: 18px;
                font-size: 12px;
                color: rgba(89, 96, 136, 1);
                line-height: 16px;
                letter-spacing: 1px;

              }
            }


          }
        }
      }

      .content-right {
        margin-top: 10px;


        .card-item {
          width: 330px;
          height: 110px;
          background: linear-gradient(360deg, #FFFFFF 0%, #F0F3FF 100%);
          border-radius: 8px;
          border: 1px solid #E0E3E9;
          padding: 16px 0 0 18px;
          box-sizing: border-box;
          margin-bottom: 12px;
          position: relative;

          .title {
            font-size: 16px;
            font-weight: bold;
            color: #0A2540 !important;
            line-height: 21px;
            letter-spacing: 1px;
            margin-bottom: 10px;
          }

          .desc {
            font-size: 12px;
            color: #424866 !important;
            line-height: 16px;
            margin-bottom: 13px;
          }

          .down-pic {
            display: inline-block;
            height: 24px;
            line-height: 24px;
            background: linear-gradient( 270deg, rgba(247,249,255,0) 0%, #ECF1FF 100%);
            border-radius: 0px 2px 2px 0px;
            font-size: 12px;
            color: #005AFF;
            //margin-left: 17px;
            box-sizing: border-box;
            margin-left: -18px;
            padding: 0 10px 0 17px;
          }

          .btn {
            width: 80px;
            height: 26px;
            background: #005AFF;
            border-radius: 13px;
            font-size: 12px;
            color: #FFFFFF;
            line-height: 26px;
            text-align: center;
            position: absolute;
            top: 15px;
            right: 18px;

            &:hover {
              background-color: rgba(0, 90, 255, 0.8);
            }
          }

          .price {
            position: absolute;
            top: 14px;
            right: 24px;
            color: rgba(10, 37, 64, 1);
            font-size: 0;

            .icon_dollar {
              font-size: 12px;
              color: #0A2540;
              line-height: 16px;
            }

            .txt-price {
              font-size: 18px;
              color: #0A2540;
              line-height: 31px;
              position: relative;
              top: 1px;
              font-weight: bold;
              margin-right: 3px;
            }

            .txt-unit {
              font-size: 12px;
              color: #0A2540;
              line-height: 16px;
            }
          }
        }

        .company {
          height: 120px;
          @include BgImage2(330px,120px,"header/vip_sq.png");
          border: none;
          .title{
            margin-bottom: 3px;
          }
          .desc{
            line-height: 18px;
            margin-bottom: 10px;
          }

          .buy{
            width: 200px;
            height: 40px;
            border-radius: 15px;
            border: 1px solid rgba(255,255,255,0.5);
            //backdrop-filter: blur(2px);
            position: absolute;
            top: 16px;
            right: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 8px 0 12px;
            box-sizing: border-box;
            cursor: pointer;
            transition: all 0.3s;
            border-radius: 20px;
            border: 1px solid rgba(255,255,255,0.5);

            width: 160px;
            height: 32px;
            background: #FFFFFF;
            border-radius: 20px;
            backdrop-filter: blur(2px);
            &:hover{
              box-shadow: 0px 4px 8px 0px rgba(137,138,255,0.3);
              border-radius: 20px;
              backdrop-filter: blur(2px);
            }

            .buy-left{
              font-size: 0;
              color: #FF4216;
              span{
                font-size: 12px;
              }
            }

            .text-price{
              margin: 0 2px;
              font-weight: bold;
              font-size: 18px !important;

            }

            .buy-right{
              font-size: 12px;
              color: rgba(67, 19, 221, 1);
              margin-top: 3px;
              font-weight: bold;
              .icon-a-arrowright{
                font-size: 12px;
              }
            }
          }

          .time-box{
            height: 14px;
            width: 137px;
            text-align: center;
            font-size: 12px;
            color: rgba(32, 32, 32, 0.85);
            line-height: 12px;
            position: absolute;
            top: 56px;
            right: 25px;

            .text-miao{
              display: inline-block;
              width: 28px;

            }

            span{
              color: rgba(255, 66, 22, 1);
              font-weight: bold;
              margin: 0 2px;
            }

          }

        }

        .large-company {
        }

        .more {
          .btn {
            background-color: rgba(0, 90, 255, 0.1);
            color: rgba(0, 90, 255, 1);

            &:hover {
              background-color: rgba(0, 90, 255, 0.2);
            }
          }
        }
      }


    }


  }

}


</style>
