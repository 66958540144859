<template>
  <div class="PicPackageButton">
    {{ buttonTxt }}
  </div>
</template>

<script>
export default {
  name: "PicPackageButton",
  props: {
    buttonTxt: {
      type: String,
      default: '源文件打包下载',
    }
  }
}
</script>

<style lang="scss" scoped>
@import "common/pic-pcakage-button";
</style>
