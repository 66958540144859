import localStorage from "@/utils/localStorage";
import {MODEL_LOGIN_GUIDE} from "@/constant/MODELS";
import {fnLogin} from "@/fn/index";
import {getCookie} from "@/utils/auth";

class fnLoginGuide {
  constructor() {

  }


  /* 获取当天本地绑定 */
  getState() {
    return localStorage.getItemDate(MODEL_LOGIN_GUIDE);
  }

  /**
   * 更新当天绑定状态
   * @param {String | Number ...} value
   */
  updateState(value = 1) {
    localStorage.setItemDate(MODEL_LOGIN_GUIDE, value)
  }


  checkState() {

    if (this.getState()) {
      console.log(11111)
    } else {
      console.log(22222)
    }

  }


}

export default new fnLoginGuide();
