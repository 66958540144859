<!--
  背景路径
-->
<template>
 <no-ssr>
   <div class="background-path ">
     <div class="path-1"></div>
     <div class="path-6"></div>
     <!--    <div class="path-2"></div>-->
     <!--    <div class="path-3"></div>-->
     <!--    <div class="path-5"></div>-->

     <div class="path-wave" />
   </div>
 </no-ssr>
</template>

<script>
export default {
  name: "BackgroundPath"
}
</script>

<style lang="scss" scoped>
.background-path {
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  /*
      x: 219 374 502 465
      Y: 227  380 527  697
   */

  /* 粉红色背景 */
  .path-1 {
    //@include BgImage(2700px, 492px, "home/images/layer-1.svg");
    @include BgImage(3139px, 492px, "home/path/path-3-1.svg");
    @include Position(1, 5, -74px, 200px);
    filter: blur(80px);
  }

  .path-6 {
    //@include BgImage(2700px, 492px, "home/images/layer-1.svg");
    @include BgImage(3139px, 492px, "home/path/path-3-1.svg");
    @include Position(1, 5, -62px, 320px);
    filter: blur(40px);
  }

  /* 绿色波纹 */
  .path-2 {
    @include BgImage(2141px, 518px, "home/images/layer-2-2.png");
    @include Position(1, 5, -170px, 375px);
  }

  .path-3 {
    @include BgImage(2291px, 548px, "home/images/layer-2-3.png");
    @include Position(1, 5, -245px, 502px);
  }

  /* 白底 */
  .path-5 {
    @include BgImage(2474px, 549px, "home/images/layer-4.svg");
    @include Position(1, 5, -265px, 694px);
  }

  .path-wave{
    @include BgImage(3552px, 243px, "home/path/icon_wave5.svg?v=2");
    //@include BgImage(3139px, 492px, "home/path/path-3-1.svg");
    //@include Position(1, 5, -265px, 694px);
    //@include Position(1,5,-265px,589px);
    @include Position(1,5,50%,589px);
    transform: translateX(-50%);
  }
}
</style>
