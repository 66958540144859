
export default {
  data(){
    return{
      headline: "注册声明",
    }
  },

  methods:{
    toggleTab(vo,idx){
      this.headline = vo;
      // this.act = idx;
      console.log(vo)

      this.$emit('toggleTab',idx)
    }
  }
}
