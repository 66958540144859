<template>
  <!--      :class="[ {'on': NAV.PIC_PACKAGE.id == navIndex} ]"
  @mouseover="isShow = true"
    @mouseleave="isShow = false"
  -->
  <div
    class="NavItemHot NavItemBusiness menu-item"
    :class="[ {'on': NAV.BUSINESS.id == navIndex} ]"
    @mouseover="toggleHeaderAllDropDown(1)"
    @mouseleave="toggleHeaderAllDropDown(false)"
  >
    <a
      :href="businessLink"
      target="_blank"
      @click="updateNavId(NAV.BUSINESS.id)"
      @mouseover="toggleHeaderAllDropDown(1,1)"
    >
      电商
<!--      <i class="icon_NavItemHot icon_12"/>-->
<!--      <i class="icon_NavItemHot icon_nhj"/>-->
      <i
        v-for="(vo,idx) in HEADER.NAV.BUSINESS.icons"
        :key="idx"
        class="icon_NavItemHot"
        :class="vo.type"
        :style="{background:'url('+ PAGE_PATH.BASE_IMG_UPLOAD + vo.url+') no-repeat center/100% '}"
      />
    </a>

    <no-ssr>
      <div v-show="isShow"
           class="dropbox">
        <HeaderAllDropDown ref="HeaderAllDropDown"/>
      </div>

    </no-ssr>
  </div>
</template>

<script>
import {NAV} from "../common/module";
import {fnRouter} from "@/fn";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import headerNavMixin from "~/components/common/header/header-nav/common/header-nav-mixin";
import HeaderAllDropDown from "@/components/_Public/Header/HeaderAllDropDown";

const comboboxList = [
  NAV.CALENDAR,
  NAV.TIMELINE
]

export default {
  name: "NavItemBusiness",
  mixins: [
    headerNavMixin
  ],
  computed:{
    businessLink(){
      return fnRouter.getRouterLink(PAGE_PATH.NAV.BUSINESS);
    },
  },
  components:{
    HeaderAllDropDown
  },
  data() {
    return {
      comboboxList,
      isShow: false,
      PAGE_PATH
    }
  },
  methods: {
    navigateTo(vo){
      this.$emit('navigateTo',vo)
    },

    toggleHeaderAllDropDown(flag = true, val) {
      if (flag) {
        setTimeout(()=>{
          this.isShow = true;
          if (val >-1){
            this.$refs.HeaderAllDropDown.menuIndex = val
          }
        },0)
      } else {
        this.isShow = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../common/nav";
@import "../common/icon";

.NavItemBusiness {
  position: relative;

  .dropbox {
    @include Position(1, 5, -36px, 47px);
  }

  > a{
    position: relative;

    .icon_NavItemHot {
      @include Position(1, 2, -15px, -10px);

    }
  }

  .icon-more {
    font-size: 24px;
  }

  .more-combobox {
    @include Position(1, 5, -26px, 40px);
    //transform: translateX(-50%);
    width: 808px;
    height: 432px;
    border-radius: 12px;
    background: #FFFFFF;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.06), 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
    padding: 6px;
    box-sizing: border-box;
    animation: fadeinT 0.5s 1;
    cursor: default;
    width: 110px;
    height: 84px;
    background: #FFFFFF;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.0600), 0px 0px 12px 0px rgba(0, 0, 0, 0.0800);
    border-radius: 10px;

    .nav-list{
      .nav-item{
        //width: 98px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        //background: #6969FF;
        border-radius: 4px;

        @include ElementHover($color-primary);

        &.ON{
          color: $color-primary;
        }
      }
    }



    &:after {
      content: '';
      width: 10px;
      height: 10px;
      background-color: #FFFFFF;
      @include Position(1, 5, 45%, -4px);
      transform: rotate(135deg);
    }
  }


}
</style>
