<template>
  <div class="payment-dialog" id="paymentDialog" v-show="isShow">
    <cg-dialog @close="closeDialog">
      <div slot="dialog_content" class="payment-dialog-content">
        <div class="dialog-header">
          <div class="account">登录帐号：{{ uniq_id }}</div>
          <div class="member-opening" v-if="vipIsPerson == 0 && vipIsBusiness == 0">VIP会员未开通</div>
          <div class="member-opening" v-else>{{ (vipIsBusinessData.end_time || vipIsPersonData.end_time)  }}
            到期，购买后有效期将顺延
          </div>
          <i class="icon_chaopx"/>
        </div>
        <div class="dialog-bar">
<!--          <i class="icon_gift"/>-->
<!--          购买潮国创意VIP · 日更优质内容随心下-->
        </div>
        <div class="dialog-main">
          <div class="dialog-main-left">
            <h3>{{ memberList[memberActive].title }}</h3>
            <ul>
              <li
                v-for="(vo,idx) in memberList[memberActive].permission" :key="vo.title"
                :class="[ vo.isPer ? 'on' : 'no' , memberList[memberActive].class ]">
                <i
                  :class=" [ memberList[memberActive].permissionArr[idx] === 1? 'icon-gou': 'icon-cha2' ] "
                  class="iconfont"/>
                <span>{{ vo.desc }}</span>
              </li>
            </ul>
            <i class="icon_diamond"/>
          </div>
          <div class="dialog-main-right">
            <div class="member-list">
              <div
                v-for="(vo,idx) in memberList"
                :key="vo.title + 'member'"
                :class="[vo.class,{'on':idx === memberActive}]"
                class="member-item"
                @click="toggleMember(vo,idx)"
              >
                <div class="title">{{ vo.title }}</div>
                <div class="price">
                  <i class="icon_dollar">¥</i>
                  {{ vo.price }}
                  <!--                  <i class="time">/ {{ vo.time }}</i>-->
                </div>
                <div class="price-desc">{{ vo.priceDesc }}</div>
              </div>
            </div>


            <div class="payment-wrap">
              <div class="payment-box">
                <div class="payment-item wx">
                  <div class="qr-code">
                    <img
                      class="qr-wft"
                      v-if="paymentData.isShowWft"
                      :src="paymentData.wxUrl"
                      alt="">
                    <vue-qr
                      v-else
                      :text="paymentData.wxUrl"
                      :margin="0"
                      :logo-scale="0.3"
                      :size="200"
                    />
                  </div>
                  <div class="icon-box">
                    <i class="iconfont icon-wepay"/>
                    微信支付
                  </div>
                </div>
                <div class="payment-item zfb">
                  <div class="qr-code qr-code-zfb">
                    <vue-qr
                      :text="paymentData.zfbUrl"
                      :margin="0"
                      :logo-scale="0.3"
                      :size="200"
                    />
                  </div>
                  <div class="icon-box">
                    <i class="iconfont icon-alipay" style="color:#06B4FD;"/>
                    支付宝支付
                  </div>
                </div>
              </div>
              <div class="protocol">支付即同意
                <NavigationTo :path="PROTOCOL_PAY_0">
                  <span slot="content" class="_pointer">《VIP服务协议》</span>
                </NavigationTo>
              </div>
            </div>


          </div>
        </div>
      </div>
    </cg-dialog>
  </div>
</template>

<script>
/* id 过滤套餐 1为199 2为399 */
import {PROTOCOL_PAY_0} from "~/constant/PATH";
import {memberList} from "./module";


import vueQr from 'vue-qr'
import {mapGetters} from "vuex";

export default {
  name: "PaymentDialog",
  components: {
    vueQr
  },
  computed: {
    ...mapGetters('user',
      ['phone', 'uniq_id', 'vipIsPerson', 'vipIsPersonData', 'vipIsBusiness', 'vipIsBusinessData'
      ])
  },
  props: {
    payment: {
      type: Object,
      default: () => {
        return {
          isShowWft: '',
          zfbUrl: '',
          wxUrl: '',
        }
      }
    },
    memberIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isShow: false,
      PROTOCOL_PAY_0,

      memberList,
      memberActive: 0,
      paymentData: {}
    }
  },
  watch: {
    payment: {
      handler(val) {
        if (val == undefined) return;
        this.paymentData = {...this.paymentData, ...val};
      },
      immediate: true
    },
    memberIndex(n, o) {
      if (n == undefined) return;
      this.memberActive = n;
    }
  },
  methods: {
    closeDialog() {
      this.isShow = false;
      this.$emit('closePayDialog');
    },
    toggleMember(vo, idx) {
      this.memberActive = idx;
      let text = this.memberList[idx].title;
      this.$emit('toggleMember', vo, idx, text)
    }
  },
  filters: {
    // filterTime(val) {
    //   return val.slice(0, 10)
    // }
  }
}
</script>

<style lang="scss" scoped>
#paymentDialog {
  /deep/ .dialog-close {
    background: #555B7A !important;
    @include ElementHover('', '');

    .icon-cha {
      color: #FFFFFF;
    }
  }
}

.payment-dialog {


  .payment-dialog-content {
    width: 882px;
    position: relative;

    .dialog-header {
      padding: 17px 0 15px 40px;
      background: #424866;
      position: relative;

      .account {
        font-size: 13px;
        color: #FFFFFF;
        line-height: 17px;
        margin-bottom: 6px;
      }

      .member-opening {
        font-size: 13px;
        color: #A3AFEF;
        line-height: 17px;
      }

      .icon_chaopx {
        @include BgImage(430px, 43px, 'member/icon_chaopx.png');
        @include Position(1, 2, 36px, 0);

      }
    }

    .dialog-bar {
      width: 100%;
      @include BgImage(100%,36px,'member/payment-act-bar.jpg')
      //background: linear-gradient(180deg, #FFEEA7 0%, #FFD96E 100%);
      //font-size: 13px;
      //color: #FF5D5A;
      //line-height: 36px;
      //letter-spacing: 1px;
      //@include LayoutFlex(1);
      //
      //.icon_gift {
      //  @include BgImage(20px, 20px, 'member/icon_gift.png');
      //  margin-right: 8px;
      //}
    }

    .dialog-main {
      height: 492px;

      display: flex;
      background: #F5F6FA;

      .dialog-main-left {
        width: 204px;
        padding: 32px 0 0 40px;
        height: 100%;
        box-sizing: border-box;
        flex-shrink: 0;


        h3 {
          font-size: 18px;
          font-weight: bold;
          color: #424866;
          line-height: 24px;
          letter-spacing: 1px;
          margin-bottom: 20px;
        }

        ul {
          li {
            @include LayoutFlex(1);
            justify-content: flex-start;
            margin-bottom: 14px;

            &:last-child {
              margin-bottom: 0;
            }

            span {
              font-size: 14px;
              color: #424866;
              line-height: 20px;
              letter-spacing: 1px;
            }

            .iconfont {
              font-size: 18px;
              margin-right: 8px;
            }

            .icon-gou {
              color: #35D0BA;
            }

            .icon-cha2 {
              color: #B3B7C7;
            }

            &.no {
              span {
                color: #B3B7C7;
              }
            }

            &.on {
              .icon-gou {
                color: $color-primary;
              }

              span {
                color: #424866;
              }
            }
          }

          li.person {
            .icon-gou {
              color: #35D0BA;
            }
          }
        }

        .icon_diamond {
          @include BgImage(207px, 192px, 'member/icon_diamond.png');
          @include Position(1, 4, 0, 0);
        }
      }

      .dialog-main-right {
        width: 678px;
        height: 492px;
        background: #FFFFFF;
        box-shadow: -4px 0px 12px 0px rgba(0, 0, 26, 0.06);
        border-radius: 0px 0px 14px 14px;
        position: relative;
        padding: 36px 48px 0;


      }
    }
  }
}


.member-list {
  display: flex;
  margin-bottom: 32px;

  .member-item {
    width: 178px;
    height: 148px;
    box-sizing: border-box;
    margin-right: 24px;
    border-radius: 8px;
    text-align: center;
    position: relative;
    flex-shrink: 0;
    overflow: hidden;
    border: 1px solid #EBEDF5;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    .title {
      font-size: 16px;
      font-weight: bold;
      color: #424866;
      line-height: 20px;
      letter-spacing: 1px;
      margin: 25px 0 6px;
    }

    .price {
      font-size: 36px;
      font-weight: bold;
      color: #4C4B51;
      line-height: 44px;


      .icon_dollar {
        font-size: 14px;
        font-weight: bold;
        color: #2D2B33;
        line-height: 19px;
      }

      .time {
        font-size: 14px;
      }

    }

    .price-desc {
      width: 100%;
      line-height: 38px;
      text-align: center;
      @include Position(1, 4, 0, 0);
      font-size: 13px;
      transition: all 0.3s ease-in-out;

    }

    &:last-child {
      margin-right: 0;
    }

    &.business {

      .price-desc {
        color: #7D7DFF;
        background-color: #F3F3FF;
      }

      &.on {
        //border: 2px solid #6969FF;
        position: relative;
        z-index: 99;
        transition: all 0.3s, ease-in-out;

        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border: 2px solid #6969FF;
          border-radius: 8px;
          z-index: 8;
        }


        .title, .price {
          color: #2D2B33;

        }

        .price-desc {
          background-color: #6969FF;
          color: #FFFFFF;
        }
      }
    }

    &.person {
      .price-desc {
        color: #35D0BA;
        background-color: #EFFCFA;
      }

      &.on {
        position: relative;
        z-index: 99;
        transition: all 0.3s, ease-in-out;

        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border: 2px solid #35D0BA;
          border-radius: 8px;
          z-index: 8;
        }

        .title, .price {
          color: #2D2B33;
        }

        .price-desc {
          background-color: #35D0BA;
          color: #FFFFFF;
        }
      }
    }
  }
}


/* 支付 */
.payment-wrap {
  //height: 236px;
  border-radius: 8px;
  border: 1px solid #EBEDF5;
  box-sizing: border-box;
  padding-bottom: 22px;
}

.payment-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 0 20px 28px;
  //box-sizing: border-box;
  width: 100%;

  .payment-item {
    margin-right: 70px;

    &:nth-child {
      margin-right: 0;
    }

    .qr-code {
      width: 120px;
      height: 120px;
      padding: 3px;
      box-sizing: border-box;
      margin-bottom: 8px;

      img {
        display: inline-block;
        width: 100%;

        &.qr-wft {
          width: 148px;
          margin: -17px 0 0 0;
        }
      }
    }

    .icon-box {
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      .iconfont {
        font-size: 16px;
        margin-right: 8px;

        &.icon-wepay {
          color: #00AA74;
        }

        &.icon-alipay {
          color: #00A7E3;
        }
      }
    }

    /* 微信 */
    &.wx {
      color: #00AA74;

      .icon-box {
        width: 148px;
      }
    }

    /* 支付宝 */
    &.zfb {
      color: #00A7E3;
    }
  }

}

/* 协议 */
.protocol {
  font-size: 12px;
  color: #9397B1;
  line-height: 16px;
  text-align: center;

  a {
    color: $color-primary;
    @include ElementHover(rgba(105, 105, 255, 0.8), '');
  }

  //letter-spacing: 1px;
}


</style>
