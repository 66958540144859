/* 定义会员类型 */

/* 定义会员类型 */
import {MEMBER_LICENSE_VIP, MEMBER_LICENSE_VIP_PATH} from "@/constant/PATH";
import {
  COMPANY_All_PURPOSE,
  COMPANY_All_PURPOSE_HALF,
  COMPANY_ONLINE,
  COMPANY_ONLINE_HALF
} from "@/constant/VIP_COMPANY";

export const vipType = {
  person: 'personVip',      // 普通
  business: 'businessVip',  // 商用
  company: 'companyVip',    // 企业
}

export const VIP_CONFIG = {
  PERSON_VIP_STATE:'personVipState',
  PERSON_VIP_INFO:'personVipInfo',
  BUSINESS_VIP_STATE:'businessVipState',
  BUSINESS_VIP_INFO:'businessVipInfo',
  COMPANY_VIP_STATE:'companyVipState',
  COMPANY_VIP_INFO:'companyVipInfo'
}


export const VIP_STATE = {
  NO: '0',      // 非会员
  ON: '1',       // 会员
  OUT: '-1',      // 过期会员
}

// 普通VIP
export const PERSON_VIP_NAME = '普通VIP';
// 商用VIP
export const BUSINESS_VIP_NAME = '商用VIP';

/* 会员权限 充值页权限 */
export let permission = [
  {
    isPer: true,
    desc: '个人商用授权',
    iconClass: 'icon-a-1shang'
  },
  {
    isPer: true,
    desc: '图片下载无限',
    iconClass: 'icon-a-2img'
  },
  {
    isPer: true,
    desc: '在线申请发票',
    iconClass: 'icon-a-3ticket'
  },
  {
    isPer: true,
    desc: '图片版权保障',
    iconClass: 'icon-a-4copyright'
  },
  {
    isPer: true,
    desc: '图片包一键下载',
    iconClass: 'icon-a-12pack'
  },
  {
    isPer: true,
    desc: '新媒体用途',
    iconClass: 'icon-a-5media'
  },
  {
    isPer: true,
    desc: '电商平台',
    iconClass: 'icon-a-7dianshang'
  },
  {
    isPer: true,
    desc: '线上推广',
    iconClass: 'icon-a-6xianshang'
  },
  {
    isPer: true,
    desc: '户外广告',
    iconClass: 'icon-a-8huwai'
  },
  {
    isPer: true,
    desc: '传媒影视',
    iconClass: 'icon-a-9film'
  },
  {
    isPer: true,
    desc: '线下印刷',
    iconClass: 'icon-a-10printer'
  },
  {
    isPer: true,
    desc: '图书出版',
    iconClass: 'icon-a-11book'
  },
]

/* 会员权限 头部会员授权下拉权限 */
let permissionSimple = [
  {
    isPer: true,
    desc: ' 版权保障'
  },
  {
    isPer: true,
    desc: '在线开票'
  },
  {
    isPer: true,
    desc: '专属客服'
  },
  {
    isPer: true,
    desc: '新媒体用途'
  },
  {
    isPer: true,
    desc: '电商用途'
  },
  {
    isPer: true,
    desc: '企业办公'
  },
  {
    isPer: true,
    desc: '广告宣传'
  },
  // {
  //   isPer: true,
  //   desc: '传媒影视'
  // },
  {
    isPer: true,
    desc: '线下用途'
  },
  {
    isPer: true,
    desc: '其他用途'
  },

]

/* 普通VIP终身 */
export const PERSON_VIP_LIFELONG = {
  NAME: '普通VIP',
  FULL_NAME: '普通VIP终身',
  USER_CROWD: '限学习交流，不可商用',
  PARENT_ID: 1,
  ID: 19, // 后端9
  TXT_COLOR: '#35D0BA',
  PRICE: 259,
  DESC: '普通海量下载',
  VALID_TIME: '终身',
  CLASS_NAME: 'person',
  PERMISSION: permission,
  PERMISSION_ARR: [0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0],
  PERMISSION_SIMPLE: permissionSimple,
  PERMISSION_SIMPLE_ARR: [1, 1, 1, 0, 0, 0, 0, 0, 0],
  PERMISSION_TEXT: {
    FULL_STATION: '不支持',
    FOREVER_USER: '不支持',
    RESOURCES_DOWNLOAD: '不限'
  },
  DETAIL_URL: '/member/license-vip',
}

/* 普通VIP包年 */
export const PERSON_VIP_INFO = {
  NAME: '普通VIP',
  FULL_NAME: '普通VIP包年',
  USER_CROWD: '限学习交流，不可商用',
  PARENT_ID: 1,
  ID: 1,
  TXT_COLOR: '#35D0BA',
  PRICE: 99,
  DESC: '普通海量下载',
  VALID_TIME: '年',
  CLASS_NAME: 'person',
  PERMISSION: permission,
  PERMISSION_ARR: [0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0],
  PERMISSION_SIMPLE: permissionSimple,
  PERMISSION_SIMPLE_ARR: [ 1, 1, 1, 0, 0, 0, 0, 0, 0],
  PERMISSION_TEXT: {
    FULL_STATION: '不支持',
    FOREVER_USER: '不支持',
    RESOURCES_DOWNLOAD: '不限'
  },
  DETAIL_URL: '/member/license-vip',
}

/* 普通VIP包季 */
export const PERSON_QUARTER_INFO = {
  NAME: '普通VIP',
  FULL_NAME: '普通VIP包季',
  USER_CROWD: '限学习交流，不可商用',
  PARENT_ID: 1,
  ID: 5,
  PRICE: 39,
  DESC: '普通下载 10张/天',
  VALID_TIME: '季',
  CLASS_NAME: 'person',
  PERMISSION: permission,
  PERMISSION_ARR: [0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0],
  PERMISSION_TEXT: {
    FULL_STATION: '不支持',
    FOREVER_USER: '不支持',
    RESOURCES_DOWNLOAD: '10张/天'
  },
  DETAIL_URL: '/member/license-vip',

}

/* 普通VIP包月 */
export const PERSON_QUARTER_MONTH_INFO = {
  NAME: '普通VIP',
  FULL_NAME: '普通VIP包月',
  USER_CROWD: '限学习交流，不可商用',
  PARENT_ID: 1,
  ID: 7,
  PRICE: 19,
  DESC: '普通下载 5张/天',
  VALID_TIME: '月',
  CLASS_NAME: 'person',
  PERMISSION: permission,
  PERMISSION_ARR: [0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0],
  PERMISSION_TEXT: {
    FULL_STATION: '不支持',
    FOREVER_USER: '不支持',
    RESOURCES_DOWNLOAD: '5张/天'
  },
  DETAIL_URL: '/member/license-vip',

}

/* 商用VIP包年 */
export const BUSINESS_VIP_INFO = {
  NAME: '商用VIP',
  FULL_NAME: '商用VIP包年',
  // USER_CROWD: '商用授权，全渠道使用', // 使用人群
  USER_CROWD: '个人多渠道商用授权', // 使用人群
  PARENT_ID: 2,
  ID: 2,
  PRICE: 199,
  DESC: '商用海量下载 + 图片包',
  VALID_TIME: '年',
  CLASS_NAME: 'business',
  PERMISSION: permission,
  PERMISSION_ARR: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  PERMISSION_TEXT: {
    FULL_STATION: '支持',
    FOREVER_USER: '支持',
    RESOURCES_DOWNLOAD: '不限'
  },
  PERMISSION_SIMPLE: permissionSimple,
  PERMISSION_SIMPLE_ARR: [1, 1, 1, 1, 1, 1, 1, 0, 0, 0],
  DETAIL_URL: '/member/license-vip',

}

/* 商用VIP包季 */
export const BUSINESS_QUARTER_INFO = {
  NAME: '商用VIP',
  FULL_NAME: '商用VIP包季',
  USER_CROWD: '商用授权，全渠道使用', // 使用人群
  PARENT_ID: 2,
  ID: 6,
  PRICE: 69,
  DESC: '商用下载 50张/天',
  VALID_TIME: '年',
  CLASS_NAME: 'business',
  PERMISSION: permission,
  PERMISSION_ARR: [1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 1, 1],
  PERMISSION_TEXT: {
    FULL_STATION: '支持',
    FOREVER_USER: '支持',
    RESOURCES_DOWNLOAD: '50张/天',
  },
  DETAIL_URL: '/member/license-vip',

}

/* 商用VIP包月 */
export const BUSINESS_VIP_MONTH_INFO = {
  NAME: '商用VIP',
  FULL_NAME: '商用VIP包月',
  USER_CROWD: '商用授权，全渠道使用', // 使用人群
  PARENT_ID: 2,
  ID: 4,
  PRICE: 49,
  DESC: '商用下载 10张/天',
  VALID_TIME: '月',
  CLASS_NAME: 'business',
  PERMISSION: permission,
  PERMISSION_ARR: [1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 1, 1],
  PERMISSION_TEXT: {
    FULL_STATION: '支持',
    FOREVER_USER: '支持',
    RESOURCES_DOWNLOAD: '10张/天'
  },
  DETAIL_URL: '/member/license-vip',

}

/* 会员套餐列表 用于遍历查找 */
export const VIP_LIST = [
  PERSON_VIP_LIFELONG,
  PERSON_VIP_INFO,
  PERSON_QUARTER_INFO,
  PERSON_QUARTER_MONTH_INFO,
  BUSINESS_VIP_INFO,
  BUSINESS_QUARTER_INFO,
  BUSINESS_VIP_MONTH_INFO,
  COMPANY_All_PURPOSE,
  COMPANY_All_PURPOSE_HALF,
  COMPANY_ONLINE,
  COMPANY_ONLINE_HALF,
]

