<template>
  <div class="vip-label-wrap" v-if="isLogin">
    <!-- 企业VIP -->
    <div class="common-vip-box company-vip-box ">
      <div
        v-show="companyVipState === VIP_STATE.ON"
        :class="{
          'active': companyVipState === VIP_STATE.ON,
        }"
        class="company-vip common-vip-item"
      >
        <div class="end-time-tips end-time-tips1" >
          <div class="vip-name">{{  getVipNameByType(3,companyVipInfo.type) }}</div>
          <div class="vip-renew">
            <span >{{ companyVipInfo.END_TIME }}到期</span>
            <NavigationTo :path="companyVipInfo.PAY_URL" >
              <span slot="content" class="txt-renew">续费</span>
            </NavigationTo>
          </div>
        </div>
      </div>
    </div>

    <!-- 商用VIP -->
    <div class="common-vip-box business-vip-box">
      <div
        v-show="businessVipState === VIP_STATE.ON"
        :class="{ 'active': businessVipState === VIP_STATE.ON }"
        class="business-vip common-vip-item"
      >
        <!-- 到期时间 -->
        <div class="end-time-tips end-time-tips1" v-if="businessVipState === VIP_STATE.ON">
          <div class="vip-name">商用{{ businessVipInfo.vip | getVipTimeType }}VIP</div>
          <div class="vip-renew">
            <span >{{ businessVipInfo.END_TIME }}到期</span>
            <NavigationTo :path="businessVipInfo.PAY_URL">
              <span slot="content" class="txt-renew">续费</span>
            </NavigationTo>
          </div>
        </div>
      </div>
    </div>

    <!-- 普通VIP -->
    <div class="common-vip-box person-vip-box ">
      <div
        v-show="personVipState === VIP_STATE.ON"
        :class="{ 'active': personVipState === VIP_STATE.ON }"
        class="person-vip common-vip-item"
      >
        <!-- 到期时间 -->
        <div class="end-time-tips end-time-tips1">
          <div class="vip-name">普通{{ personVipInfo.vip | getVipTimeType }}VIP</div>
          <div class="vip-renew">
            <span v-if="personVipInfo.vip != 6">{{ personVipInfo.END_TIME }}到期</span>

            <template v-if="personVipInfo.vip != 6 && personVipInfo.vip != 1">
              <NavigationTo :path="personVipInfo.PAY_URL">
                <span slot="content" class="txt-renew">续费</span>
              </NavigationTo>
            </template>

            <!-- 体验vip跳转至终身 -->
            <template v-if="personVipInfo.vip == 1">
              <NavigationTo :path="PAGE_PATH.PAYMENT.PERSON">
                <span slot="content" class="txt-renew">续费</span>
              </NavigationTo>
            </template>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapState} from "vuex";
import {VIP_STATE} from "@/constant/VIP";
import {fnUser} from "@/fn";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import {getVipNameByType} from "@/fn/_Privately/User";

export default {
  name: "UserVipLabel",
  computed: {
    ...mapState('user',
      [
        'personVipState',
        'personVipInfo',
        'businessVipState',
        'businessVipInfo',
        'companyVipState',
        'companyVipInfo',
        'isLogin'
      ]
    ),
  },


  mounted() {
  },

  data() {
    return {
      VIP_STATE,
      isVip:false,
      PAGE_PATH
    }
  },
  methods:{

    getVipNameByType(moduleType,type){
      return getVipNameByType(moduleType,type)
    }

  },
  filters: {
    getVipTimeType(val) {
      switch (parseInt(val)) {
        case 5:
          return '年';
        case 4:
          return '季';
        case 3:
          return '月';
        case 6:
          return '终身';
        case 1:
          return '体验';
      }
    },
    getCompanyVipTimeType(val) {
      switch (parseInt(val)) {
        case 3:
          return '线上用途';
        case 5:
          return '全用途';
      }
    },
    end_time_handle(val) {
      if (typeof (val) === 'string') {
        return val.slice(0, 10);
      }
      return val;
    },

    disposeVip(type){
      if (type == 3){

      }
    }
  }
}
</script>

<style lang="scss" scoped>


@mixin LabelBg($bgImg,$bgActiveImg:'',$bgWidth:74px,$bgHeight:26px) {
  //@include BgImage($bgWidth, $bgHeight, 'home/vip/#{$bgImg}.png');
  position: relative;

  &.active {
    @include BgImage($bgWidth, $bgHeight, 'home/vip/#{$bgActiveImg}.svg');
  }
}


.txt-vip {
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
  padding-left: 32px;
  line-height: 100%;
}


/* 公共代码抽离 */
.common-vip-box {
  margin-right: 8px;
  display: flex;
  align-items: center;

  &:last-child {
    margin-right: 0;
  }

  .end-time {
    font-size: 12px;
    color: #596088;
    line-height: 18px;
    margin-left: 8px;
  }

  .common-vip-item {
    position: relative;

    &:after {
      content: '';
      width: 100%;
      height: 20px;
      background-color: transparent;
      @include Position(1, 4, 0, -19px);

    }

    @include ElementHover('', '') {
      .end-time-tips1 {
        display: block;
      }

      .end-time-tips3 {
        display: block;
      }
    }

    .txt-vip {
      //margin-top: 8px;
      font-weight: normal;
      @include Position(1, 2, 10px, 50%);
      transform: translateY(-40%);
    }

    .end-time-tips {
      //@include Position(1, 4, 3px, -50px);
      @include Position(1,5,3px,40px);
      line-height: 20px;
      //height: 28px;
      background: #424866;
      padding: 4px 8px;
      white-space: nowrap;
      border-radius: 4px;
      display: none;
      //font-size: 0;
      z-index: 3;
      color: #FFFFFF;
      font-size: 12px;

      .vip-name{

      }
      .vip-renew{
        white-space: nowrap;
        display: flex;

        .txt-renew{
          font-size: 12px;
          color: #EFC78C;
          margin-left: 3px;
          @include ElementHover('',''){
            text-decoration: underline;
          }
        }

      }

      a {
        font-size: 12px;
        color: #EFC78C;
        margin: 0 8px;

        @include ElementHover(#EFC78C, '') {
          text-decoration: underline;
        }
      }

      .icon-cha {
        font-size: 10px;
        color: #FFFFFF;
      }

      &:after {
        content: '';
        width: 6px;
        height: 6px;
        background-color: #424866;
        @include Position(1, 5, 11px, -3px);
        transform: rotate(135deg);
      }
    }
  }
}

.business-vip-box,.person-vip-box{
  margin-top: -2px;
}


/* 会员标签 */
.vip-label-wrap {
  display: flex;
  align-items: center;
  position: relative;


  .person-vip {
    @include BgImage( 35px,35px, '/user/vip/icon_vip%40person.svg');
  }

  .business-vip {
    @include BgImage( 35px,35px, '/user/vip/icon_vip%40business.svg');
  }

  .person-vip-box, .business-vip {
    .txt-vip {
      transform: translateY(-52%);
      .txt-vip-year {
        @include BgImage(30px, 12px, 'user/txt-year.svg');
      }

      .txt-vip-quarter {
        @include BgImage(31px, 12px, 'user/txt-quarter.svg');
      }

      .txt-vip-month {
        @include BgImage(31px, 12px, 'user/txt-month.svg');
      }

      .txt-vip-tiyan {
        @include BgImage(24px, 12px, 'user/txt-vip-tiyan.svg?v=1');
        position: relative;
        left: -4px;
      }

    }
  }

  .company-vip-box {

    position: relative;


    .company-vip {
      @include BgImage( 35px,35px, '/user/vip/icon_vip%40company.svg');
      margin-top: -3px;

      .txt-vip {
        //margin-top: 9px;
        //padding-left: 38px;
        //color: #FFE198;

        .txt-vip-online {
          @include BgImage(47px, 12px, 'user/txt-online.svg');
        }

        .txt-vip-all {
          //ftp://qianku%252Fqianku-js@v0.ftp.upyun.com/chaotu/user/txt-online.png
          @include BgImage(35px, 12px, 'user/txt-all.svg');
        }

      }

      &.active {
        .txt-vip {
          color: #FFE198;
        }
      }
    }
  }

  .VipNotification {
    @include Position(1, 4, 0, -36px);
  }

}
</style>
