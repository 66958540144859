
export const localCookies = [
  'isLogin',
  'isShowLoginCloseBtn',
  'phone',
  'pv_count_count',
  'vipIsBusinessData',
  'vipIsBusiness',
  'vipIsPersonData',
  'vipIsPerson',
  'downloadCount',
  'device',
  'personVipState',
  'personVipInfo',
  'businessVipState',
  'businessVipInfo',
  'companyVipState',
  'companyVipInfo',
  'userInfo',
  'isHasUserCoupon',
  'CREATOR_AUTH',
]

export const serverCookies = [
  'inviter_uniq_id',
  'auth_id',
  'user_uniq_id',
]
