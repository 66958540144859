
class CompanyController{

  /* 检查用户认证主体状态
  *  拒绝状态 0-待审核 1-通过 2-拒绝且不可下载 3-拒绝但仍可下载
  *  */
  checkMainAuthenticationStatus(status,reject_reason){
    const {$store} = $nuxt;

    if (status == 0){
      console.log("当前未认证")
      $store.commit('_Privately/Company/setShowMainAuthenticationModal',true);
      return false;
    }
    if (status == 2 || status == 3){
      $store.commit('_Privately/Company/setShowMainRiskWaringModalInfo',{
        show: true,
        message:reject_reason,
      });
      return false;
    }
  }
}

export default  new CompanyController();
