<template>
  <div class="inner-wrap">
    <div class="inner-content">
      <div class="user-box">
        <img
            :src="avatar"
            class="avatar">
        <div class="user-info">
          <div class="user-name">
            <span class="user-name-t">{{ '潮国创意用户' || name }}</span>
            <span class="user-id">
              潮国ID：<span>{{ uniq_id }}</span>
              <i
                  id="ClipboardBtn"
                  class="icon_copy _pointer"
                  :data-clipboard-text="uniq_id"
                  @click="copyUid"/>
            </span>
          </div>
          <no-ssr>
            <div class="user-vip">
              <div class="user-vip-label">
                <!--                <UserVipLabel class="UserVipLabel"/>-->
                <VipLabel class="UserVipLabel"/>

                <VipNotification/>
              </div>
              <!-- 开通vip -->
              <NavigationTo
                  v-if="vipIsPerson == 0 && vipIsBusiness == 0 && companyVipState == -1"
                  :path="MEMBER_LICENSE_VIP">
                <a
                    slot="content"
                    class="icon_vip-box-no-vip"
                    @click.prevent="tag('充值:个人中心顶部按钮:图片')"
                >
                  开通商用授权VIP
                </a>
              </NavigationTo>
            </div>
          </no-ssr>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {fnVip} from "@/fn";
import {BUSINESS_VIP_NAME, PERSON_VIP_NAME} from "@/constant/VIP";
import {MEMBER_LICENSE_VIP} from "@/constant/PATH";
import UserVipLabel from '@/components/user/user-vip-label'
import Clipboard from "clipboard";
import _ from 'lodash'
import VipNotification from '@/components/user/vip-notification'
import {tag} from "~/utils/statUtil";
import VipLabel from "@/components/user/vip-label";

export default {
  name: "UserCenterHeader",
  computed: {
    ...mapState('user', [
      'avatar',
      'name',
      'uniq_id',
      'vipIsPerson',
      'vipIsPersonData',
      'vipIsBusiness',
      'vipIsBusinessData',
      'companyVipState'
    ])
  },
  props: {
    userRecord: {
      type: Object,
      default: () => {
        return {
          lnum: 0,
          cnum: 0,
          dnum: 0,
        }
      }
    }
  },
  // eslint-disable-next-line vue/order-in-components
  components: {
    UserVipLabel,
    VipNotification,
    VipLabel
  },
  // eslint-disable-next-line vue/order-in-components
  data() {
    return {
      PERSON_VIP_NAME,
      BUSINESS_VIP_NAME,
      MEMBER_LICENSE_VIP
      /* userRecord: {
         lnum: 0,
         cnum: 0,
         dnum: 0,
       },*/
    }
  },
  methods: {
    tag(stat) {
      tag(stat)
    },
    copyUid: _.throttle(function () {
      let clipboard = new Clipboard('#ClipboardBtn')
      clipboard.on('success', e => {
        this.$myNotify.success("复制成功")
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        // 不支持复制
        this.$myNotify.show('该浏览器不支持自动复制')
        // 释放内存
        clipboard.destroy()
      })
    }, 2000),
  },
  filters: {
    getVipMaxGrade(val) {
      return fnVip.getVipMaxGrade(val)
    },
    end_time_handle(val) {
      if (typeof (val) === 'string') {
        return val.slice(0, 10);
      }
      return val;
    },
  }
}
</script>

<style lang="scss" scoped>
.inner-wrap {
  position: relative;

  &::after {
    content: '';
    @include Position(1, 5, 0, 0);
    @include BgImage(100%, 360px, 'user/bar-bg.jpg', 1);
    z-index: -1;

  }
}

.inner-content {
  width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 10;
  padding-top: 72px;
  //padding-bottom: 80px;

  .user-box {
    margin: 0px 0 56px;
    display: flex;

    .avatar {
      width: 96px;
      height: 96px;
      border: 1px solid rgba(255, 255, 255, 0.6);
      border-radius: 50%;
      //background-color: deepskyblue;
      margin-right: 25px;
    }

    .user-info {
      .user-name {
        //margin-bottom: 16px;
        margin: 12px 0 16px;
        display: flex;
        align-items: flex-end;

        .user-name-t {
          height: 31px;
          font-size: 24px;
          color: #0A2540;
          line-height: 31px;
        }

        .user-id {
          height: 18px;
          font-size: 13px;
          color: #596088;
          line-height: 18px;
          margin-left: 16px;
        }
      }

      .user-vip {

        .user-vip-label {
          display: flex;
          align-items: center;
          //justify-content: center;

          /deep/ .vip-label-wrap {
            .business-vip,.person-vip {
              @include IconVip(1, 35, 35);
            }

            .company-vip {
              @include IconVip(2, 35, 35);
            }
          }

          .UserVipLabel {
            margin-right: 12px;
          }
        }


        .icon_vip-box {
          display: inline-flex;
          height: 24px;
          background: linear-gradient(90deg, #65EADE 0%, #35D0BA 100%);
          border-radius: 0px 24px 24px 0px;
          font-size: 12px;
          color: #FFFFFF;
          line-height: 24px;
          padding: 0 10px 0 18px;
          position: relative;
          margin-left: 19px;

          i {
            @include Position(1, 5, -19px, 50%);
            transform: translateY(-50%);
          }


        }

        .icon_vip-box-sy {
          background: linear-gradient(47deg, #A2A2FF 0%, #6969FF 100%);
        }

        .icon_vip-box-all {
          height: 24px;
          padding: 0;

          .vip-gr-box, .vip-sy-box {
            display: inline-block;
            position: relative;

            @include ElementHover('', '') {
              .jbiao {
                display: block;
              }
            }
          }

          .jbiao {
            display: none;
            width: 198px;
            height: 47px;
            line-height: 47px;
            text-align: center;
            background: #FFFFFF;
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 26, 0.08);
            border-radius: 4px;
            @include Position(1, 5, 0, 45px);
            font-size: 14px;
            color: #001013;

            i {
              font-size: 12px;
              color: #596088;
            }

            &::before {
              content: '';
              display: inline-block;
              width: 10px;
              height: 10px;
              border-radius: 1px;
              background-color: #FFFFFF;
              //color: red;
              position: absolute;
              top: -5px;
              left: 14px;
              box-shadow: 0px 0px 8px 0px rgba(0, 0, 26, 0.08);
              transform: rotate(45deg);
            }

            &::after {
              content: '';
              display: inline-block;
              width: 30px;
              height: 10px;
              background-color: #fff;
              @include Position(1, 5, 0, 0);
              z-index: 2;
              left: 8px;
            }
          }
        }

        .end-time {
          height: 18px;
          font-size: 12px;
          color: #596088;
          line-height: 18px;
          margin-left: 16px;
        }

        .icon_vip-box-no-vip {
          display: inline-block;
          font-size: 12px;
          color: #FFFFFF;
          width: 120px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          background: linear-gradient(47deg, #A2A2FF 0%, #6969FF 100%);
          border-radius: 16px;
          //#8F8FE8   #6060E8
          @include ElementHover('', '') {
            background: linear-gradient(47deg, #8F8FE8 0%, #6060E8 100%);
          }
        }
      }
    }

    .user-record {
      display: flex;
      justify-content: flex-end;
      height: 48px;
      margin: 18px 16px 0 0;
      flex: 1;

      .record-item {
        @include LayoutFlex(2);
        margin-left: 64px;

        .name {
          height: 18px;
          font-size: 13px;
          color: #596088;
          line-height: 18px;
          margin-bottom: 6px;
        }

        .desc {
          height: 28px;
          font-size: 20px;
          font-weight: bold;
          color: #596088;
          line-height: 28px;
          @include ElementHover(#6969FF, '');
        }
      }
    }
  }

  .record-box {
    .record-menu {
      width: 164px;
      height: 480px;
      background: linear-gradient(180deg, #F5F6FA 0%, #F5F6FA 17%, #FBFBFD 100%);
      border-radius: 14px;
      padding: 8px 6px 0;
      box-sizing: border-box;
      position: relative;
      margin-right: 30px;
      float: left;


      .bar {
        background: #FFFFFF;
        box-shadow: 0px 2px 4px 0px rgba(10, 37, 64, 0.1);
        border-radius: 12px;
        transition: all 0.3s ease-in;
        position: absolute;
        top: 8px;

        &::before {
          content: '';
          display: inline-block;
          width: 2px;
          height: 36px;
          background: linear-gradient(180deg, #A2A2FF 0%, #6969FF 100%);
          border-radius: 2px;
          @include Position(1, 5, 0, 50%);
          transform: translateY(-50%);
        }
      }

      li {
        font-size: 14px;
        color: #596088;
        width: 152px;
        height: 60px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;

        .iconfont {
          font-size: 18px;
          margin: 0 10px 0 24px;
        }

        &.on {
          color: #6969FF;
        }

        @include ElementHover(#6969FF, '');

      }
    }


  }
}

.vip-gr {
  @include BgImage(38px, 38px, 'user/sy.svg');
}

.vip-sy {

  @include BgImage(38px, 38px, 'user/gr.svg');
}

.icon_copy {
  @include BgImage(12px, 12px, 'home/icon_copy.png');
  margin-left: 12px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
  }
}

</style>
