<template>
  <div class="PicTagLegalCopyRecommend">
    <i
      v-if="picItemInfo.type == 2"
      class="icon_PicTagLegalCopyRecommend" />
    <i
      v-if="picItemInfo.type == 3"
      class="icon_PicTagLegalCopySpecial" />
  </div>
</template>

<script>
import {
  MEMBER_LICENSE_VIP
} from "@/constant/PATH";

export default {
  name: "PicTagLegalCopyRecommend",
  props: {
    // 单个图片信息
    picItemInfo: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      MEMBER_LICENSE_VIP
    }
  }
}
</script>

<style lang="scss" scoped>
.PicTagLegalCopyRecommend{
  @include Position(1,5,16px,16px);

  .icon_PicTagLegalCopyRecommend{
    @include BgImage(88px,28px,'home/timeline-pic/icon_PicTagLegalCopyRecommend.svg');
  }
  .icon_PicTagLegalCopySpecial{
    @include BgImage(88px,28px,'home/timeline-pic/icon_PicTagLegalCopySpecial.svg?v=2');
  }
}

</style>
