<template>
  <div class="ProfitModal tip-dialog-wrap"
       v-if="isFreeDownDialog.isShow"
  >
    {{ isFreeDownDialog }}
    <CgModalMiddle

      @close="close">
      <div
        slot="modal_content"
        class="tip-dialog">
        <div class="title">
          当前下载：该图仅限非盈利用途使用
        </div>
        <div class="desc">
          仅供个人学习、交流使用，不可用于商业盈利性用途 <br>
          须署名素材来源：潮国创意
        </div>
        <div class="btn-group">
          <div
            class="btn-item btn-item-dl"
            @click="know">
            我知道了
          </div>
          <!--  @click="toggleVip();limitTag();" -->
          <div
            class="btn-item btn-item-sy"
            @click="navigatorToPay();limitTag();">
            购买商用VIP

            <!--            <RollMsgButton class="RollMsgButton"/>-->
          </div>
        </div>
      </div>
    </CgModalMiddle>
  </div>
</template>

<script>
import RollMsgButton from '@/components/common/header/header-vip-person/roll-msg-btn'
import {mapState} from "vuex";

export default {
  name: "ProfitModal",
  data() {
    return {
      isShowFreeDownload: false,
    }
  },
  computed: {
    ...mapState('pic', [ 'isFreeDownDialog']),
    tupianPackagePayDialogShow() {
      return this.$store.state.tupianPackage.tupianPackagePayDialog.isShow
    }
  },
  components: {
    RollMsgButton
  },
  methods: {
    navigatorToPay() {
      this.$emit('navigatorToPay')
    },
    limitTag() {
      this.$emit('limitTag')
    },
    know() {
      this.$store.commit('pic/SET_FREE_DOWN_DIALOG', {isShow: false})
      this.$emit('know')
    },
    close() {
      this.$store.commit('pic/SET_FREE_DOWN_DIALOG', {isShow: false})
    }
  }
}
</script>

<style lang="scss" scoped>
.tip-dialog-wrap {
  /deep/ .dialog-cover {
    z-index: 990 !important;
  }

  /deep/ .dialog-content {
    z-index: 991 !important;
  }
}

.tip-dialog {
  width: 561px;
  height: 350px;
  background: #FFFFFF;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 22px;
    font-weight: bold;
    color: #424866;
    line-height: 30px;
    margin: 86px 0 24px;
  }

  .desc {
    font-size: 14px;
    color: #596088;
    line-height: 20px;
    text-align: center;
    margin-bottom: 56px;
  }

  .btn-group {
    display: flex;

    .btn-item {
      width: 160px;
      height: 44px;
      line-height: 44px;
      text-align: center;
      border-radius: 25px;
      border: 1px solid #6969FF;
      cursor: pointer;
    }

    .btn-item-dl {
      margin-right: 24px;
      color: $color-primary;
      @include ElementHover(#FFFFFF, #5E5EE8);
    }

    .btn-item-sy {
      background: #6969FF;
      border-radius: 25px;
      color: #FFFFFF;
      position: relative;

      @include ElementHover('', #5E5EE8);

      .RollMsgButton {
        @include Position(1, 2, 16px, -21px);
      }
    }
  }
}
</style>
