<template>
  <!-- 用户登录下拉框

-->
  <div
    v-show="isShowCombobox"
    class="user-combobox _user-select">
    <div class="user-panel">

      <div class="avatar-box">
        <div class="avatar-wrap">
          <NavigationTo :path="USER_CENTER">
            <img
              slot="content"
              :src="avatar"
              class="avatar"
              alt="">
          </NavigationTo>
          <!-- 登录设备 -->
          <HeaderLoginDevice v-if="accountType === COMMON.HEADER.AVATAR_COMBOBOX.ACCOUNT_TYPE.PERSON" />
          <div class="company-role" v-else>
            成员
          </div>
        </div>

        <div class="user-box">
          <div class="user-name-box">
            <div class="user-name">潮国创意用户</div>
            <VipLabel/>
          </div>
          <div class="uid">
            <span class="uid">潮国ID：<span class="clipboardBtn">{{ uniq_id }}</span></span>
            <i
              id="ClipboardBtn"
              class="icon_copy"
              :data-clipboard-text=" uniq_id "
              @click="copyUid"/>
          </div>
          <div class="badge"/>
        </div>


        <template v-if="accountType === COMMON.HEADER.AVATAR_COMBOBOX.ACCOUNT_TYPE.PERSON">
          <div class="card-box ">
            <div class="card-item person">
              <div class="title">普通VIP</div>
              <div class="desc">
                {{ personVipState === VIP_STATE.ON ? filterTime(personVipInfo.end_time) + '到期' : '海量图片免费下' }}
              </div>

              <NavigationTo :path="PAY_PERSON">
                <a slot="content" class="btn" @click.prevent="tag('充值:头像下拉入口:图片')">
                  {{ personVipState === VIP_STATE.ON ? '续费' : '立即开通' }}
                </a>
              </NavigationTo>

            </div>
            <div class="card-item business">
              <div class="title">商用VIP</div>
              <div class="desc">
                {{ businessVipState === VIP_STATE.ON ? filterTime(businessVipInfo.end_time) + '到期' : '海量图片免费下' }}
              </div>
              <NavigationTo :path="PAY_BUSINESS">
                <a slot="content" class="btn" @click.prevent="tag('充值:头像下拉入口:图片')">
                  {{ businessVipState === VIP_STATE.ON ? '续费' : '立即开通' }}
                </a>
              </NavigationTo>
            </div>
            <div class="card-item company">
              <div class="title">企业VIP</div>
              <div class="desc">
                {{ companyVipState === VIP_STATE.ON ? filterTime(companyVipInfo.end_time) + '到期' : '商用授权有保障' }}
              </div>

              <NavigationTo :path="PAGE_PATH.PAYMENT.COMPANY">
                <a slot="content" target="_blank" class=" btn" @click.prevent="tag('充值:头像下拉入口:图片')">
                  {{ companyVipState === VIP_STATE.ON ? '续费' : '立即开通' }}
                </a>
              </NavigationTo>

            </div>
          </div>
          <div
            class="text-login-out _pointer"
            @click="loginOut">
            退出
          </div>
        </template>
        <template v-else>

          <!-- 退出切换企业按钮 -->
          <div class="toggle-company-box">
            <div class="toggle-company">
              <i class="icon_switch" />
              切换企业
            </div>
            <i class="icon_line" />
            <div
              class="txt-exit"
              @click="loginOut"
            >退出</div>
          </div>

          <div class="card-box card-box2">
          </div>

        </template>

      </div>

    </div>

    <!--  会员到期提示和过期提示  -->
    <div
      v-if="accountType === COMMON.HEADER.AVATAR_COMBOBOX.ACCOUNT_TYPE.PERSON"
      class="VipNotification-wrap" >
      <VipNotification class="VipNotification"/>
    </div>

    <template v-if="accountType === COMMON.HEADER.AVATAR_COMBOBOX.ACCOUNT_TYPE.PERSON">
      <div class="box">
        <div
            v-for="vo in list"
            :key="vo.name"
            class="box-item _pointer"
          >
            <NavigationTo :path="vo.path">
              <div slot="content">
                <i :class="vo.iconClass"/>
                <span>{{ vo.name }}</span>
              </div>
            </NavigationTo>
          </div>
      </div>
      <div
        v-if="downloadCount"
        class="free-download">
        <i class="icon_download"/>
        <span class="curr-down-count">
          今日免费下载<br>
          <span class="down-count">
            {{ downloadCount.today_free_down ? downloadCount.today_free_down : 0 }}
          </span>
          /{{ downloadCount.day_free_limit }}
        </span>

        <span class="line"/>

        <span class="curr-down-count">
          累计免费下载<br>
          <span class="down-count">
            {{ downloadCount.down_cnt }}</span> /{{ downloadCount.free_down_cnt }}
        </span>

        <div
          @click="navigationToInvite(7)"
          class="free-download-btn _pointer">
          <i class="icon_star"/>
          获得更多 <br>
          免费次数
        </div>
      </div>
      <div class="tips">
        *10次下载仅注册10天内有效
      </div>
    </template>

    <template v-else>

      <div class="box">
      </div>
    </template>
  </div>

</template>

<script>
//页面引入swiper
import {mapGetters, mapState} from "vuex";
import {
  MEMBER_LICENSE_VIP,
  USER_CENTER,
  INVITE_FRIENDS, PAY_BUSINESS, PAY_PERSON, PAY, COMPANY_INTRODUCE
} from "@/constant/PATH";
import {tag} from "@/utils/statUtil";
import {BUSINESS_VIP_INFO, PERSON_VIP_INFO} from "@/constant/VIP";
import {DEVICE} from "@/constant/SYSTEM";
import _ from 'lodash'
import Clipboard from "clipboard";
import VipLabel from '@/components/user/vip-label';
import {VIP_STATE} from "@/constant/VIP";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import VipNotification from '@/components/user/vip-notification/indexV2'
import {fnInviteFriend} from "~/fn";

import HeaderLoginDevice from '@/components/common/header/header-login/header-login-device'
import {COMMON} from "@/constant/COMMON";

export default {
  name: "UserCombobox",
  components: {
    VipLabel,
    VipNotification,
    HeaderLoginDevice
  },
  data() {
    return {
      list: COMMON.HEADER.AVATAR_COMBOBOX.USER_CENTER_NAV_LIST,
      isShowCombobox: false,
      MEMBER_LICENSE_VIP,
      USER_CENTER,
      INVITE_FRIENDS,
      PERSON_VIP_INFO,
      BUSINESS_VIP_INFO,
      DEVICE,
      PAY_PERSON,
      PAY_BUSINESS,
      VIP_STATE,
      PAY,
      COMPANY_INTRODUCE,
      PAGE_PATH,
      accountType: COMMON.HEADER.AVATAR_COMBOBOX.ACCOUNT_TYPE.COMPANY,
      COMMON
    }
  },
  computed: {
    ...mapGetters('user',
      ['name', 'uid', 'avatar', 'uniq_id', 'vipIsPerson', 'vipIsPersonData', 'vipIsBusiness', 'vipIsBusinessData', 'downloadCount']),
    ...mapState('system', ['device']),
    ...mapState('user',
      [
        'personVipState',
        'personVipInfo',
        'businessVipState',
        'businessVipInfo',
        'companyVipState',
        'companyVipInfo',
      ]
    ),
  },
  methods: {
    navigationTo(path) {
      this.$router.push(path);
    },
    loginOut() {
      this.$store.dispatch('user/loginOut');
      this.$emit('loginOut')
    },
    tag(stat) {
      tag(stat)
    },

    copyUid: _.throttle(function () {
      let clipboard = new Clipboard('#ClipboardBtn')
      clipboard.on('success', e => {
        this.$myNotify.success("复制成功")
        // this.updateCopyStatus(1)
        // setTimeout(() => {
        //   this.updateCopyStatus(0)
        // }, 2000)

        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        // 不支持复制
        this.$myNotify.show('该浏览器不支持自动复制')
        // 释放内存
        clipboard.destroy()
      })
    }, 2000),

    filterTime(val) {
      return val.slice(0, 10) || ''
    },

    navigationToInvite(){
      fnInviteFriend.inviteFriendEntry(7);
    }
  },
  // eslint-disable-next-line vue/order-in-components
  filters: {
    filterTime(val) {
      return val.slice(0, 10) || ''
    }
  }
}
</script>

<style lang="scss" scoped>

.VipNotification-wrap {
  margin: 0 28px;

  /deep/ .VipNotification {
    //margin: 0 28px;
    margin: 0 auto;
    //text-align: center;
    border-radius: 0px 0px 12px 12px !important;
    padding-left: 8px;
    box-sizing: border-box;

    .icon-cha {
      margin-left: 12px !important;
    }
  }
}

@keyframes fadeinT {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.user-combobox {
  width: 480px;
  //height: 333px;
  box-sizing: border-box;
  background: #FFFFFF;
  box-shadow: 0 0 8px 0 rgba(0, 0, 26, 0.08);
  border-radius: 12px;
  z-index: 10;
  //padding-bottom: 28px;
  //padding-bottom: 10px;
  animation: fadeinT 0.5s 1;
  overflow: hidden;
  cursor: default;


  .user-panel {
    padding: 28px 0 0 28px;
    height: 182px;
    background: linear-gradient(134deg, #F7F8FB 0%, #ECEFF6 100%);
    border-radius: 12px;
    box-sizing: border-box;
    position: relative;
    @include BgImage(480px, 182px, 'user/user-combobox-bg.png?v=1');

    .avatar-box {
      display: flex;
      align-items: center;

      .avatar-wrap {
        width: 52px;
        height: 52px;
        position: relative;
        margin-right: 16px;

        .avatar {
          display: inline-block;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          border: 1px solid rgba(255, 255, 255, 0.6);
          margin-right: 16px;
          overflow: hidden;
        }

        .company-role{
          height: 18px;
          background: #FFFFFF;
          border-radius: 9px;
          color: $color-primary;
          font-size: 12px;
          padding: 0 6px;
          @include Position(1,4,50%,-8px);
          transform: translateX(-50%);
          display: inline-block;
          white-space: nowrap;
        }
      }

      .user-box {
        margin-top: 4px;

        .user-name-box {
          display: flex;

          .user-name {
            font-size: 16px;
            color: #2D2B33;
            margin-right: 8px;
          }

        }

        .uid {
          font-size: 12px;
          color: #596088;
          line-height: 16px;
          margin-top: 6px;

          .icon_copy {
            @include BgImage(12px, 12px, 'home/icon_copy.png');
            margin-left: 12px;
            position: relative;

            &:after {
              content: '';
              position: absolute;
              top: -10px;
              right: -10px;
              bottom: -10px;
              left: -10px;
            }
          }
        }
      }

      .text-login-out {
        @include Position(1, 2, 28px, 28px);
        font-size: 14px;
        color: #6969FF;
        line-height: 20px;
        text-decoration: underline;

      }

      .card-box {
        width: 424px;
        @include Position(1, 4, 28px, 0);
        @include LayoutFlex(3);

        .VipNotification {
          //@include Position(1, 4, 0, -26px);
        }

        @mixin CardItem(
          $bgImg,
          $titleColor:#FFFFFF,
          $btnTxtColor:transparent,
          $btnBgColor:rgba(255, 255, 255, 0.9),
          $btnHoverBgColor:rgba(255, 255, 255, 1),
        ) {
          @include BgImage(136px, 78px, 'home/#{$bgImg}.png?v=5');
          padding: 8px 0 0 8px;
          box-sizing: border-box;
          position: relative;
          transition: all 0.5s ease-in-out;

          @include ElementHover('', '') {
          }
          .title {
            font-size: 14px;
            font-weight: bold;
            color: $titleColor;
            line-height: 20px;
          }
          .desc {
            font-size: 12px;
            color: $titleColor;
            line-height: 16px;
            margin: 2px 0 7px;
            font-weight: normal;

          }
          .btn {
            display: inline-block;
            padding: 0 8px;
            background: $btnBgColor;
            font-size: 12px;
            color: $btnTxtColor;
            line-height: 18px;
            border-radius: 4px;
            @include ElementHover('', $btnHoverBgColor);

            a {
              color: $btnTxtColor;
            }
          }

          .expire-tip {
            @include Position(1, 4, 0, -26px);
            display: flex;
            width: 293px;
            height: 28px;
            background: #424866;
            z-index: 3;
            font-size: 12px;
            color: #FFFFFF;
            @include LayoutFlex(1);
            border-radius: 4px;


            .remaining-days {
            }

            a {
              color: #EFC78C;
              margin: 0 12px 0 8px;
            }

            .icon-cha {
              font-size: 10px;
            }
          }
        }

        .card-item {
          @include LayoutFlex(2);

          &.person {
            @include CardItem('card-person', #ffffff, #29BEA9);
          }

          &.business {
            @include CardItem('card-business', #ffffff, #5C5CF2);
          }

          &.company {
            @include CardItem('card-company', #FFE198, #253F92, rgba(255, 225, 152, 0.9), rgba(255, 225, 152, 1));
          }
        }


      }

      .card-box2{

      }

      /* 退出切换企业按钮 */
      .toggle-company-box{
        //width: 140px;
        height: 28px;
        background: #FFFFFF;
        border-radius: 14px 0px 0px 14px;
        @include Position(1,2,0,10px);
        display: flex;
        align-items: center;
        padding: 0 2px 0 3px;

        .toggle-company{
          display: flex;
          align-items: center;
          font-size: 13px;
          color: #2D2B33;
          line-height: 16px;

          height: 22px;
          //background: #6969FF;
          border-radius: 13px;
          padding: 0 8px;

          .icon_switch{
            @include BgImage(16px,16px,'header/login/icon_switch.png');
            margin-right: 4px;
          }

          @include ElementHover('', rgba(105, 105, 255, 0.08) )
        }
        .icon_line{
          width: 1px;
          height: 14px;
          background: #CACDDE;
          border-radius: 1px;
          margin: 0 2px;
        }
        .txt-exit{
          font-size: 13px;
          color: #6969FF;
          line-height: 22px;
          padding: 0 8px;
          border-radius: 13px;

          @include ElementHover('', rgba(105, 105, 255, 0.08) )
        }
      }


    }
  }

  .box {
    margin-top: 20px;
    @include LayoutFlex(1);

    .box-item {
      width: 136px;
      height: 64px;
      background: rgba(245, 246, 250, 0.5);
      border-radius: 12px;
      margin-right: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include ElementHover('', #F5F6FA);


      &:last-child {
        margin-right: 0;
      }

      i {
        //margin-bottom: 10px;
        margin-right: 12px;
      }

      span {
        font-size: 13px;
        color: #596088;
        //line-height: 17px;
      }

      .icon_eyes {
        @include BgImage(24px, 24px, "home/images/user-combobox/icon_eyes.svg");
      }

      .icon_collect {
        @include BgImage(24px, 24px, "home/images/user-combobox/icon_collect.svg");
      }

      .icon_download {
        @include BgImage(24px, 24px, "home/images/user-combobox/icon_download.svg");
      }

      .icon_user {
        @include BgImage(24px, 24px, "home/images/user-combobox/icon_user.svg");
      }
    }

  }

  .free-download {
    width: 424px;
    height: 72px;
    background: #FFCE6B;
    border-radius: 12px;
    position: relative;
    display: flex;
    padding: 13px 28px;
    box-sizing: border-box;
    margin: 20px 28px 0;

    .icon_download {
      font-size: 18px;
      color: #2D2B33;
      margin-right: 12px;
      @include BgImage(72px, 72px, 'home/icon_download.png');
      @include Position(1, 5, 0, 0);
    }

    .curr-down-count {
      line-height: 24px;
      margin-top: -3px;
      font-size: 12px;
      color: #2D2B33;

      .down-count {
        font-size: 20px;
        font-weight: bold;
        color: #2D2B33;
        line-height: 20px;
      }

    }

    .line {
      width: 1px;
      height: 20px;
      background: rgba(45, 43, 51, 0.5);
      border-radius: 1px;
      margin: 13px 28px;
    }


    .free-download-btn {
      @include Position(1, 2, 0, 0);
      font-size: 13px;
      color: #FFFFFF;
      line-height: 19px;
      display: flex;
      align-items: center;
      transition: all 0.5s ease-in;

      width: 148px;
      height: 72px;
      background: #35D0BA;
      border-radius: 12px;

      @include ElementHover('', #2FBAAA) {
      }

      .icon_star {
        display: inline-block;
        @include BgImage(23px, 21px, 'login/icon_star.png');
        margin: 0 9px 0 31px;
      }
    }
  }

  .tips{
    font-size: 12px;
    color: #ED5700;
    line-height: 16px;
    padding: 4px 0 24px 28px;
  }
}
</style>
