/* localStorage 封装方法 */
import localStorage from "@/utils/localStorage";

class FnLocalStorage {
  constructor() {
  }

  getItemDate(key) {
    return localStorage.getItemDate(key);
  }

  setItemDate(key, value = 1) {
    localStorage.setItemDate(key, value);
  }

  getItem(key) {
    return localStorage.getItem(key);
  }

  setItem(key, val) {
    localStorage.setItem(key, val)
  }

  clearItem(key) {
    localStorage.remove(key)
  }


}

export default new FnLocalStorage();
