class FnHome {

  /* 处理每日推荐数据 */
  disposeDayRecommendList(data) {

    let index = 0
    for (const itemKey in data) {
      index += 1


      data[itemKey].forEach((vo, idx) => {
        vo._position = index
        vo._serial = idx + 1;
        // vo._location_date = itemKey

/*
        if (idx == 4 && vo.id == '9926'){
          vo.type = 3
          console.log('------------------vo')
          console.log(vo)
        }
*/



        /* 检查类型1 - 自选*/
        if (vo.type == 1 && vo.uniq_id == undefined) {
          data[itemKey].splice(idx, 1);
          idx--;
        }

        /* 检查类型2 - 自定义*/
        if (vo.type == 2) {

        }

        /* 检查类型3 - 专题 */
        if (vo.type == 3 && vo.topic_id == undefined) {
          data[itemKey].splice(idx, 1);
          idx--;
        }
      })

    }

    /*data.forEach( (item,index) =>{
      for (const itemKey in item) {
        item[itemKey].forEach((vo,idx) =>{
          vo._position = index + 1
          vo._serial = idx + 1;
          vo._location_date = itemKey
        })
      }
    })*/

    console.log('DATA----')
    console.log({
      data
    })

    return data;
  }

  /* 处理首页专题数据 */
  disposeTopicList(data) {
    data.forEach((item, index) => {
      item.list.forEach((vo, idx) => {
        vo._position = index + 1;
        vo._serial = idx + 1;
        vo._type = 3;
      })
    })

    return data;
  }
}

export default new FnHome();
