import {getFavoriteHistory, getFavoriteList} from "@/api/user/favorites";
import {fnFavorites, fnLogin} from "@/fn";
import {toggleMaterialCollect} from "@/api/material";
import myNotify from "@/utils/notify";

export default {
  namespaced: true,

  state() {
    return {
      showDialog: false,
      showDialogByCompany: false,
      showCreateDialog: false,
      showUpdateDialog: false,
      showCancelDialog: false,
      showMoveDialog: false,
      showCollectSuccessDialog: false,
      showCollectSuccessDialogTimer: false,  // 控制弹框延迟关闭
      showCreateErrDialog: false,

      currentTupianInfo: {},  // 当前收藏图片
      // lastFavorite: "",    // 最近一次收藏文件夹 默认默认文件夹
      favoriteList: [],    // 收藏夹列表
      favoriteHistory: -1, // 最近一次收藏文件夹 默认默认文件夹,

      collectSuccessInfo: {},    // 收藏成功信息和
    }
  },

  mutations: {
    SET_SHOW_DIALOG(state, payload) {
      state.showDialog = payload;
    },

    SET_SHOW_DIALOG_BY_COMPANY(state, payload) {
      state.showDialogByCompany = payload;
    },
    SET_SHOW_CREATE_DIALOG(state, payload) {
      state.showCreateDialog = payload;
    },
    SET_SHOW_UPDATE_DIALOG(state, payload) {
      state.showUpdateDialog = payload;
    },
    SET_SHOW_CANCEL_DIALOG(state, payload) {
      state.showCancelDialog = payload;
    },
    SET_SHOW_MOVER_DIALOG(state, payload) {
      state.showMoveDialog = payload;
    },
    SET_SHOW_COLLECT_SUCCESS_DIALOG(state, payload) {
      state.showCollectSuccessDialog = payload;
    },
    SET_SHOW_COLLECT_SUCCESS_DIALOG_TIMER(state, payload) {
      state.showCollectSuccessDialogTimer = payload;
    },

    SET_COLLECT_SUCCESS_INFO(state, payload) {
      state.collectSuccessInfo = payload;
    },

    SET_SHOW_CREATE_ERR_DIALOG(state, payload) {
      state.showCreateErrDialog = payload;
    },

    SET_CURRENT_TUPIAN_INFO(state, payload) {
      state.currentTupianInfo = payload;
    },
    SET_FAVORITE_LIST(state, payload) {
      state.favoriteList = payload;
    },
    SET_FAVORITE_HISTORY(state, payload) {
      state.favoriteHistory = payload;
    }
  },


  actions: {

    /* 获取收藏夹列表 */
    async getFavoriteList({dispatch, commit, state}, params ) {

      let _params = {
        sort: 'asc',
        page: 1,
        limit: 999,
      }
      _params = Object.assign(_params,params)


      let {data} = await getFavoriteList(_params);
      commit('SET_FAVORITE_LIST', data.data.list);

    },

    /* 查询上一次收藏夹信息 */
    async getFavoriteHistory({dispatch, commit, state}) {
      let {data} = await getFavoriteHistory();
      if (data.status == 200) {
        commit('SET_FAVORITE_HISTORY', data.data.history_id);
      }
    },

    /* 收藏图片 */
    async collect({dispatch, commit, state}, tupianItem = {
      tupianInfo: {}, // 图片信息
      handleType: "collect" // 图片操作类型，默认收藏
    }) {
      return new Promise(((resolve, reject) => {
        let {tupianInfo, handleType} = tupianItem
        /* 获取上一次收藏夹信息 */
        fnFavorites.saveCurrentTupianInfo(tupianInfo);
        dispatch('getFavoriteHistory');
        let isCollect = tupianInfo.isCollect || false
        toggleMaterialCollect({
          uniq_id: tupianInfo.uniq_id,
          // act: isCollect === false ? 'collect' : 'remove',
          act: handleType,
          pck: tupianInfo.package == 1 ? 'package' : 'general',
          favId: this.favoriteHistory,
          isFast: tupianInfo.isFast | 1
        }).then(res => {
          /*let msg = isCollect === false ? '收藏成功' : '已取消收藏';
          // this.$set(tupianInfo, 'isCollect', !tupianInfo.isCollect)
          // this.$myNotify.success(msg);
          console.log(res)
          let {data, status, message} = res.data;
          if (isCollect === false) {
            commit('SET_COLLECT_SUCCESS_INFO', data)
            fnFavorites.showCollectSuccessDialog(true);
          } else {
            myNotify.show('取消收藏成功')
          }*/

          resolve(res);
        }).catch(err=>{
          reject(err)
        })
      }))
    }
  }
}
