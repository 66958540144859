import Cookies from 'js-cookie'

class FnCookie {

  /**
   * 获取cookie
   * @param key
   * @returns {*}
   */
  get(key) {
    return Cookies.get(key)
  }

  /**
   * 设置cookie
   * @param key cookie key
   * @param value cookie 值
   * @param isExpires 设置过期时间
   * @returns {*}
   */
  set(key, value, isExpires) {

    if (isExpires) {
      return Cookies.set(key, value, {
        expires: isExpires
      });
    } else {
      return Cookies.set(key, value)
    }
  }

  /**
   * 删除cookie
   * @param key 删除cookie key 可为单个字符串或者多个字符串构成的数组
   */
  remove(value) {
    if (Array.isArray(key)) {
      key.forEach(cookie => {
        Cookies.remove(cookie);
      })
    } else {
      Cookies.remove(key);
    }
  }

}


export default new FnCookie();
