<template>
    <no-ssr>
      <div class="bar" id="Act618FloatBar_517"
      >
        <div class="bar-mask" v-if="isShow"></div>
        <a class="bar-img"
             :href="actLink"
             target="_blank"
            :class="{ 'show':isShow }"
            @click="close"
        >
          <div class="inner_center">
            <i class="iconfont icon-cha" @click.stop.prevent="close" />
          </div>
        </a>
      </div>
    </no-ssr>
</template>

<script >
import {ACT_618, NEW_YEAR_ACT} from "@/constant/_Public/Path";
  import {fnLocalStorage} from "@/fn";
  import {MODEL_ACT_618, MODEL_ACT_618_BAR} from "@/constant/MODELS";
  import {dataSign,dataSign2,dataSign3} from "@/pages/act/618/module";

  export default {
    name: "actFloatBar",
    data(){
      return{
        actLink:NEW_YEAR_ACT,
        isShow:false
      }
    },
    mounted() {

      /* 根据时间跳转对应期数的签到活动 */
      this.checkCurrentDateInDataSign()

      setTimeout(()=>{
        this.init();
      },200)
    },
    methods:{
      init(){
        let act618 = fnLocalStorage.getItemDate(MODEL_ACT_618_BAR)
        console.log(act618)
        this.isShow = act618 == undefined;
        console.log('111111111111111')
        console.log(this.isShow)
      },
      close(){
        this.isShow = false;
        fnLocalStorage.setItemDate(MODEL_ACT_618_BAR,1)
        /* 防止关闭不了，强制dom关闭 */
       setTimeout(()=>{
         document.getElementById('Act618FloatBar_517').style.display = "none";
       },300)

      },

       checkCurrentDateInDataSign() {
        const today = new Date();
        const month = today.getMonth() + 1; // 注意月份从0开始，所以加1
        const day = today.getDate();
        const currentDateStr = `${month}.${day}`;

         console.log({currentDateStr})

        if (dataSign.some(item => item.date == currentDateStr)){
          this.actLink = "/act/618";
          console.log(11111)
        }else if (dataSign2.some(item => item.date == currentDateStr)){
          this.actLink = "/act/618/2";
          console.log(222222)
        }else if (dataSign3.some(item => item.date == currentDateStr)){
          this.actLink = "/act/618/3";
          console.log(33333)
        }
    }
    }
  }
</script>

<style scoped lang="scss">

.bar-mask{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25) !important;
  z-index: 99;
}


.bar-img{
  width: 100%;
  height: 880px;
  display: block;
  @include BgImage2(100%,880px,"act/618/newYearBar.png", 1);
  vertical-align: middle;
  position: fixed;
  bottom: -999px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  cursor: pointer;
  transition: all 0.8s ease;

  &.show{
    bottom: 0;
  }


}

.inner_center{
  height: 100%;
  position: absolute;
}

.icon-cha {
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.7);
  color: rgba(0, 0, 0, 1);
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  font-size: 14px;
  @include ElementHover('', rgba(255, 255, 255, 0.8));
  position: absolute;
  bottom: 360px;
  right: 0;
}
</style>
