/* 新版个人VIP */
export const PERSON_VIP = {
  label: "个人VIP",
  desc: "个人海量下载，限个人学习交流使用",
  downScope: [
    {
      label: "限时免费",
      scope: true
    },
    {
      label: "个人会员专享",
      scope: true
    },
    {
      label: "企业会员专享",
      scope: false
    }
  ],
  useScope: [
    {
      label: "版权保障",
      scope: true
    },
    {
      label: "在线开票",
      scope: true
    },
    {
      label: "专属客服",
      scope: true
    },
    {
      label: "新媒体用途",
      scope: false
    },
    {
      label: "电商用途",
      scope: false
    },
    {
      label: "企业办公",
      scope: false
    },
    {
      label: "广告宣传",
      scope: false
    },
    {
      label: "线下用途",
      scope: false
    },
    {
      label: "其他用途",
      scope: false
    },
  ]
}

export const PERSON_VIP_LIST = [

]
