
export default {
  namespaced: true,
  state() {
    return {
      showDownLimitByPersonYearLimitModal: false
    }
  },

  mutations: {
    SET_SHOW_DOWN_LIMIT_BY_PERSON_YEAR_LIMIT_MODAL(state, payload) {
      state.showDownLimitByPersonYearLimitModal = payload;
    },

  },
  actions: {},
}
