<template>
  <div class="PicPackageButtonPlain">
    原图打包下载
  </div>
</template>

<script>
export default {
  name: "PicPackageButtonPlain"
}
</script>

<style lang="scss" scoped>
@import "common/pic-pcakage-button";
</style>
