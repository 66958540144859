import localStorage from "~/utils/localStorage";
import {MODEL_BIND_IPHONE, MODEL_USER_SURVEY} from "~/constant/MODELS";

import {fnLogin, fnUser} from '~/fn';
import {getCookie} from "@/utils/auth";

class BindIphone {
  constructor() {
  }

  /* 检查用户是否绑定手机号码 */
  checkIsBind() {
    if (fnLogin.checkLogin()) {
      if ( getCookie('phone') == 0  && !this.getTodayIsBind()) {
        console.log('--手机号码未绑定--')
        try {
          // cgBindIphone();
        } catch (e) {
          console.log(e)
        }
      }
    }
  }

  /* 获取当天本地绑定 */
  getTodayIsBind() {
    return localStorage.getItemDate(MODEL_BIND_IPHONE);
  }

  /**
   * 更新当天绑定状态
   * @param {String | Number ...} value
   */
  updateTodayBindState(value = 1) {
    localStorage.setItemDate(MODEL_BIND_IPHONE, value)
  }
}

export default new BindIphone();
