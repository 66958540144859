import request from '@/utils/request'
import qs from 'qs'

/* 充值漏斗 */
export function tagStat(params) {
  return request({
    url: '?m=Tag',
    method: 'post',
    data: qs.stringify(params)
  })
}
export function setTagCookie(params) {
  return request({
    url: '?m=Tag&a=setTagCookie',
    method: 'post',
    data: qs.stringify(params)
  })
}

export function queryJsConfig(query) {
  return request({
    url: '?m=WechatApi&a=jsConfig',
    method: 'get',
    params: query
  })
}

export function toggleStat(query) {
  return request({
    url: '?m=StatApi&a=toggleStat',
    method: 'get',
    params: query
  })
}

export function packageStat(query) {
  return request({
    url: '?m=StatApi&a=packageStat',
    method: 'get',
    params: query
  })
}

export function companyStat(query) {
  return request({
    url: '/?m=Company&a=track',
    method: 'get',
    params: query
  })
}
