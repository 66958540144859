/* 拉新活动 */
import {getIsNewUser, inviteActStatistical} from "~/api/invite-friends";
import {INVITE_FRIENDS} from "@/constant/PATH";

class FnInviteFriend {

  /* 检查是否是新注册用户 */
  checkIsNewUser() {
    let {$store} = $nuxt;
    let routerPath = $store.state.user.path;
    let inviteWhere = routerPath.indexOf('/invite/') > -1;
    let inviteH5Where = routerPath.indexOf('/invite-h5/') > -1;

    if (inviteWhere || inviteH5Where) {
      let params = {}
      if (inviteH5Where) {
        params.act_id = 4
      }
      getIsNewUser(params).then(res => {
        if (res.data.status === 1) {
          $store.commit('user/SET_IS_NEW_USER', 1)
        } else {
          $store.commit('user/SET_IS_NEW_USER', 2)
        }
      })
    }
  }

  inviteFriendEntry(entry) {
    let path = INVITE_FRIENDS + '?entry=' + entry;
    window.open(path, '_blank')
  }

  async inviteActStatistical(entry) {
    await inviteActStatistical({entry})
  }
}

export default new FnInviteFriend();
