
class FnThreeD {

  disposeList(list){
    if (Array.isArray(list) && list.length){
      list.forEach(item => {
        if (Array.isArray(item.childs) && item.childs.length){
          // item.classifyId = item.childs[0].id
          item.classifyId = -1;
        }
      })
      return list
    }else{
      return [];
    }


  }
}

export default new FnThreeD();
