var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-combobox _user-select"},[_c('div',{staticClass:"user-panel"},[_c('div',{staticClass:"avatar-box"},[_c('div',{staticClass:"avatar-wrap"},[_c('NavigationTo',{attrs:{"path":_vm.USER_CENTER}},[_c('img',{staticClass:"avatar",attrs:{"slot":"content","src":_vm.avatar,"alt":""},slot:"content"})]),_vm._v(" "),(_vm.accountType === _vm.COMMON.HEADER.AVATAR_COMBOBOX.ACCOUNT_TYPE.PERSON)?_c('HeaderLoginDevice'):_c('div',{staticClass:"company-role"},[_vm._v("成员")])],1),_vm._v(" "),_c('div',{staticClass:"user-box"},[_c('div',{staticClass:"user-name-box"},[_c('div',{staticClass:"user-name"},[_vm._v("潮国创意用户")]),_vm._v(" "),_c('VipLabel')],1),_vm._v(" "),_c('div',{staticClass:"uid-box"},[_c('span',{staticClass:"uid"},[_vm._v("潮国ID："),_c('span',{staticClass:"clipboardBtn"},[_vm._v(_vm._s(_vm.uniq_id))])]),_vm._v(" "),_c('i',{staticClass:"icon_copy",attrs:{"id":"ClipboardBtn","data-clipboard-text":_vm.uniq_id},on:{"click":_vm.copyUid}}),_vm._v(" "),(_vm.downloadCount.free_down_cnt - _vm.downloadCount.down_cnt > 0)?_c('div',{staticClass:"free-down"},[_vm._v("\n            免费下载（"+_vm._s(_vm.downloadCount.free_down_cnt - _vm.downloadCount.down_cnt >
              _vm.downloadCount.day_free_limit
                ? _vm.downloadCount.day_free_limit
                : _vm.downloadCount.free_down_cnt - _vm.downloadCount.down_cnt > 0
                ? _vm.downloadCount.free_down_cnt - _vm.downloadCount.down_cnt
                : 0)+"次/"+_vm._s(_vm.downloadCount.free_down_cnt - _vm.downloadCount.down_cnt > 0
                ? _vm.downloadCount.free_down_cnt - _vm.downloadCount.down_cnt
                : 0)+"次）\n          ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"badge"})]),_vm._v(" "),_c('div',{staticClass:"card-box"},[_c('div',{staticClass:"card-item company"},[_c('div',{staticClass:"title"},[_vm._v("企业VIP")]),_vm._v(" "),_c('div',{staticClass:"desc"},[_vm._v("商用版权保障，授权书在线下载")]),_vm._v(" "),_c('NuxtLink',{staticClass:"btn",attrs:{"to":_vm.PAGE_PATH.PAYMENT.COMPANY,"target":"_blank"},on:{"click":function($event){return _vm.tag('充值:头像下拉入口:图片')}}},[_vm._v("\n            立即开通\n          ")]),_vm._v(" "),_c('i',{staticClass:"icon_vip"})],1),_vm._v(" "),_c('div',{staticClass:"card-item business"},[_c('div',{staticClass:"title"},[_vm._v("个人VIP")]),_vm._v(" "),_c('div',{staticClass:"desc"},[_vm._v("\n            "+_vm._s(_vm.personCase)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"vip-status"},[(_vm.personVipInfo.vip != 6)?_c('a',{staticClass:"btn",attrs:{"href":_vm.PAY_PERSON,"target":"_blank"},on:{"click":function($event){return _vm.tag('充值:头像下拉入口:图片')}}},[_vm._v("\n              "+_vm._s(_vm.personVipState === _vm.VIP_STATE.ON ? "续费" : "立即开通")+"\n            ")]):_vm._e(),_vm._v(" "),(
                _vm.personVipInfo.vip != 6 && _vm.personVipInfo.remaining_days <= 365
              )?_c('a',{staticClass:"btn",attrs:{"href":_vm.PAGE_PATH.BASE_URL + _vm.PAGE_PATH.PAYMENT.UPGRADE,"target":"_blank"}},[_vm._v("\n              "+_vm._s(_vm.personVipState === _vm.VIP_STATE.ON ? "升级" : "")+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.personVipInfo.vip == 6)?[_c('a',{staticClass:"btn",attrs:{"href":_vm.personVipState == _vm.VIP_STATE.ON
                    ? _vm.setUrl(_vm.payment.UPGRADE)
                    : _vm.PAY_PERSON,"target":"_blank"}},[_vm._v("\n                "+_vm._s(_vm.personVipState == _vm.VIP_STATE.ON ? "升级" : "立即开通")+"\n              ")])]:_vm._e()],2),_vm._v(" "),_c('i',{staticClass:"icon_vip"})])])])]),_vm._v(" "),_c('div',{staticClass:"VipNotification-wrap"},[_c('VipNotification',{staticClass:"VipNotification"})],1),_vm._v(" "),_c('div',{staticClass:"box"},_vm._l((_vm.list),function(vo){return _c('a',{key:vo.name,staticClass:"box-item _pointer",attrs:{"href":vo.path,"target":"_blank"},on:{"click":function($event){$event.preventDefault();return _vm.navTo(vo)}}},[_c('i',{staticClass:"icon",class:[vo.iconClass, { _hide: vo.name === '领优惠券' }]}),_vm._v(" "),_c('span',{staticClass:"name",class:{ _hide: vo.name === '领优惠券' }},[_vm._v(_vm._s(vo.name)+"\n        "),(vo.name === '免费下载')?_c('i',{staticClass:"iconfont icon-question"},[_vm._m(0,true)]):_vm._e()]),_vm._v(" "),(vo.name === '宠粉专区')?_c('span',{staticClass:"red-circle"}):_vm._e(),_vm._v(" "),(vo.name === '领优惠券')?_c('div',{staticClass:"box-item-coupon"},[_c('i',{staticClass:"icon_coupon"})]):_vm._e()])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"dropbox"},[_c('span',{staticClass:"dropbox-item"},[_vm._v("\n              您可在素材详情页将链接分享给好友，好友通过专属链接注册成功，即可免费下载此图。\n            ")])])
}]

export { render, staticRenderFns }