
export default {
  namespaced: true,

  state(){
    return{
      showMainAuthenticationModal: false, // 主体认证填写弹框
      showMainRiskWaringModalInfo: {
        show:false,
        message: ""
      }, // 主体认证填写弹框
      showMainInReviewModal:false
    }
  },

  mutations:{
    setShowMainAuthenticationModal(state,payload){
      state.showMainAuthenticationModal = payload;
    },
    setShowMainRiskWaringModalInfo(state,payload){
      state.showMainRiskWaringModalInfo = payload;
    },
    setShowMainInReviewModal(state,payload){
      state.showMainInReviewModal = payload;
    },
  }
}
