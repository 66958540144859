export default {
  namespaced: true,
  state(){
    return {
      screenWidth: 0,
      bannerHeight: 127,
      isShowSearch: false     /* 头部搜索 */
    }
  },
  getters: {
    screenWidth: (state) => {
      return state.screenWidth;
    },
    bannerHeight: (state) => {
      return state.bannerHeight;
    }
  },
  mutations: {
    SET_SCREEN_WIDTH(state, payload) {
      state.screenWidth = payload;
    },
    SET_BANNER_HEIGHT(state, payload) {
      state.bannerHeight = payload;
    },
    SET_IS_SHOW_SEARCH(state, payload) {
      state.isShowSearch = payload
    }
  },
  actions: {},
}
