<template>
  <div
      :class="[picItemInfo.isCollect ? 'on':'no']"
      class="PicTagCollectNew  collect2 _pointer"

      @mouseover="PicTagCollectMouseover({ picItemInfo,idx})"
      @mouseleave="PicTagCollectMouseleave({ picItemInfo,idx})"
  >

    <!-- 未收藏 -->
    <div class="uncollected" v-if="!picItemInfo.isCollect">
      <!-- 收藏 -->
      <div class="txt-collect" @click="showCollectDialog(picItemInfo)">
        收藏
      </div>
      <!-- 快捷收藏 -->
      <div class="kuaic-box dropbox-p2" @click="collectFast(picItemInfo)">
        <i class="icon_kuaic"></i>

        <div class="dropbox">
          <div class="dropbox-item">
            {{ '快速收藏到文件夹' }}
          </div>
        </div>
      </div>
    </div>


    <!-- 已收藏 -->
    <div v-else
         class="collect dropbox-p"
         @click="toggleCollect(picItemInfo)">
      已收藏

      <div class="dropbox">
        <div class="dropbox-item">
          {{ '取消收藏' }}
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import {toggleMaterialCollect} from "@/api/material";
import {fnFavorites, fnLogin} from "@/fn";
import {mapState} from "vuex";
import {completeTaskByFanClub} from "@/api/fans/club";
import {COMMON} from "@/constant/COMMON";


export default {
  name: "PicTagCollectNew",
  props: {
    // 单个图片信息
    picItemInfo: {
      type: Object,
      default: () => {
      }
    },
    idx: {
      type: String | Number,
      default: ''
    }
  },
  computed: {
    ...mapState('favorites',
        [
          "showDialog",
          "currentTupianInfo",
          "favoriteList",
          "favoriteHistory"
        ]
    ),
    ...mapState('user',
        [
          'personVipState',
          'personVipInfo',
          'businessVipState',
          'businessVipInfo',
          'companyVipState',
          'companyVipInfo',
          'userInfo',
          'companyInfoByPerson',
        ],
    ),
  },
  methods: {
    toggleCollect(vo) {
      if (fnLogin.checkLogin()) {
        let isCollect = vo.isCollect || false
        toggleMaterialCollect({
          uniq_id: vo.uniq_id,
          act: isCollect === false ? 'collect' : 'remove',
          pck: this.picItemInfo.package == 1 ? 'package' : 'general',
          favId: vo.favoriteId || 0,
          isFast: false
        }).then(res => {
          let msg = isCollect === false ? '收藏成功' : '已取消收藏';
          this.$set(vo, 'isCollect', !vo.isCollect)
          this.$myNotify.success(msg);
          console.log(res)
          let {data, status, message} = res.data;
          console.log(data)
          // this.$store.commit('favorites/SET_COLLECT_SUCCESS_INFO', data)
          // fnFavorites.showCollectSuccessDialog(true);

          /* 检查是否是领取任务 */
          setTimeout(() => {
            let {t_id, task_type} = this.$route.query
            if (task_type == 1) {
              completeTaskByFanClub({id: t_id}).then(res => {
                let {status, data, message} = res.data;
                if (status == 200) {
                  this.$myNotify.success("任务已完成")
                  this.$router.push({
                    query: {}
                  })

                } else {
                  this.$myNotify.error(message)

                }
              })
            }
          }, 1000)

        })
      } else {
        fnLogin.loginIntercept('请先登录');
      }
    },

    async collectFast(picItemInfo) {
      if (fnLogin.checkLogin()) {
        fnFavorites.saveCurrentTupianInfo(picItemInfo);
        this.$store.dispatch('favorites/collect', {
          tupianInfo: picItemInfo
        }).then(res => {
          this.$set(picItemInfo, 'isCollect', !picItemInfo.isCollect)
          let {data, status, message} = res.data;
          this.$store.commit('favorites/SET_COLLECT_SUCCESS_INFO', data)
          this.$store.commit('favorites/SET_SHOW_COLLECT_SUCCESS_DIALOG_TIMER', true)
          setTimeout(() => {
            fnFavorites.showCollectSuccessDialog(true);
          }, 300)

          /* 检查是否是领取任务 */
          setTimeout(() => {
            let {t_id, task_type} = this.$route.query
            if (task_type == 1) {
              completeTaskByFanClub({id: t_id}).then(res => {
                let {status, data, message} = res.data;
                if (status == 200) {
                  this.$myNotify.success("任务已完成")
                  this.$router.push({
                    query: {}
                  })

                } else {
                  this.$myNotify.error(message)

                }
              })
            }
          }, 1000)

        })
      } else {
        fnLogin.loginIntercept('请先登录');
      }


    },

    PicTagCollectMouseover(info) {
      this.$emit('PicTagCollectMouseover', info)
    },
    PicTagCollectMouseleave(info) {
      this.$emit('PicTagCollectMouseleave', info)
    },

    showCollectDialog(picItemInfo) {

      if (fnLogin.checkLogin()) {
        fnFavorites.saveCurrentTupianInfo(picItemInfo);


        if (this.companyInfoByPerson.switch && this.companyInfoByPerson.switch.type == COMMON.HEADER.AVATAR_COMBOBOX.ACCOUNT_TYPE.COMPANY) {
          this.$store.dispatch('favorites/getFavoriteList', {mode: 'share'})
          fnFavorites.showDialogByCompany();

        } else {
          this.$store.dispatch('favorites/getFavoriteList', {mode: 'private'})
          fnFavorites.showDialog();

        }

        /* 保存当前选中图片信息 */


      } else {
        fnLogin.loginIntercept(false)
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.collect2 {
  @include Position(1, 2, 16px, -50px);
  //@include Position(1, 2, 16px, 50px);
  transition: all 0.5s ease-in-out;
  z-index: 2;

  .uncollected {
    width: 80px;
    height: 32px;
    //background: #6969FF;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    position: relative;
    display: flex;

    .txt-collect {
      font-size: 14px;
      color: #FFFFFF;
      line-height: 32px;
      padding-left: 15px;
      width: 57px;
      border-radius: 8px 0 0 8px;
      box-sizing: border-box;
      background: #6969FF;
      cursor: pointer;

      &:hover {
        background: rgba(120, 120, 255, 1);
      }
    }

    .kuaic-box {
      width: 23px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #6969FF;
      border-radius: 0 8px 8px 0;
      cursor: pointer;

      .icon_kuaic {
        @include BgImage(11px, 14px, 'tupian/kuaic.svg');
      }

      &:hover {
        background: rgba(120, 120, 255, 1);
      }
    }


    &:after {
      content: '';
      display: inline-block;
      width: 1px;
      height: 18px;
      background: rgba(234, 239, 246, 0.2);
      @include Position(1, 5, 56px, 50%);
      transform: translateY(-50%);
    }
  }

  .collect {
    width: 57px;
    height: 32px;
    line-height: 32px;
    background: #F8CC44;
    border-radius: 8px;
    font-size: 14px;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;

    &:hover {
      background: rgba(222, 183, 64, 1);
    }
  }

  /*&.on {
    background-color: #F8CC44;

    .icon-collect {
      color: #FFFFFF;
    }

    &:hover {
      background: #DEB740;
    }


  }

  &.no {
    .icon-collect {
      color: #596080;
    }

    &:hover {
      .icon-collect {
        color: #F8CC44;
      }
    }
  }*/

}


.dropbox-p {
  position: relative;

  &:hover {
    .dropbox {
      display: block !important;
    }
  }

  .dropbox {
    @include Position(1, 5, 50%, 42px);
    transform: translateX(-50%);
    z-index: 9;
    background: #424866;
    border-radius: 8px;
    display: none;


    &:after {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #424866;
      @include Position(1, 5, 50%, -5px);
      transform: translateX(-50%) rotate(135deg);
    }

    .dropbox-item {
      //width: 200px;
      height: 28px;
      line-height: 28px;
      padding: 0px 8px;
      font-size: 12px;
      color: #FFFFFF;
      white-space: nowrap;

      &:last-child {
        margin-bottom: 0;
      }

    }
  }
}

.dropbox-p2 {
  position: relative;

  &:hover {
    .dropbox {
      display: block !important;
    }
  }

  .dropbox {
    //@include Position(1, 5, 50%, 42px);
    @include Position(1, 2, -64px, 42px);
    transform: translateX(-50%);
    z-index: 9;
    background: #424866;
    border-radius: 8px;
    display: none;


    &:after {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #424866;
      @include Position(1, 2, 8px, -4px);
      transform: translateX(-50%) rotate(135deg);
    }

    .dropbox-item {
      //width: 200px;
      height: 28px;
      line-height: 28px;
      padding: 0px 8px;
      font-size: 12px;
      color: #FFFFFF;
      white-space: nowrap;

      &:last-child {
        margin-bottom: 0;
      }

    }
  }


}


</style>
