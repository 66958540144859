import {getCookie, setCookie, setCustomCookie} from "@/utils/auth";
import {DEVICE} from "@/constant/SYSTEM";

export default {
  namespaced: true,
  state(){
    return{
      device: getCookie('device') || DEVICE.iphone,  // 1手机 2微信 3QQ
    }
  },

  mutations: {
    SET_DEVICE(state, payload) {
      setCustomCookie('device',payload)
      state.device = payload;
    },
  },
  actions: {},
}
