/**
 * @Author: wulizhou
 * @Description:  签约模块api
 * @Date:
 **/

import request from "@/utils/request";
import qs from "qs";

/* 获取签约状态 */
export function getSignStatus(query) {
  return request({
    url: '/?m=PartTimeJob&a=auditProgress',
    method: 'get',
    params: query
  })
}


/* 银行列表 */
export function getBankList(query) {
  return request({
    url: '/?m=Config&a=bank',
    method: 'get',
    params: query
  })
}

/* 签约 */
export function sign(data) {
  return request({
    url: '/?m=PartTimeJob&a=apply',
    method: 'post',
    data: qs.stringify(data)
  })
}


/* 审核详情 */
export function getAuditDetail(query) {
  return request({
    url: '/?m=PartTimeJob&a=auditDetail',
    method: 'get',
    params: query
  })
}

/* 获取法大大二维码 */
export function getFddCode(query) {
  return request({
    url: '/?m=PartTimeJob&a=extSign',
    method: 'get',
    params: query
  })
}

/* 获取法大大续签二维码 */
export function getFddRenewCode(query) {
  return request({
    url: '/?m=PartTimeJob&a=getFadadaUrl',
    method: 'get',
    params: query
  })
}


/* 签约合同有效期 */
export function getContractExpire(query) {
  return request({
    url: '/?m=PartTimeJob&a=contractExpire',
    method: 'get',
    params: query
  })
}


/* 下载合同  */
export function downSignContract(query) {
  return request({
    url: '/?m=PartTimeJob&a=contractDownload',
    method: 'get',
    params: query
  })
}
