<template>
  <div class="CgInput __form">
    <div class="__form-input">
      <input
        ref="input"
        :placeholder="inputItem.placeholder"
        type="text"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
        @change="handleChange"
        v-bind="$attrs"
      >
<!--      <i
        v-if="inputItem.inputVal.length || inputItem.input.length"
        class="iconfont icon-error-circle-fill"
        @click="clear"
      />-->
      <span
        v-if="inputItem.isShowErrTxt"
        class="input-err">{{ inputItem.errTxt }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "CgInput",
  props: {
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    inputItem: {
      type: Object,
      default: () => {}
    },
    value: [String, Number],
  },
  computed: {
    nativeInputValue() {
      return this.value === null || this.value === undefined ? '' : String(this.value);
    },
  },
  watch: {
    nativeInputValue() {
      this.setNativeInputValue();
    },
  },
  mounted() {
    this.setNativeInputValue();
  },
  methods: {
    handleInput(event) {

      this.$emit('input', event.target.value);
    },
    handleChange(event) {
      this.$emit('change', event.target.value);
    },
    handleFocus(event) {
      this.$emit('focus', event);
    },
    handleBlur(event) {
      this.$emit('blur', event);
    },
    clear() {
      this.$emit('input', '');
      this.$emit('change', '');
      this.$emit('clear');
    },


    getInput() {
      return this.$refs.input || this.$refs.textarea;
    },

    setNativeInputValue() {
      const input = this.getInput();
      if (!input) return;
      if (input.value === this.nativeInputValue) return;
      input.value = this.nativeInputValue;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "styles/form";

.CgSelect {
  .select {
    width: 200px;
    line-height: 50px;
    border: 1px solid #000;
    border-radius: 4px;
  }
}
</style>
