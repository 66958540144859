<template>
  <transition name="fade"
              enter-active-class="fadeOutLeftBig"
              leave-active-class="fadeInUp"
              @after-leave="afterLeave"
              @after-enter="afterEnter">
    <!--  @mouseenter="clearTimer" @mouseleave="createTimer"  -->
    <div class="notification class-pointer"
         :style="style" v-show="visible">
      <div class="box">
        <div class="icon_box" :style="{  background:message[type].bgColor }">
          <i class="iconfont" :class="message[type].iconClass" :style="{  color:message[type].color }"></i>
        </div>
        <span class="content">{{ content }}</span>
      </div>
    </div>
  </transition>
</template>

<script>
let message = [
  {
    iconClass: "icon-info",
    color: "rgb(19, 149, 238)",
    bgColor: "rgba(19, 149, 238, 0.1)",
  },
  {
    iconClass: "icon-success",
    color: "rgb(53, 208, 186)",
    bgColor: "rgba(53, 208, 186, 0.1)",
  },
  {
    iconClass: "icon-error",
    color: "rgb(238, 82, 83)",
    bgColor: "rgb(238, 82, 83, 0.1)",
  },
  {
    iconClass: "icon-alert",
    color: "rgb(248, 204, 68)",
    bgColor: "rgba(248, 204, 68, 0.1)",
  }
]
export default {
  name: 'Notification',
  props: {
    content: {
      type: String,
      default: '这是一条成功提示',
    },
    btn: {
      type: String,
      default: '关闭'
    },
    // 提示类型 0默认 1成功 2错误 3警告
    type: {
      type: String | Number,
      default: 0,
    }
  },
  data() {
    return {
      visible: true,
      message
    }
  },
  computed: {
    style() {
      return {}
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
    afterLeave() {
      this.$emit('closed')
    },
    afterEnter() {
    },
    clearTimer() {
    },
    createTimer() {
    }
  }
}
</script>

<style lang="scss" scoped>
//@import "/src/renderer/styles/class";
@keyframes fadeOutLeftBig {
  from {
    opacity: 0;
    transform: translate3d(65px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 1;
    transform: none;
    transition: 1s;

  }
  to {
    opacity: 0;
    transform: translate3d(0, -65px, 0);
  }
}

.fadeOutLeftBig {
  animation-duration: 500ms;
  animation-name: fadeOutLeftBig;
}

.fadeInUp {
  animation-duration: 500ms;
  animation-name: fadeInUp;
}

.iconfont {
  font-size: 22px;
  margin-right: 5px;
}

.notification {
  position: fixed;
  transition: all .3s;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 13px;
  z-index: 99999999;
  height: 0.6rem;
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  top: -100px;

  .box {
    box-shadow: 0px 4px 8px 0px rgba(10, 37, 64, 0.1);
    border-radius: 3rem;
    padding: 0 0.5rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  }


  .icon_box {
    width: 42px;
    height: 42px;
    background: #1395EE;
    @include LayoutFlex(1);
    border-radius: 50%;
    margin-right: 20px;

    .iconfont {
      font-size: 28px;
      margin-left: 6px;
      margin-top: -1px;
    }
  }

  .content {
    font-size: 30px;
    color: #2D2B33;
    line-height: 52px;
  }

}


.content {
  padding: 0;
}

.btn {
  color: #ff4081;
  padding-left: 24px;
  margin-left: auto;
  cursor: pointer;
}


.notification {

  //width: 212px;


}
</style>
