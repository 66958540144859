<template>
  <div
    :class="[ {'on': NAV.FREE.id == navIndex} ]"
    class="NavItemHot menu-item"
    @mouseover="isShow = true"
    @mouseleave="isShow = false"
  >
    <a
      :href="freeNavLink"
      target="_blank"
      @click="updateNavId(NAV.FREE.id)"
    >
      免费专区
      <i
        v-for="(vo,idx) in HEADER.NAV.FREE.icons"
        :key="idx"
        class="icon_NavItemHot"
        :class="vo.type"
        :style="{background:'url('+ PAGE_PATH.BASE_IMG_UPLOAD + vo.url+') no-repeat center/100% '}"
      />
    </a>


    <!-- 下拉 -->
<!--    <div
      v-show="isShow && (downloadCount.free_down_cnt - downloadCount.down_cnt > 0)"
      class="combobox hot-combobox ">
      <div
        v-if="downloadCount"
        class="free-download ">
        &lt;!&ndash;        <i class="icon_download"/>&ndash;&gt;
        <div class="curr-down-count">
          <span>今日可免费下载</span>
          <span class="down-count">
&lt;!&ndash;            {{ downloadCount.today_free_down ? downloadCount.today_free_down : 0 }} <i>/ {{
              downloadCount.day_free_limit
            }}</i>&ndash;&gt;
            &lt;!&ndash; 剩余次数和日最大下载对比，大于则日最大次数下载，否则 &ndash;&gt;
            {{ (downloadCount.free_down_cnt - downloadCount.down_cnt) > downloadCount.day_free_limit ? downloadCount.day_free_limit : (downloadCount.free_down_cnt - downloadCount.down_cnt > 0 ? downloadCount.free_down_cnt - downloadCount.down_cnt :0 )   }}
            <i>次</i>
          </span>

        </div>

        <div class="curr-down-count green">
          <span>剩余免费下载</span>
          <span class="down-count">
&lt;!&ndash;            {{ downloadCount.down_cnt }}<i> / {{ downloadCount.free_down_cnt }} </i>&ndash;&gt;
            {{ downloadCount.free_down_cnt - downloadCount.down_cnt > 0 ? downloadCount.free_down_cnt - downloadCount.down_cnt : 0 }}
            <i>次</i>
          </span>
        </div>
      </div>
      <div class="tips ">
        规则：10次免费下载机会，仅限注册后10天内使用，每天可下载一张免费专区内容
      </div>
    </div>-->
  </div>
</template>

<script>
import {IMG_BASE_URL} from "@/constant/PATH";
import {topbarMenuList} from "@/components/common/header/module";
import {PAGE_PATH} from '@/constant/PAGE_PATH';
import {THREE_D} from "@/constant/3d/3D";
import {getDropdownByHot, getDropdownByThreeD} from "@/api/nav";
import {fnRouter} from "@/fn";
import {NAV} from "~/components/common/header/header-nav/common/module";
import headerNavMixin from "@/components/common/header/header-nav/common/header-nav-mixin";
import {mapGetters, mapState} from "vuex";


export default {
  name: "NavItemFestival",
  mixins: [headerNavMixin],
  props: {
    navIndex: {
      type: String | Number,
      default: -1
    }
  },
  data() {
    return {
      IMG_BASE_URL,
      isShow: false,
      monthIdx: 1,    // 默认显示月份
      currentMonth: [],  // 当月
      headerBgColor: '', // 头部背景色
      topbarMenuList,
      PAGE_PATH,
      THREE_D,
      classifyList: [],
      picList: [],
      categoryList: [],
      recommendList: [],
      NAV
    }
  },
  computed: {
    threeDLink() {
      return fnRouter.getRouterLink(PAGE_PATH.THREE_D.DEFAULT);
    },
    freeNavLink() {
      return fnRouter.getRouterLink(PAGE_PATH.NAV.FREE);
    },

    ...mapState('user',
      ['name', 'uid', 'avatar', 'uniq_id', 'vipIsPerson', 'vipIsPersonData', 'vipIsBusiness', 'vipIsBusinessData', 'downloadCount']),

  },
  watch: {
    isShow: {
      handler(n, o) {
        if (n == true) {
          // this.init();
        }
      },
    },
  },
  methods: {

    init() {
      this.getData();
    },

    navigateToList() {
      let findItem = this.topbarMenuList.find(vo => vo.id === NAV.FREE.id)
      this.$emit('navigateToList', findItem)
    },

    updateNavId(navId) {
      this.$emit('updateNavId', navId)
    }


  },
}
</script>

<style lang="scss" scoped>
@import "../common/nav";
@import "../common/icon";


.NavItemHot {
  position: relative;
  z-index: 99;

  > a {
    position: relative;

    .icon_NavItemHot {
      @include Position(1, 5, 33px, -16px);

    }
  }

  .hot-combobox {
    @include Position(1, 5, -16px, 40px);
    border-radius: 12px;
    box-sizing: border-box;
    animation: fadeinT 0.5s 1;

    //width: 730px;
    //max-height: 402px;
    background: #FFFFFF;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.06), 0px 0px 12px 0px rgba(0, 0, 0, 0.08);


    .festival-header-bar {
      @include BgImage(730px, 56px, 'header/nav/festival-bg.png');
      position: absolute;
      left: 0;
      top: 0;
    }

    /* 图片集合 */
    .festival-month {
      width: 100%;
      border-radius: 12px 12px 0px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 19px;
      padding-left: 4px;


      .festival-month-item {
        //margin-right: 28px;
        //margin: 0 14px;
        padding: 0 14px;
        font-size: 14px;
        color: #FFFFFF;
        cursor: pointer;
        position: relative;
        z-index: 6;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          transition: all 0.5s ease;

          .festival-month-item-bar {
            @include BgImage(84px, 37px, 'header/nav/icon_festival-bg.svg');
            position: absolute;
            z-index: -1;
            left: 50%;
            transform: translateX(-50%);
          }

          .festival-month-txt {
            position: relative;
            z-index: 6;
            font-size: 14px;
            font-weight: bold;
            color: #424866;
          }
        }

      }
    }

    .festival-pics {
      display: flex;
      padding: 32px 34px 0;
      flex-wrap: wrap;

      .festival-pics-item {
        width: 202px;
        height: 124px;
        position: relative;
        margin: 0 27px 32px 0;
        border-radius: 4px;

        &:nth-child(3n) {
          margin-right: 0;
        }

        .img-box {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          overflow: hidden;

          img {
            //width: 100%;
            height: 100%;
            vertical-align: middle;
            transition: all 0.5s ease;
          }
        }

        &:hover {
          img {
            transform: scale(1.1);
          }
        }

        .icon_date {
          display: inline-block;
          width: 58px;
          line-height: 19px;
          text-align: center;
          //border-radius: 4px 4px 4px 0px;
          font-size: 12px;
          color: #0A2540;
          @include Position(1, 5, -3px, -8px);

          @include BgImage(58px, 21px, 'header/nav/icon_date.svg');
        }
      }
    }


    .icon_triangle {
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: #FFFFFF;
      @include Position(1, 5, 56px, -4px);
      transform: rotate(135deg);
    }
  }

  .d-combobox {
    display: inline-block;
    width: 523px;
    //height: 421px;
    background: #FFFFFF;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.06), 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
    @include Position(1, 5, -38px, 40px);
    border-radius: 12px;
    //padding: 15px 32px 34px 34px;
    box-sizing: border-box;
    text-align: left;
    animation: fadeinT 0.5s 1;
    height: 358px !important;

    > a {
      display: inline-block;
      width: 100%;
      height: 100%;
      padding: 15px 32px 34px 34px;
      box-sizing: border-box;
    }

    &:after {
      content: '';
      width: 10px;
      height: 10px;
      background-color: #FFFFFF;
      @include Position(1, 5, 56px, -4px);
      transform: rotate(135deg);
    }

    .head-box {

      font-weight: bold;
      color: #0A2540;
      line-height: 40px;
      height: 40px;
      letter-spacing: 1px;
      margin-bottom: 19px;
      font-size: 0;

      .color-green {
        color: #1FCBAD;
        font-size: 17px;
      }

      .title {
        font-size: 17px;
      }

    }

    .seek-design {
      @include BgImage(167px, 40px, 'header/nav/3d/design-bg.png');
      @include Position(1, 2, 34px, 15px);
      text-align: center;
      line-height: 40px;
      font-size: 13px;
      font-weight: bold;
      color: #244962;
      letter-spacing: 1px;
    }

    .classify-wrap {
      .classify-list {

        .classify-item {
          display: flex;
          margin-bottom: 12px;

          &:last-child {
            margin-bottom: 0;
          }

          &:first-child {
            .three-level {
              padding-top: 0;
            }
          }

          .second-level {
            font-size: 16px;
            font-weight: bold;
            color: #424866;
            line-height: 22px;
            letter-spacing: 1px;
            margin-right: 17px;
          }

          .three-level {
            width: 246px;
            display: inline-flex;
            flex-wrap: wrap;
            padding-top: 1px;
            text-align: center;

            a {
              display: inline-block;
              //width: 33.3%;
              //min-width: 56px;
              min-width: 72px;
              margin-right: 10px;
              margin-bottom: 8px;
              font-size: 14px;
              color: rgba(10, 37, 64, 0.8);
              line-height: 19px;
              @include ElementHover($color-primary, '');
              text-align: left;

              &:nth-child(3n) {
                margin-right: 0;
              }
            }
          }
        }
      }
    }

    .pics {
      @include Position(1, 2, 34px, 74px);
      display: flex;
      flex-direction: column;

      a {
        display: inline-block;
        width: 91px;
        height: 118px;
        box-shadow: 0px 0px 4px 0px rgba(213, 221, 236, 0.4);
        border-radius: 8px;
        margin-bottom: 12px;
        overflow: hidden;

        img {
          display: inline-block;
          width: 100%;
          height: 100%;
          vertical-align: middle;
          transition: all 0.5s ease;
        }

        &:hover {
          img {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  .combobox {
    box-sizing: border-box;
    //width: 168px;
    //height: 195px;
    background: #FFFFFF;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.06), 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
    padding: 15px 10px;

    &:after {
      content: '';
      width: 10px;
      height: 10px;
      background-color: #FFFFFF;
      @include Position(1, 5, 56px, -4px);
      transform: rotate(135deg);
    }

    .free-download {
      border-radius: 12px;
      position: relative;

      .icon_download {
        font-size: 18px;
        color: #2D2B33;
        margin-right: 12px;
        @include BgImage(72px, 72px, 'home/icon_download.png');
        @include Position(1, 5, 0, 0);
      }

      .curr-down-count {
        line-height: 24px;
        margin-top: -3px;
        font-size: 12px;
        color: #2D2B33;
        white-space: nowrap;
        background: #FFCE6B;
        border-radius: 12px;
        width: 180px;
        height: 44px;
        padding: 0 11px 0 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        position: relative;

        &:after{
          content: '';
          display: inline-block;
          @include BgImage(96px,44px,'header/nav/download.svg');
          @include Position(1,5,0,0);
        }

        .down-count {
          font-size: 20px;
          font-weight: bold;
          color: #2D2B33;
          line-height: 20px;

          i {
            height: 20px;
            font-size: 12px;
            color: #2D2B33;
            line-height: 20px;
            font-weight: normal;
          }
        }

        &.green {
          background: #35D0BA !important;
          margin: 10px 0 0px;
          color: #FFFFFF;

          .down-count {
            color: #FFFFFF;

            i {
              color: #FFFFFF;
            }
          }
        }

      }

      .line {
        width: 1px;
        height: 20px;
        background: rgba(45, 43, 51, 0.5);
        border-radius: 1px;
        @include Position(1, 5, 128px, 26px);
      }


      .free-download-btn {
        @include Position(1, 2, 0, 0);
        font-size: 13px;
        color: #FFFFFF;
        line-height: 19px;
        display: flex;
        align-items: center;
        transition: all 0.5s ease-in;

        width: 148px;
        height: 72px;
        background: #35D0BA;
        border-radius: 12px;

        @include ElementHover('', #2FBAAA) {
        }

        .icon_star {
          display: inline-block;
          @include BgImage(23px, 21px, 'login/icon_star.png');
          margin: 0 9px 0 31px;
        }
      }


    }

    .tips {
      font-size: 12px;
      text-align: left;
      margin-top: 9px;
      color: #ED5700;
      line-height: 18px;
    }
  }
}

</style>
