import request from '@/utils/request'
import qs from 'qs'

/* 个人中心VIP会员接口 */
export function getUserRecordV2(query) {
  return request({
    url: '/?m=Api&a=userCollectV2',
    method: 'get',
    params: query
  })
}

export function getUserRecord(query) {
  return request({
    url: '?m=Api&a=userRecord',
    method: 'get',
    params: query
  })
}

export function getUserRecordByMultiple(query) {
  return request({
    url: '?m=Authorize&a=multiOrderList',
    method: 'get',
    params: query
  })
}



/* 企业认证 */
export function companyAuthentication(data) {
  return request({
    url: '?m=Company&a=authentication',
    method: 'post',
    data: qs.stringify(data)
  })
}


/* 企业授权 下载协议 */
export function downloadProtocolByCompany(query) {
  return request({
    url: '?m=Vrf&a=check',
    method: 'get',
    params: query
  })
}


/* 是否是会员 */

export function getAccountIsVip(query) {
  return request({
    url: '?m=VipDemand&a=userVipInfo',
    method: 'get',
    params: query
  })
}

/* 优惠券列表 */
export function getCouponListByUserCenter(query) {
  return request({
    url: '?m=Coupon&a=center',
    method: 'get',
    params: query
  })
}

/* 领取优惠券列表 */
export function receiveCouponByUserCenter(query) {
  return request({
    url: '?m=Coupon&a=collect',
    method: 'get',
    params: query
  })
}


export function couponStat(data) {
  return request({
    url: '?m=Coupon&a=stat',
    method: 'post',
    data: qs.stringify(data)
  })
}


export function getUserView(query) {
  return request({
    url: '?m=Api&a=uSetDat',
    method: 'get',
    params: query
  })
}


/* 单张购买记录 */
export function getLeafletList(query) {
  return request({
    url: '?m=Picture&a=singlePageList',
    method: 'get',
    params: query
  })
}

/* 下载单张校验 */
export function checkLeafletDown(query) {
  return request({
    url: '?m=Vrf&a=check',
    method: 'get',
    params: query
  })
}

// /?m=Picture&a=singlePageAuth
export function leafletCertification(data) {
  return request({
    url: '?m=Picture&a=singlePageAuth',
    method: 'post',
    data: qs.stringify(data)
  })
}
export function leafletDownProtocol(data) {
  return request({
    url: '?m=Vrf&a=singlePageVrf ',
    method: 'post',
    data: qs.stringify(data)
  })
}

export function getUserCouponInvite(query) {
  return request({
    url: '?m=Activity&a=personalCenterHelp',
    method: 'get',
    params: query
  })
}

export function checkUserCouponInvite(data) {
  return request({
    url: '?m=Activity&a=personalCenterInvite',
    method: 'post',
    data: qs.stringify(data)
  })
}


export function checkUserPayFace(data) {
  return request({
    url: '?m=Auxfun&a=payFace',
    method: 'post',
    data: qs.stringify(data)
  })
}

export function userCertification(data) {
  return request({
    url: '?m=Auxfun&a=realAuthName',
    method: 'post',
    data: qs.stringify(data)
  })
}


/* 教育认证 */
export function userEduCertification(data) {
  return request({
    url: '?m=Api&a=educationAuth',
    method: 'post',
    data: qs.stringify(data)
  })
}

export function checkUserIsAgreeNewProtocol(query) {
  return request({
    url: '?m=StatApi&a=userAgreement',
    method: 'get',
    params: query
  })
}


