<template>
  <div class="container InvoiceRemindModal" >
    <ModalMiddle @close="close" v-if="InvoiceRemindModal">
      <div slot="ModalContent" class="ModalContent">
        <div class="txt">
          如需申请发票，请联系在线客服
        </div>

        <a class="btn"
           :href="SERVICE_LINK"
           target="_blank"
        >申请发票
        </a>
      </div>
    </ModalMiddle>
  </div>
</template>

<script>

/* 引入公共方法和常量 */
import * as fnCommon from '@/fn/_fn';
import * as CON_COMMON from '@/constant/_constant';

/* 引入组件 */

/* 引入VUEX */
import {mapState} from "vuex";
import {SERVICE_LINK} from "@/constant/_constant/CON_CONFIG";

/* 引入API */

export default {
  name: "InvoiceRemindModal"

  , components: {}

  , props: {}

  , watch: {}

  , computed: {
    ...mapState('Modal',['InvoiceRemindModal'])
  }

  , data() {
    return {
      SERVICE_LINK
    }
  }

  , methods: {
    close(){
      this.$store.commit('Modal/SET_INVOICE_REMIND_MODAL', false)
    }
  }

}
</script>

<style lang="scss" scoped>

.ModalContent{
  background: #FFFFFF;
  width: 340px;
  height: 280px;
  border-radius: 14px;

  font-size: 16px;
  //color: #596088;
  line-height: 26px;
  padding: 80px 40px 50px;
  box-sizing: border-box;
  text-align: center;


  .txt{

  }
  .btn{
    width: 260px;
    border-radius: 8px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    background: #696AFF;
    margin-top: 80px;

    &:hover {
      background: #7878FF;
    }
  }
}
</style>
