<template>
  <div class="PicItem PicItemRecommend PicItemTopic">
    <a
      :href="picItemInfo.link_url"
      target="_blank"
      :title="picItemInfo.title"
      class="pic-img-wrap"
    >
      <img
        v-lazy=" PAGE_PATH.BASE_IMG_URL + picItemInfo.pictures[0].proto_pic + '!/fw/672/quality/90/unsharp/true/compress/true'"
        slot="content"
        :alt="picItemInfo.title"
        :title="picItemInfo.title"
        class="pic-img"
        @contextmenu.prevent=""
      >
    </a>
    <!-- 收藏 -->
    <PicTagCollectTopic :pic-item-info="picItemInfo"/>
    <!-- 标题 -->
    <NavigationTo :path="picItemInfo.link_url">
      <div slot="content">
        <PicTagTitle slot="content" :pic-item-info="picItemInfo"/>
      </div>
    </NavigationTo>
    <!-- 标签 -->
    <!--    <PicTagLegalCopy :pic-item-info="picItemInfo"/>-->
    <!--    -->
    <PicTagLegalCopyRecommend :pic-item-info="picItemInfo"/>
  </div>
</template>

<script>
import picItemExtend from "@/components/pic/pic-item/common/pic-item-extend";

export default {
  name: "PicItemSpecial",
  mixins: [picItemExtend]
}
</script>

<style lang="scss" scoped>
@import "./common/pic-item";
</style>
