import HeaderLogo from '@/components/common/header/header-logo';
import HeaderNav from '@/components/common/header/header-nav'
import HeaderSearch from '@/components/common/header/heade-search'
import HeaderSubmitPic from '@/components/common/header/header-submit-pic';
import HeaderVipPerson from '@/components/common/header/header-vip-person';
import HeaderCompanyLicense from '@/components/common/header/header-company-license';
import HeaderLogin from '@/components/common/header/header-login';


let ComponentMixin = {
  components:{
    HeaderLogo,
    HeaderNav,
    HeaderSearch,
    HeaderSubmitPic,
    HeaderVipPerson,
    HeaderCompanyLicense,
    HeaderLogin,
  }
}

export default ComponentMixin;
