<template>
  <div
    class="PicButtonGroup button-group"
    :class="[{ 'Company':vipType == 4 }]"
  >
    <div class="mask" @click="navTo"/>

    <!-- 商用VIP下载按钮 -->

    <no-ssr>
      <div v-if="!isLogin">
<!--        <PicButtonFree
          :button-type="vipType"
          @click.native="downloadByFree(picItemInfo,1)"/>-->

        <PicButtonBusiness
          :button-txt=" '下载' + (picItemInfo.file_type)"
          :button-type="vipType"
          @click.native="downloadByBusiness(picItemInfo,1)"
        />

      </div>

      <div v-if="isLogin">
        <div v-if="(personVipState != 1 && businessVipState != 1 && companyVipState != 1)">
          <!-- 非vip -->
          <template v-if="picItemInfo.is_ai == 0">
<!--            <PicButtonFree
              :button-type="vipType"
              v-if=" vipType >= picItemInfo.layer"
              @click.native="downloadByFree(picItemInfo,1)"/>-->

            <PicButtonBusiness
              :button-txt=" '下载' + (picItemInfo.file_type)"
              :button-type="vipType"
              @click.native="downloadByBusiness(picItemInfo,1)"
            />
          </template>
        </div>

        <div v-else>
          <template v-if="picItemInfo.is_ai == 0">
            <PicButtonBusiness
              :button-txt=" '下载' + (picItemInfo.file_type)"
              :button-type="vipType"
              @click.native="downloadByBusiness(picItemInfo,1)"
            />
          </template>
        </div>

      </div>
    </no-ssr>

    <!-- 下载源文件 -->
    <PicButtonSourceFile
      :button-type="vipType"
      v-if="picItemInfo.is_ai == 0"
      @click.native="downloadByBusiness(picItemInfo,2)"
    ></PicButtonSourceFile>


    <PicButtonSourceFile
      :button-type="vipType"
      v-if="picItemInfo.is_ai == 1"
      :button-txt=" '下载' + (picItemInfo.file_type)"
      @click.native="downloadByBusiness(picItemInfo,1)"
    ></PicButtonSourceFile>


  </div>
</template>

<script>
// components

import {fnLogin, fnPic, fnRouter} from "@/fn";
import PicButtonGroupExtend from "@/components/pic/pic-button-group/common/pic-button-group-extend";
import {PAY_BUSINESS} from "@/constant/PATH";
import {tag} from "@/utils/statUtil";
import {mapState} from "vuex";
import PicButtonSourceFile from '@/components/pic/pic-button/pic-button-source-file'
import {getCookie} from "@/utils/auth";

export default {
  name: "PicButtonGroup",
  components: {
    PicButtonSourceFile
  },

  mixins: [PicButtonGroupExtend],
  computed: {
    ...mapState('user',
      [
        'personVipState',
        'personVipInfo',
        'businessVipState',
        'businessVipInfo',
        'companyVipState',
        'companyVipInfo',
        'isHasUserCoupon',
        'isSwitchAccountType',
        'isSwitchCompanyMain',
      ]
    ),
  },


  watch: {
    isSwitchAccountType: {
      handler(n, o) {
        console.log(n)
        if (n == true) {
          // console.log('-- PicButtonGroup isSwitchAccountType --')
          setTimeout(() => {
            this.init();
          }, 500)
        }
      }
    },

    isSwitchCompanyMain: {
      handler(n, o) {
        console.log(n)
        if (n == true) {
          // console.log('-- PicButtonGroup isSwitchCompanyMain --')
          setTimeout(() => {
            this.init();
          }, 500)
        }
      }
    },
  },
  data() {
    return {
      vipType: -1,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$store.commit('user/SET_SWITCH_ACCOUNT_TYPE', false)
      this.$store.commit('user/SET_SWITCH_COMPANY_MAIN', false)
      this.vipType = this.getUserMaxVip();
    },

    getUserMaxVip() {
      let personVipState = getCookie('personVipState')
      let businessVipState = getCookie('businessVipState')
      let companyVipState = getCookie('companyVipState')

      if (companyVipState == 1) {
        return 4
      } else if (businessVipState == 1) {
        return 3
      } else if (personVipState == 1) {
        return 2
      } else {
        return 1
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "common/button-group";
</style>
