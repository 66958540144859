<template>
  <div class="CgSelect __form">
    <div class="__form-input __form-select">
      <input
        ref="input"
        v-bind="$attrs"
        class="select"
        type="text"
        readonly
        :disabled="disabled"
        @blur="handleBlur"
        @click="handleClick"
        :class="[ { 'disabled': disabled } ]"
        :placeholder="placeholder || inputItem.placeholder"
      >
      <i class="iconfont2 icon-qy-arrow-down-bold-line" />
      <span
        v-if="inputItem.isShowErrTxt"
        class="input-err">{{ inputItem.errTxt }}</span>
<!--

-->
      <ul
        v-show="isShow"
        ref="options"
        class="options __form-scrollbar"
        :class="optionPositionType"
      >
        <li
          v-for="vo in options"
          @click="selectItem(vo)"
          @mousedown="selectItem(vo)"
          class="overflow-one"
        >
          {{ vo.label || vo[label] }}
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
export default {
  name: "CgSelect",
  props: {
    options: {
      type: Array,
      default: () => []
    },
    label:{
      type: String,
      default: 'name'
    },
    placeholder:{
      type: String,
      default: ''
    },
    disabled:{
      type: Boolean,
      default: false
    },
    inputItem: {
      type: Object,
      default: () => {
        placeholder:''
      }
    },
    value: [String, Number],
  },
  data() {
    return {
      isShow: false,
      optionPositionType:''
    }
  },
  computed: {
    nativeInputValue() {
      return this.value === null || this.value === undefined ? '' : String(this.value);
    },
  },
  watch: {
    nativeInputValue() {
      this.setNativeInputValue();
    },
  },
  mounted() {
    this.setNativeInputValue();
      /*if (!!this.$refs.input) {
        let scrollTop = this.$refs.input.getBoundingClientRect().bottom
        let height = this.$refs.input.offsetHeight;
        console.log(scrollTop)
      }*/

  },
  methods: {
    handleClick(){
      if (this.disabled){
        return
      }
      let scrollTop1 = this.$refs.input.getBoundingClientRect() // 距离底部距离
      let scrollTop = this.$refs.input.getBoundingClientRect().bottom // 距离底部距离
      this.$nextTick(()=>{
        let height = this.$refs.options.offsetHeight;   // 获取下拉框高度
        console.log(this.$refs.options.offsetHeight)

      })





      console.log({
        scrollTop1,
        scrollTop,
      })

      if (window.innerHeight - scrollTop > 252){
        this.optionPositionType = 'BUTTON'


      }else{
        setTimeout(()=>{
          console.log(this.$refs.options.offsetHeight)
          if (this.$refs.options.offsetHeight < 252){
            this.optionPositionType = 'TOP_SIM'
            this.$refs.options.style.top = -this.$refs.options.offsetHeight - 10 + 'px'

          }else{
            this.optionPositionType = 'TOP'
            this.$refs.options.style.top = -264 + 'px'
          }
        },0)
      }

      console.log(scrollTop)


      this.$emit('click')
      this.isShow = true;
    },
    handleInput(event) {
      this.$emit('input', event.target.value);
    },
    handleChange(event) {
      this.$emit('change', event.target.value);
    },
    handleFocus(event) {
      this.isShow = true;
      this.$emit('focus', event);
    },
    handleBlur(event) {
      this.isShow = false;
      this.$emit('blur', event);
    },
    clear() {
      this.$emit('input', '');
      this.$emit('change', '');
      this.$emit('clear');
    },
    selectItem(vo) {
      this.isShow = false;
      // this.value = vo.label || vo[this.label]
      this.$emit('select', vo)
    },
    getInput() {
      return this.$refs.input || this.$refs.textarea;
    },
    setNativeInputValue() {
      const input = this.getInput();
      if (!input) return;
      if (input.value === this.nativeInputValue) return;
      input.value = this.nativeInputValue;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "styles/form";

.disabled{
  cursor: not-allowed !important;  // 表示一个红色的圈加一个斜杠
}

</style>
