<template>
  <div class="PicPackagePayModal">

    <!-- 图片包支付 -->
    <PicPackagePay
      ref="PicPackagePay"
      :pic-package-desc="picPackageDesc"
      :payment="paymentData"
      @closePayDialog="closePayDialog"
    />

    <!--  支付成功弹框  -->
    <PaySuccessDialog
      ref="PaySuccessDialog"
      :pay-success-url="USER_CENTER_PIC_PACKAGE"
      :vip-full-name="text"/>

    <!--  支付挽留弹框  -->
    <PayRetainDialog
      ref="PayRetainDialog"
      @cancel="PayRetainCancel"
      @confirm="PayRetainConfirm"
    />

    <!-- 下载限制弹框 -->
    <DownloadInterceptDialog
      :time="clippingTime"
      ref="DownloadInterceptDialog"
    />

  </div>
</template>

<script>
import PicPackagePay from '~/components/pay/pic-package-pay'
import {PaySuccessDialog, PayRetainDialog} from "@/components/payment/index.js"
import {DownloadInterceptDialog} from "@/components/material";
import {USER_CENTER_PIC_PACKAGE} from "@/constant/PATH";
import {fnLogin, fnPay} from "@/fn";
import {packageStat} from "@/api/stat";
import {tag} from "@/utils/statUtil";
import {downStat} from "@/api/material";
import axios from "axios";
import {mapGetters, mapState} from "vuex";
import fnCollection from "@/fn/topic/fnCollection";
import FnUser from "@/fn/_fn/FnUser";
import {getCookie} from "@/utils/auth";
import {setPicFreeDownCouponModal} from "~/components/_Privately/Pic/PicFreeDownCouponModal/module";

export default {
  name: "PicPackagePayModal",
  components: {
    PicPackagePay,
    PaySuccessDialog,
    PayRetainDialog,
    DownloadInterceptDialog,
  },
  data() {
    return {
      picPackageDesc: {},
      paymentData: {},
      USER_CENTER_PIC_PACKAGE,
      clippingTime: '',
      text:'',
      timerList: [],
      timer: null,
    }
  },
  mounted() {
    console.log(this.$store.state.tupianPackage)
  },
  computed: {
    ...mapState('tupianPackage', ['tupianPackagePayDialog']),
    tupianPackagePayDialogShow() {
      return this.$store.state.tupianPackage.tupianPackagePayDialog.isShow
    }
  },
  watch:{
    tupianPackagePayDialogShow(n){
      if (n == true){
        this.showPayDialog(this.$store.state.tupianPackage.tupianPackagePayDialog.tupianItem)
      }
    }
  },
  methods: {
    /* 显示支付弹框*/
    showPayDialog(vo) {
      this.fillPicPackageDesc(vo);
      if (fnLogin.checkLogin()) {
        tag('充值:图片包购买按钮:图片')
        fnPay.getPayCode(vo.uniq_id, 2).then(res => {
          let {payment, ali_pay, wechat_pay} = res;
          this.paymentData = payment;
          this.getPayResult(ali_pay.order_no)
          this.getPayResult(wechat_pay.order_no)
          this.$refs.PicPackagePay.isShow = true;
        })
        packageStat({event: 'buy'})
      } else {
        this.$store.commit("user/SET_IS_SHOW_LOGIN", true);
      }
    },


    getPayResult(order_no) {
      this.timer = setInterval(() => {
        fnPay.getPayResult(order_no).then(res => {
          if (res.status === 1) {
            this.$refs.PaySuccessDialog.isShow = true;
            this.$refs.PicPackagePay.isShow = false;
            clearInterval(this.timer);// 满足条件时 停止计时
            this.timerList.forEach((item, index) => {
              clearInterval(item);
            })
            this.timerList = [];
            this.timer = 0;
          }
        })
      }, 2000)
      this.timerList.push(this.timer)
    },


    fillPicPackageDesc(vo) {
      this.picPackageDesc = {
        title: vo.title,
        num: vo.pic_num,
        price: vo.package_price,
      }
    },
    PayRetainCancel() {
      this.$refs.PayRetainDialog.isShow = false;
      this.$refs.PicPackagePay.isShow = false;
      this.clearTimer();
    },

    PayRetainConfirm() {
      this.$refs.PicPackagePay.isShow = true;
    },
    /* 支付挽留 */
    closePayDialog() {
      this.$store.commit('tupianPackage/SET_TUPIAN_PACKAGE_PAY_DIALOG',{ isShow: false })
      this.$refs.PayRetainDialog.isShow = true;
    },
    clearTimer() {
      if (this.timer) {
        this.timerList.forEach((item, index) => {
          clearInterval(item);
        })
        this.timerList = [];
        this.timer = 0;
      }
    },

    download(channel, uniq_id) {

     




      let tempStr = `https://dl.chaopx.com/down/download?channel=${channel}&uniq_id=${uniq_id}&module=2`
      /* 检查是否是大小专题 */
      let { $router, $route } = $nuxt;

      if($route.path.indexOf('/collection') > -1){
        let temp = fnCollection.parseCollectionId($route.params.id);
        let _params = `&topic_id=${temp.collectionId}&refererUrl=${window.location.href}`
        tempStr += _params;
      }
      axios.get(tempStr).then(res => {
        let {status, time, url, msg,free_down} = res.data.data;
        console.log(
          {status, time, url, msg}
        )

        if (FnUser.checkCompanyVipExpiredModal()) {
          if (status == 0 || status == 2) {
            this.$store.commit('Modal/SET_COMPANY_VIP_EXPIRED_MODAL', true)
            return false;
          }
        }


        if (status === 1) {

          if (free_down == 1) {
            setPicFreeDownCouponModal(this.$store, true);
          }
          window.location.href = url;
          packageStat({event: 'down', channel: channel})
          downStat({uniq_id: uniq_id, module: 2})
        } else if (status === -200) {
          this.clippingTime = time;
          this.$refs.DownloadInterceptDialog.isShow = true;
        } else {
          this.$myNotify.show(msg)
        }
      });
    },

  }
}
</script>

<style scoped>

</style>
