<template>
  <div
    v-show="!isLogin && isShowLogin && !spider"
    class="login-wrap"
    id="LoginDialog"
  >
    <div class="__box-cover"/>

    <div class="login-box __box-content">

      <div class="login-picture">
        <!--        <i class="icon_logo"/>
                <div class="headline">
                  登录立享 <br>
                  <span>10</span>
                  天免费下载
                </div>
        &lt;!&ndash;        <div class="subhead">每日更新 新用户免费下载</div>&ndash;&gt;
                <i class="icon_go"/>-->
      </div>

      <div class="login-content">
        <div class="headline"> {{ loginIndex === 0 ? '手机号' : '微信扫码' }}登录注册</div>
        <div class="txt_register1">首次登录领取 免费下载10天</div>
        <i
          v-show="isShowLoginCloseBtn"
          class="iconfont icon-cha _pointer"
          :class="{ 'ON': isShowLogin == true}"
          @click="toggleShowLogin"/>
        <div class="form" v-show="loginIndex === 0">
          <div class="form-item form-item-iphone">
            <input
              v-model="iphone"
              placeholder="输入手机号"
              @input="changeIPhone"
              @blur="verifyIphone">
            <div class="form-clues">{{ verifyIphoneClues }}</div>
          </div>
          <div id="nc"></div>
          <div class="form-item form-item-code">
            <input
              v-model="code"
              type="number"
              placeholder="输入验证码"
              @input="changeCode"
            >
            <span
              :class="[downTimeStatus ? 'code-on': 'code-disabled']"
              class="text-get-code _pointer"
              @click="getCode">{{ codeText }}</span>
            <div class="form-clues">{{ verifyCodeClues }}</div>
          </div>
          <div
            class="form-item form-item-submit"
            @click="login">登录
          </div>
        </div>
        <div class="login-wx" v-if="loginIndex === 1">
          <img :src="wxLoginCode" alt="" class="login-wx-code">
          <div class="mask" v-if="wxLoginCodeState === 0"
               @click="getWxLoginCode"
          >
            <i class="icon_refresh"/>
            <div>二维码失效</div>
            <div>请点击刷新</div>
          </div>
        </div>

        <div class="login-ways toggle-login-icon">
          <div class="login-way-item login-way-item-wx" @click="toggleLoginWay(1)" v-if="loginIndex === 0">
            <i class="iconfont icon-wechat2 _pointer"></i>
            微信登录
          </div>
          <div class="login-way-item login-way-item-iphone" @click="toggleLoginWay(0)" v-else>
            <i class="iconfont icon-mobile _pointer"></i>
            手机号登录
          </div>
          <div class="login-way-item login-way-item-qq" @click="loginByQQ">
            <i class="iconfont icon-QQ2 _pointer"></i>
            QQ登录
          </div>

        </div>
        <div class="protocol">
          <i
            :class="[ 'icon_gouxuan  _pointer', { 'on' : isChecked } ]"
            @click="toggleCheckProtocol"
          >
               <span class="tips" v-if="isCheckedTip">
                请阅读并勾选协议
              </span>
          </i>
          同意并阅读
          <a @click="navigatorTo(PROTOCOL_PROBLEM_0)">《用户协议》</a>、
          <a @click="navigatorTo(PROTOCOL_PROBLEM_1)">《版权声明》</a>、
          <a @click="navigatorTo(PROTOCOL_PROBLEM_2)">《隐私政策》</a>
        </div>
      </div>

    </div>

  </div>
</template>

<script>

// components
// apis
import {getAttentionWxState, getWxLoginCode} from "@/api/login";
// tools / js
import _ from 'lodash'
import {mapActions, mapState} from 'vuex'
import {fnBrowser, fnLocalStorage, fnLogin} from "@/fn";
import localStorage from "@/utils/localStorage";
// modules
import {PROTOCOL_PROBLEM_0, PROTOCOL_PROBLEM_1, PROTOCOL_PROBLEM_2} from "@/constant/PATH";
import {DEVICE} from "@/constant/SYSTEM";
import {MODEL_INTRODUCE_DIALOG, MODEL_NEW_USER_GIFT} from "@/constant/MODELS";

export default {
  name: "Login",
  data() {
    return {
      PROTOCOL_PROBLEM_0,
      PROTOCOL_PROBLEM_1,
      PROTOCOL_PROBLEM_2,


      codeText: "获取验证码",
      verifyIphoneClues: "",   // 请输入手机号 手机号格式不正确
      verifyCodeClues: "",     // 请输入验证码 验证码不正确
      iphone: "",
      code: "",
      verifyStatus: false,
      countdownTime: 60,
      downTimeStatus: true,
      isChecked: true,

      isCheckedTip: false,
      loginIndex: 1,// 0手机号码 1微信登录
      wxLoginCode: '',
      wxLoginCodeState: 1,
      wxLoginCodeTimer: null,
      attentionWxStateTimer: null,
      showWxLogin: false,

      timer: null,
      timerList: [],

      codeForm: {
        sessionId: undefined,
        sig: undefined,
        token: undefined,
        scene: undefined,
      }

    }
  },
  watch: {
    isChecked(newVal, oldVal) {
      if (newVal === true) {
        this.isCheckedTip = false;
      }
    },

    isShowLogin: {
      handler(newVal, oldVal) {
        if (newVal == true) {


          let _index = (this.loginWay === 1 || this.loginWay === 0) ? this.loginWay : 1;
          this.toggleLoginWay(_index)
          this.loginIndex = _index;



        } else {
          /*if (!this.stopListenLoginClose){
            this.clearTimer();
            return
          }*/
          this.listenPageLogin();
          /* 判断开启条件 */
          /*let isShow = fnLocalStorage.getItemDate(MODEL_INTRODUCE_DIALOG);
          console.log(isShow)
          if (!isShow) {
          } else {
            this.listenPageLogin();
          }*/

        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapState('user', [
      'isShowLogin',
      'loginWay',
      'isLogin',
      'isShowLoginCloseBtn',
    ]),
    ...mapState('base', [
      'isShowIntroduceDialog',
      'spider'
    ]),
    ...mapState('login', [
      'stopListenLoginClose',
    ]),
  },
  mounted() {
    if (process.client) {
      // this.$localStorage.setItem('isCheckedProtocol', 1)
      let flag = this.$localStorage.getItem('isCheckedProtocol')
      if (flag == 1) {
        // this.$localStorage.setItem('isCheckedProtocol', 1)
        this.isChecked = Boolean(flag)
      } else {

      }
    }

    let showWxLogin = this.$localStorage.getItem('showWxLogin');
    if (showWxLogin != undefined) {
      this.showWxLogin = showWxLogin;
    }

    this.initHuaKuai();

  },
  methods: {
    ...mapActions('user', ['LoginSuccessHandle']),

    initHuaKuai() {
      let _this = this;

      AWSC.use("nc", function (state, module) {
        // 初始化
        window.nc = module.init({
          // 应用类型标识。它和使用场景标识（scene字段）一起决定了滑动验证的业务场景与后端对应使用的策略模型。您可以在阿里云验证码控制台的配置管理页签找到对应的appkey字段值，请务必正确填写。
          appkey: "FFFF0N0000000000638F",
          //使用场景标识。它和应用类型标识（appkey字段）一起决定了滑动验证的业务场景与后端对应使用的策略模型。您可以在阿里云验证码控制台的配置管理页签找到对应的scene值，请务必正确填写。
          scene: "nc_login",
          // 声明滑动验证需要渲染的目标ID。
          renderTo: "nc",
          width: 330,
          //前端滑动验证通过时会触发该回调参数。您可以在该回调参数中将会话ID（sessionId）、签名串（sig）、请求唯一标识（token）字段记录下来，随业务请求一同发送至您的服务端调用验签。
          success: function (data) {
            let {sessionId, sig, token} = data
            _this.codeForm = {
              sessionId,
              sig,
              token,
              scene: 'nc_login',
            }
          },
          // 滑动验证失败时触发该回调参数。
          fail: function (failCode) {
            window.console && console.log(failCode)
          },
          // 验证码加载出现异常时触发该回调参数。
          error: function (errorCode) {
            window.console && console.log(errorCode)
          }
        });
      })
    },

    toggleLoginWay(index) {
      /* 检查是否勾选协议 */
      if (!this.isChecked) {
        this.$myNotify.error('请先勾选协议')
        this.isCheckedTip = true
        return false
      }
      this.clearAllTimer();
      this.loginIndex = index;
      fnLogin.clearListenLoginByQQ();

      if (index === 1) {
        this.getWxLoginCode();
      } else {
        // this.resetForm();
        this.clearAllTimer();

      }
    },

    /* 微信登陆码倒计时 */
    wxLoginCodeCountdown() {
      clearInterval(this.wxLoginCodeTimer);
      //
      let count = 60;
      this.wxLoginCodeTimer = setInterval(() => {
        count--;
        if (count === 0) {
          this.wxLoginCodeState = 0;  // 过期
          // this.wxLoginCode = ''
          clearInterval(this.wxLoginCodeTimer);
          clearInterval(this.attentionWxStateTimer)
        }
      }, 1000)

    },

    getWxLoginCode() {
      this.wxLoginCodeState = 1;
      // clearInterval(this.wxLoginCodeTimer);
      clearInterval(this.attentionWxStateTimer)

      getWxLoginCode().then(res => {
        if (res.data.status === 1) {
          this.wxLoginCode = res.data.code
          this.wxLoginCodeCountdown();

          this.attentionWxStateTimer = setInterval(() => {
            this.getAttentionWxState()
          }, 2000)
        }
      })
    },

    getAttentionWxState() {
      getAttentionWxState().then(res => {
        if (res.data.status === 1) {
          fnLogin.clearListenLoginByQQ();
          this.$myNotify.success('微信登录成功', () => {
            this.loginIndex = 0;
            clearInterval(this.attentionWxStateTimer)
            this.$store.commit('system/SET_DEVICE', DEVICE.wx)
            this.LoginSuccessHandle();
            this.clearAllTimer();
          })
        }
      })
    },

    verifyIphone() {
      let temp = this.$utils.verify.iphone(this.iphone);
      let clues = ["请输入手机号", "", "手机号格式不正确"];
      this.verifyIphoneClues = clues[temp.status];
      this.verifyStatus = temp.status === 1;
      return temp.status === 1;
    },

    changeIPhone() {
      this.iphone = this.iphone.replace(/[^\d]/g, '')
      if (this.iphone.length) {
        this.verifyIphoneClues = '';
      }
    },

    verifyCode() {
      let bool = !(this.code === '');
      let clues = ["请输入验证码", ""];
      this.verifyCodeClues = clues[Number(bool)];
      this.verifyStatus = bool;
      return bool
    },

    changeCode() {
      if (this.code.length > 0) {
        this.verifyCodeClues = '';
      }
    },

    getCode: _.throttle(function () {

      if (!this.isChecked) {
        this.$myNotify.error('请先勾选协议')
        this.isCheckedTip = true
        return false
      }
      // this.verifyIphone();
      let temp = this.$utils.verify.iphone(this.iphone);
      let clues = ["请输入手机号", "", "手机号格式不正确"];
      if (temp.status != 1){
        this.$myNotify.error(clues[temp.status])
        return false
      }

      /* 验证滑块 */
      if (this.codeForm.sessionId == undefined) {
        this.$myNotify.error('请先进行滑块验证')
        return false
      }


      if (this.verifyStatus && this.downTimeStatus) {
        this.$utils.time.countdown(this.countdownTime, 60, time => {
          this.downTimeStatus = false;
          this.codeText = `${time}秒后再试`
        }).then(r => {
          this.codeText = `获取验证码`;
          this.downTimeStatus = true;
        })
        let params = Object.assign(this.codeForm,{ phone: this.iphone })
        this.$store.dispatch("user/GetVerificationCode", params)
          .then(res => {
            let {info, status} = res.data;
            if (status !== 1) {
              this.verifyCodeClues = info;
              window.nc.reset()
            }
          })
          .catch(err => {

          });
      } else {
      }
    }, 2000),

    login: _.throttle(function () {
      if (!this.isChecked) {
        this.$myNotify.error('请先勾选协议')
        this.isCheckedTip = true
        return false
      }
      if (this.verifyIphone() && this.verifyCode()) {

        if (this.verifyStatus) {
          // 调用登录接口
          let userInfo = {
            phone: this.iphone,
            code: this.code
          }

          this.$store.dispatch("user/LoginByVerificationCode", userInfo)
            .then(res => {
              let {status, info} = res.data;
              if (status == 0) {
                this.verifyCodeClues = info
              } else if (status === 1) {
                // 关闭qq查询
                fnLogin.clearListenLoginByQQ();
                this.$store.commit('system/SET_DEVICE', DEVICE.iphone)
                setTimeout(() => {
                  this.resetForm();
                }, 2000)
              }
            })
            .catch(err => {
            })
        }
        return false;
      }
    }, 2000),

    toggleCheckProtocol() {
      this.isChecked = !this.isChecked;
      this.$localStorage.setItem('isCheckedProtocol', Number(this.isChecked))
    },

    resetForm() {
      setTimeout(() => {
        this.verifyIphoneClues = '';
        this.verifyCodeClues = '';
        this.codeText = '获取验证码';
        this.iphone = ''
        this.code = ''
        this.countdownTime = 60;
        this.downTimeStatus = true;
        this.verifyStatus = true;
      }, 2000)
    },

    clearAllTimer() {
      clearInterval(this.wxLoginCodeTimer);
      clearInterval(this.attentionWxStateTimer)
    },
    toggleShowLogin() {
      this.resetForm();
      this.clearAllTimer();
      this.$store.commit("user/SET_IS_SHOW_LOGIN", false);
      this.$store.commit('user/SET_LOGIN_WAY', 1)
      this.loginIndex = 1;
      // localStorage.setItemDate(MODEL_NEW_USER_GIFT, -1);

      window.nc.reset()

    },

    listenPageLogin() {
      /* 5s 一次检测是否登录 */
      // this.timer = null;
      // window.clearTimeout(this.timer)
      /* 排除弹框登录路由 */
      let excludeRouter = ['/act/rabbit'];
      for (let i = 0; i < excludeRouter.length; i++) {
        if (this.$store.state.user.path.indexOf(excludeRouter[i]) > -1) {
          this.$store.commit('user/SET_IS_SHOW_LOGIN', false);
          return false;
        }
      }

      this.clearTimer();
      this.timer = window.setTimeout(() => {
        if (!fnLogin.checkLogin()) {
          this.$store.commit('user/SET_IS_SHOW_LOGIN', true);
          // this.timer = null;
          // window.clearTimeout(this.timer)
          this.clearTimer();
        } else {
          this.$store.commit('user/SET_IS_SHOW_LOGIN', false);
          // this.timer = null;
          // window.clearTimeout(this.timer)
          this.clearTimer();
        }
      }, 10000)
      this.timerList.push(this.timer)
    },

    loginByQQ() {
      fnLogin.clearListenLoginByQQ();
      fnLogin.loginByQQ(this.$store);
    },

    navigatorTo(path) {
      fnBrowser.navTo(path)
    },


    clearTimer() {
      window.clearInterval(this.timer);// 满足条件时 停止计时
      if (this.timer) {
        this.timerList.forEach((item, index) => {
          window.clearInterval(item);
        })
        this.timerList = [];
        this.timer = null;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./index";

.login-box {
  // filter: grayscale(100%) !important;
}
</style>
