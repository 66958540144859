<template>
  <div class="collect-desktop-bar" v-if="isShow && prompt">

    <div class="inner_center">

      <i class="icon_logo"></i>

      <i class="iconfont icon-cha" @click="close"/>

      <div class="collect-desc">
        添加
        <span class="color-orange">桌面快捷图标</span>
        寻找好设计不迷路，免费享受极速浏览体验
        <i class="icon_smile"></i>
      </div>

      <div class="add-collect" @click="download">
        立即添加
      </div>

    </div>
  </div>
</template>

<script>
import {MODEL_COLLECT_DESKTOP_BAR} from "@/constant/MODELS";

export default {
  name: "CollectDesktopBar",
  data() {
    return {
      prompt: null,
      isShow: true
    }
  },

  mounted() {
    window.addEventListener("beforeinstallprompt", this.beforeinstallprompt);
    this.checkIsShow();
  },

  methods: {
    beforeinstallprompt(e) {
      console.log('-- e --')
      this.prompt = e
      e.preventDefault();
      return false;
    },
    download() {
      console.log(1111)
      if (this.prompt) {
        // 异步触发横幅显示，弹出选择框，代替浏览器默认动作
        this.prompt.prompt();
        // 接收选择结果
        this.prompt.userChoice
          .then((result) => {
            // {outcome: "dismissed", platform: ""} // 取消添加
            // {outcome: "accepted", platform: "web"} // 完成添加
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    checkIsShow() {
      if (!this.getTodayIsShow()) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    },

    getTodayIsShow() {
      return this.$localStorage.getItemDate(MODEL_COLLECT_DESKTOP_BAR);
    },

    close() {
      this.isShow = false;
      this.$localStorage.setItemDate(MODEL_COLLECT_DESKTOP_BAR);
    }
  },
}
</script>

<style lang="scss" scoped>
.collect-desktop-bar {
  width: 100%;
  height: 79px;
  background: #000000;
  border-radius: 1px;
  opacity: 0.75;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 9;

  .inner_center {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .icon_logo {
    @include BgImage(179px, 42px, 'home/icon_logo.png');
    margin-right: 28px;
  }

  .icon-cha{
    font-size: 20px;
    @include Position(1,2,20px,50%);
    transform: translateY(-50%);
    color: #FFFFFF;
    cursor: pointer;
  }

  .collect-desc {
    font-size: 18px;
    color: #DAD5F5;

    .color-orange {
      font-size: 18px;
      font-weight: bold;
      color: #FFDD79;
      line-height: 24px;
      margin: 0 6px;
    }

    .icon_smile {
      @include BgImage(22px, 22px, 'home/icon_smile.png');
      margin: 0 150px 0 6px;
    }
  }

  .add-collect {
    width: 172px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    background: #6969FF;
    border-radius: 4px;
    border: 2px solid #FFDD79;
    font-size: 16px;
    font-weight: bold;
    color: #FFDD79;
    cursor: pointer;

    @include ElementHover('',#7F7FFF)
  }
}
</style>
