<template>
  <div >
    <no-ssr>
      <div class="LoginGuide" v-if="!isLogin ">
        <div
          v-if="state === LOGIN_GUIDE_STATE.BUOY && isShowState"
          class="LoginGuideBuoy"

        >
          <div
            :class="{ 'active': isShow }"
            class="gift"
            @mouseover="mouseover"
            @mouseleave="mouseleave"
          >
            <i class="icon-gift" @click="login"></i>

            <div class="gift-deploy">
              <div class="content">
                <div class="txt-gift"><i class="color-red">10天</i>免费下载</div>
                <div class="get-gift-btn" @click="login">立即领取</div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="state === LOGIN_GUIDE_STATE.BAR "
          class="LoginGuideBar"
          @click="login"
        >
<!--          <span class="color-red">首次登录送10次免费下载</span>-->
<!--          <span class="color-blank">，超高清图片随心下载</span>-->
          <i class="iconfont icon-cha" @click.stop="closeBar"/>
        </div>
      </div>
    </no-ssr>
  </div>

</template>

<script>
import fnLoginGuide from "@/fn/fnLoginGuide";
import {LOGIN_GUIDE_STATE} from "@/constant/LOGIN_GUIDE";
import {fnLogin} from "@/fn";
import {mapState} from "vuex";
import {MODEL_NEW_USER_GIFT} from "@/constant/MODELS";
import {PAGE_PATH} from "@/constant/PAGE_PATH";

const GIFT_STATE = 'GIFT_STATE'
/* 邀请好友bar和浮标白名单 */
const WHITE_LIST = [
  PAGE_PATH.TUPIAN_LIST,
]

export default {
  name: "LoginGuide",
  data() {
    return {
      LOGIN_GUIDE_STATE,
      state: -1,
      isShow: false,
      isShowState: false,
      GIFT_STATE
    }
  },
  computed: {
    ...mapState('user', ['isLogin']),
  },
  mounted() {
    this.init();

  },
  methods: {

    init() {
      this.initShow();
      this.checkLoginGuideState();
      if (!this.getState() || this.getState() == 1) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    },
    initShow() {
      let path = this.$route.path
      if (path === '/') {
        this.isShowState = true;
        return;
      }

      for (let i = 0; i < WHITE_LIST.length; i++) {
        if (path.indexOf(WHITE_LIST[i]) > -1) {
          this.isShowState = true;
          break;
        } else {
          this.isShowState = false;
        }
      }
    },

    mouseleave() {
      this.isShow = false
      this.updateState(2)
    },
    mouseover() {
      this.isShow = true
      this.updateState(1)
    },

    closeBuoy() {
      this.isShow = false;
      this.updateState(2)
    },

    checkLoginGuideState() {
      if (!fnLoginGuide.getState() || fnLoginGuide.getState() === LOGIN_GUIDE_STATE.BAR) {
        this.state = LOGIN_GUIDE_STATE.BAR;
        fnLoginGuide.updateState(LOGIN_GUIDE_STATE.BAR);
      } else {
        this.state = fnLoginGuide.getState();
        fnLoginGuide.updateState(fnLoginGuide.getState());
      }
    },

    closeBar() {
      fnLoginGuide.updateState(LOGIN_GUIDE_STATE.BUOY);
      this.state = LOGIN_GUIDE_STATE.BUOY;

      this.mouseover()
      setTimeout(()=>{
        this.mouseleave();
      },1500)
    },

    login() {
      fnLogin.loginIntercept(false);
    },


    getState() {
      // return localStorage.getItemDate(MODEL_NEW_USER_GIFT);
      return sessionStorage.getItem(GIFT_STATE);

    },
    updateState(value = 1) {
      // localStorage.setItemDate(MODEL_NEW_USER_GIFT, value);
      sessionStorage.setItem(GIFT_STATE, value)
    },

  }
}
</script>

<style lang="scss" scoped>
.LoginGuide {

  .LoginGuideBuoy {
    //position: fixed;
    //right: 32px;
    //bottom: 30px;
    margin-top: 10px;
    width: 42px;
    height: 42px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.1000);
    border-radius: 6px 0px 0px 6px;
    //overflow: hidden;


    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 100px;
      height: 56px;
      background-color: transparent;
      z-index: -1;
      border-radius: 50%;
    }

    .gift-deploy {
      @include Position(1, 2, 0px, -8px);
      //transition: width 0.5s, transform 0.5s;
      transition: all 0.5s ease-in-out;
      opacity: 0;
      display: flex !important;
      align-items: center;
      @include BgImage(238px, 58px, 'act/login-guide/login-guide-gift-bg2.png?v=2');
      z-index: -1;

      .icon-cha {
        font-size: 12px;
        color: #ACB1C4;
        margin-left: 12px;
        position: relative;
        cursor: pointer;
        transition: transform 0.5s ease-out;

        &:after {
          content: '';
          position: absolute;
          top: -5px;
          right: -5px;
          bottom: -5px;
          left: -5px;
        }

        &:hover {
          transform: rotateZ(180deg);
        }
      }

      .content {
        margin: 0 6px 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          white-space: nowrap;
        }

        .txt-gift {
          font-size: 14px;
          font-weight: bold;
          line-height: 19px;

          .color-red {
            color: #FF2C5F;
          }
        }

        .get-gift-btn {
          display: inline-block;
          width: 70px;
          line-height: 22px;
          text-align: center;
          background: linear-gradient(133deg, #FD7777 0%, #FB4267 100%);
          border-radius: 10px;
          font-size: 12px;
          color: #FFE387;
          cursor: pointer;
          margin-left: 6px;

          &:hover {
            background: linear-gradient(133deg, #FB4267 0%, #FD7777 100%);
          }
        }
      }

    }

    .gift {
      //@include Position(1, 2, 0, 0);
      width: 100%;
      height: 100%;
      background: #FFFFFF;
      //background: rgba(255, 86, 158, 0.26);
      //filter: blur(30px);
      box-shadow: 0px 0px 8px 0px rgba(10, 37, 64, 0.1);
      border-radius: 6px 0px 0px 6px;
      @include LayoutFlex(1);
      cursor: pointer;
      //overflow: hidden;
      position: relative;

      .icon-gift {
        @include BgImage(27px, 27px, 'act/login-guide/login-guide-gif.png');
        position: relative;
      }


      &.active {
        overflow: visible !important;

        .gift-deploy {
          display: flex;
          //width: calc(188px);
          opacity: 1;
        }
      }
    }


  }

  .LoginGuideBar {
    //@include BgImage(1200px, 98px, 'act/login-guide/login-guide-bar.png');
    @include BgImage(1200px, 98px, 'act/login-guide/login-guide-bar2.png');
    position: fixed;
    left: 50%;
    bottom: 0;
    //transform: translateX(-50%);
    margin-left: -600px;
    z-index: 99;
    padding: 44px 0 0 332px;
    box-sizing: border-box;
    cursor: pointer;

    font-size: 20px;
    font-weight: bold;
    color: #FF2C5F;
    line-height: 27px;
    letter-spacing: 2px;

    // filter: grayscale(100%) !important;


    .color-blank {
      color: #1E2743;
    }

    .icon-cha {
      font-size: 14px;
      color: #FFFFFF;
      @include Position(1, 5, 12px, 24px);
      cursor: pointer;
    }
  }
}
</style>
