<template>
  <div class="home-dialog" v-show="isShow">
    <CgModal :is-show-close="false">
      <div slot="modal_content" class="modal-content">
        <a
          class="buy-btn"
          :href="MEMBER_LICENSE_VIP"
          target="_blank"
          @click="tag('充值:充值弹窗:图片');close"
        ></a>
      </div>
      <i
        class="iconfont icon-cha"
        slot="modal_close"
        @click="close"
      ></i>
    </CgModal>
  </div>
</template>

<script>
import {MEMBER_LICENSE_VIP} from "@/constant/PATH";
import {fnLogin, fnUser} from "@/fn";
import {MODEL_HOME_BAR, MODEL_HOME_Dialog} from "@/constant/MODELS";
import {tag} from "@/utils/statUtil";

export default {
  name: "TopUpHomeDialog",
  data() {
    return {
      MEMBER_LICENSE_VIP,
      isShow: false
    }
  },
  computed: {
    isLogin() {
      return this.$store.state.user.isLogin
    }
  },
  watch: {
    isLogin(n, o) {
      if (n == true) {
        setTimeout(() => {
          this.checkIsShow();
        }, 500)
      }else{
        this.isShow = false
      }
    }
  },
  mounted() {
    this.checkIsShow();
  },
  methods: {
    checkIsShow() {
      let loginWhere = fnLogin.checkLogin() && !fnUser.checkIsVip() && !this.getTodayIsShow();
      console.log('------loginWhere')
      console.log(loginWhere)
      if (loginWhere) {
        this.isShow = true
      } else {
        this.isShow = false;
      }
    },

    getTodayIsShow() {
      return this.$localStorage.getItemDate(MODEL_HOME_Dialog);
    },

    close() {
      this.isShow = false;
      this.$localStorage.setItemDate(MODEL_HOME_Dialog)
    },
    tag(stat) {
      tag(stat)
    }
  }
}
</script>

<style lang="scss" scoped>
.home-dialog {

  /deep/ .Modal-content {
    background-color: transparent !important;
  }

  .modal-content {
    @include BgImage(521px, 557px, 'act/top-up/home-dialog.png?v=1');

    .buy-btn {
      @include BgImage(368px, 60px, 'act/top-up/icon_btn.png?v=1');
      @include Position(1, 4, 50%, 35px);
      transform: translateX(-50%);
      cursor: pointer;
    }
  }


  .icon-cha {
    display: inline-block;
    width: 38px;
    height: 38px;
    background: #FFFFFF;
    opacity: 0.8;
    border-radius: 50%;
    font-size: 17px;
    @include LayoutFlex(1);
    @include Position(1, 2, -30px, 52px);
    cursor: pointer;
    transition: transform 0.5s ease-out;
    color: #20395B;

    &:hover {
      transform: rotateZ(180deg);
    }
  }
}

</style>
