<template>
  <div class="PicPackageButtonPrice">
    <div class="pic-price">
      <span class="pic-child-num">购买此包</span>
<!--      <i class="text-dollar">¥</i>-->
<!--      <span class="text-price">{{ // picPackageInfo.price }}</span>-->
      <span class="pic-child-num">/{{ picPackageInfo.number - 1 }}张</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PicPackageButtonPrice",
  props: {
    // 单个图片信息
    picPackageInfo: {
      type: Object,
      default: () => {
        price:0;
        number:0
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import "common/pic-pcakage-button";

.pic-price {
  color: #2D2B33;
  font-weight: bold;
  font-size: 0;

  /*.text-dollar {
    font-size: 12px;
    line-height: 24px;
  }

  .text-price {
    font-size: 18px;
    font-weight: bold;
    line-height: 48px;
  }*/

  .pic-child-num {
    font-size: 20px !important;
    line-height: 54px;
    font-weight: normal;
  }
}
</style>
