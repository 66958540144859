import Vue from 'vue'
import utils from '@/utils/index.js';
import Notification from '@/components/common/notification'
import localStorage from '@/utils/localStorage.js'
import myNotify from "@/utils/notify";
import IphoneMyNotify from "@/utils/notify-h5";
import * as swiperAni from '@/assets/swiper.animate'


Vue.prototype.$utils = utils;
Vue.prototype.$localStorage = localStorage;
Vue.prototype.$myNotify = myNotify;
Vue.prototype.$IphoneMyNotify = IphoneMyNotify;

if (!Vue._use_components_Notification) {
  Vue._use_components_Notification = true
  Vue.use(Notification);
  console.log(Vue, 'logVue');
}



