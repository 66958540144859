const moduleFlag = true;
const storePath = '_Privately/User/';
const setUserBindIphoneAloneModal = 'setUserBindIphoneAloneModal'

export const UserBindIphoneAloneModalStatus = {
  userBindIphoneAloneModal: false,  // 是否展示绑定手机弹框
}

export const UserBindIphoneAloneModalMutations = {
  [setUserBindIphoneAloneModal](state, payload) {
    moduleFlag && (state.userBindIphoneAloneModal = payload)
  },
}

/* 封装调用方法 */

/* 切换展示绑定手机弹框 */
export function setUserBindIphoneAloneModalStatus(store, payload) {
  store.commit(storePath + setUserBindIphoneAloneModal, payload);
}
