import Vue from "vue";
import HtTooltip from './tooltip'
import HtButton from './button'

if (!Vue._use_components_ht_plugins) {
  Vue._use_components_ht_plugins = true
  Vue.use(HtTooltip)
  Vue.use(HtButton)
}

