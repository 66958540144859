<template>
  <div class="application-use-section">
    <div class="application-bg-new"/>
    <div class="inner_center application-content">
      <div class="application-headline _layout-right">{{ applicationData.headline }}</div>
      <div
        class="application-subhead _layout-right"
        v-html="applicationData.subhead"/>
      <div class="application-list _layout-right">
        <div
          v-for="vo in applicationData.iconList"
          :key="vo.iconClass"
          class="application-item">
          <i
            :class="vo.iconClass"
            class="application-icon"/>
          <div class="application-name">{{ vo.title }}</div>
        </div>
      </div>
      <div class="support-bmp-headline _layout-right">{{ applicationData.bmpHeadline }}</div>
      <div class="support-bmp-list _layout-right">
        <div
          v-for="vo in applicationData.bpmList"
          :key="vo"
          class="support-bmp-item"> {{ vo }}
        </div>
      </div>
      <div class="application-view"/>

    </div>
  </div>
</template>

<script>
/* 可用应用程序 */
export const applicationData = {
  headline: "在所有应用程序都能流畅工作",
  subhead: `我们的图片主要来源于最优秀的设计师，这些设计师有着创意灵感，他们的设计蕴含着潮流概念。<br>
            这些也是企业、UI设计师和分析师使用和寻找的。`,
  iconList: [
    {
      iconClass: "icon-sketch",
      title: "Sketch App"
    },
    {
      iconClass: "icon-ae",
      title: "Adobe After Effect"
    },
    {
      iconClass: "icon-ai",
      title: "Adobe lllustrator"
    },
    {
      iconClass: "icon-ps",
      title: "Adobe Photoshop"
    },
  ],
  bmpHeadline: "海量文件格式，兼容主流设计软件",
  bpmList: [
    ".ae",
    ".c4d",
    ".eps",
    ".psd",
    ".png",
    ".ai",
    ".sketch",
  ]

}

export default {
  name: "ApplicationUse",
  data() {
    return {
      applicationData,
    }
  }
}
</script>

<style lang="scss" scoped>
.application-use-section {
  height: 722px;
  position: relative;

  .application-bg {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    @include Position(1, 5, 0, 0);
  }

  .application-bg-new {
    @include BgImage(100%, 722px, "home/images/application-bg-new.png", 1);
    @include Position(1, 5, 0, 0);

  }


  ._layout-right {
    display: flex;
    justify-content: flex-end !important;
  }

  .application-headline {
    font-size: 48px;
    font-weight: bold;
    color: #0A2540;
    line-height: 64px;
    letter-spacing: 2px;
    padding-top: 112px;
    margin-bottom: 24px;
  }

  .application-subhead {
    //width: 780px;
    height: 48px;
    font-size: 16px;
    color: #0A2540;
    line-height: 24px;
    letter-spacing: 1px;
    text-align: right;

  }

  .application-list {
    margin: 72px 0 56px;
    display: flex;

    .application-item {
      margin-left: 50px;
      @include LayoutFlex(2);

      .application-icon {
        display: inline-block;
        width: 80px;
        height: 80px;
        background-color: pink;
        margin-bottom: 10px;
      }

      .icon-sketch {
        @include BgImage(80px, 78px, "home/images/application/icon-sketch.svg");
      }

      .icon-ae {
        @include BgImage(80px, 78px, "home/images/application/icon-ae.svg");
      }

      .icon-ai {
        @include BgImage(80px, 78px, "home/images/application/icon-ai.svg");
      }

      .icon-ps {
        @include BgImage(80px, 78px, "home/images/application/icon-ps.svg");
      }

      .application-name {
        height: 28px;
        font-size: 10px;
        color: rgba(0, 0, 0, 0.35);
        line-height: 14px;
      }


    }
  }

  //.support-bmp{
  //flex-direction: column;
  .support-bmp-headline {
    height: 24px;
    font-size: 22px;
    font-weight: bold;
    color: #0A2540;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .support-bmp-list {
    @include LayoutFlex(1);

    .support-bmp-item {
      height: 32px;
      background: #FFFFFF;
      border-radius: 8px;
      line-height: 32px;
      text-align: center;
      font-size: 14px;
      color: #0A2540;
      padding: 0 8px;
      margin-left: 12px;
    }
  }

  .application-content {
    position: relative;

    .application-view {
      @include BgImage(490px, 490px, "home/images/application-view.png");
      @include Position(1, 5, 0, 132px);
    }

    @include Screen(1) {
      .application-view {
        @include BgImage(420px, 420px, "home/images/application-view.png");
        @include Position(1, 5, 0, 131px);
      }
    }
  }

  //}
}
</style>
