import {FANS} from "@/constant/fans/FANS";
import { PAGE_PATH } from '@/constant/PAGE_PATH'

class FnFans {

  /* 解析路由 */
  parseId(id) {
    console.log(id)
    let params = {
      module: 'new',
      page: 1
    }
    /* undefined 直接返回默认参数 */
    if (id == undefined) {
      return params
    }
    id = id.replace('.html', '');
    let idArr = id.split('-');
    params.module = idArr[0] || 'new';
    params.page = parseInt(idArr[1]) || 1;
    return params;
  }


  /* 获取需求池完成状态 */
  getDemandStatus(status = 0) {
    return FANS.DEMAND_POOL.STATUS[status];
  }


  /* 处理需求池列表 */
  disposeDemandList(list) {
    if (Array.isArray(list) && list.length) {


      list.forEach(vo =>{
        /* 处理需求状态 */
        vo._status = this.getDemandStatus(vo.status);
        /* 处理图片地址 */
        vo._case_material_url = vo.case_material_url_new.length ? PAGE_PATH.BASE_IMG_URL + vo.case_material_url_new[0].picUrl:'';
        /* 处理跳转a链接 */
        vo._link =  PAGE_PATH.BASE_URL + PAGE_PATH.FANS.DEMAND_DETAIL + '/' +vo.id + '.html'
      })

      /* 第三个位置插入空对象，用于插入排行榜 */
      if (list.length >= 3){
        list.splice(3, 0, {});
      }
      return list;

    } else {
      console.error('list 必须是有长度的数组')
      return [];
    }
  }

  /* 处理需求池详情接口 */
  disposeDemandDetail(detail){


    let data = detail;
    if (data.keyword && data.keyword.length) {


      // let keywordArr = data.keyword.split(' ')
      let keywordArr = data.keyword.split(/[,，\s]+/);

      let _keyword = [];
      for (let i = 0; i < keywordArr.length; i++) {
        let obj = {
          label: keywordArr[i]
        }
        if(obj != ''){
          _keyword.push(obj)
        }
      }
      data._keyword = _keyword
    }

    /* 处理图片 */


    /* 处理进度 */
    let _percentage = 0;
    switch(parseInt(data.status)){
      case 0:
        _percentage = 0;
        break;
      case 1:
        _percentage = 50;
        break;
      case 2:
        _percentage = 0;
        break;
      case 3:
        _percentage = 100;
        break;
    }

    data._percentage = _percentage


    /* 处理预览图片 */
    let previewList = []
    if (data.cover_img && data.cover_img.length){
      data.cover_img.forEach(item =>{
        previewList.push( PAGE_PATH.BASE_IMG_URL + item.picUrl )
      })
    }

    data._previewList = previewList

    console.log(data)

    return data;
  }



}

export default new FnFans();
