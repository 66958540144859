/**
 * @Author: wulizhou
 * @Description: 创作者中心
 * @Date:
 **/

import {
  CREATOR_WORKS,
  CREATOR_WORKS_MANAGE,
  CREATOR_SIGN,
  CREATOR_USER_CENTER,
  CREATOR_USER_CENTER_CERTIFICATE, CREATOR_TASK, CREATOR_PIC, CREATOR_DESIGN_GUIDE,
} from '@/constant/_constant/CON_BASIC'

/* 布局侧边栏 */
export const ASIDE = [
   /*{
    label: "签约认证",
    path: CREATOR_SIGN,
    iconClass: "icon-cz-a-paint-fill3",
    children: []
    , isOpen: true
  },*/

  {
    label: "任务中心",
    path: CREATOR_TASK,
    iconClass: "icon-cz-paint-fill3",
    children: []
    , isOpen: true
  },

  {
    label: "官方素材库",
    path: CREATOR_PIC,
    iconClass: "icon-cz-a-paint-fillbeifen2",
    children: []
    , isOpen: true
  },



  {
    label: "创作作品",
    path: CREATOR_WORKS,
    iconClass: "icon-cz-paint-fill",
    children: []
    , isOpen: false
  }
  , {
    label: "作品管理",
    path: CREATOR_WORKS_MANAGE,
    iconClass: "icon-cz-a-paint-fill2",
    children: []
    , isOpen: false
  }
  , {
    label: "设计指南",
    path: CREATOR_DESIGN_GUIDE,
    iconClass: "icon-cz-a-paint-fillbeifen3",
    children: []
    , isOpen: false
  }

  , {
    label: "个人中心",
    path: CREATOR_USER_CENTER,
    iconClass: "icon-cz-a-paint-fill4",
    children: [
     /* {
        label: "认证资料",
        path: CREATOR_USER_CENTER_CERTIFICATE,
        iconClass: "icon-cz-a-paint-fill4",
      },
      {
        label: "签约合同",
        path: CREATOR_USER_CENTER,
        iconClass: "icon-cz-a-paint-fill4",
      }*/
    ]
    , isOpen: false
  }
]

/* 审核状态 */
export const SIGN_STATUS = {
  DEFAULT: 0,  // 默认 未填写
  LOADING: 1,   // 审核中
  FAIL: 3,    // 审核失败
  CONTRACT: 2,  // 电子合同
  CONTRACT_FAIL: 4,  // 电子合同失败
  SUCCESS: 5  // 签约成功
}

/* 签约鉴权字段 */
export const CREATOR_AUTH = "CREATOR_AUTH";

export const SIGN_TYPE = {
  PERSON: "person", // 个人
  WORK_ROOM: "work_room"  // 工作室
}
