<template>
  <div
    class="PicButtonSourceFile"
    :class="'PicButtonBusiness' + buttonType"
  >
    {{ buttonTxt }}
  </div>
</template>

<script>
export default {
  name: "PicButtonSourceFile",
  props: {
    buttonTxt: {
      type: String,
      default: '下载源文件'
    },
    buttonType:{
      type: Number | String,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.PicButtonSourceFile{
  width: 240px;
  height: 54px;
  background: #6969FF;
  border-radius: 27px;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 54px;
  text-align: center;

  &:hover{
    background: #7878FF;
  }

  &.PicButtonBusiness4{
    background: linear-gradient(133deg, #3D4266 0%, #292C42 100%);
    color: #FFE5CE;

    &:hover{
      background: linear-gradient(133deg, #292C42 0%, #3D4266 100%);
    }
  }

}
</style>
