/**
 * @Author wulizhou
 * @Date 2022/4/24
 * @Description 数组工具函数封装
 */

class toolArray {
  /**
   * 获取两个随机数之间的数组
   * - 示例 ( 1 , -3 ) 返回 [-3,-2,-1,0,2]
   * @param { Number } value1
   * @param { Number } value2
   * @returns {*[]} 返回两个随机数之间的数组
   */
  getValuesArr(value1, value2) {
    let max = Math.max(value1, value2)
    let min = Math.min(value1, value2)
    let val = []
    for (let i = min; i <= max; i++) {
      val.push(i)
    }
    return val;
  }

  /* 简单去重 */

}

export default new toolArray();
