import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _37ab71aa = () => interopDefault(import('../pages/3d/index.vue' /* webpackChunkName: "pages/3d/index" */))
const _131fbc58 = () => interopDefault(import('../pages/calendar/index.vue' /* webpackChunkName: "pages/calendar/index" */))
const _1b92a00e = () => interopDefault(import('../pages/company/index.vue' /* webpackChunkName: "pages/company/index" */))
const _353c64e8 = () => interopDefault(import('../pages/creator/index.vue' /* webpackChunkName: "pages/creator/index" */))
const _b2f6cde4 = () => interopDefault(import('../pages/error-page/index.vue' /* webpackChunkName: "pages/error-page/index" */))
const _8051e4da = () => interopDefault(import('../pages/exchange-code/index.vue' /* webpackChunkName: "pages/exchange-code/index" */))
const _1609f5da = () => interopDefault(import('../pages/fans/index.vue' /* webpackChunkName: "pages/fans/index" */))
const _7e489e5f = () => interopDefault(import('../pages/fans-club/index.vue' /* webpackChunkName: "pages/fans-club/index" */))
const _8c4f8042 = () => interopDefault(import('../pages/feedback/index.vue' /* webpackChunkName: "pages/feedback/index" */))
const _ef57144a = () => interopDefault(import('../pages/introduce/index.vue' /* webpackChunkName: "pages/introduce/index" */))
const _1764c223 = () => interopDefault(import('../pages/invite/index.vue' /* webpackChunkName: "pages/invite/index" */))
const _056b244d = () => interopDefault(import('../pages/invite-h5/index.vue' /* webpackChunkName: "pages/invite-h5/index" */))
const _d770a94c = () => interopDefault(import('../pages/invite-member/index.vue' /* webpackChunkName: "pages/invite-member/index" */))
const _f3a43ca8 = () => interopDefault(import('../pages/module.js' /* webpackChunkName: "pages/module" */))
const _607c0323 = () => interopDefault(import('../pages/part-time/index.vue' /* webpackChunkName: "pages/part-time/index" */))
const _34166732 = () => interopDefault(import('../pages/protocol/index.vue' /* webpackChunkName: "pages/protocol/index" */))
const _5943e55e = () => interopDefault(import('../pages/red-book-share/index.vue' /* webpackChunkName: "pages/red-book-share/index" */))
const _0640af5e = () => interopDefault(import('../pages/timeline-update/index.vue' /* webpackChunkName: "pages/timeline-update/index" */))
const _3982d2d8 = () => interopDefault(import('../pages/upgrade/index.vue' /* webpackChunkName: "pages/upgrade/index" */))
const _83d839da = () => interopDefault(import('../pages/user-center/index.vue' /* webpackChunkName: "pages/user-center/index" */))
const _2654619c = () => interopDefault(import('../pages/act/618/index.vue' /* webpackChunkName: "pages/act/618/index" */))
const _6b50e32c = () => interopDefault(import('../pages/act/hb-capture/index.vue' /* webpackChunkName: "pages/act/hb-capture/index" */))
const _5a442688 = () => interopDefault(import('../pages/act/newYear/index.vue' /* webpackChunkName: "pages/act/newYear/index" */))
const _50ef780a = () => interopDefault(import('../pages/calendar/index2.vue' /* webpackChunkName: "pages/calendar/index2" */))
const _3261f40e = () => interopDefault(import('../pages/calendar/module.js' /* webpackChunkName: "pages/calendar/module" */))
const _4ab18930 = () => interopDefault(import('../pages/collection/downloadMixin.js' /* webpackChunkName: "pages/collection/downloadMixin" */))
const _c18f92f0 = () => interopDefault(import('../pages/collection/mixin.js' /* webpackChunkName: "pages/collection/mixin" */))
const _2a0003b0 = () => interopDefault(import('../pages/collection/share-code/index.vue' /* webpackChunkName: "pages/collection/share-code/index" */))
const _bcaefb76 = () => interopDefault(import('../pages/company/case-collection/index.vue' /* webpackChunkName: "pages/company/case-collection/index" */))
const _8686fa00 = () => interopDefault(import('../pages/company/collect-record/index.vue' /* webpackChunkName: "pages/company/collect-record/index" */))
const _1e3a8d2f = () => interopDefault(import('../pages/company/main-certificate/index.vue' /* webpackChunkName: "pages/company/main-certificate/index" */))
const _98d3383c = () => interopDefault(import('../pages/company/mandate-record/index.vue' /* webpackChunkName: "pages/company/mandate-record/index" */))
const _496d607c = () => interopDefault(import('../pages/company/pay-record/index.vue' /* webpackChunkName: "pages/company/pay-record/index" */))
const _1c8da2bf = () => interopDefault(import('../pages/company/team-manage/index.vue' /* webpackChunkName: "pages/company/team-manage/index" */))
const _291955d8 = () => interopDefault(import('../pages/components/HelpStatusModal.vue' /* webpackChunkName: "pages/components/HelpStatusModal" */))
const _71de574a = () => interopDefault(import('../pages/creator/design-guide/index.vue' /* webpackChunkName: "pages/creator/design-guide/index" */))
const _7dd787a3 = () => interopDefault(import('../pages/creator/pic/index.vue' /* webpackChunkName: "pages/creator/pic/index" */))
const _253318da = () => interopDefault(import('../pages/creator/sign/index.vue' /* webpackChunkName: "pages/creator/sign/index" */))
const _309e56a2 = () => interopDefault(import('../pages/creator/task/index.vue' /* webpackChunkName: "pages/creator/task/index" */))
const _5d9c4c70 = () => interopDefault(import('../pages/creator/user-center/index.vue' /* webpackChunkName: "pages/creator/user-center/index" */))
const _688df41b = () => interopDefault(import('../pages/creator/works/index.vue' /* webpackChunkName: "pages/creator/works/index" */))
const _1a6c236d = () => interopDefault(import('../pages/creator/works-manage/index.vue' /* webpackChunkName: "pages/creator/works-manage/index" */))
const _251d5eb5 = () => interopDefault(import('../pages/detail/mixin.js' /* webpackChunkName: "pages/detail/mixin" */))
const _56713a5a = () => interopDefault(import('../pages/enterprise/case/index.vue' /* webpackChunkName: "pages/enterprise/case/index" */))
const _170306c2 = () => interopDefault(import('../pages/fans/festival-lottery/index.vue' /* webpackChunkName: "pages/fans/festival-lottery/index" */))
const _dcdca0d4 = () => interopDefault(import('../pages/fans/member-demand-pool/index.vue' /* webpackChunkName: "pages/fans/member-demand-pool/index" */))
const _a30c846e = () => interopDefault(import('../pages/introduce/company/index.vue' /* webpackChunkName: "pages/introduce/company/index" */))
const _5ae68fb4 = () => interopDefault(import('../pages/introduce/company-plus/index.vue' /* webpackChunkName: "pages/introduce/company-plus/index" */))
const _b033964c = () => interopDefault(import('../pages/introduce/multiple/index.vue' /* webpackChunkName: "pages/introduce/multiple/index" */))
const _0d588692 = () => interopDefault(import('../pages/invite/team-role.vue' /* webpackChunkName: "pages/invite/team-role" */))
const _e67f376c = () => interopDefault(import('../pages/invoice/module.js' /* webpackChunkName: "pages/invoice/module" */))
const _d5400994 = () => interopDefault(import('../pages/invoice/write.vue' /* webpackChunkName: "pages/invoice/write" */))
const _12a50533 = () => interopDefault(import('../pages/member/license-vip.vue' /* webpackChunkName: "pages/member/license-vip" */))
const _342b4cc4 = () => interopDefault(import('../pages/part-time/module.js' /* webpackChunkName: "pages/part-time/module" */))
const _92500752 = () => interopDefault(import('../pages/pay/act-company/index.vue' /* webpackChunkName: "pages/pay/act-company/index" */))
const _3ad5b05a = () => interopDefault(import('../pages/pay/copy.vue' /* webpackChunkName: "pages/pay/copy" */))
const _a943126a = () => interopDefault(import('../pages/pay/edu/index.vue' /* webpackChunkName: "pages/pay/edu/index" */))
const _870226f6 = () => interopDefault(import('../pages/pay/module.js' /* webpackChunkName: "pages/pay/module" */))
const _3f8a74e9 = () => interopDefault(import('../pages/protocol/components/index.vue' /* webpackChunkName: "pages/protocol/components/index" */))
const _4a0ad89c = () => interopDefault(import('../pages/survey/user/index.vue' /* webpackChunkName: "pages/survey/user/index" */))
const _2a8f555c = () => interopDefault(import('../pages/tags/fnTag.js' /* webpackChunkName: "pages/tags/fnTag" */))
const _fcf39c96 = () => interopDefault(import('../pages/topic/mixin.js' /* webpackChunkName: "pages/topic/mixin" */))
const _7eabab92 = () => interopDefault(import('../pages/tupian/model.js' /* webpackChunkName: "pages/tupian/model" */))
const _8e738596 = () => interopDefault(import('../pages/user-center/code-record/index.vue' /* webpackChunkName: "pages/user-center/code-record/index" */))
const _134378a6 = () => interopDefault(import('../pages/user-center/collect-record/index.vue' /* webpackChunkName: "pages/user-center/collect-record/index" */))
const _367085dc = () => interopDefault(import('../pages/user-center/coupon-record/index.vue' /* webpackChunkName: "pages/user-center/coupon-record/index" */))
const _08e98f7a = () => interopDefault(import('../pages/user-center/download-record/index.vue' /* webpackChunkName: "pages/user-center/download-record/index" */))
const _3263479a = () => interopDefault(import('../pages/user-center/leaflet-record/index.vue' /* webpackChunkName: "pages/user-center/leaflet-record/index" */))
const _317c4418 = () => interopDefault(import('../pages/user-center/user-info/index.vue' /* webpackChunkName: "pages/user-center/user-info/index" */))
const _11462993 = () => interopDefault(import('../pages/user-center/vip-record/index.vue' /* webpackChunkName: "pages/user-center/vip-record/index" */))
const _5431f1fc = () => interopDefault(import('../pages/act/618/2.vue' /* webpackChunkName: "pages/act/618/2" */))
const _5440097d = () => interopDefault(import('../pages/act/618/3.vue' /* webpackChunkName: "pages/act/618/3" */))
const _3775008a = () => interopDefault(import('../pages/act/618/actBar/index.vue' /* webpackChunkName: "pages/act/618/actBar/index" */))
const _150fd0f8 = () => interopDefault(import('../pages/act/618/actRule.vue' /* webpackChunkName: "pages/act/618/actRule" */))
const _13a4321e = () => interopDefault(import('../pages/act/618/actWait.vue' /* webpackChunkName: "pages/act/618/actWait" */))
const _0bf8a986 = () => interopDefault(import('../pages/act/618/module.js' /* webpackChunkName: "pages/act/618/module" */))
const _c3f24fea = () => interopDefault(import('../pages/act/hb-capture/module.js' /* webpackChunkName: "pages/act/hb-capture/module" */))
const _4e4eb16a = () => interopDefault(import('../pages/act/newYear/actBar/index.vue' /* webpackChunkName: "pages/act/newYear/actBar/index" */))
const _7539d7d0 = () => interopDefault(import('../pages/act/newYear/actRule.vue' /* webpackChunkName: "pages/act/newYear/actRule" */))
const _b2fdabde = () => interopDefault(import('../pages/act/newYear/actWait.vue' /* webpackChunkName: "pages/act/newYear/actWait" */))
const _b2e59346 = () => interopDefault(import('../pages/act/newYear/module.js' /* webpackChunkName: "pages/act/newYear/module" */))
const _dab08128 = () => interopDefault(import('../pages/company/team-manage/invite.vue' /* webpackChunkName: "pages/company/team-manage/invite" */))
const _d3d4e866 = () => interopDefault(import('../pages/company/team-manage/join.vue' /* webpackChunkName: "pages/company/team-manage/join" */))
const _4c82adce = () => interopDefault(import('../pages/company/team-manage/role.vue' /* webpackChunkName: "pages/company/team-manage/role" */))
const _63174276 = () => interopDefault(import('../pages/creator/sign/sign-progress.vue' /* webpackChunkName: "pages/creator/sign/sign-progress" */))
const _f17893b6 = () => interopDefault(import('../pages/creator/sign/sign-renew.vue' /* webpackChunkName: "pages/creator/sign/sign-renew" */))
const _79b5eb12 = () => interopDefault(import('../pages/creator/sign/sign-write.vue' /* webpackChunkName: "pages/creator/sign/sign-write" */))
const _fa2b4396 = () => interopDefault(import('../pages/creator/user-center/certificate.vue' /* webpackChunkName: "pages/creator/user-center/certificate" */))
const _beb8fc12 = () => interopDefault(import('../pages/creator/works-manage/WorkDrawer.vue' /* webpackChunkName: "pages/creator/works-manage/WorkDrawer" */))
const _d8d5fe2c = () => interopDefault(import('../pages/fans/common/mixin.js' /* webpackChunkName: "pages/fans/common/mixin" */))
const _67b11cf7 = () => interopDefault(import('../pages/fans/demand-pool/mixin.js' /* webpackChunkName: "pages/fans/demand-pool/mixin" */))
const _7b957e7b = () => interopDefault(import('../pages/introduce/multiple/module.js' /* webpackChunkName: "pages/introduce/multiple/module" */))
const _7f0dc06c = () => interopDefault(import('../pages/pay/edu/module.js' /* webpackChunkName: "pages/pay/edu/module" */))
const _e83d4f78 = () => interopDefault(import('../pages/pay/enterprise-recharge/coupon-pulldown.vue' /* webpackChunkName: "pages/pay/enterprise-recharge/coupon-pulldown" */))
const _38b0a009 = () => interopDefault(import('../pages/pay/enterprise-recharge/module.js' /* webpackChunkName: "pages/pay/enterprise-recharge/module" */))
const _cde741f0 = () => interopDefault(import('../pages/pay/enterprise-recharge/transfer/index.vue' /* webpackChunkName: "pages/pay/enterprise-recharge/transfer/index" */))
const _f665461a = () => interopDefault(import('../pages/pay/leaflet/module.js' /* webpackChunkName: "pages/pay/leaflet/module" */))
const _51489c45 = () => interopDefault(import('../pages/pay/personal-recharge/coupon-pulldown.vue' /* webpackChunkName: "pages/pay/personal-recharge/coupon-pulldown" */))
const _f2887d6c = () => interopDefault(import('../pages/pay/personal-recharge/module.js' /* webpackChunkName: "pages/pay/personal-recharge/module" */))
const _675e2714 = () => interopDefault(import('../pages/protocol/common/protocol-extend.js' /* webpackChunkName: "pages/protocol/common/protocol-extend" */))
const _462d15d4 = () => interopDefault(import('../pages/protocol/components/about-company.vue' /* webpackChunkName: "pages/protocol/components/about-company" */))
const _89e86594 = () => interopDefault(import('../pages/protocol/components/common-problem.vue' /* webpackChunkName: "pages/protocol/components/common-problem" */))
const _09321800 = () => interopDefault(import('../pages/protocol/components/login.vue' /* webpackChunkName: "pages/protocol/components/login" */))
const _09a9eb18 = () => interopDefault(import('../pages/protocol/components/vip.vue' /* webpackChunkName: "pages/protocol/components/vip" */))
const _2d3fc320 = () => interopDefault(import('../pages/survey/user/components/index.js' /* webpackChunkName: "pages/survey/user/components/index" */))
const _1dba223d = () => interopDefault(import('../pages/survey/user/module.js' /* webpackChunkName: "pages/survey/user/module" */))
const _27956d51 = () => interopDefault(import('../pages/tupian/common/mixin.js' /* webpackChunkName: "pages/tupian/common/mixin" */))
const _42a09da9 = () => interopDefault(import('../pages/tupian/common/model.js' /* webpackChunkName: "pages/tupian/common/model" */))
const _321a7b72 = () => interopDefault(import('../pages/user-center/collect-record/module.js' /* webpackChunkName: "pages/user-center/collect-record/module" */))
const _0a1fcf7d = () => interopDefault(import('../pages/user-center/coupon-record/module.js' /* webpackChunkName: "pages/user-center/coupon-record/module" */))
const _46ce4dca = () => interopDefault(import('../pages/user-center/download-record/module.js' /* webpackChunkName: "pages/user-center/download-record/module" */))
const _36151998 = () => interopDefault(import('../pages/user-center/vip-record/module.js' /* webpackChunkName: "pages/user-center/vip-record/module" */))
const _7b62596a = () => interopDefault(import('../pages/act/618/actBar/bar.vue' /* webpackChunkName: "pages/act/618/actBar/bar" */))
const _28c187aa = () => interopDefault(import('../pages/act/newYear/actBar/bar.vue' /* webpackChunkName: "pages/act/newYear/actBar/bar" */))
const _3f08ddfa = () => interopDefault(import('../pages/introduce/multiple/components/authorized-scope.vue' /* webpackChunkName: "pages/introduce/multiple/components/authorized-scope" */))
const _09bdd067 = () => interopDefault(import('../pages/introduce/multiple/components/Question.vue' /* webpackChunkName: "pages/introduce/multiple/components/Question" */))
const _131ace5a = () => interopDefault(import('../pages/introduce/multiple/components/vip-card-group.vue' /* webpackChunkName: "pages/introduce/multiple/components/vip-card-group" */))
const _e3b1ffa8 = () => interopDefault(import('../pages/survey/user/components/age.vue' /* webpackChunkName: "pages/survey/user/components/age" */))
const _af9aa2fe = () => interopDefault(import('../pages/survey/user/components/industry.vue' /* webpackChunkName: "pages/survey/user/components/industry" */))
const _77bb93ea = () => interopDefault(import('../pages/survey/user/components/job.vue' /* webpackChunkName: "pages/survey/user/components/job" */))
const _76190918 = () => interopDefault(import('../pages/user-center/download-record/components/CertificationModal.vue' /* webpackChunkName: "pages/user-center/download-record/components/CertificationModal" */))
const _3fe289a4 = () => interopDefault(import('../pages/enterprise/case/detail/_id.vue' /* webpackChunkName: "pages/enterprise/case/detail/_id" */))
const _6302e2ba = () => interopDefault(import('../pages/company/pay/_id.vue' /* webpackChunkName: "pages/company/pay/_id" */))
const _77e5dac6 = () => interopDefault(import('../pages/fans/demand-detail/_id.vue' /* webpackChunkName: "pages/fans/demand-detail/_id" */))
const _3b6999a9 = () => interopDefault(import('../pages/fans/demand-detail/_mixin.js' /* webpackChunkName: "pages/fans/demand-detail/_mixin" */))
const _cd28f7de = () => interopDefault(import('../pages/fans/demand-pool/_id.vue' /* webpackChunkName: "pages/fans/demand-pool/_id" */))
const _526f2566 = () => interopDefault(import('../pages/fans/member-demand-pool-detail/_id.vue' /* webpackChunkName: "pages/fans/member-demand-pool-detail/_id" */))
const _837c5a60 = () => interopDefault(import('../pages/pay/enterprise-recharge/_id.vue' /* webpackChunkName: "pages/pay/enterprise-recharge/_id" */))
const _655cc23a = () => interopDefault(import('../pages/pay/leaflet/_id.vue' /* webpackChunkName: "pages/pay/leaflet/_id" */))
const _1b8e3f83 = () => interopDefault(import('../pages/pay/package/_id.vue' /* webpackChunkName: "pages/pay/package/_id" */))
const _f1135f5e = () => interopDefault(import('../pages/pay/personal-recharge/_id.vue' /* webpackChunkName: "pages/pay/personal-recharge/_id" */))
const _2be16f59 = () => interopDefault(import('../pages/pay/upgrade/_id.vue' /* webpackChunkName: "pages/pay/upgrade/_id" */))
const _667b1740 = () => interopDefault(import('../pages/collection/_id.vue' /* webpackChunkName: "pages/collection/_id" */))
const _44b11293 = () => interopDefault(import('../pages/detail/_id.vue' /* webpackChunkName: "pages/detail/_id" */))
const _43c05416 = () => interopDefault(import('../pages/invite-h5/_id.vue' /* webpackChunkName: "pages/invite-h5/_id" */))
const _3872f14b = () => interopDefault(import('../pages/invite/_id.vue' /* webpackChunkName: "pages/invite/_id" */))
const _51408771 = () => interopDefault(import('../pages/pay-company-plus/_id.vue' /* webpackChunkName: "pages/pay-company-plus/_id" */))
const _30f10e4c = () => interopDefault(import('../pages/pay/_id.vue' /* webpackChunkName: "pages/pay/_id" */))
const _e33efeb2 = () => interopDefault(import('../pages/pic-package/_id.vue' /* webpackChunkName: "pages/pic-package/_id" */))
const _7db6cbca = () => interopDefault(import('../pages/tags/_id.vue' /* webpackChunkName: "pages/tags/_id" */))
const _1e61df93 = () => interopDefault(import('../pages/topic/_id.vue' /* webpackChunkName: "pages/topic/_id" */))
const _595a6a29 = () => interopDefault(import('../pages/tupian/_id.vue' /* webpackChunkName: "pages/tupian/_id" */))
const _20a83cd3 = () => interopDefault(import('../pages/venue/_id.vue' /* webpackChunkName: "pages/venue/_id" */))
const _9b02cfea = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/3d",
    component: _37ab71aa,
    name: "3d"
  }, {
    path: "/calendar",
    component: _131fbc58,
    name: "calendar"
  }, {
    path: "/company",
    component: _1b92a00e,
    name: "company"
  }, {
    path: "/creator",
    component: _353c64e8,
    name: "creator"
  }, {
    path: "/error-page",
    component: _b2f6cde4,
    name: "error-page"
  }, {
    path: "/exchange-code",
    component: _8051e4da,
    name: "exchange-code"
  }, {
    path: "/fans",
    component: _1609f5da,
    name: "fans"
  }, {
    path: "/fans-club",
    component: _7e489e5f,
    name: "fans-club"
  }, {
    path: "/feedback",
    component: _8c4f8042,
    name: "feedback"
  }, {
    path: "/introduce",
    component: _ef57144a,
    name: "introduce"
  }, {
    path: "/invite",
    component: _1764c223,
    name: "invite"
  }, {
    path: "/invite-h5",
    component: _056b244d,
    name: "invite-h5"
  }, {
    path: "/invite-member",
    component: _d770a94c,
    name: "invite-member"
  }, {
    path: "/module",
    component: _f3a43ca8,
    name: "module"
  }, {
    path: "/part-time",
    component: _607c0323,
    name: "part-time"
  }, {
    path: "/protocol",
    component: _34166732,
    name: "protocol"
  }, {
    path: "/red-book-share",
    component: _5943e55e,
    name: "red-book-share"
  }, {
    path: "/timeline-update",
    component: _0640af5e,
    name: "timeline-update"
  }, {
    path: "/upgrade",
    component: _3982d2d8,
    name: "upgrade"
  }, {
    path: "/user-center",
    component: _83d839da,
    name: "user-center"
  }, {
    path: "/act/618",
    component: _2654619c,
    name: "act-618"
  }, {
    path: "/act/hb-capture",
    component: _6b50e32c,
    name: "act-hb-capture"
  }, {
    path: "/act/newYear",
    component: _5a442688,
    name: "act-newYear"
  }, {
    path: "/calendar/index2",
    component: _50ef780a,
    name: "calendar-index2"
  }, {
    path: "/calendar/module",
    component: _3261f40e,
    name: "calendar-module"
  }, {
    path: "/collection/downloadMixin",
    component: _4ab18930,
    name: "collection-downloadMixin"
  }, {
    path: "/collection/mixin",
    component: _c18f92f0,
    name: "collection-mixin"
  }, {
    path: "/collection/share-code",
    component: _2a0003b0,
    name: "collection-share-code"
  }, {
    path: "/company/case-collection",
    component: _bcaefb76,
    name: "company-case-collection"
  }, {
    path: "/company/collect-record",
    component: _8686fa00,
    name: "company-collect-record"
  }, {
    path: "/company/main-certificate",
    component: _1e3a8d2f,
    name: "company-main-certificate"
  }, {
    path: "/company/mandate-record",
    component: _98d3383c,
    name: "company-mandate-record"
  }, {
    path: "/company/pay-record",
    component: _496d607c,
    name: "company-pay-record"
  }, {
    path: "/company/team-manage",
    component: _1c8da2bf,
    name: "company-team-manage"
  }, {
    path: "/components/HelpStatusModal",
    component: _291955d8,
    name: "components-HelpStatusModal"
  }, {
    path: "/creator/design-guide",
    component: _71de574a,
    name: "creator-design-guide"
  }, {
    path: "/creator/pic",
    component: _7dd787a3,
    name: "creator-pic"
  }, {
    path: "/creator/sign",
    component: _253318da,
    name: "creator-sign"
  }, {
    path: "/creator/task",
    component: _309e56a2,
    name: "creator-task"
  }, {
    path: "/creator/user-center",
    component: _5d9c4c70,
    name: "creator-user-center"
  }, {
    path: "/creator/works",
    component: _688df41b,
    name: "creator-works"
  }, {
    path: "/creator/works-manage",
    component: _1a6c236d,
    name: "creator-works-manage"
  }, {
    path: "/detail/mixin",
    component: _251d5eb5,
    name: "detail-mixin"
  }, {
    path: "/enterprise/case",
    component: _56713a5a,
    name: "enterprise-case"
  }, {
    path: "/fans/festival-lottery",
    component: _170306c2,
    name: "fans-festival-lottery"
  }, {
    path: "/fans/member-demand-pool",
    component: _dcdca0d4,
    name: "fans-member-demand-pool"
  }, {
    path: "/introduce/company",
    component: _a30c846e,
    name: "introduce-company"
  }, {
    path: "/introduce/company-plus",
    component: _5ae68fb4,
    name: "introduce-company-plus"
  }, {
    path: "/introduce/multiple",
    component: _b033964c,
    name: "introduce-multiple"
  }, {
    path: "/invite/team-role",
    component: _0d588692,
    name: "invite-team-role"
  }, {
    path: "/invoice/module",
    component: _e67f376c,
    name: "invoice-module"
  }, {
    path: "/invoice/write",
    component: _d5400994,
    name: "invoice-write"
  }, {
    path: "/member/license-vip",
    component: _12a50533,
    name: "member-license-vip"
  }, {
    path: "/part-time/module",
    component: _342b4cc4,
    name: "part-time-module"
  }, {
    path: "/pay/act-company",
    component: _92500752,
    name: "pay-act-company"
  }, {
    path: "/pay/copy",
    component: _3ad5b05a,
    name: "pay-copy"
  }, {
    path: "/pay/edu",
    component: _a943126a,
    name: "pay-edu"
  }, {
    path: "/pay/module",
    component: _870226f6,
    name: "pay-module"
  }, {
    path: "/protocol/components",
    component: _3f8a74e9,
    name: "protocol-components"
  }, {
    path: "/survey/user",
    component: _4a0ad89c,
    name: "survey-user"
  }, {
    path: "/tags/fnTag",
    component: _2a8f555c,
    name: "tags-fnTag"
  }, {
    path: "/topic/mixin",
    component: _fcf39c96,
    name: "topic-mixin"
  }, {
    path: "/tupian/model",
    component: _7eabab92,
    name: "tupian-model"
  }, {
    path: "/user-center/code-record",
    component: _8e738596,
    name: "user-center-code-record"
  }, {
    path: "/user-center/collect-record",
    component: _134378a6,
    name: "user-center-collect-record"
  }, {
    path: "/user-center/coupon-record",
    component: _367085dc,
    name: "user-center-coupon-record"
  }, {
    path: "/user-center/download-record",
    component: _08e98f7a,
    name: "user-center-download-record"
  }, {
    path: "/user-center/leaflet-record",
    component: _3263479a,
    name: "user-center-leaflet-record"
  }, {
    path: "/user-center/user-info",
    component: _317c4418,
    name: "user-center-user-info"
  }, {
    path: "/user-center/vip-record",
    component: _11462993,
    name: "user-center-vip-record"
  }, {
    path: "/act/618/2",
    component: _5431f1fc,
    name: "act-618-2"
  }, {
    path: "/act/618/3",
    component: _5440097d,
    name: "act-618-3"
  }, {
    path: "/act/618/actBar",
    component: _3775008a,
    name: "act-618-actBar"
  }, {
    path: "/act/618/actRule",
    component: _150fd0f8,
    name: "act-618-actRule"
  }, {
    path: "/act/618/actWait",
    component: _13a4321e,
    name: "act-618-actWait"
  }, {
    path: "/act/618/module",
    component: _0bf8a986,
    name: "act-618-module"
  }, {
    path: "/act/hb-capture/module",
    component: _c3f24fea,
    name: "act-hb-capture-module"
  }, {
    path: "/act/newYear/actBar",
    component: _4e4eb16a,
    name: "act-newYear-actBar"
  }, {
    path: "/act/newYear/actRule",
    component: _7539d7d0,
    name: "act-newYear-actRule"
  }, {
    path: "/act/newYear/actWait",
    component: _b2fdabde,
    name: "act-newYear-actWait"
  }, {
    path: "/act/newYear/module",
    component: _b2e59346,
    name: "act-newYear-module"
  }, {
    path: "/company/team-manage/invite",
    component: _dab08128,
    name: "company-team-manage-invite"
  }, {
    path: "/company/team-manage/join",
    component: _d3d4e866,
    name: "company-team-manage-join"
  }, {
    path: "/company/team-manage/role",
    component: _4c82adce,
    name: "company-team-manage-role"
  }, {
    path: "/creator/sign/sign-progress",
    component: _63174276,
    name: "creator-sign-sign-progress"
  }, {
    path: "/creator/sign/sign-renew",
    component: _f17893b6,
    name: "creator-sign-sign-renew"
  }, {
    path: "/creator/sign/sign-write",
    component: _79b5eb12,
    name: "creator-sign-sign-write"
  }, {
    path: "/creator/user-center/certificate",
    component: _fa2b4396,
    name: "creator-user-center-certificate"
  }, {
    path: "/creator/works-manage/WorkDrawer",
    component: _beb8fc12,
    name: "creator-works-manage-WorkDrawer"
  }, {
    path: "/fans/common/mixin",
    component: _d8d5fe2c,
    name: "fans-common-mixin"
  }, {
    path: "/fans/demand-pool/mixin",
    component: _67b11cf7,
    name: "fans-demand-pool-mixin"
  }, {
    path: "/introduce/multiple/module",
    component: _7b957e7b,
    name: "introduce-multiple-module"
  }, {
    path: "/pay/edu/module",
    component: _7f0dc06c,
    name: "pay-edu-module"
  }, {
    path: "/pay/enterprise-recharge/coupon-pulldown",
    component: _e83d4f78,
    name: "pay-enterprise-recharge-coupon-pulldown"
  }, {
    path: "/pay/enterprise-recharge/module",
    component: _38b0a009,
    name: "pay-enterprise-recharge-module"
  }, {
    path: "/pay/enterprise-recharge/transfer",
    component: _cde741f0,
    name: "pay-enterprise-recharge-transfer"
  }, {
    path: "/pay/leaflet/module",
    component: _f665461a,
    name: "pay-leaflet-module"
  }, {
    path: "/pay/personal-recharge/coupon-pulldown",
    component: _51489c45,
    name: "pay-personal-recharge-coupon-pulldown"
  }, {
    path: "/pay/personal-recharge/module",
    component: _f2887d6c,
    name: "pay-personal-recharge-module"
  }, {
    path: "/protocol/common/protocol-extend",
    component: _675e2714,
    name: "protocol-common-protocol-extend"
  }, {
    path: "/protocol/components/about-company",
    component: _462d15d4,
    name: "protocol-components-about-company"
  }, {
    path: "/protocol/components/common-problem",
    component: _89e86594,
    name: "protocol-components-common-problem"
  }, {
    path: "/protocol/components/login",
    component: _09321800,
    name: "protocol-components-login"
  }, {
    path: "/protocol/components/vip",
    component: _09a9eb18,
    name: "protocol-components-vip"
  }, {
    path: "/survey/user/components",
    component: _2d3fc320,
    name: "survey-user-components"
  }, {
    path: "/survey/user/module",
    component: _1dba223d,
    name: "survey-user-module"
  }, {
    path: "/tupian/common/mixin",
    component: _27956d51,
    name: "tupian-common-mixin"
  }, {
    path: "/tupian/common/model",
    component: _42a09da9,
    name: "tupian-common-model"
  }, {
    path: "/user-center/collect-record/module",
    component: _321a7b72,
    name: "user-center-collect-record-module"
  }, {
    path: "/user-center/coupon-record/module",
    component: _0a1fcf7d,
    name: "user-center-coupon-record-module"
  }, {
    path: "/user-center/download-record/module",
    component: _46ce4dca,
    name: "user-center-download-record-module"
  }, {
    path: "/user-center/vip-record/module",
    component: _36151998,
    name: "user-center-vip-record-module"
  }, {
    path: "/act/618/actBar/bar",
    component: _7b62596a,
    name: "act-618-actBar-bar"
  }, {
    path: "/act/newYear/actBar/bar",
    component: _28c187aa,
    name: "act-newYear-actBar-bar"
  }, {
    path: "/introduce/multiple/components/authorized-scope",
    component: _3f08ddfa,
    name: "introduce-multiple-components-authorized-scope"
  }, {
    path: "/introduce/multiple/components/Question",
    component: _09bdd067,
    name: "introduce-multiple-components-Question"
  }, {
    path: "/introduce/multiple/components/vip-card-group",
    component: _131ace5a,
    name: "introduce-multiple-components-vip-card-group"
  }, {
    path: "/survey/user/components/age",
    component: _e3b1ffa8,
    name: "survey-user-components-age"
  }, {
    path: "/survey/user/components/industry",
    component: _af9aa2fe,
    name: "survey-user-components-industry"
  }, {
    path: "/survey/user/components/job",
    component: _77bb93ea,
    name: "survey-user-components-job"
  }, {
    path: "/user-center/download-record/components/CertificationModal",
    component: _76190918,
    name: "user-center-download-record-components-CertificationModal"
  }, {
    path: "/enterprise/case/detail/:id?",
    component: _3fe289a4,
    name: "enterprise-case-detail-id"
  }, {
    path: "/company/pay/:id?",
    component: _6302e2ba,
    name: "company-pay-id"
  }, {
    path: "/fans/demand-detail/:id?",
    component: _77e5dac6,
    name: "fans-demand-detail-id"
  }, {
    path: "/fans/demand-detail/:mixin?",
    component: _3b6999a9,
    name: "fans-demand-detail-mixin"
  }, {
    path: "/fans/demand-pool/:id?",
    component: _cd28f7de,
    name: "fans-demand-pool-id"
  }, {
    path: "/fans/member-demand-pool-detail/:id?",
    component: _526f2566,
    name: "fans-member-demand-pool-detail-id"
  }, {
    path: "/pay/enterprise-recharge/:id?",
    component: _837c5a60,
    name: "pay-enterprise-recharge-id"
  }, {
    path: "/pay/leaflet/:id?",
    component: _655cc23a,
    name: "pay-leaflet-id"
  }, {
    path: "/pay/package/:id?",
    component: _1b8e3f83,
    name: "pay-package-id"
  }, {
    path: "/pay/personal-recharge/:id?",
    component: _f1135f5e,
    name: "pay-personal-recharge-id"
  }, {
    path: "/pay/upgrade/:id?",
    component: _2be16f59,
    name: "pay-upgrade-id"
  }, {
    path: "/collection/:id?",
    component: _667b1740,
    name: "collection-id"
  }, {
    path: "/detail/:id?",
    component: _44b11293,
    name: "detail-id"
  }, {
    path: "/invite-h5/:id",
    component: _43c05416,
    name: "invite-h5-id"
  }, {
    path: "/invite/:id",
    component: _3872f14b,
    name: "invite-id"
  }, {
    path: "/pay-company-plus/:id?",
    component: _51408771,
    name: "pay-company-plus-id"
  }, {
    path: "/pay/:id?",
    component: _30f10e4c,
    name: "pay-id"
  }, {
    path: "/pic-package/:id?",
    component: _e33efeb2,
    name: "pic-package-id"
  }, {
    path: "/tags/:id?",
    component: _7db6cbca,
    name: "tags-id"
  }, {
    path: "/topic/:id?",
    component: _1e61df93,
    name: "topic-id"
  }, {
    path: "/tupian/:id?",
    component: _595a6a29,
    name: "tupian-id"
  }, {
    path: "/venue/:id?",
    component: _20a83cd3,
    name: "venue-id"
  }, {
    path: "/",
    component: _9b02cfea,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
