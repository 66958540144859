/* 企业服务 api */

/* 成员管理 */
import request from "@/utils/request";
import qs from "qs";

/* 成员列表 */
export function getMemberList(query) {
  return request({
    url: '?m=CompanyMember&a=index',
    method: 'get',
    params: query
  })
}
/* 修改团队角色 */
export function updateTeamRole(data) {
  return request({
    url: '?m=CompanyMember&a=memberTeamActorEdit',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 修改用户备注 */
export function updateMemberRemark(data) {
  return request({
    url: '?m=CompanyMember&a=memberRemarkEdit',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 成员删除 */
export function removeMember(data) {
  return request({
    url: '?m=CompanyMember&a=memberRemove',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 修改成员主体 */
export function updateMemberMain(data) {
  return request({
    url: '?m=CompanyMember&a=modifyMain',
    method: 'post',
    data: qs.stringify(data)
  })
}



/* 团队管理 */
/* 增加 */
export function addTeam(data) {
  return request({
    url: '?m=CompanyMember&a=teamAdd',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 列表 */
export function getTeamList(query) {
  return request({
    url: '?m=CompanyMember&a=team',
    method: 'get',
    params: query
  })
}

/* 增加角色 */
export function addRole(data) {
  return request({
    url: '?m=CompanyMember&a=actorAdd',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 获取角色列表 */
export function getRoleList(query) {
  return request({
    url: '?m=CompanyMember&a=actor',
    method: 'get',
    params: query
  })
}

/* 团队角色列表 */
export function getTeamRoleList(query) {
  return request({
    url: '?m=CompanyMember&a=teamActor',
    method: 'get',
    params: query
  })
}

/* 团队角色编辑 */
export function updateTeamRoleList(data) {
  return request({
    url: '?m=CompanyMember&a=teamActorEdit',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 团队角色删除 */
export function removeTeamRoleList(data) {
  return request({
    url: '?m=CompanyMember&a=teamActorDel',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 设置管理员 */
export function updateAdmin(data) {
  return request({
    url: '?m=CompanyMember&a=adminTransfer',
    method: 'post',
    data: qs.stringify(data)
  })
}


/* _____ 申请加入 _____ */
/* 申请列表 */
export function getJoinList(query) {
  return request({
    url: '?m=CompanyMember&a=review',
    method: 'get',
    params: query
  })
}

/* 申请未读数量 */
export function getJoinNoReadNum(query) {
  return request({
    url: '?m=CompanyMember&a=applyCorner',
    method: 'get',
    params: query
  })
}

/* 链接邀请 */
export function inviteByLink(data) {
  return request({
    url: '?m=CompanyMember&a=linkInvite',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 申请状态 */
export function getJoinStatus(query) {
  return request({
    url: '?m=CompanyMember&a=reviewProgress',
    method: 'get',
    params: query
  })
}

/* 管理员直接邀请( 不需审核 ) */
export function inviteMember(data) {
  return request({
    url: '?m=CompanyMember&a=directInvite',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 申请审核 */
export function auditJoinTeam(data) {
  return request({
    url: '?m=CompanyMember&a=audit',
    method: 'post',
    data: qs.stringify(data)
  })
}



