<template>
  <div class="select-box __form" >
    <div class="__form-input __form-select">

<!--
   :class="{'on':isVisibleFirst}"
   -->
<!--    <div class="curr" >
      <span v-if="selectionBoxDisplay">请选择</span>
      <span v-else>{{ theString }}</span>
      <span class="arrow"/>
    </div>-->

      <input
        ref="input"
        v-bind="$attrs"
        class="select"
        type="text"
        readonly
        :disabled="disabled"
        @blur="handleBlur"
        @click="showFirstLayer"
        :class="[ { 'disabled': disabled } ]"
        :placeholder="inputItem.placeholder"
      >

      <i class="iconfont2 icon-qy-arrow-down-bold-line" />

      <span
        v-if="inputItem.isShowErrTxt"
        class="input-err">{{ inputItem.errTxt }}</span>


      <div class="list-box options clearfix" v-if="isVisibleFirst">
        <div class="list __form-scrollbar">
          <div
            class="item overflow-one"
            v-for="(theitem,index) in list"
            @mousedown="givenValueOfCurr(index)"
            :class="{'active':index==curr}">{{ theitem.industry }}
          </div>

        </div>
        <div
          class="list __form-scrollbar"
          v-if="isVisibleSecond">
          <div
            class="item overflow-one" v-for="(theSeItem,index1) in list[curr].childs"
            @mousedown="clickingSecondLayer(theSeItem,index1)"
            :class="{'active':index1==currSec}">
            {{ theSeItem.industry }}
          </div>

        </div>
        <div class="icon"></div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "IndustryCascader",
  data() {
    return {

      curr: -1,
      currSec: -1,

      firstTimeClick: 0,

      isVisibleFirst: false,

      isVisibleSecond: false,

      theString: "",

      selectionBoxDisplay: true,

    }
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    label:{
      type: String,
      default: 'name'
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled:{
      type: Boolean,
      default: false
    },
    inputItem: {
      type: Object,
      default: () => {}
    },
    value: [String, Number],
  },
  computed: {
    nativeInputValue() {
      return this.value === null || this.value === undefined ? '' : String(this.value);
    },
  },
  watch: {
    nativeInputValue() {
      this.setNativeInputValue();
    },
  },
  mounted() {
    this.setNativeInputValue();
  },
  methods: {
    showFirstLayer() {
      // this.isVisibleFirst = !this.isVisibleFirs;
      this.isVisibleFirst = true;
      // this.firstTimeClick += 1;
      // if (this.firstTimeClick > 1) {
      //   this.isVisibleSecond = true;
      // }
      // this.selectionBoxDisplay = false;

    },
    givenValueOfCurr(index) {
      this.curr = index;
      console.log(this.list[this.curr].childs);
      this.isVisibleFirst = true
      this.isVisibleSecond = true;
      this.currSec = -1;
      this.theString = this.list[this.curr].industry + "\/";
      console.log(this.theString)
    },
    clickingSecondLayer(theSeItem,index1) {
      this.isVisibleFirst = false;
      this.currSec = index1;
      console.log(this.isVisibleFirst);
      this.isVisibleSecond = false;
      event.stopPropagation();
      this.theString += this.list[this.curr].childs[this.currSec].industry;
      console.log(this.theString)

      theSeItem.theString = this.theString

      console.log(theSeItem)
      this.$emit('industryCascaderConfirm',theSeItem)

    },


    handleClick(){
      if (this.disabled){
        return
      }
      this.$emit('click')
      this.isShow = true;
    },
    handleInput(event) {
      this.$emit('input', event.target.value);
    },
    handleChange(event) {
      this.$emit('change', event.target.value);
    },
    handleFocus(event) {
      this.isShow = true;
      this.$emit('focus', event);
    },
    handleBlur(event) {

      this.$emit('blur', event);
    },
    clear() {
      this.$emit('input', '');
      this.$emit('change', '');
      this.$emit('clear');
    },
    selectItem(vo) {
      this.isShow = false;
      // this.value = vo.label || vo[this.label]
      this.$emit('select', vo)
    },
    getInput() {
      return this.$refs.input || this.$refs.textarea;
    },
    setNativeInputValue() {
      const input = this.getInput();
      if (!input) return;
      if (input.value === this.nativeInputValue) return;
      input.value = this.nativeInputValue;
    },

  }
}
</script>

<style lang="scss" scoped>
@import "styles/form";
* {
  padding: 0;
  margin: 0;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


.options{
  overflow: visible !important;
  padding: 6px !important;
}
a {
  text-decoration: none;
}

.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table;
}

.clearfix:after {
  clear: both;
}

input {
  outline: none;
}

ol,
ul {
  list-style: none;
}

body {
  padding: 20px;
}

.select-box {
  position: relative;
  z-index: 10;
}

.select-box .curr {
  //width: 240px;
  width: 100%;
  padding-left: 15px;
  padding-right: 30px;
  border: 1px solid #ddd;
  border-radius: 5px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  position: relative;
  z-index: 15;
  background-color: white;
}

.select-box .curr:hover {
  border-color: #aaa;
  cursor: pointer;
}

.arrow {
  position: absolute;
  right: 10px;
  top: 17px;
  display: block;
  width: 10px;
  height: 10px;
  border-left: 1px solid #aaa;
  border-top: 1px solid #aaa;
  transform: rotate(225deg);
  transform-origin: 2px 2px;
  transition: 200ms;
}

.on .arrow {
  transform: rotate(45deg);
}

.list-box {
  position: absolute;
  //top: 30px;
  top: 56px;
  left: 0;
  padding: 5px 0;
  z-index: 10;
  transition: 200ms;
  background: #FFFFFF;
  display: flex;
  width: 350px;

  &:first-child{
    border-right: 1px solid #F5F6FA;

  }
}

.on .list-box {
  top: 50px;
}

.list-box .list {
  float: left;
  width: 200px;
  height: 204px;
  //overflow: auto;
  max-height: 200px;
  overflow-y: scroll;
}

.list-box .list.first {
  border: none;
}

.list .item {
  height: 38px;
  line-height: 38px;
  padding: 0 15px;
  font-size: 14px;
  cursor: pointer;
  position: relative;
}

.list .item:hover {
  background-color: rgb(245, 247, 250);
}

.list .item.active {
  color: #409EFF;
  font-weight: bold;
}

.list .icon {
  position: absolute;
  top: -6px;
  left: 30px;
  width: 10px;
  height: 10px;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
  z-index: 20;
  transform: rotate(45deg);
  background-color: white;
}

.list .arrow-icon {
  position: absolute;
  right: 10px;
  top: 17px;
  display: block;
  width: 10px;
  height: 10px;
  border-left: 1px solid #aaa;
  border-top: 1px solid #aaa;
  transform: rotate(135deg);
  transform-origin: 2px 2px;
  transition: 200ms;
}
</style>
