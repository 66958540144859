<template>
  <div class="Logo">

    <a
      href="/"
      class="logo-box"
      title="潮国创意"
    >
      <i
        class="iconfont icon-logo icon-logo1"
        :class="logoColor"
        @click="topTop"
        @mouseover="isShowCollectWebsite = true"
        @mouseleave="isShowCollectWebsite = false"
      />
      <i class="logo-name">潮国创意</i>
    </a>

    <!-- 收藏提示 -->
    <i
      v-if="showCollect"
      :class="[iconType, isShowCollectWebsite ? 'ON':'NO' ]"
      class="icon_collect-website"
    >
      拖动LOGO至书签栏，立即收藏潮国创意
    </i>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: "Logo",
  props:{
    logoWebsitePosition: {
      type: String,
      default: 'blue'
    },
    iconType: {
      type: String,
      default: 'blue'
    },
    showCollect:{
      type: Boolean,
      default:  true
    },
    logoColor: ''
  },
  computed: {
    ...mapGetters('user', ['isLogin', 'avatar']),
  },
  watch: {
    isLogin(n, o) {
      if (n == true) {
        this.isShowCollectWebsite = true;
        setTimeout(() => {
          this.isShowCollectWebsite = false;
        }, 5000)
        // this.getCouponList();
        /* 更新用户是否购买图片包 */
      } else {
        // this.showNewTipButton = false
      }
    },
  },
  data(){
    return{
      isShowCollectWebsite: false,
    }
  },
  methods:{
    topTop() {
      this.$emit('topTop');
    },
  }
}
</script>

<style lang="scss" scoped>
.Logo{
  position: relative;

  .logo-box{
    cursor: pointer;
  }

  .logo-name {
    position: absolute;
    z-index: -99;
    color: transparent;
    opacity: 0;
    left: -999px;
  }

  .icon-logo {
    font-size: 24px;
    //margin-right: 40px;
    color: #252626;
    position: relative;
    z-index: 10;
    background-color: transparent;

    &._white{
      color: #FFFFFF;
    }

  }

  .icon_collect-website {
    @include BgImage(299px, 52px, 'header/icon_collect-website@default.png?v=1');
    @include Position(1, 5, 22px, 10px);
    font-size: 14px;
    color: #FFFFFF;
    line-height: 60px;
    text-align: center;
    //animation: fadeinT 0.5s 1;
    transition: all 0.5s ease-in-out;
    z-index: -1;
    opacity: 0;

    &.blue{
      @include BgImage(297px, 49px, 'header/icon_collect-website@blue.png?v=1');
    }

    &.ON{
      @include Position(1, 5, 22px, 34px);
      opacity: 1;
    }
    &.NO{
      @include Position(1, 5, 22px, 10px);
      opacity: 0;
    }
  }
}
</style>
