import {getPicCategory} from "~/api/material";
import {getDropdownByHot, getDropdownByThreeD} from "@/api/nav";
import {getDemandDetail, getDemandDetailTimeline, getDemandList} from "@/api/fans/demand-pool";
import {getVenueDetail} from "@/api/act/venue";
import {getContractExpire, getFddCode, getSignStatus} from "@/api/creator/sign";
import request from "@/utils/request";
import {act618Sign} from "@/api/act/618";


export default ({app: {$request}}, inject) => {
  inject('api', {
    getHomeMaterialList(data) {
      return $request.get('/?m=Api&a=getIndexPic', data)
    },
    getBanner(data) {
      return $request.get('/?m=Api&a=indexBanner', data)
    },
    /* 首页推荐 */
    getHomeTopicRecommend(data) {
      return $request.get('/?m=Api&a=indexTopic', data)
    },


    getMenuNavList(data) {
      return $request.get('/?m=Dropdown&a=keywords', data)
    },

    /* 设计风格下拉 */
    getDropdownByDesignStyle(data) {
      return $request.get('/?m=Dropdown&a=design', data)
    },

    /* 首页日历列表 */
    getHomeCalendarList(data) {
      return $request.get('/?m=Dropdown&a=calendar', data)
    },

    /* 首页搜索词 */
    getHotwords(data) {
      return $request.get('/?m=Api&a=getHotwords', data)
    },
    /* Tag列表 */
    getTagList(data) {
      return $request.get('/?m=Auxfun&a=lexicon', data)
    },

    /* 首页搜索词 */
    getSpiderWords(data) {
      return $request.get('/?m=Problem&a=spiderWord', data)
    },
    /* 图片分类 */
    getMaterialClassify(data) {
      return $request.get('/?m=Api&a=getCategory', data)
    },

    /* 设计风格分类 */
    getMaterialStyleClassify(data) {
      return $request.get('/?m=Picture&a=designStyle', data)
    },

    /* 图片列表 */
    getMaterialList(data) {
      return $request.get('/?m=Api&a=getList', data)
    },

    getTupianListRecommendWord(data) {
      return $request.get('/?m=Auxfun&a=topSearch', data)
    },

    /* 图片包列表 */
    getPicPackageList(data) {
      return $request.get('/?m=Api&a=package', data)
    },

    /* 图片详情 */
    getMaterialDetail(data) {
      return $request.get('/?m=Api&a=getDetail', data)
    },

    /* 详情预览 */
    glanceDetail(data) {
      return $request.get('/?m=Api&a=glance', data)
    },

    /* 详情专题推荐 */
    detailTopicList(data) {
      return $request.get('/?m=Topic&a=picBottom', data)
    },
    /* 素材一级分类 */
    getPicCategory(data) {
      return $request.get('/?m=Api&a=getPicCategory', data)
    },

    /* 图片包预览 */
    packageStat(data) {
      return $request.get('?m=StatApi&a=packageStat', data)
    },

    /* 素材套图 */
    getMaterialSuite(data) {
      return $request.get('/?m=Api&a=getSetPic', data)
    },

    /* 更多潮图 */
    getTrendList(data) {
      return $request.get('/?m=Api&a=recommend', data)
    },

    /* 更多潮图 */
    getCompanyInfoByPerson(data) {
      return $request.get('/?m=Company&a=personalCenter', data)
    },


    /* 图片列表页 ----- */
    /* 列表页底部推荐专题 */
    getTupianListMoreTopic(data) {
      return $request.get('/?m=Topic&a=materialBottom', data)
    },


    /* 专题合集 */
    /* 专题分类 */
    getTopicNav(data) {
      return $request.get('/?m=Topic&a=top', data)
    },
    /* 专题列表 */
    getTopicList(data) {
      return $request.get('/?m=Topic&a=index', data)
    },
    /* 专题列表 */
    getTopicClassify(data) {
      return $request.get('/?m=Topic&a=category', data)
    },

    /* 大合集列表 */
    getCollectionListByLarge(data) {
      return $request.get('/?m=Topic&a=detail', data)
    },
    getCollectionList(data) {
      return $request.get('/?m=Topic&a=material', data)
    },
    getTopicRecommend(data) {
      return $request.get('/?m=Topic&a=bottom', data)
    },
    getTopicCollectStatus(data) {
      return $request.get('/?m=Topic&a=userCollect', data)
    },

    /* 3D 介绍页 */
    getThreeDClassifyList(data) {
      return $request.get('/?m=Dropdown&a=homePage3D', data)
    },

    getThreeDRecommend(data) {
      return $request.get('/?m=Dropdown&a=recommend3D', data)
    },


    /* 导航 */
    /* 全部热点下拉 */
    getDropdownByHot(data) {
      return $request.get('/?m=Dropdown&a=hot', data)
    },
    getDropdownByThreeD(data) {
      return $request.get('/?m=Dropdown&a=navigate3D', data)
    },
    getDropdownByFestival(data) {
      return $request.get('/?m=Dropdown&a=festival', data)
    },


    /* 需求池 */
    /* 需求池列表 */
    getDemandList(data) {
      return $request.get('/?m=VipDemand&a=index', data)
    },
    /* 需求详情 */
    getDemandDetail(data) {
      return $request.get('/?m=VipDemand&a=detail', data)
    },
    getDemandDetailTimeline(data) {
      return $request.get('/?m=VipDemand&a=timeLine', data)
    },


    /* 会场 */
    /* 会场详情 */
    getVenueDetail(data) {
      return $request.get('/?m=Venue&a=detail', data)
    },


    /* 创作者中心 */
    /* 获取签约状态 */
    getSignStatus(data) {
      return $request.get('/?m=PartTimeJob&a=auditProgress', data)
    },

    getContractExpire(data) {
      return $request.get('/?m=PartTimeJob&a=contractExpire', data)
    },

    getFddCode(data) {
      return $request.get('/?m=PartTimeJob&a=extSign', data)
    },

    getFddRenewCode(data) {
      return $request.get('/?m=PartTimeJob&a=getFadadaUrl', data)
    },

    requestByGet(url,data) {
      return $request.get(url, data)
    },


    generateTask(data) {
      return $request.get('/?m=Huaban&a=generateTask', data)
    },

    act618Sign(data) {
      return $request.get('/?m=Activity&a=signState', data)
    },



  })
}
