<template>
  <div class="menu-list HeaderNav">
    <!-- 默认固定显示菜单 -->
    <!--      v-bind="$attrs"-->
    <NavItemHot
      :nav-index="navIndex"
      @navigateToList="navigateToList"
      @updateNavId="updateNavId"
      :picList="picList"
      :festivalList="festivalList"
      :solarTermsList="solarTermsList"
      :recommendList="recommendList"
    />
    <NavItemPPT
      :nav-index="navIndex"
      @navigateToList="navigateToList"
      @updateNavId="updateNavId"
      v-bind="$attrs"
      :festivalData="festivalData"
    />
    <NavItem3D
      :nav-index="navIndex"
      @navigateToList="navigateToList"
      @updateNavId="updateNavId"
      :threeDCategoryList="threeDCategoryList"
      :threeDRecommendList="threeDRecommendList"
    />
    <NavItemBusiness
      :nav-index="navIndex"
      @navigateToList="navigateToList"
      @updateNavId="updateNavId"
    />

    <NavItemFree
      @updateNavId="updateNavId"
      :nav-index="navIndex"
    />
    <div
      v-for="vo in navItemList"
      :key="vo.path"
      class="menu-item menu-item-more _pointer "
      :class="[ {'on': vo.id == navIndex} ]"
    >
      <i
        v-if="vo.id === 6"
        class="icon_new"/>
      <a
        :href="vo.path"
        target="_blank"
        @click.prevent="navigateTo(vo),updateNavId(vo.id)"
      >
        {{ vo.label }}
      </a>
    </div>

    <NavItemMore
      v-if="navItemMore.length"
      :nav-index="navIndex"
      :list="navItemMore"
      @navigateTo="navigateTo"
      @updateNavId="updateNavId"
      :navType="navType"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
import {MARKETING_CALENDAR, MATERIAL_LIST_CUSTOM, PIC_PACKAGE, TIMELINE_UPDATE} from "@/constant/PATH";
import {topbarMenuList} from "@/components/common/header/module";
import {parseListUrl, parseUrlStr} from "@/components/list/module";
import {flowStatistics} from "@/api/flow-statistics";
import {fnFlowStatistics, fnInviteFriend, fnLocalStorage} from "@/fn";
import {mapState} from "vuex";
import HeaderAllDropDown from "@/components/_Public/Header/HeaderAllDropDown";

import {
  NavItemHot,
  NavItemSolarTerms,
  NavItemPPT,
  NavItem3D,
  NacItemPicPackage,
  NavItemMore,
  NavItemBusiness,
  NavItemFree,
  NavItemStyle,
  NavItemTopic,
} from '@/components/common/header/header-nav/components/index'
import {COMMON} from "@/constant/COMMON";
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import {NAV} from "@/components/common/header/header-nav/common/module";
import Bus from "~/components/common/header/header-nav/common/Bus";//引入bus


export default {
  name: "HeaderNav",
  components: {
    NavItemHot,
    NavItemSolarTerms,
    NavItemPPT,
    NavItem3D,
    NacItemPicPackage,
    NavItemMore,
    NavItemBusiness,
    NavItemFree,
    NavItemStyle,
    NavItemTopic,
    HeaderAllDropDown
  },
  computed: {
    // ...mapState('nav', ['navIndex']),
  },
  watch: {
    clientWidth: {
      handler(n, o) {
        if (n === 'W1440') {
          if (this.navType === COMMON.HEADER.NAV.TYPE.HOME_HEADER) {

            this.navItemList = [
              NAV.TOPIC,
              NAV.PIC_PACKAGE,
              NAV.CALENDAR,
            ]
            this.navItemMore = [
              // NAV.TIMELINE,
              // NAV.INVITE,
              // NAV.PART_TIME,
            ];
          } else {
            this.navItemList = [
              NAV.TOPIC,
              // NAV.PIC_PACKAGE,
              // NAV.CALENDAR,
            ]
            this.navItemMore = [
              // NAV.CALENDAR,
              NAV.PIC_PACKAGE,
              NAV.CALENDAR,
            ]
          }

        } else if (n === 'W1200') {
          if (this.navType === COMMON.HEADER.NAV.TYPE.HOME_HEADER) {
            this.navItemList = [
              // NAV.STYLE,
              // NAV.TOPIC,
              // NAV.PIC_PACKAGE,
              // NAV.CALENDAR,
              NAV.TOPIC,
              NAV.PIC_PACKAGE,

            ]
            this.navItemMore = [
              // NAV.TOPIC,
              // NAV.PIC_PACKAGE,
              // NAV.CALENDAR,
              NAV.CALENDAR,
            ]
          } else {
            this.navItemList = [
              // NAV.TOPIC,
            ]
            this.navItemMore = [
              // NAV.FREE,
              NAV.TOPIC,
              NAV.PIC_PACKAGE,
              NAV.CALENDAR,
            ]
          }
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      MARKETING_CALENDAR,
      TIMELINE_UPDATE,
      PIC_PACKAGE,
      topbarMenuList: topbarMenuList.slice(),
      MATERIAL_LIST_CUSTOM,
      COMMON,
      // menuNavIndex: 0,  // 菜单导航默认选中
      clientWidth: 0,
      navItemList: [], // 1440默认显示部分导航
      navItemMore: [], // 1200 1440隐藏下拉导航
      isShowNavItemMore: false, // 是否显示更多
      navIndex: -1,
      isShow:false
    }
  },
  props: {
    d: {
      type: String | Number,
      default: 0
    },

    navType: {
      type: String,
      default: COMMON.HEADER.NAV.TYPE.OTHER
    },
    picList:[],
    festivalList:[],
    solarTermsList:[],
    recommendList:[],
    threeDCategoryList: [],
    threeDRecommendList: [],
    festivalData:{},
    designStyle:{},
    styleCategoryList:[],
    styleRecommendList:[],
    isFixedHeader:false
  },
  mounted() {
    this.initClientWidth();
    window.addEventListener('resize', () => this.initClientWidth(), false)
    /*let navIndex = fnLocalStorage.getItem('updateNavId')
    console.log(navIndex)
    console.log(!navIndex)*/
    // this.navIndex = !navIndex ? -1 : navIndex;

   /* if (navIndex == undefined) {
      this.navIndex = -1;
    } else {
      this.navIndex = navIndex
    }
    console.log(this.navIndex)*/

    /* 导航栏勾选 */

    let currentRoutePath = this.$route.path;
    if (currentRoutePath.indexOf('/tupian/') > -1 && currentRoutePath.indexOf('.html') > -1) {
      console.log('tupian list page')

      let tupianRouterId = this.$route.params.id;
      let tempParams = parseListUrl(this.$route.path, tupianRouterId)
      if (tempParams.categoryType == 0) {
        this.updateNavId(tempParams.home_keyword_id)
      } else {
        this.updateNavId(NAV.STYLE.id)
      }
    } else {
      if(currentRoutePath == '/tupian'){
        this.updateNavId(0)
      }
      if(currentRoutePath == '/calendar'){
        this.updateNavId(NAV.CALENDAR.id)
      }

      if(currentRoutePath.indexOf('pic-package') >-1){
        this.updateNavId(NAV.PIC_PACKAGE.id)
      }

      if(currentRoutePath.indexOf('/topic') >-1){
        this.updateNavId(NAV.TOPIC.id)
      }

      if(currentRoutePath.indexOf('/timeline-update') >-1){
        this.updateNavId(NAV.TIMELINE.id)
      }
    }

  },

  destroyed() {
    window.onresize = null;
  },

  methods: {

    initClientWidth() {
      let clientWidth = document.body.clientWidth


      if (clientWidth > 1980) {
        this.clientWidth = 'W1440'
      } else if (clientWidth > 1440 && clientWidth <= 1980) {
        this.clientWidth = 'W1440'
      } else if (clientWidth >= 0 && clientWidth <= 1440) {
        this.clientWidth = 'W1200'
      }
    },
    navigateToList(vo) {

      let pingyin = 0;
      if (this.$route.path.indexOf('/tupian') > -1 && this.$route.path.indexOf('.html') > -1) {
        if (this.$route.path.indexOf('/detail') > -1) {
          pingyin = 0;
        } else {
          let tempParams = parseUrlStr(this.$route.params.id);
          pingyin = tempParams.pinyin;
        }
      }

      const {id} = vo;
      let path
      if (id == 99) {
        path = `${this.MATERIAL_LIST_CUSTOM}/0-0-0-0-6-0-default-1-0.html`;
      } else {
        // path = `${this.MATERIAL_LIST_CUSTOM}/${pingyin}-${id}-0-0-0-0-default-1.html`;
        path = `${this.MATERIAL_LIST_CUSTOM}/0-${id}-0-0-0-0-default-1-0.html`;
      }
      // this.listenStatistics(vo.id)
      window.open(path, '_blank');
    },
    navigateTo(vo) {

      if (vo.id == 95) {
        fnInviteFriend.inviteFriendEntry(16)
        return
      }



      let path = vo.path || vo.link;
      window.open(path, '_blank');
    },

    /* 流量入口监听 */
    listenStatistics(id) {


      if (id == 0) {
        fnFlowStatistics.pageFlow('hot')
      } else if (id == 2) {
        fnFlowStatistics.pageFlow('festival')
      } else if (id == 3) {
        // fnFlowStatistics.pageFlow('solarTerms')
      } else if (id == 4) {
        fnFlowStatistics.pageFlow('3D')
      }else if (id == NAV.BUSINESS.id) {
        fnFlowStatistics.pageFlow('eBusiness')
      }else if (id == NAV.FREE.id) {
        fnFlowStatistics.pageFlow('freeZone')
      }else if (id == NAV.STYLE.id) {
        fnFlowStatistics.pageFlow('style')
      }else if (id == NAV.TOPIC.id) {
        fnFlowStatistics.pageFlow('topic')
      }else if (id == 998) {
        fnFlowStatistics.pageFlow('draft')
      }else if (id == NAV.PPT.id) {
        fnFlowStatistics.pageFlow('ppt')
      }
    },

    updateNavId(id) {
      // fnLocalStorage.setItem('updateNavId', navId)
      // eBusiess 电商,freeZone 免费专区,style 设计风格,topic 专题,draft 供稿
      this.navIndex = id
      this.listenStatistics(id);
      /*if (id == 0) {
        fnFlowStatistics.pageFlow('hot')
      } else if (id == 2) {
        fnFlowStatistics.pageFlow('festival')
      } else if (id == 3) {
        // fnFlowStatistics.pageFlow('solarTerms')
      } else if (id == 4) {
        fnFlowStatistics.pageFlow('3D')
      }else if (id == NAV.BUSINESS.id) {
        fnFlowStatistics.pageFlow('eBusiness')
      }else if (id == NAV.FREE.id) {
        fnFlowStatistics.pageFlow('freeZone')
      }else if (id == NAV.STYLE.id) {
        fnFlowStatistics.pageFlow('style')
      }else if (id == NAV.TOPIC.id) {
        fnFlowStatistics.pageFlow('topic')
      }else if (id == 998) {
        fnFlowStatistics.pageFlow('draft')
      }*/

    }
  }
}
</script>

<style lang="scss" scoped>
@import "./common/nav";

.HeaderNav{
  position: relative;
}
.dropbox {
  @include Position(1, 5, -38px, 47px);
}

</style>
